<div fxLayout='column' class="edit-report-panel">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Valutazione
        </mat-label>
    </div>
    <form [formGroup]="reportCopingForm" fxFlex fxFill>
        <mat-form-field appearance="outline" class="report-wrapper">
            <mat-label>Valutazione</mat-label>
            <textarea matInput formControlName="evaluation" required class="report-valutation"></textarea>
            <mat-error *ngIf="!this.reportCopingForm.get('evaluation').hasError('required')">Valutazione non valida
            </mat-error>
            <mat-error *ngIf="this.reportCopingForm.get('evaluation').hasError('required')">Valutazione obbligatoria
            </mat-error>
        </mat-form-field>
    </form>
    <div fxLayout='row-reverse'>
        <button (click)="closeDialog(true)" mat-raised-button color="accent" class="delete-submit-button"
                aria-label="Salva">
            Salva
        </button>
        <button (click)="closeDialog(false)" mat-raised-button color="accent" class="delete-submit-button"
                aria-label="ESCI">
            Annulla
        </button>
    </div>
</div>