<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Report coping
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userNominative}}
        </mat-label>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Dati assistito
        </div>
        <form [formGroup]="userDataForm" class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="surname">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Codice fiscale</mat-label>
                <input matInput formControlName="fiscalCode">
            </mat-form-field>
        </form>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Report coping
        </div>
        <form [formGroup]="reportCopingForm" class="inner-form" fxLayout="column">
            <mat-form-field appearance="outline" class="date-field">
                <mat-label>Data</mat-label>
                <input matInput formControlName="date" type="date" (focusout)="fixDate()" onkeydown="return false" [max]="'9999-12-31'" required>
                <mat-error *ngIf="!this.reportCopingForm.get('date').hasError('required')">Data non valida</mat-error>
                <mat-error *ngIf="this.reportCopingForm.get('date').hasError('required')">Data obbligatoria</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="description">
                <mat-label>Valutazione</mat-label>
                <textarea matInput formControlName="evaluation" required></textarea>
                <mat-error *ngIf="!this.reportCopingForm.get('evaluation').hasError('required')">Valutazione non valida</mat-error>
                <mat-error *ngIf="this.reportCopingForm.get('evaluation').hasError('required')">Valutazione obbligatoria</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="panel-buttons-container" fxLayout="row">
        <button mat-raised-button color="accent" class="save-button"
                aria-label="SAVE" (click)="saveReport()">
            SALVA
        </button>
        <button mat-raised-button color="accent" class="cancel-button"
                aria-label="CANCEL" (click)="resetFields()">
            ANNULLA
        </button>
        <button mat-raised-button color="accent" class="back-button"
                aria-label="INDIETRO" (click)="goBack()">
            INDIETRO
        </button>
    </div>
</div>