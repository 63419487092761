import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';

import {AuthenticationService} from '../../services/auth/authentication.service';
import {PASSWORD_REGEX} from '../../common/commons.class';
import {ToastManager} from '../../management/utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector     : 'change-password',
    templateUrl  : './change-password-page.component.html',
    styleUrls    : ['./change-password-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ChangePasswordPageComponent extends ToastManager implements OnInit, OnDestroy
{
    changePasswordForm: FormGroup;
    hide: boolean;
    user;
    loggedUser: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthenticationService,
        private sbar: MatSnackBar
    )
    {
        super(sbar);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void
    {
        this.user = JSON.parse(sessionStorage.getItem('User'));
        this.hide = true;
        this.changePasswordForm = this._formBuilder.group({
            password       : ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator, Validators.pattern(PASSWORD_REGEX)]]
        });
        this.changePasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.changePasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    unDoClick(): void
    {
        this.changePasswordForm.reset();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    changePassword(): void{
        if(this.changePasswordForm.get('password').hasError("pattern")) {
            this.showToast("La password deve essere almeno 8 caratteri, contenere almeno una lette e almeno un numero", 8000);
            return;
        }
        const bodyData = {
            email: this.user.account.email,
            password: this.changePasswordForm.get('password').value,
        }

        this.authService.editPassword(bodyData).subscribe(()=>
            this.router.navigate(['/login-page'])
        )
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
