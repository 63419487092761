import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {DeleteFileDialogComponent} from '../delete-file-dialog/delete-file-dialog.component';
import {AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX, AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX, BASE_REGEX, TEXT_ONLY_REGEX} from '../../../common/commons.class';
import {ExerciseCategory} from 'app/main/class/exercise-category';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ExerciseService} from '../../../services/exercise/exercise.service';
import {FileService} from '../../../services/file/file.service';
import {Endpoints} from '../../../class/endpoints';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {StringUtils} from '../../../common/string-utils';

const UNDO_SET_CODE = 0;
const MODIFY_SET_CODE = 1;
const MAXLENGHT = 60;
const URL_EXERCISE_CODE = 1;

@Component({
    selector: 'app-exercise-dialog',
    templateUrl: './exercise-dialog.component.html',
    styleUrls: ['./exercise-dialog.component.scss'],
    providers: [
        ExerciseCategory
    ]
})
export class ExerciseDialogComponent extends ToastManager implements OnInit {
    public idealMovementType;
    downloadInProgress: boolean;
    modifyPressed = false;
    form: FormGroup;
    loggedUser;
    categories;
    idealMovementURL: any = {
        result: {
            fileName: '',
        },
        fileDownloadURL: ''
    };
    iconData: any = {
        result: {
            fileName: ''
        },
        fileDownloadURL: ''

    };
    videoData: any = {
        result: {
            fileName: ''
        },
        fileDownloadURL: ''

    };
    idealMovementPath;
    iconPath;
    videoPath;
    oldIconData;
    oldVideData;
    oldIdealMovementData;

    // 3 promise
    constructor(
        public dialogRef: MatDialogRef<ExerciseDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data,
        @Inject(DOCUMENT) private _document: Document,
        private _formBuilder: FormBuilder,
        private exerciseCategories: ExerciseCategory,
        private _sBar: MatSnackBar,
        private exerciseService: ExerciseService,
        private fileService: FileService
    ) {
        super(_sBar);
        this.downloadInProgress = false;
        this.form = this._formBuilder.group({
            name: ['', [Validators.maxLength(MAXLENGHT), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX)]],
            category: ['', Validators.required],
            description: ['', [Validators.required, Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
        });
        this.idealMovementType = "excercises/idealMovements/";
    }

    deleteExercise(id): void {
        const bodyData = {
            id: id,
        };
        this.exerciseService.deleteExercise(bodyData).subscribe(() => {
            this._document.defaultView.location.reload();
        });
    }

    openFileRemovalDialog(type) {
        const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
            width: '350px',
            data: {
                type,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            this.deleteFile(result);
        });
    }

    public deleteFile(type): void {
        let bodyData;
        switch (type) {
            case 0:
                bodyData = {
                    name: this.idealMovementURL && this.idealMovementURL.result.fileName
                };
                this.data.exercise.idealMovement = null;
                this.idealMovementPath = null;
                this.idealMovementURL = null;
                break;
            case 1:
                bodyData = {
                    name: this.videoData && this.videoData.result.fileName
                };
                this.videoData = null;
                this.data.exercise.video = null;
                this.videoPath = null;

                break;
            case 2:
                bodyData = {
                    name: this.iconData && this.iconData.result.fileName
                };
                this.data.exercise.icon = null;
                this.iconData = null;
                this.iconPath = null;
                break;
            default:
                break;
        }
    }

    fileChange(event, type): void {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            let uploadedFile: any;
            let blob = file.slice(0, file.size, file.type);
            uploadedFile = new File([blob], Math.floor(Date.now() / 1000) + '.' + file.name.split('.').pop(), {type: file.type});
            const formData: FormData = new FormData();
            formData.append('file', uploadedFile, uploadedFile.name);
            switch (type) {
                case 0:
                    formData.append('fileType', 'excercises/idealMovements');
                    break;
                case 1:
                    formData.append('fileType', 'excercises/videos');
                    break;
                case 2:
                    formData.append('fileType', 'excercises/icons');
                    break;
                default:
                    break;
            }
            formData.append('authUuc', this.loggedUser.uuc);
            this.fileService.uploadFile(formData)
                .subscribe(
                    data => {
                        switch (type) {
                            case 0:
                                if (event.target.files[0].type !== 'application/json') {
                                    this.showToast("Attenzione! File non valido.", StringUtils.ADVISE_TIME);
                                    return;
                                }
                                this.idealMovementURL = data;
                                this.data.exercise.idealMovement = data['fileDownloadURL'];
                                break;
                            case 1:
                                if (event.target.files[0].type !== 'video/mp4' && event.target.files[0].type !== 'video/avi' && event.target.files[0].type !== 'video/mpeg') {
                                    this.showToast("Attenzione! File non valido.", StringUtils.ADVISE_TIME);
                                    return;
                                }
                                this.videoData = data;
                                this.data.exercise.video = data['fileDownloadURL'];
                                break;
                            case 2:
                                if (event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/png') {
                                    this.showToast("Attenzione! File non valido.", StringUtils.ADVISE_TIME);
                                    return;
                                }
                                this.iconData = data;
                                this.data.exercise.icon = data['fileDownloadURL'];
                                break;
                            default:
                                break;
                        }
                    },
                    error => console.log(error)
                );
        }
    }

    modifyExercise(): void {
        this.modifyPressed = true;
        const bodyData = {
            id: this.data.exercise.id,
            name: this.form.get('name').value,
            category: this.form.get('category').value,
            description: this.form.get('description').value,
            idealMovement: this.idealMovementURL && this.idealMovementURL.fileDownloadURL,
            icon: this.iconData && this.iconData.fileDownloadURL,
            video: this.videoData && this.videoData.fileDownloadURL,
            oldIdealMovement: this.oldIdealMovementData.fileDownloadURL,
            oldVideo: this.oldVideData.fileDownloadURL,
            oldIcon: this.oldIconData.fileDownloadURL
        };

        this.exerciseService.updateExercise(bodyData).subscribe(() => {
            this.closeDialog();
            this._document.defaultView.location.reload();
        }, error => {
                this.showToast("Attenzione! Esercizio già presente", StringUtils.ADVISE_TIME);
                this.modifyPressed = false;
        });
    }

    ngOnInit(): void {
        this.categories = this.exerciseCategories.getCategories()

        this.categories.sort((first, second) => (first.toLowerCase() < second.toLowerCase()) ? -1 : (first.toLowerCase() > second.toLowerCase()) ? 1 : 0);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        this.form.patchValue({
            name: this.data.exercise.name,
            category: this.data.exercise.category,
            description: this.data.exercise.description,
        });

        this.idealMovementURL.result.fileName = this.data.exercise.idealMovement && this.getNameByURL(this.data.exercise.idealMovement);
        this.videoData.result.fileName = this.data.exercise.video && this.getNameByURL(this.data.exercise.video);
        this.iconData.result.fileName = this.data.exercise.icon && this.getNameByURL(this.data.exercise.icon);
        this.idealMovementURL.fileDownloadURL = this.data.exercise.idealMovement;
        this.oldIdealMovementData = this.idealMovementURL;
        this.oldIconData = this.iconData;
        this.oldVideData = this.videoData;

        this.videoData.fileDownloadURL = this.data.exercise.video && this.data.exercise.video;
        this.iconData.fileDownloadURL = this.data.exercise.icon;

        this.data.view === 0 && this.form.disable();
    }

    unDoClick(): void {
        this.data.exercise.idealMovement = this.oldIdealMovementData && this.oldIdealMovementData.fileDownloadURL;
        this.data.exercise.video = this.oldIconData && this.oldVideData.fileDownloadURL;
        this.data.exercise.icon = this.oldVideData && this.oldIconData.fileDownloadURL;

        this.closeDialog();
    }

    getDownloadFileUrl(fileName: string) {
        const url = Endpoints.DUEVITAB_DOWNLOAD_FILE_API + '/' + fileName;
        return url;
    }

    saveData(file, fileName) {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('display', 'none');
        let url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    downloadFile(url, type): void {
        this.downloadInProgress = true;
        this.fileService.downloadFile(this.getDownloadFileUrl(url + "/file")).subscribe(result => {
            let toSaveFile = result;
            this.saveData(toSaveFile, this.form.get('name').value + "_" + type);
        }, error => {
            console.log(error)
        }, () => {
            this.downloadInProgress = false;
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getNameByURL(string: string): string {
        return string.split('/')[7];
    }

}
