import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GetExerciseByCategoryResponse} from '../../models/get-exercise-by-category-response';

import {map} from 'rxjs/operators';
import {GetAllExercisesResponse} from '../../models/get-all-exercises-response';
import {GetAllExercisesToRateResponse} from '../../models/get-all-exercises-to-rate-response';
import {log} from 'util';
import {GetExerciseExecutionDetailsResponse} from '../../models/get-exercise-execution-details-response';
import {GetBothMovementsResponse} from '../../models/get-both-movements-response';
import {Endpoints} from '../../class/endpoints';
import { GetElabMovementsResponse } from 'app/main/models/get-elab-movements-response';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  constructor(private http: HttpClient,
              ) { }

  public getExerciseByCategory(category: string): Observable<GetExerciseByCategoryResponse>{
      return this.http.get<GetExerciseByCategoryResponse>(
          Endpoints.DUEVITAB_GET_EXERCISES_BY_CATEGORY_API,
          {
              headers: new HttpHeaders({
                  'category': category
              })
          }
      ).pipe(map(getExerciseByCategory => {
          return getExerciseByCategory;
      }));
  }

  public getAllExercises(): Observable<GetAllExercisesResponse>{
      return this.http.get<GetAllExercisesResponse>(
          Endpoints.DUEVITAB_EXERCISES_API
      ).pipe(map(getAllExercises => {
          return getAllExercises;
      }));
  }

  public deleteExercise(body: any): Observable<any>{
      return this.http.request<any>("DELETE",
          Endpoints.DUEVITAB_EXERCISES_API,
          {body: body}
      ).pipe(map(deleteExercise => {
          return deleteExercise;
      }));
  }

  public updateExercise(body: any): Observable<any>{
      return this.http.put<any>(
          Endpoints.DUEVITAB_EXERCISES_API,
          body
      ).pipe(map(updateExercise => {
          return updateExercise;
      }));
  }

  public insertExercise(body: any): Observable<any>{
      return this.http.post<any>(
          Endpoints.DUEVITAB_EXERCISES_API,
          body
      ).pipe(map(insertExercise => {
          return insertExercise;
      }));
  }

  public insertExerciseExecutionRating(body: any): Observable<any>{
      return this.http.post<any>(
          Endpoints.DUEVITAB_GET_EXERCISE_RATE_API,
          body
      ).pipe(map(insertExerciseExecutionRating => {
          return insertExerciseExecutionRating;
      }));
  }

  public getExerciseExecutionRating(uuc: string): Observable<GetAllExercisesToRateResponse>{
      return this.http.get<GetAllExercisesToRateResponse>(
          Endpoints.DUEVITAB_GET_EXERCISE_RATE_API,
          {
              headers: new HttpHeaders({
                  'physiotherapistId': uuc
              })
          }
      ).pipe(map(getExerciseExecutionRating => {
          return getExerciseExecutionRating;
      }));
  }

  public getExerciseExecutionDetails(executionId: string): Observable<GetExerciseExecutionDetailsResponse>{
      return this.http.get<GetExerciseExecutionDetailsResponse>(
          Endpoints.DUEVITAB_GET_EXERCISE_EXECUTION_DETAILS_API,
          {
              headers: new HttpHeaders({
                  'execution-id': executionId
              })
          }
      ).pipe(map(getExerciseExecutionDetails => {
          return getExerciseExecutionDetails;
      }));
  }

  public getExecutionMovement(movementId: string): Observable<GetBothMovementsResponse>{
      return this.http.get<GetBothMovementsResponse>(
          Endpoints.DUEVITAB_GET_EXECUTION_MOVEMENT_API,
          {
              headers: new HttpHeaders({
                  'id': movementId
              })
          }
      ).pipe(map(getExecutionMovement => {
          return getExecutionMovement;
      }));
  }

  public getElaboratedMovements(movementId: string): Observable<GetElabMovementsResponse>{
    return this.http.get<GetElabMovementsResponse>(
        Endpoints.DUEVITAB_GET_ELAB_EXECUTION_MOVEMENT_API,
        {
            headers: new HttpHeaders({
                'id': movementId
            })
        }
    ).pipe(map(getElabMovements => {
        return getElabMovements;
    }));
}
}
