<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Storico PRI
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userSurname + ' ' + userName}}
        </mat-label>
    </div>
    <div class="border-container" fxLayout='column'>
        <div class="h2 container-title">
            Filtri di ricerca
        </div>
        <div class="resizable filter-container" fxLayout="row" fxLayoutAlign="space-between center"
             fxFlex="1 0 auto" [formGroup]="priFilterForm">
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Nome progetto</mat-label>
                <input formControlName="priName" matInput>
                <mat-error *ngIf="this.priFilterForm.get('priName').hasError('required')">Nome progetto obbligatorio
                </mat-error>
                <mat-error *ngIf="!this.priFilterForm.get('priName').hasError('required')">Nome progetto non valido
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Stato</mat-label>
                <mat-select formControlName="status">
                    <mat-option value="Attivo">Attivo</mat-option>
                    <mat-option value="Sospeso">Sospeso</mat-option>
                    <mat-option value="Terminato">Terminato</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center">
            <div class='buttonDiv'>
                <button (click)='resetFilters()' class='cancel-button' mat-raised-button color="accent"
                        aria-label="RESET MY PASSWORD">
                    Annulla
                </button>
                <button (click)='filterButton()' class='submitButton' mat-raised-button color="accent"
                        aria-label="RESET MY PASSWORD">
                    Filtra
                </button>
            </div>
        </div>
    </div>
    <div class="border-container" fxLayout='column'>
        <div class="h2 container-title">
            Progetti Riabilitativi Individuali
        </div>
        <div class='mat-elevation-z1 tableResponsive margin-table'>
            <table mat-table [dataSource]="priHistoryDataSource" matSort matSortDisableClear="true"
                   class="mat-elevation-z1 tableStyle"
                   (matSortChange)="sortdata($event, priHistoryDataSource)">
                <ng-container matColumnDef="priName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="priName" class="columns"> Nome
                    </th>
                    <td mat-cell isEllipsisActive *matCellDef="let pri"
                        class="columns"> {{pri.priname}} </td>
                </ng-container>
                <ng-container matColumnDef="physiatrist">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="physiatrist" class="columns"> Fisiatra</th>
                    <td mat-cell isEllipsisActive *matCellDef="let pri; let i = index"
                        class="columns"> {{pri.physiatristNominative}} </td>
                </ng-container>
                <ng-container matColumnDef="beginDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate" class="date-columns">
                        Data assegnazione
                    </th>
                    <td mat-cell *matCellDef="let pri"
                        class="date-columns"> {{this.datePipe.transform(pri.beginDate, "dd/MM/yyyy")}} </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate" class="date-columns"> Data
                        dimissione
                    </th>
                    <td mat-cell *matCellDef="let pri"
                        class="date-columns"> {{this.datePipe.transform(pri.endDate, "dd/MM/yyyy")}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="date-columns"> Stato</th>
                    <td mat-cell isEllipsisActive *matCellDef="let pri; let i = index"
                        class="date-columns"> {{pri.status}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                    <td mat-cell *matCellDef="let pri" class="column-action">
                        <mat-icon class="clickable-icon" matTooltip="Visualizza progetto riabilitativo {{pri.priname}}"
                                  matTooltipPosition="above" [matTooltipShowDelay]="500"
                                  matTooltipClass="custom-tooltip"
                                  (click)="showPRIInfo(pri)">info
                        </mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="priHistoryColumnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: priHistoryColumnNames;"></tr>

            </table>
            <div *ngIf="priHistoryDataSource !== undefined && priHistoryDataSource.data.length === 0"
                 class="no-record mat-elevation-z1"><label>Nessun pri trovato per l'utente</label></div>
            <div
                *ngIf="priHistoryDataSource !== undefined && priHistoryDataSource.data.length !== 0 && priHistoryDataSource.filteredData.length === 0"
                class="no-record mat-elevation-z1"><label>Nessun pri trovato corrispondente ai criteri di
                ricerca inseriti</label></div>
            <mat-paginator #priPaginator [pageSizeOptions]="[5, 10, 20,50,100,200]"
                           showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div class="bottom-buttons" fxLayout="row">
        <button mat-raised-button color="accent" class="back-button"
                aria-label="BACK" routerLink="/list-assisted-users">
            INDIETRO
        </button>
    </div>
</div>