<div fxLayout='column' class="play-video-dialog">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Esecuzione esercizio
        </mat-label>
    </div>
    <div fxFill fxLayout="row" class="video-container" fxLayoutAlign="center">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#fff" type="ball-atom" [fullScreen]="false"
        ></ngx-spinner>
        <video [ngClass]="handVideo ? 'video-execution-mirror' : 'video-execution'" controls>
            <source *ngIf="videoSource" [src]="videoSource" type='video/mp4'/>
        </video>
    </div>
    <div fxLayout='row-reverse' class="buttons-container">
        <button (click)="closeDialog()" mat-raised-button color="accent" class="delete-submit-button"
                aria-label="ESCI">
            Esci
        </button>
    </div>
</div>