<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div>
                <img class="logo" src="../../../../assets/addedAsset/Icon/logo.png" alt="logo">
                <form name="loginForm" [formGroup]="loginForm" novalidate>
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="email">
                        <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                        <mat-error *ngIf="loginForm.get('email').hasError('required')">
                            Username obbligatorio
                        </mat-error>
                        <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('pattern')">
                            Username non valido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error>
                            Password obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                        fxLayoutAlign="space-between center">
                        <a class="forgot-password" [routerLink]="'/reset-password-page-form'">
                            Reset password
                        </a>
                    </div>
                </form>
                <div class="submit_div" fxLayout='row'>
                    <button mat-raised-button color="accent" (click)="login()" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid">
                        ACCEDI
                    </button>
                    <button mat-raised-button color="accent" (click)="unDoClick()" class="submit-button"
                        aria-label="UNDO">
                        ANNULLA
                    </button>
                </div>
            </div>
        </div>
    </div>