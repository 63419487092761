import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {EMAIL_REGEX} from "../../common/commons.class";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastManager} from '../../management/utils/toast-manager';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {StringUtils} from '../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-reset-password-find',
    templateUrl: './request-reset-password-page.component.html',
    styleUrls: ['./request-reset-password-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class RequestResetPasswordPageComponent extends ToastManager implements OnInit {
    resetPasswordForm: FormGroup;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private _sBar: MatSnackBar
    ) {
        super(_sBar);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
    }

    unDoClick(): void {
        this.resetPasswordForm.reset();
    }

    sendRequest(): void{
        const bodyData = {
            username: this.resetPasswordForm.get('email').value,
        };
        this.spinner.show();
        this.authService.requestResetEmail(bodyData).subscribe((data) => {
            this.spinner.hide();
                if (data != null && data.success){
                    this.showToast('Abbiamo inviato una mail a ' + this.resetPasswordForm.get('email').value + ' con le istruzioni necessarie per continuare il reset della password' , StringUtils.ADVISE_TIME);
                }else {
                    this.showToast('Attenzione! Email non presente', StringUtils.ADVISE_TIME);
                }
            },
            error=>{
                this.spinner.hide();
                this.showToast('Attenzione! Email non presente', StringUtils.ADVISE_TIME);
            }
        );
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


