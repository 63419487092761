<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Dashboard
        </mat-label>
    </div>

    <mat-grid-list cols="2" rowHeight="1:1" *ngIf="!this.lroStatsVisible">
        <mat-grid-tile>
            <ngx-charts-gauge [scheme]="colorSchemeUsers" [results]="resultsUsers" [legend]="legendUsers"
                [legendTitle]="legendUsersTitle" [angleSpan]="240" [startAngle]="-120" [min]="0"
                [max]="maxGaugeValueUsers" [smallSegments]="0" [bigSegments]="10" [showText]="true" [showAxis]="true"
                [units]="'utenti totali'">
            </ngx-charts-gauge>
        </mat-grid-tile>
        <mat-grid-tile>
            <ngx-charts-gauge [scheme]="colorSchemeAssisted" [results]="resultsAssisted" [legend]="legendAssisted"
                [legendTitle]="legendAssistedTitle" [angleSpan]="240" [startAngle]="-120" [min]="0"
                [max]="maxGaugeValueAssisted" [smallSegments]="0" [bigSegments]="10" [showText]="true" [showAxis]="true"
                [units]="'assistiti totali'">
            </ngx-charts-gauge>
        </mat-grid-tile>
    </mat-grid-list>

    <div class="border-container" *ngIf="this.lroStatsVisible">
        <div class="h2 div-section-title">
            Assistiti arruolati
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="border-container" fxFlex="69">
                <div class="h2 div-section-title">
                    Elenco
                </div>
                <div class='mat-elevation-z1 tableResponsive padded-table'>
                    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true"
                        class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event)">
                        <ng-container matColumnDef="surname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="surname" class="column-header">
                                Cognome
                            </th>
                            <td mat-cell #surname isEllipsisActive *matCellDef="let user; let i = index"
                                isEllipsisActive class="column" (click)="userClicked(user)"> {{user.userData.surname}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-header"> Nome
                            </th>
                            <td mat-cell #name isEllipsisActive *matCellDef="let user" isEllipsisActive class="column"
                                (click)="userClicked(user)"> {{user.userData.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fiscalCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="fiscalCode" class="column-header">
                                Codice fiscale
                            </th>
                            <td mat-cell *matCellDef="let user" class="column" (click)="userClicked(user)">
                                {{user.userData.fiscalCode}} </td>
                        </ng-container>
                        <ng-container matColumnDef="beginDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate" class="column-header">
                                Data arruolamento
                            </th>
                            <td mat-cell *matCellDef="let user" class="column" (click)="userClicked(user)">
                                {{this.datePipe.transform(user.enrolDate, 'dd/MM/yyyy')}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <div *ngIf="dataSource === undefined || dataSource.data.length === 0"
                        class="no-record mat-elevation-z1"><label>Nessun assistito arruolato</label></div>
                    <div *ngIf="dataSource !== undefined && dataSource.data.length !== 0 &&
                     dataSource.filteredData.length === 0" class="no-record mat-elevation-z1"><label>Nessun assistito
                            trovato corrispondente ai criteri di
                            ricerca inseriti</label></div>
                    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
            <div class="border-container" fxFlex="29">
                <div class="h2 div-section-title">
                    Filtra
                </div>
                <div fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="40" class="search-field">
                        <mat-label>Cognome</mat-label>
                        <input matInput [formControl]="this.surnameFilter">
                        <mat-error *ngIf="this.surnameFilter.hasError('pattern')">Cognome non valido
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="40" class="search-field">
                        <mat-label>Nome</mat-label>
                        <input matInput [formControl]="nameFilter">
                        <mat-error *ngIf="this.nameFilter.hasError('pattern')">Nome non valido
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="40" class="search-field">
                        <mat-label>Codice fiscale</mat-label>
                        <input matInput [formControl]="fiscalCodeFilter">
                        <mat-error *ngIf="this.fiscalCodeFilter.hasError('pattern')">Codice fiscale non valido
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="panel-buttons-container inner" fxLayout="row">
                    <button mat-raised-button color="accent" class="save-button" aria-label="CERCA"
                        (click)="filterButton()">
                        CERCA
                    </button>
                    <button mat-raised-button color="accent" class="cancel-button" aria-label="ANNULLA"
                        (click)="resetFilters()">
                        ANNULLA
                    </button>
                </div>
                <div></div>
            </div>
        </div>
    </div>
    <div *ngIf="lroStatsVisible && selectedUser !== undefined && selectedUser !== null && this.selectedPRM !== null"
        class="border-container">
        <div class="h2 div-section-title">
            Dashboard: {{selectedUser.userData.surname}} {{selectedUser.userData.name}}
        </div>

        <div class="border-container">
            <div class="h2 div-section-title">
                Livello Raggiungimento Obiettivo
            </div>
            <div class="graph-holder">
                <canvas baseChart [datasets]="lroChartData" [labels]="lroChartLabels" [options]="lroChartOptions"
                    [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
            </div>
        </div>

        <div class="border-container">
            <div class="h2 div-section-title">
                Progetto di Riabilitazione Motoria
            </div>
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="48" fxLayout="column" fxFlexAlign="space-between center">
                        <mat-label fxFlex="20">Avanzamento</mat-label>
                        <div class="efficacy-bar-wrapper" fxLayoutAlign="space-between center" fxLayout="row"
                            fxFlex="20">
                            <mat-progress-bar fxFlex="87" color="accent" [mode]="'determinate'"
                                [value]="this.exerciseExecutionPercentage">
                            </mat-progress-bar>
                            <label class="percentage" fxFlex="10">{{this.exerciseExecutionPercentage}}%</label>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" fxFlex="48">
                        <mat-label>Durata media esercizi</mat-label>
                        <input matInput [formControl]="meanWorkingTimeForm">
                    </mat-form-field>
                </div>
                <div fxLayout="row" class="label-divider">
                    <label class="label">Valutazione media di {{this.executedExercises}} esercizi</label>
                </div>
                <div fxLayout="row" fxFlexAlign="space-between center">
                    <div fxLayout="column" fxFlex="33">
                        <mat-label fxFlex="30" class="rating-label">Assistito</mat-label>
                        <div fxFlex="70" class="rating-label">
                            <mat-star-rating class="star-rating" [rating]="this.meanAssistedEvaluation" [starCount]="5"
                                [disabled]="true" color="accent"></mat-star-rating>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33">
                        <mat-label fxFlex="30" class="rating-label">Fisioterapista</mat-label>
                        <div fxFlex="70" class="rating-label">
                            <mat-star-rating class="star-rating" [rating]="this.meanPhysiotherapistEvaluation"
                                [starCount]="5" [disabled]="true" color="accent"></mat-star-rating>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33">
                        <mat-label fxFlex="30" class="rating-label">Efficacia</mat-label>
                        <div fxFlex="70" class="rating-label">
                            <mat-star-rating class="star-rating" [rating]="this.meanEfficacy" [starCount]="5"
                                [disabled]="true" color="accent"></mat-star-rating>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="label-divider">
                    <label class="label">Dettaglio esercizio</label>
                </div>
                <div fxLayout="row" class="execution-detail-wrapper">
                    <mat-form-field appearance="outline" class="exercise-select">
                        <mat-label>Nome</mat-label>
                        <mat-select [formControl]="exerciseExecutionFormControl" required>
                            <mat-option *ngFor="let exercise of this.selectedPRM.exerciseExecutions" [value]="exercise">
                                {{exercise.name}} ({{this.datePipe.transform(exercise.scheduledDate, "dd/MM/yyyy")}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="accent" class="details-button" aria-label="DETAIL"
                        (click)="executionDetails(exerciseExecutionFormControl.value)">
                        DETTAGLIO
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="lroStatsVisible && this.selectedPRM === null" class="no-record mat-elevation-z1">
        Nessun PRM attivo per l'assistito selezionato.
    </div>
</div>