<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Lista assistiti
        </mat-label>
    </div>
    <div class="filterDiv" fxLayout='column'>
        <div class="h2 filterTitle">
            Ricerca Assistiti</div>
        <div class="resizable" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Cognome</mat-label>
                <input [formControl]="surnameFilter" matInput>
                <mat-error>Campo non popolato correttamente</mat-error>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32" class="padding-left-four">
                <mat-label>Nome</mat-label>
                <input [formControl]="nameFilter" matInput>
                <mat-error>Campo non popolato correttamente</mat-error>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32" class="padding-left-four">
                <mat-label>Codice fiscale</mat-label>
                <input [formControl]="fiscalCodeFilter" oninput="this.value = this.value.toUpperCase()" matInput>
                <mat-error>Campo non popolato correttamente</mat-error>
                <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center">
            <div class='buttonDiv'>
                <button (click)='filterButton()' class='submitButton' mat-raised-button color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="!getFormValidation()">
                    Filtra
                </button>
                <button (click)='resetFilters()' class='submitButton' mat-raised-button color="accent"
                        aria-label="RESET MY PASSWORD">
                    Annulla
                </button>
            </div>
        </div>
    </div>
    <div class="filterDiv">
        <div class="h2 filterTitle">Assistiti</div>
        <div class='mat-elevation-z1 tableResponsive padded-table'>
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true"
                   class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event)">
                <ng-container matColumnDef="surname" class="column-surname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="surname"> Cognome</th>
                    <td mat-cell #surname isEllipsisActive *matCellDef="let user; let i = index" class="surname-cell" isEllipsisActive> {{user.surname}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-name"> Nome </th>
                    <td mat-cell #name isEllipsisActive *matCellDef="let user" isEllipsisActive> {{user.name}} </td>
                </ng-container>
                <ng-container matColumnDef="fiscalCode">
                    <th mat-header-cell *matHeaderCellDef class="column-fiscalcode" mat-sort-header="fiscalCode"> Codice fiscale
                    </th>
                    <td mat-cell *matCellDef="let user" class="column-fiscalcode"> {{user.fiscalCode}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni </th>
                    <td mat-cell *matCellDef="let user" class="column-action-content">
                        <div>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Visualizza scheda assistito {{user.surname}} {{user.name}} "
                                      (click)="showAssistedUserInfo(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip" svgIcon="userInfo"
                                *ngIf="loggedUser.role === 'Fisioterapista' || loggedUser.role === 'Fisiatra' ||
                                loggedUser.role === 'Psicologo' ||
                                loggedUser.role === 'Amministratore'"></mat-icon>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Visualizza PRI assistito {{user.surname}} {{user.name}} "
                                      (click)="showAssistedUserPRI(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip" svgIcon="priIcon"
                                *ngIf="loggedUser.role === 'Fisioterapista' || loggedUser.role === 'Fisiatra' ||
                                loggedUser.role === 'Psicologo' ||
                                loggedUser.role === 'Amministratore'"></mat-icon>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Visualizza storico PRI assistito {{user.surname}} {{user.name}} "
                                      (click)="showAssistedUserPRIHistory(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip" svgIcon="priHistory"
                                *ngIf="loggedUser.role === 'Fisioterapista' || loggedUser.role === 'Fisiatra' ||
                                loggedUser.role === 'Psicologo' ||
                                loggedUser.role === 'Amministratore'"></mat-icon>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Follow-up dell'assistito {{user.surname}} {{user.name}} "
                                      (click)="beginFollowUp(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip" svgIcon="followUpIcon"
                                *ngIf="loggedUser.role === 'Fisiatra' ||
                                loggedUser.role === 'Amministratore'"></mat-icon>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Report coping {{user.surname}} {{user.name}}"
                                      (click)="addReportCoping(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip" svgIcon="reportCopingIcon"
                                      *ngIf="loggedUser.role === 'Psicologo' ||
                                      loggedUser.role === 'Amministratore'"></mat-icon>
                            <mat-icon class="clickable-icon icon-margin"
                                      matTooltip="Concludi PRI assistito {{user.surname}} {{user.name}} "
                                      (click)="endPRI(user)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip"
                                      *ngIf="loggedUser.role === 'Fisiatra' ||
                                loggedUser.role === 'Amministratore'">exit_to_app</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <div *ngIf="dataSource !== undefined && dataSource.data.length === 0 && dataSource.filteredData.length === 0" class="no-record mat-elevation-z1"><label>Nessun assistito presente</label></div>
            <div *ngIf="dataSource !== undefined && dataSource.data.length !== 0 && dataSource.filteredData.length === 0" class="no-record mat-elevation-z1"><label>Nessun assistito trovato corrispondente ai criteri di ricerca inseriti</label></div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons ></mat-paginator>
        </div>
    </div>
</div>