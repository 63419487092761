<div fxLayout='column' class="add-exercise-valutation-dialog">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Valutazione esercizi
        </mat-label>
    </div>
    <div fxFill fxLayout="row" class="form-container" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="video-container" fxFlex="49">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#fff" type="ball-atom" [fullScreen]="false"
            ></ngx-spinner>
            <video [ngClass]="handVideo ? 'video-execution-mirror' : 'video-execution'" fxFill controls>
                <source *ngIf="videoURL" [src]="videoURL" type='video/mp4'/>
            </video>
        </div>
        <div fxFlex="49" fxLayout="column" class="wrapper">
            <div class="star-container">
                <mat-star-rating [rating]="rating" [starCount]="5" [color]="starColor"
                                 (ratingUpdated)="onRatingChanged($event)">
                </mat-star-rating>
            </div>
            <div class="note-container">
                <mat-form-field appearance="outline" class="note-text-area">
                    <mat-label>Note</mat-label>
                    <textarea matInput [formControl]="ratingNotes" class="note"></textarea>
                </mat-form-field>
            </div>
            <div fxLayout='row-reverse' class="buttons-container">
                <button (click)="closeDialog(false)" mat-raised-button color="accent" class="delete-submit-button"
                        aria-label="ESCI">
                    Annulla
                </button>
                <button (click)="closeDialog(true)" mat-raised-button color="accent" class="delete-submit-button"
                        aria-label="ESCI">
                    Conferma valutazione
                </button>
            </div>
        </div>
    </div>
</div>