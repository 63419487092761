<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"
></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Dettaglio PRI
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userSurname + ' ' + userName}}
        </mat-label>
    </div>

    <mat-tab-group color="accent" mat-align-tabs="center" #tabGroup>
        <!-- ********** Sezione Dati generali ********** -->
        <mat-tab label="Dati generali">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Anagrafica assistito
                </div>
                <form [formGroup]="userDataForm" class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="49">
                        <mat-label>Cognome</mat-label>
                        <input matInput formControlName="surname">
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="49">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name">
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                    </mat-form-field>
                </form>
            </div>
            <div class="border-container">
                <div class="h2 div-section-title">
                    Dati progetto
                </div>
                <form [formGroup]="projectDataForm" class="inner-form" fxLayout="row"
                      fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="56">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-error *ngIf="!this.projectDataForm.get('name').hasError('required')">Nome non valido
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('name').hasError('required')">Nome obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25">
                        <mat-label>Data assegnazione</mat-label>
                        <input matInput formControlName="startDate" type="date" onkeydown="return false" [max]="'9999-12-31'" required>
                        <mat-error *ngIf="!this.projectDataForm.get('startDate').hasError('required')">Data assegnazione
                            non valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('startDate').hasError('required')">Data assegnazione
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="14" disabled>
                        <mat-label>Tempi previsti (giorni)</mat-label>
                        <input matInput formControlName="daysCount" required>
                        <mat-error *ngIf="!this.projectDataForm.get('daysCount').hasError('required')">Tempi previsti
                            non validi
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('daysCount').hasError('required')">Tempi previsti
                            obbligatori
                        </mat-error>
                    </mat-form-field>
                </form>
                <form [formGroup]="projectDataForm" class="inner-form" fxLayout="column">
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Anamnesi</mat-label>
                        <textarea matInput formControlName="anamnesis" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('anamnesis').hasError('required')">Anamnesi non
                            valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('anamnesis').hasError('required')">Anamnesi
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Sintesi</mat-label>
                        <textarea matInput formControlName="synthesis" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('synthesis').hasError('required')">Sintesi non
                            valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('synthesis').hasError('required')">Sintesi
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Obiettivi</mat-label>
                        <textarea matInput formControlName="goals" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('goals').hasError('required')">Obiettivi non
                            valido
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('goals').hasError('required')">Obiettivi
                            obbligatorio
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Diagnosi
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Patologie</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Patologie riscontrate *</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="(filteredDiagnosis === undefined ||
                    filteredDiagnosis === []) && editMode === EDIT_AS_PHYSIATRIST">Inserisci almeno una parola chiave
                    </div>
                    <mat-selection-list #availableDiagnosisPatologies
                                        (selectionChange)="foundDiagnosisPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedAvailableDiagnosisPatology">
                        <mat-list-option *ngFor="let pat of filteredDiagnosis" (click)="changeSwapDirection(true)"
                                         [value]="pat" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(0)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxFlex="45">
                        <mat-selection-list #foundDiagnosisPatologies
                                            (selectionChange)="availableDiagnosisPatologies.deselectAll()"
                                            [ngClass]="diagnosis.found.length!==0 ? 'lists' : 'lists-with-error'"
                                            multiple="false" [(ngModel)]="selectedFoundDiagnosisPatology">
                            <mat-list-option *ngFor="let pat of diagnosis.found" (click)="changeSwapDirection(false)"
                                             [value]="pat" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                                {{pat.name}}
                            </mat-list-option>
                        </mat-selection-list>
                        <mat-label class="custom-error-label"
                                   *ngIf="diagnosis.found.length===0">
                            Patologie riscontrate obbligatorie
                        </mat-label>
                    </div>
                </div>
                <div class="inner-form" fxLayout="row" *ngIf="editMode === EDIT_AS_PHYSIATRIST">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca diagnosi</mat-label>
                        <input matInput [(ngModel)]="filterDiagnosisValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                                (click)="filterPatologies()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Comorbidità
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Patologie</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Patologie riscontrate</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="(filteredComorbidity === undefined ||
                    filteredComorbidity === []) && editMode === EDIT_AS_PHYSIATRIST">Inserisci almeno una parola chiave
                    </div>
                    <mat-selection-list #availableComorbidityPatologies
                                        (selectionChange)="foundComorbidityPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedAvailableComorbidityPatology">
                        <mat-list-option *ngFor="let pat of filteredComorbidity" (click)="changeSwapDirection(true)"
                                         [value]="pat" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(1)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <mat-selection-list #foundComorbidityPatologies
                                        (selectionChange)="availableComorbidityPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedFoundComorbidityPatology">
                        <mat-list-option *ngFor="let pat of comorbidity.found" (click)="changeSwapDirection(false)"
                                         [value]="pat" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="inner-form" fxLayout="row" *ngIf="editMode === EDIT_AS_PHYSIATRIST">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca diagnosi</mat-label>
                        <input matInput [(ngModel)]="filterComorbidityValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                                (click)="filterComorbidity()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Tipo operazione
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Operazioni</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Operazioni subite</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="(filteredProcedures === undefined ||
                    filteredProcedures === []) && editMode === EDIT_AS_PHYSIATRIST">Inserisci almeno una parola chiave
                    </div>
                    <mat-selection-list #availableOperations (selectionChange)="foundOperations.deselectAll()"
                                        class="lists" multiple="false" fxFlex="45"
                                        [(ngModel)]="selectedAvailableOperation">
                        <mat-list-option *ngFor="let op of filteredProcedures" (click)="changeSwapDirection(true)"
                                         [value]="op" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                            {{op.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(2)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <mat-selection-list #foundOperations (selectionChange)="availableOperations.deselectAll()"
                                        class="lists" multiple="false" fxFlex="45" [(ngModel)]="selectedFoundOperation">
                        <mat-list-option *ngFor="let op of procedures.found" (click)="changeSwapDirection(false)"
                                         [value]="op" [disabled]="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST">
                            {{op.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="inner-form" fxLayout="row" *ngIf="editMode === EDIT_AS_PHYSIATRIST">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca procedure</mat-label>
                        <input matInput [(ngModel)]="filterProceduresValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                                (click)="filterProcedures()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>

            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" mat-raised-button color="accent" class="save-button"
                        aria-label="SALVA" (click)="updateData()">
                    SALVA
                </button>
                <button *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" mat-raised-button color="accent" class="cancel-button"
                        aria-label="ANNULLA" (click)="resetData(0)">
                    ANNULLA
                </button>
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Team riabilitativo ********** -->
        <mat-tab label="Team riabilitativo">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Team riabilitativo
                </div>
                <a class="add-text" *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" (click)="openAddUserDialog()">+ aggiungi</a>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="rehabilitationTeamDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, rehabilitationTeamDataSource)">
                        <ng-container matColumnDef="surname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="surname" class="column-team-surname">
                                Cognome
                            </th>
                            <td mat-cell #surname isEllipsisActive *matCellDef="let element"
                                class="column-team-surname"> {{element.surname}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-team-name">
                                Nome
                            </th>
                            <td mat-cell #name isEllipsisActive *matCellDef="let element"
                                class="column-team-name"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Ruolo
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.role}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions" *ngIf="editMode === EDIT_AS_PHYSIATRIST">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let element; let i = index" class="column-action">
                                <div>
                                    <mat-icon
                                        *ngIf="this.editMode === EDIT_AS_PHYSIATRIST && element.role !== this.loggedUser.role && element.role !== 'Psicologo'"
                                        class="clickable-icon"
                                        matTooltip="Elimina {{element.role.toLowerCase() + ' ' + element.surname + ' ' + element.name}}"
                                        matTooltipPosition="above" [matTooltipShowDelay]="500"
                                        matTooltipClass="custom-tooltip"
                                        (click)="openDeleteTeamElementDialog(element, i)">delete
                                    </mat-icon>
                                    <mat-icon
                                        *ngIf="this.editMode === EDIT_AS_PHYSIATRIST && element.role !== this.loggedUser.role && element.role === 'Psicologo'"
                                        class="clickable-icon"
                                        matTooltip="Sostituisci {{element.role.toLowerCase() + ' ' + element.surname + ' ' + element.name}}"
                                        matTooltipPosition="above" [matTooltipShowDelay]="500"
                                        matTooltipClass="custom-tooltip"
                                        (click)="openChangeTeamElementDialog(i)">autorenew
                                    </mat-icon>
                                    <mat-icon
                                        *ngIf="(this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST || element.role === this.loggedUser.role) && element.role !== 'Psicologo'"
                                        class="disabled-icon">
                                        delete
                                    </mat-icon>
                                    <mat-icon
                                        *ngIf="(this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST || element.role === this.loggedUser.role) && element.role === 'Psicologo'"
                                        class="disabled-icon">
                                        autorenew
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="teamDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: teamDisplayedColumns;"></tr>

                    </table>
                    <div
                        *ngIf="rehabilitationTeamDataSource === undefined || rehabilitationTeamDataSource.data.length === 0"
                        class="no-record mat-elevation-z1"><label>Nessun utente inserito</label></div>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" mat-raised-button color="accent" class="cancel-button"
                        aria-label="SALVA" (click)="updateData()">
                    SALVA
                </button>
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Valutazione funzionale ********** -->
        <mat-tab label="Valutazione funzionale">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Valutazione personale
                </div>
                <a class="add-text" *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" (click)="openAddEvaluationDialog()">+ aggiungi</a>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="evaluationsDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, evaluationsDataSource)">
                        <ng-container matColumnDef="acronym">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="acronym" class="column-acronym">
                                Sigla
                            </th>
                            <td mat-cell #acronym isEllipsisActive *matCellDef="let evaluation"
                                class="column-acronym"> {{evaluation.attributeValutation.dimensionValutation.standardValutation.acronym}} </td>
                        </ng-container>
                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="category" class="column-category">
                                Categoria
                            </th>
                            <td mat-cell #category isEllipsisActive *matCellDef="let evaluation"
                                class="column-category"> {{evaluation.attributeValutation.dimensionValutation.description}} </td>
                        </ng-container>
                        <ng-container matColumnDef="attribute">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="attribute"> Attributo
                            </th>
                            <td mat-cell *matCellDef="let evaluation"> {{evaluation.attributeValutation.name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="value"> Valore
                            </th>
                            <td mat-cell *matCellDef="let evaluation">{{evaluation.value}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions" *ngIf="editMode === EDIT_AS_PHYSIATRIST">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let evaluation; let i = index" class="column-action">
                                <div>
                                    <mat-icon *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" class="clickable-icon"
                                              matTooltip="Elimina valutazione funzionale"
                                              matTooltipPosition="above" [matTooltipShowDelay]="500"
                                              matTooltipClass="custom-tooltip" (click)="openDeleteEvaluationDialog(i)">
                                        delete
                                    </mat-icon>
                                    <mat-icon *ngIf="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PSYCHOLOGIST" class="disabled-icon">
                                        delete
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="evaluationsDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: evaluationsDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="evaluationsDataSource === undefined || evaluationsDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessuna valutazione funzionale</label></div>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode === EDIT_AS_PHYSIATRIST" mat-raised-button color="accent" class="cancel-button"
                        aria-label="SALVA" (click)="updateData()">
                    SALVA
                </button>
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Stato di salute ********** -->
        <mat-tab label="Stato di salute">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Parametri vitali
                </div>
                <div class='health-parameters-container' [formGroup]="healthParametersForm">
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa diastolica (mmHg)</mat-label>
                        <input matInput formControlName="diastolicBloodPressure" required>
                        <mat-error
                            *ngIf="!this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">Pressione arteriosa
                            non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">
                            Pressione arteriosa obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa sistolica (mmHg)</mat-label>
                        <input matInput formControlName="systolicBloodPressure" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza cardiaca (bpm)</mat-label>
                        <input matInput formControlName="cardioFrequency" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca
                            non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Ritmo cardiaco</mat-label>
                        <mat-select formControlName="cardioRhythm" required>
                            <mat-option *ngFor="let rythm of availableRythms" [value]="rythm">
                                {{rythm}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco
                            non valido
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco
                            obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza respiratoria</mat-label>
                        <input matInput formControlName="breathFrequency" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria
                            non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Saturazione arteriosa (%)</mat-label>
                        <input matInput formControlName="arterialSaturation" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa obbligatoria
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Follow-up ********** -->
        <mat-tab label="Follow-up">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Follow-up
                </div>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="followUpDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, followUpDataSource)">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Data</th>
                            <td mat-cell #date *matCellDef="let followUp"> {{this.datePipe.transform(followUp.date,"dd/MM/yyyy (HH:mm)")}} </td>
                               
                        </ng-container>
                        <ng-container matColumnDef="lro">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="lro"> LRO</th>
                            <td mat-cell *matCellDef="let followUp"> {{followUp.lro}} </td>
                        </ng-container>
                        <ng-container matColumnDef="editPlan">
                            <th mat-header-cell *matHeaderCellDef> Modifica piano</th>
                            <td mat-cell *matCellDef="let followUp"> {{followUp.editPlan ? 'Si' : 'No'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let followUp" class="column-action">
                                <div>
                                    <mat-icon class="clickable-icon"
                                              matTooltip="Modifica follow-up" matTooltipPosition="above"
                                              [matTooltipShowDelay]="500"
                                              matTooltipClass="custom-tooltip"
                                              (click)="openFollowUpDialog(followUp, false)">info
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="followUpDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: followUpDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="followUpDataSource === undefined || followUpDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessun follow-up presente</label></div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Report coping ********** -->
        <mat-tab label="Report coping">
            <div class="border-container">
                <div class="h2 div-section-title">
                    Report coping
                </div>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="reportCopingDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, reportCopingDataSource)">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Data</th>
                            <td mat-cell #date
                                *matCellDef="let reportCoping"> {{this.datePipe.transform(reportCoping.date, "dd/MM/yyyy")}} </td>
                        </ng-container>
                        <ng-container matColumnDef="valutation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="valutation"> Valutazione</th>
                            <td mat-cell *matCellDef="let reportCoping"> {{reportCoping.valutation}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions" *ngIf="editMode === EDIT_AS_PSYCHOLOGIST">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let reportCoping" class="column-action">
                                <div>
                                    <mat-icon *ngIf="this.editMode === EDIT_AS_PSYCHOLOGIST"
                                              class="clickable-icon"
                                              matTooltip="Modifica report coping" matTooltipPosition="above"
                                              (click)="openEditReportCopingDialog(reportCoping)"
                                              [matTooltipShowDelay]="500"
                                              matTooltipClass="custom-tooltip">edit
                                    </mat-icon>
                                    <mat-icon *ngIf="this.editMode === EDIT_MODE_DISABLED || this.editMode === EDIT_AS_PHYSIATRIST"
                                              class="disabled-icon">edit
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="reportCopingDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: reportCopingDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="reportCopingDataSource === undefined || reportCopingDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessun report coping presente</label></div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>

        <!-- ********** Sezione Piano riabilitativo ********** -->
        <mat-tab label="Piano riabilitativo">
            <div class='grid' [formGroup]="prmDataForm">
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Fisioterapista</mat-label>
                    <input matInput formControlName="physiotherapist">
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Stato</mat-label>
                    <input matInput formControlName="status">
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Data inizio</mat-label>
                    <input matInput formControlName="beginDate">
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Data fine</mat-label>
                    <input matInput formControlName="endDate">
                </mat-form-field>
            </div>
            <div class="border-container" fxLayout="column">
                <div class="h2 div-section-title">
                    Esercizi
                </div>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="exercisesSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, exercisesSource)">
                        <ng-container matColumnDef="execOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="execOrder"> Ordine esecuzione</th>
                            <td mat-cell #execOrder isEllipsisActive
                                *matCellDef="let exercise"> {{exercise.sequence}} </td>
                        </ng-container>
                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="category"> Categoria</th>
                            <td mat-cell #category isEllipsisActive
                                *matCellDef="let exercise; let i = index"> {{exercise.category}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Nome
                            </th>
                            <td mat-cell *matCellDef="let exercise"> {{exercise.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="scheduledDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="scheduledDate"> Data programmata -
                                Ora
                            </th>
                            <td mat-cell
                                *matCellDef="let exercise"> {{this.datePipe.transform(exercise.scheduledDate, "dd/MM/yyyy (HH:mm)")}} </td>
                        </ng-container>
                        <ng-container matColumnDef="workingTime">
                            <th mat-header-cell *matHeaderCellDef> Ripetizioni</th>
                            <td mat-cell *matCellDef="let exercise"> {{exercise.scheduledWorkingTime}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let exercise; let i = index">
                                <div>
                                    <mat-icon
                                        *ngIf="exercise.executionDate !== undefined && exercise.executionDate !== null"
                                        class="clickable-icon"
                                        (click)="openExerciseExecutionInfoPage(exercise)"
                                        matTooltip="Info esecuzione esercizio" matTooltipPosition="above"
                                        [matTooltipShowDelay]="500"
                                        matTooltipClass="custom-tooltip">info
                                    </mat-icon>
                                    <mat-icon
                                        *ngIf="exercise.executionDate === undefined || exercise.executionDate === null"
                                        class="disabled-icon"
                                        matTooltip="Esercizio non ancora eseguito" matTooltipPosition="above"
                                        [matTooltipShowDelay]="500"
                                        matTooltipClass="custom-tooltip">info
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <div *ngIf="exercisesSource === undefined || exercisesSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessun esercizio assegnato all'utente</label></div>
                </div>
            </div>
            <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="this.editMode !== EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button"
                        aria-label="INDIETRO" (click)="goBack()">
                    TORNA ALLA LISTA
                </button>
            </form>
        </mat-tab>
    </mat-tab-group>
    <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
        <button *ngIf="this.editMode === EDIT_MODE_DISABLED" mat-raised-button color="accent" class="back-button" aria-label="INDIETRO" (click)="goBack()">
            TORNA ALLA LISTA
        </button>
    </form>
</div>