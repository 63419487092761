import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {GetExerciseByCategoryResponse} from '../../models/get-exercise-by-category-response';
import {map} from 'rxjs/operators';
import {GetUserPrmResponse} from '../../models/get-user-prm-response';
import {Endpoints} from '../../class/endpoints';

@Injectable({
  providedIn: 'root'
})
export class PrmService {

    constructor(private http: HttpClient,
                ) { }

    public editPRM(requestBody: any): Observable<any>{
        return this.http.put<any>(
            Endpoints.DUEVITAB_PRM_API,
            requestBody
        ).pipe(map(editPRM => {
            return editPRM;
        }));
    }

    public insertPRM(requestBody: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_PRM_API,
            requestBody
        ).pipe(map(insertPRM => {
            return insertPRM;
        }));
    }

    public checkPRM(requestBody: any): Observable<GetUserPrmResponse>{
        return this.http.post<GetUserPrmResponse>(
            Endpoints.DUEVITAB_USER_HAS_ACTIVE_PRM_API,
            requestBody
        ).pipe(map(checkPRM => {
            return checkPRM;
        }));
    }
}
