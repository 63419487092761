import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ToastManager} from '../../utils/toast-manager';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {
    AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX,
    AT_LEAST_ONE_CHARACTER_REGEX,
    BASE_REGEX
} from '../../../common/commons.class';
import {PRIBaseData} from '../../../class/interfaces/PRIBaseData';
import {PriService} from '../../../services/pri/pri.service';
import {PRI} from '../../../class/pri';
import {StringUtils} from '../../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;

@Component({
    selector: 'user-pri-history',
    templateUrl: './user-pri-history-page.component.html',
    styleUrls: ['./user-pri-history-page.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, DatePipe
    ],
})
export class UserPriHistoryPageComponent extends ToastManager implements OnInit, OnDestroy, AfterViewInit {

    sub: any;
    userName: string;
    userSurname: string;
    userUuc: string;
    userFiscalCode: string;
    private loggedUser: any;
    priFilterForm: FormGroup;

    private filterPredicate: {
        priName: string,
        status: string
    };

    @ViewChild('priPaginator') priPaginator: MatPaginator;

    priHistoryDataSource: MatTableDataSource<PRIBaseData>;
    priHistoryList: PRIBaseData[];
    priHistoryColumnNames = ['priName', 'physiatrist', 'beginDate', 'endDate', 'status', 'actions'];

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private priService: PriService,
        private spinner: NgxSpinnerService
    ) {
        super(snackBar);
        this.spinner.show();
        this.priHistoryList = [];
        this.priHistoryDataSource = new MatTableDataSource(this.priHistoryList);
        this.priFilterForm = this._formBuilder.group({
            priName: ['', Validators.pattern(AT_LEAST_ONE_CHARACTER_REGEX)],
            status: ['', Validators.pattern(AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX)]
        });
        this.priFilterForm.setValidators(this.emptyFieldsValidator);
        this.priHistoryDataSource.filterPredicate = this.createFilter();
    }

    ngOnInit(): void {

        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userName = params['userName'];
            this.userSurname = params['userSurname'];
            this.userFiscalCode = params['fCode'];
            this.userUuc = params['uuc'];
            this.initData();
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
    }

    ngAfterViewInit(): void {
        this.priHistoryDataSource.paginator = this.priPaginator;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'priName':
                    return this.compare(a.priname, b.priname, isAsc);
                case 'physiatrist':
                    return this.compare(a.physiatristNominative, b.physiatristNominative, isAsc);
                case 'beginDate':
                    return this.compare(a.beginDate, b.beginDate, isAsc);
                case 'endDate':
                    return this.compare(a.endDate, b.endDate, isAsc);
                case 'status':
                    return this.compare(a.status, b.status, isAsc);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    filterButton(): void {
        if ((this.priFilterForm.get('priName').value === '' && this.priFilterForm.get('status').value === '')
            || this.priFilterForm.get('priName').value === null && this.priFilterForm.get('status').value === null) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
        } else if (this.priFilterForm.get('priName').hasError('pattern') || this.priFilterForm.get('status').hasError('pattern')) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
        } else {
            this.filter();
        }
    }

    filter(): void {

        this.filterPredicate = {
            priName: '',
            status: ''
        };
        this.filterPredicate.priName = String(this.priFilterForm.get('priName').value);
        this.filterPredicate.status = String(this.priFilterForm.get('status').value);
        this.priHistoryDataSource.filterPredicate = this.createFilter();
        this.priHistoryDataSource.filter = JSON.stringify(this.filterPredicate);
    }

    createFilter(): (data: any, filter: string) => boolean {
        // tslint:disable-next-line:only-arrow-functions
        const filterFunction = function(data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return String(data.priname).toLowerCase().indexOf(String(searchTerms.priName.toLowerCase())) !== -1
                && String(data.status).toLowerCase().indexOf(String(searchTerms.status.toLowerCase())) !== -1;
        };
        return filterFunction;
    }

    resetFilters() {
        this.priFilterForm.reset();
        this.priFilterForm.get('priName').setValue('');
        this.priFilterForm.get('status').setValue('');
        this.priHistoryDataSource.filter = '';
    }

    initData() {
        this.priService.getPRIHistory(this.userUuc).subscribe((response) => {
            if (response.success) {
                this.priHistoryList = response.priList;
                this.priHistoryDataSource = new MatTableDataSource<PRIBaseData>(this.priHistoryList);
                this.priHistoryDataSource._updateChangeSubscription();
            } else {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }
            this.spinner.hide();
        }, error => {
            this.spinner.hide();
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    private emptyFieldsValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            if ((group.get('priName').value === '' || group.get('priName').value === null) &&
                (group.get('status').value === '' || group.get('status').value === null)) {
                group.get('status').setErrors({required: true});
                group.get('priName').setErrors({required: true});
            } else {
                group.get('status').setErrors({required: false});
                group.get('priName').setErrors({required: false});
            }
            return;
        };
    }

    showPRIInfo(pri: PRI) {
        this.router.navigate(['/pri-info'], {
            queryParams: {
                surname: this.userSurname,
                name: this.userName,
                fiscalCode: this.userFiscalCode,
                userUUC: this.userUuc,
                priId: pri.id,
                edit: StringUtils.EDIT_MODE_DISABLED,
                origin: StringUtils.PRI_HISTORY_ORIGIN
            }
        });
        return true;
    }
}