<div class="background" fxLayout="row">
    <div fxLayout='column'>
        <div class="header mt-0 delete" fxLayout="row" fxLayoutAlign="center">
            <mat-label class='header_label'>ATTENZIONE!</mat-label>
        </div>
        <div class='Align-center' fxLayout='row'>
            <label class="text-center">Sono presenti esercizi non ancora eseguiti, si desidera procedere ugualmente con la chiusura del PRI?</label>
        </div>
        <div fxLayout='row-reverse'>
            <button (click)='closeDialog()' mat-raised-button color="accent" class="delete-submit-button">
                NO
            </button>
            <button (click)='endPri()' mat-raised-button color="accent" class="delete-submit-button">
                SI
            </button>
        </div>
    </div>
</div>