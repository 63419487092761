import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Users } from '../../../class/users';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FISCAL_CODE_REGEX, NUMBERS_ONLY_REGEX } from '../../../common/commons.class';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import { User } from '../../../class/interfaces/user';

import { ToastManager } from '../../utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExerciseCategory } from '../../../class/exercise-category';
import { PrmExercise } from '../../../class/interfaces/prm-exercise';
import { RemoveAssignedExerciseDialogComponent } from '../remove-assigned-exercise-dialog/remove-assigned-exercise-dialog.component';
import { Prm } from '../../../class/prm';
import { EditPRMDialogComponent } from '../edit-prm-confirm-dialog/edit-prm-confirm-dialog.component';
import { myCustomTooltipDefaults } from '../../../common/custom-tooltip';
import { ExerciseService } from '../../../services/exercise/exercise.service';
import { PrmService } from '../../../services/prm/prm.service';
import { UserService } from '../../../services/users/user.service';
import { PriService } from '../../../services/pri/pri.service';
import * as moment from 'moment';
import { StringUtils } from '../../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-new-prm',
    templateUrl: './insert-new-prm-page.component.html',
    styleUrls: ['./insert-new-prm-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, Users, DatePipe, ExerciseCategory
    ],
})
export class InsertNewPrmPageComponent extends ToastManager implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    loggedUser: any;
    isEditMode: boolean;

    findAssistedForm: FormGroup;
    userDataForm: FormGroup;
    findPressed: boolean = false;
    foundUser: User;
    noUserFound: boolean = false;
    userFound: boolean = false;
    exercisesCategories;
    priDate: Date;

    toSendPRM: Prm;
    insertExerciseForm: FormGroup;
    addExercisePressed: boolean;
    exercisesList: PrmExercise[];
    exercisesSource: MatTableDataSource<PrmExercise>;
    displayedColumns = ['execOrder', 'category', 'name', 'scheduledDate', 'scheduledTime', 'workingTime', 'execDate', 'actions'];

    availableExercises: PrmExercise[];

    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private users: Users,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private categories: ExerciseCategory,
        private snackBar: MatSnackBar,
        private exerciseService: ExerciseService,
        private prmService: PrmService,
        private userService: UserService,
        private priService: PriService,
        private spinner: NgxSpinnerService
    ) {
        super(snackBar);
        this.isEditMode = false;
        this.toSendPRM = new Prm();
        this.toSendPRM.version = 0;
        this.findAssistedForm = this._formBuilder.group({
            fiscalCode: ['', [Validators.pattern(FISCAL_CODE_REGEX), Validators.required]]
        });
        this.userDataForm = this._formBuilder.group({
            surname: { value: '', disabled: true },
            name: { value: '', disabled: true },
            birthDate: { value: '', disabled: true }
        });
        this.insertExerciseForm = this._formBuilder.group({
            category: ['', [Validators.required]],
            name: ['', [Validators.required]],
            scheduledDate: ['', [Validators.required]],
            scheduledTime: ['', [Validators.required]],
            workingTime: ['', [Validators.required, Validators.max(360), Validators.pattern(NUMBERS_ONLY_REGEX)]]
        });
        this.exercisesList = [];
        this.exercisesSource = new MatTableDataSource<any>(this.exercisesList);
    }

    ngOnInit(): void {
        this.exercisesCategories = this.categories.getCategories();
        this.exercisesCategories.sort((first, second) => (first.toLowerCase() < second.toLowerCase()) ? -1 : (first.toLowerCase() > second.toLowerCase()) ? 1 : 0);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
    }

    findAssisted() {
        if (this.findAssistedForm.get('fiscalCode').hasError('required')) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            this.findPressed = false;
            return;
        }
        if (!this.findAssistedForm.valid) {
            this.showToast('Attenzione! Campi non popolati correttamente.', StringUtils.ADVISE_TIME);
            this.findPressed = false;
            return;
        }
        this.refreshUserData();
    }

    refreshUserData() {
        this.findPressed = true;
        this.noUserFound = false;
        this.userFound = false;
        const bodyData = {
            fiscalCode: this.findAssistedForm.get('fiscalCode').value,
        };
        if (!StringUtils.ITALIAN_FISCAL_CODE.test(bodyData.fiscalCode)) {
            this.findPressed = false;
            this.showToast("Codice fiscale non valido", StringUtils.ADVISE_TIME);
            return;
        }
        this.userService.getUserByFiscalCode(bodyData).subscribe(response => {
            if (response.success) {
                this.foundUser = response.user;
                this.foundUser.birthDate = this.getBirthDateByFiscalCode(bodyData.fiscalCode);

                let checkPRIBody = {
                    assistedId: this.foundUser.uuc
                };
                this.priService.checkPRI(checkPRIBody).subscribe(response => {
                    if (response.hasPRI) {
                        this.priDate = response.priDate;
                        let checkPRMBody = {
                            assistedId: this.foundUser.uuc
                        };
                        this.prmService.checkPRM(checkPRMBody).subscribe(response => {
                            if (response.success) {
                                this.isEditMode = true;
                                this.openEditPRMDialog(response.hasActivePRM);
                            } else {
                                this.isEditMode = false;
                                this.userDataForm.get('name').setValue(this.foundUser.name);
                                this.userDataForm.get('surname').setValue(this.foundUser.surname);
                                this.userDataForm.get('birthDate').setValue(this.datePipe.transform(this.foundUser.birthDate, 'yyyy-MM-dd'));
                                this.findPressed = false;
                                this.userFound = true;
                                this.noUserFound = false;
                            }
                            this.findAssistedForm.disable();
                        }, error => {
                            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                            this.noUserFound = true;
                            this.userFound = false;
                            this.findPressed = false;
                        });
                    } else {
                        this.showToast(StringUtils.EMPTY_PRI_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                        this.findPressed = false;
                    }
                }, error => {
                    this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                    this.noUserFound = true;
                    this.userFound = false;
                    this.findPressed = false;
                });
            } else {
                this.noUserFound = true;
                this.userFound = false;
                this.findPressed = false;
            }
        }, error => {
            this.noUserFound = true;
            this.findPressed = false;
            this.userFound = false;
        });

    }

    openEditPRMDialog(activePrm: Prm) {
        for (let exercise of activePrm.exerciseExecutions) {
            exercise.id = null;
        }
        const dialogRef = this.dialog.open(EditPRMDialogComponent, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.userDataForm.get('name').setValue(this.foundUser.name);
                this.userDataForm.get('surname').setValue(this.foundUser.surname);
                this.userDataForm.get('birthDate').setValue(this.datePipe.transform(this.foundUser.birthDate, 'yyyy-MM-dd'));
                this.findPressed = false;
                this.userFound = true;
                this.noUserFound = false;
                this.toSendPRM = activePrm;
                this.exercisesList = activePrm.exerciseExecutions;
                this.exercisesSource = new MatTableDataSource<any>(this.exercisesList);
                this.sort.sort({ id: null, start: 'desc', disableClear: false });
                this.sort.sort({ id: 'execOrder', start: 'desc', disableClear: false });
                this.exercisesSource._updateChangeSubscription();
            } else {
                this.resetForm();
                this.findPressed = false;
            }
        });
    }

    getBirthDateByFiscalCode(fiscalCode: string) {
        let monthMap = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
        let day: string,
            month: string,
            year: string;
        if (fiscalCode !== null) {
            let codeDay: number = Number(fiscalCode[9] + fiscalCode[10]);
            if (codeDay > 35) {
                day = String(codeDay - 40);
            } else {
                day = String(codeDay);
            }
            month = String(monthMap.indexOf(fiscalCode.charAt(8)) + 1);

            let currentYear = String(new Date().getFullYear()).substr(2, 2);
            let codeYear: number = Number(fiscalCode[6] + fiscalCode[7]);
            if (codeYear > Number(currentYear)) {
                year = '19' + codeYear;
            } else {
                year = '20' + codeYear;
            }
            return new Date(year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0'));
        } else {
            return new Date('1900-01-01');
        }
    }

    resetForm() {
        this.findAssistedForm.reset();
        this.noUserFound = false;
        this.userFound = false;
        this.findAssistedForm.enable();
        this.findAssistedForm.get('fiscalCode').setErrors(null);
    }

    orderExercisesByDate() {

        this.exercisesSource.data = this.exercisesSource.data.sort((a, b) => {
            return this.compare(a.scheduledDate, b.scheduledDate, true, true);
        });
        for(let i=0; i<this.exercisesSource.data.length; i++) {
            this.exercisesSource.data[i].sequence = i+1;
        }

    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'execOrder':
                    return this.compare(a.sequence, b.sequence, isAsc);
                case 'category':
                    return this.compare(a.category, b.category, isAsc);
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'scheduledDate':
                    return this.compare(a.scheduledDate, b.scheduledDate, isAsc, true);
                case 'scheduledTime':
                    return this.compare(a.scheduledDate, b.scheduledDate, isAsc, true);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean, isDates: boolean = false) {
        if(isDates) {
            a = a instanceof Date ? a : moment(a).toDate();
            b = b instanceof Date ? b : moment(b).toDate();
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    openDeleteExerciseDialog(exercise: any, index: number) {
        this.resetListErrors();
        const dialogRef = this.dialog.open(RemoveAssignedExerciseDialogComponent, {
            data: {
                name: exercise.name
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.removeAssignedExercise(index);
            }
        });
    }

    removeAssignedExercise(id: number) {
        this.exercisesSource.data.splice(id, 1);
        this.exercisesSource._updateChangeSubscription();
    }

    updateAvailableExercises(category: any) {
        this.exerciseService.getExerciseByCategory(category).subscribe(data => {
            if (data.success) {
                this.availableExercises = data.exercises;
            } else {
                this.showToast('Attenzione! Impossibile caricare gli esercizi', StringUtils.ADVISE_TIME);
            }
        }, error => {
            this.showToast('Attenzione! Impossibile caricare gli esercizi', StringUtils.ADVISE_TIME);
        });
    }

    assignExercise() {
        this.insertExerciseForm.markAllAsTouched();
        if (this.insertExerciseForm.get('category').hasError('required') || this.insertExerciseForm.get('name').hasError('required') ||
            this.insertExerciseForm.get('scheduledDate').hasError('required') || this.insertExerciseForm.get('scheduledTime').hasError('required') || this.insertExerciseForm.get('workingTime').hasError('required')) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.insertExerciseForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        let dateToSchedule = this.insertExerciseForm.get('scheduledDate').value;
        let timeToSchedule = this.insertExerciseForm.get('scheduledTime').value;

        let assignDate: Date = new Date(dateToSchedule + "T" + timeToSchedule);
        if (assignDate < new Date()) {
            this.showToast('Attenzione! E’ necessario programmare l’esercizio inserendo una data successiva o uguale alla data odierna', StringUtils.ADVISE_TIME);
            return;
        }
        if (assignDate < new Date(this.priDate)) {
            this.showToast('Attenzione! E’ necessario programmare l’esercizio inserendo una data successiva o uguale alla data del PRI (' +
                this.datePipe.transform(this.priDate, 'dd/MM/yyyy') + ')', StringUtils.ADVISE_TIME + 2);
            return;
        }
        let toAssignExercise: PrmExercise;
        toAssignExercise = {
            exerciseId: this.insertExerciseForm.get('name').value.id,
            userName: this.foundUser.name,
            userSurname: this.foundUser.surname,
            name: this.insertExerciseForm.get('name').value.name,
            category: this.insertExerciseForm.get('category').value,
            sequence: this.exercisesSource.data.length + 1,
            scheduledDate: moment(assignDate).toDate(),
            scheduledWorkingTime: this.insertExerciseForm.get('workingTime').value,
            physiotherapistId: this.loggedUser.uuc
        };

        if (this.exercisesSource.data.some(exercise => {
            let fixedDate: Date = new Date();
            fixedDate.setTime(new Date(exercise.scheduledDate).getTime());
            if (toAssignExercise.name === exercise.name &&
                toAssignExercise.category === exercise.category &&
                toAssignExercise.scheduledDate.getTime() === fixedDate.getTime()) {
                exercise.hasError = true;
                return true;
            } else {
                exercise.hasError = false;
                return false;
            }
        })) {
            this.showToast('Attenzione! Esercizio già presente', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.exercisesSource.data.some(exercise => {
            let fixedDate: Date = new Date();
            fixedDate.setTime(new Date(exercise.scheduledDate).getTime());
            if (toAssignExercise.scheduledDate.getTime() === fixedDate.getTime()) {
                exercise.hasError = true;
                return true;
            } else {
                exercise.hasError = false;
                return false;
            }
        })) {
            this.showToast('Attenzione! La pianificazione degli esercizi non è valida', StringUtils.ADVISE_TIME);
            return;
        }
        this.exercisesSource.data.push(toAssignExercise);
        this.exercisesSource.data.sort((first, second) => (
            new Date(first.scheduledDate).getTime() < new Date(second.scheduledDate).getTime()) ? -1 :
            (new Date(first.scheduledDate).getTime() > new Date(second.scheduledDate).getTime()) ? 1 : 0);
        for (let i = 0; i < this.exercisesSource.data.length; i++) {
            this.exercisesSource.data[i].sequence = i + 1;
        }
        if (this.sort !== undefined) {
            this.sort.sort({ id: 'execOrder', start: 'desc', disableClear: false });
        }
        this.exercisesSource._updateChangeSubscription();
        this.resetExerciseForm();
    }

    resetExerciseForm() {
        this.insertExerciseForm.reset();
        this.insertExerciseForm.get('category').setValue('');
    }

    resetPRM() {
        this.resetForm();
        this.resetExerciseForm();
        this.exercisesSource.data = [];
        this.resetListErrors();
    }

    checkSchedulingErrors() {
        let auxData = this.exercisesSource.data;
        auxData.sort((first, second) => (first.sequence < second.sequence) ? -1 : (first.sequence > second.sequence) ? 1 : 0);
        let toCheckElement = undefined;
        return auxData.some((exerciseExecution) => {
            let result: boolean = false;
            if (toCheckElement !== undefined) {
                let toCheckDate: Date = new Date(toCheckElement.scheduledDate);
                let currentDate: Date = new Date(exerciseExecution.scheduledDate);
                result = (toCheckDate.getTime() + toCheckElement.scheduledWorkingTime * 60000) > (currentDate.getTime());
                if (result) {
                    toCheckElement.hasError = true;
                    exerciseExecution.hasError = true;
                }
            }
            toCheckElement = exerciseExecution;
            return result;
        });

    }

    resetListErrors() {
        for (let exerciseExecution of this.exercisesSource.data) {
            exerciseExecution.hasError = false;
        }
    }

    savePRM() {
        if (this.exercisesSource.data.length === 0 || this.checkSchedulingErrors()) {
            this.showToast('Attenzione! La pianificazione degli esercizi non è valida', StringUtils.ADVISE_TIME);
            return;
        }
        for (let exercise of this.exercisesSource.data) {
            exercise.assistedId = this.foundUser.uuc;
            let assignDate: Date = new Date(exercise.scheduledDate);
            let executionDate: Date = new Date(exercise.executionDate);
            if (isNaN(new Date(exercise.scheduledDate).getTime())
                || exercise.scheduledWorkingTime === 0 || exercise.scheduledWorkingTime === null) {
                this.showToast('Attenzione! Campi obbligatori non popolati correttamente', StringUtils.ADVISE_TIME);
                exercise.hasError = true;
                return;
            } else if ((executionDate === undefined || executionDate === null || executionDate.getTime() === 0 || exercise.executionDate == null) && assignDate < new Date()) {
                this.showToast('Attenzione! E’ necessario programmare l’esercizio inserendo una data successiva o uguale alla data odierna', StringUtils.ADVISE_TIME);
                exercise.hasError = true;
                return;
            } else if ((executionDate === undefined || executionDate === null || executionDate.getTime() === 0) && assignDate < new Date(this.priDate)) {
                this.showToast('Attenzione! E’ necessario programmare l’esercizio inserendo una data successiva o uguale alla data del PRI (' +
                    this.datePipe.transform(this.priDate, 'dd/MM/yyyy') + ')', StringUtils.ADVISE_TIME + 2);
                exercise.hasError = true;
                return;
            } else {
                exercise.hasError = false;
            }
        }

        this.toSendPRM = {
            version: this.toSendPRM.version++,
            physiotherapistId: this.loggedUser.uuc,
            exerciseExecutions: this.exercisesSource.data,
            assistedId: this.foundUser.uuc
        };
        const body = {
            prm: this.toSendPRM
        };
        if (this.isEditMode) {
            this.spinner.show();
            this.prmService.editPRM(body).subscribe((response) => {
                this.spinner.hide();
                if (response.success) {
                    this.showToast('PRM modificato correttamente', StringUtils.ADVISE_TIME);
                    this.resetPRM();
                    this.router.navigate(['/list-assisted-users']);
                } else {
                    this.showToast('Attenzione! Impossibile modificare il PRM', StringUtils.ADVISE_TIME);
                }
            }, error => {
                this.spinner.hide();
                this.showToast('Attenzione! Impossibile modificare il PRM', StringUtils.ADVISE_TIME);
            });
        } else {
            this.prmService.insertPRM(body).subscribe((response) => {
                if (response.success) {
                    this.showToast('PRM inserito correttamente', StringUtils.ADVISE_TIME);
                    this.resetPRM();
                } else {
                    this.showToast('Attenzione! Impossibile inserire il PRM', StringUtils.ADVISE_TIME);
                }
            }, error => {
                this.showToast('Attenzione! Impossibile inserire il PRM', StringUtils.ADVISE_TIME);
            });
        }

    }

    updateScheduledDate(exercise: PrmExercise, newDate: Date) {
        let time = moment(exercise.scheduledDate).format('HH:mm')
        exercise.scheduledDate = moment(newDate + "T" + time).toDate();
        this.orderExercisesByDate();
    }

    updateScheduledTime(exercise: PrmExercise, newTime: string) {
        let date = moment(exercise.scheduledDate).format('YYYY-MM-DD')
        exercise.scheduledDate = moment(date + "T" + newTime).toDate();
        this.orderExercisesByDate();
        console.log(this.exercisesSource.data);
    }
}

