import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {BasicUserInfo} from '../../../class/interfaces/basic-user-info';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../services/users/user.service';
import {StringUtils} from '../../../common/string-utils';

@Component({
    selector: 'add-team-element-dialog',
    templateUrl: './change-team-element-dialog.component.html',
    styleUrls: ['./change-team-element-dialog.component.scss']
})
export class ChangeTeamElementDialog extends ToastManager implements OnInit {

    loggedUser: any;
    availableTeamElements: BasicUserInfo[];
    teamElement: BasicUserInfo[];
    addTeamElementForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ChangeTeamElementDialog>,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        super(snackBar);
        this.addTeamElementForm = this._formBuilder.group({
            teamElement: [undefined, [Validators.required]],
            role: [{value: '', disabled: true}, [Validators.required]]
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        this.loadAvailableAssistantList();
        this.availableTeamElements = [];
    }

    ngOnInit(): void {
    }

    loadAvailableAssistantList() {
        this.userService.getPsychiatristUsers().subscribe((response) => {
            this.availableTeamElements = response.psychologists.sort((first, second) => {
                return (first.surname.toLowerCase() < second.surname.toLowerCase() ? -1 : 1);
            });
        });
    }

    closeDialog(selectUser: boolean): void {
        if(selectUser) {
            this.addTeamElementForm.markAllAsTouched();
            if(this.addTeamElementForm.get("teamElement").hasError("required")) {
                this.addTeamElementForm.markAllAsTouched();
                this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
            if(!this.addTeamElementForm.valid) {
                this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
            this.dialogRef.close(this.addTeamElementForm.get("teamElement").value);
        }else{
            this.dialogRef.close();
        }
    }

    updateForm(event) {
        this.addTeamElementForm.get("role").setValue(event.source.value.role);
    }
}
