<mat-dialog-content fxLayout='column' class="efficacy-dialog">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Esecuzione esercizio
        </mat-label>
    </div>
    <div *ngIf="!elabMovementsMod">
        <div fxFill fxLayout="column" *ngIf="bonesReady">
            <div class="border-container" *ngFor="let relevantExecutionBone of relevantExecutionBones; let i = index">
                <div class="h2 div-section-title">
                    {{relevantBoneNames[i]}}
                </div>
                <div class="bone-item">
                    <img [ngClass]="getLegendCss()" [src]="getLegendImage(i)" alt="avatar-legend">
                    <canvas *ngIf="bonesMovementChartData[i] !== undefined && bonesMovementsOptions[i] !== undefined"
                        baseChart [datasets]="bonesMovementChartData[i]" [labels]="bonesMovementsLabels"
                        [options]="bonesMovementsOptions[i]" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="elabMovementsMod && bonesReady">
        <div fxFill fxLayout="column">
            <div class="border-container" *ngFor="let bone of relevantBonesMovementsTrack | keyvalue : unsorted; index as i">
                <div class="h2 div-section-title">
                    {{isHandExercise ? handBoneNames[bone.key] : boneNames[bone.key]}}
                </div>
                <div class="bone-item">
                    <img [ngClass]="getLegendCss()" [src]="getLegendImageElabMov(bone.key)" alt="avatar-legend">
                    <canvas *ngIf="bonesMovementChartData[i] !== undefined && bonesMovementsOptions[i] !== undefined"
                        baseChart [datasets]="bonesMovementChartData[i]" [labels]="bonesMovementsLabels"
                        [options]="bonesMovementsOptions[i]" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"></canvas>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
    <div fxLayout='row-reverse' class="button-container">
        <button (click)="closeDialog()" mat-raised-button color="accent" class="back-button" aria-label="ESCI">
            Esci
        </button>
    </div>
</mat-dialog-actions>