import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Users } from '../../../class/users';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
    AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX,
    BASE_REGEX,
    FISCAL_CODE_REGEX,
    NUMBERS_ONLY_REGEX,
    TEXT_AND_NUMBERS_REGEX
} from '../../../common/commons.class';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import { RemoveDrugDialogComponent } from '../remove-drug-dialog/remove-drug-dialog.component';
import { User } from '../../../class/interfaces/user';

import { DrugPrescription } from '../../../class/drug-prescription';
import { ToastManager } from '../../utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RemoveTeamElementDialog } from '../remove-team-element-dialog/remove-team-element-dialog.component';
import { BasicUserInfo } from '../../../class/interfaces/basic-user-info';
import { AddTeamElementDialog } from '../add-team-element-dialog/add-team-element-dialog.component';
import { PRI } from '../../../class/pri';
import { AddEvaluationDialog } from '../add-evaluation-dialog/add-evaulation-dialog.component';
import { RemoveEvaluationDialogComponent } from '../remove-evaluation-dialog/remove-evaluation-dialog.component';
import { Diagnosis } from '../../../class/interfaces/diagnosis';
import { Procedure } from '../../../class/interfaces/procedure';
import { FunctionalValutation } from '../../../class/interfaces/functional-valutation';
import { myCustomTooltipDefaults } from '../../../common/custom-tooltip';
import { PriService } from '../../../services/pri/pri.service';
import { UserService } from '../../../services/users/user.service';
import { Roles } from '../../../authentication/util/roles';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { StringUtils } from '../../../common/string-utils';

export const DIAGNOSIS_TYPE = 0;
export const COMORBIDITY_TYPE = 1;
export const OPERATIONS_TYPE = 2;

export const DRUGS_STEP = 1;
export const PROJECT_DATA_STEP = 2;
export const TEAM_STEP = 3;
export const FUNCTIONAL_VAL_STEP = 4;
export const HEALTH_STEP = 5;

@Component({
    selector: 'app-new-pri',
    templateUrl: './insert-new-pri-page.component.html',
    styleUrls: ['./insert-new-pri-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, Users, DatePipe
    ],
})
export class InsertNewPriPageComponent extends ToastManager implements OnInit {

    @ViewChild(MatSort) sort: MatSort;

    loggedUser: any;
    contentReady: boolean;
    filterDiagnosisValue: string;
    filterComorbidityValue: string;
    filterProceduresValue: string;

    newPRIStep: number;
    totalStepCount: number;
    stepNames: string[] = ['Dati generali assistito', 'Dati generali progetto', 'Team riabilitativo', 'Valutazione funzionale', 'Stato di salute'];

    findAssistedForm: FormGroup;
    findPressed: boolean = false;
    foundUser: User;
    noUserFound: boolean = false;
    userFound: boolean = false;

    addedDrugPrescriptions: DrugPrescription[];
    deletedDrugPrescription: number[];
    priProject: PRI;

    todayDate: Date;
    userDataForm: FormGroup;
    insertDrugForm: FormGroup;
    addPrescritionPressed: boolean;
    drugsDataSource: MatTableDataSource<DrugPrescription>;
    drugsList: DrugPrescription[];
    displayedColumns = ['drugName', 'dose', 'beginDate', 'endDate', 'actions'];

    projectDataForm: FormGroup;
    diagnosisCheckBlock: boolean;
    diagnosisHolder: Diagnosis[];
    filteredDiagnosis: Diagnosis[];
    filteredComorbidity: Diagnosis[];
    filteredProcedures: Procedure[];
    diagnosis: {
        available: Diagnosis[],
        found: Diagnosis[]
    };
    comorbidity: {
        available: Diagnosis[],
        found: Diagnosis[]
    };
    procedures: {
        available: Procedure[],
        found: Procedure[]
    };
    selectedAvailableDiagnosisPatology: string;
    selectedAvailableComorbidityPatology: string;
    selectedAvailableOperation: string;
    selectedFoundDiagnosisPatology: string;
    selectedFoundComorbidityPatology: string;
    selectedFoundOperation: string;
    swapAddDirection: boolean;

    rehabilitationTeamDataSource: MatTableDataSource<any>;
    teamList: BasicUserInfo[];
    teamDisplayedColumns = ['surname', 'name', 'role', 'actions'];

    evaluationsDataSource: MatTableDataSource<any>;
    evaluationsList: FunctionalValutation[];
    evaluationsDisplayedColumns = ['acronym', 'category', 'attribute', 'value', 'actions'];

    healthParametersForm: FormGroup;
    availableRythms: String[];

    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private users: Users,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private priService: PriService,
        private spinner: NgxSpinnerService,
        private userService: UserService
    ) {
        super(snackBar);
        this.todayDate = new Date();
        this.todayDate.setHours(0, 0, 0, 0);
        this.diagnosisCheckBlock = true;
        this.totalStepCount = 5;
        this.newPRIStep = 1;
        this.contentReady = false;

        this.findAssistedForm = this._formBuilder.group({
            fiscalCode: ['', [Validators.pattern(FISCAL_CODE_REGEX), Validators.required]]
        });
        this.userDataForm = this._formBuilder.group({
            surname: { value: '', disabled: true },
            name: { value: '', disabled: true },
            birthDate: { value: '', disabled: true }
        });
        this.insertDrugForm = this._formBuilder.group({
            drugName: ['', [Validators.required, Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
            drugDose: ['', [Validators.required, Validators.pattern(TEXT_AND_NUMBERS_REGEX)]],
            beginDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });
        this.insertDrugForm.setValidators(this.datesCheckValidator());
        this.projectDataForm = this._formBuilder.group({
            name: ['', [Validators.required, Validators.pattern(TEXT_AND_NUMBERS_REGEX)]],
            startDate: ['', [Validators.required]],
            daysCount: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX), Validators.max(365)]],
            anamnesis: ['', [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
            synthesis: ['', [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
            goals: ['', [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
        });
        this.healthParametersForm = this._formBuilder.group({
            diastolicBloodPressure: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            systolicBloodPressure: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioFrequency: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioRhythm: ['', [Validators.required]],
            breathFrequency: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            arterialSaturation: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]]
        });
        this.healthParametersForm.setValidators(this.bloodPressureValidator());
        this.availableRythms = ['Alto', 'Basso', 'Irregolare', 'Regolare', 'Sinusale'];

        this.priProject = new PRI();
    }

    ngOnInit(): void {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        this.evaluationsList = [];
        this.teamList = [{
            fiscalCode: this.loggedUser.fiscalCode,
            name: this.loggedUser.name,
            surname: this.loggedUser.surname,
            role: this.loggedUser.role,
            uuc: this.loggedUser.uuc
        }];
        this.addedDrugPrescriptions = [];
        this.deletedDrugPrescription = [];
        this.rehabilitationTeamDataSource = new MatTableDataSource(this.teamList);
        this.evaluationsDataSource = new MatTableDataSource<any>(this.evaluationsList);
    }

    loadProjectDataLists() {
        if (this.diagnosis === undefined) {
            this.spinner.show();
            this.priService.getProjectDataList().subscribe((data) => {
                this.diagnosis = { available: data.priDiagnosisListData.pathologies.concat(), found: [] };
                this.comorbidity = { available: data.priDiagnosisListData.pathologies.concat(), found: [] };
                this.procedures = { available: data.priDiagnosisListData.surgeryOperations, found: [] };
                this.spinner.hide();
            },
                error => {
                    this.spinner.hide();
                });
        }
    }

    nextStep() {
        switch (this.newPRIStep) {
            case DRUGS_STEP:
                if (!this.findAssistedForm.disabled) {
                    this.findAssistedForm.markAllAsTouched();
                    this.showToast('Attenzione! Campi obbligatori non compilati.', StringUtils.ADVISE_TIME);
                    return;
                }
                this.priProject.assistedId = this.foundUser.uuc;
                this.loadProjectDataLists();
                break;
            case PROJECT_DATA_STEP:
                this.diagnosisCheckBlock = false;
                if (this.diagnosis.found.length === 0 || this.projectDataForm.get('name').hasError('required') || this.projectDataForm.get('startDate').hasError('required') || this.projectDataForm.get('daysCount').hasError('required')
                    || this.projectDataForm.get('anamnesis').hasError('required') || this.projectDataForm.get('synthesis').hasError('required') || this.projectDataForm.get('goals').hasError('required')) {
                    this.projectDataForm.markAllAsTouched();
                    this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return;
                }
                if (!this.projectDataForm.valid) {
                    this.projectDataForm.markAllAsTouched();
                    this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return;
                }
                this.priProject.name = this.projectDataForm.get('name').value;
                this.priProject.assignDate = this.projectDataForm.get('startDate').value;
                this.priProject.workingDays = this.projectDataForm.get('daysCount').value;
                this.priProject.anamnesis = this.projectDataForm.get('anamnesis').value;
                this.priProject.synthesis = this.projectDataForm.get('synthesis').value;
                this.priProject.goals = this.projectDataForm.get('goals').value;
                this.priProject.diagnosis = this.diagnosis.found;
                for (let comorb of this.comorbidity.found) {
                    comorb.comorbidity = true;
                }
                this.priProject.diagnosis = this.priProject.diagnosis.concat(this.comorbidity.found);
                this.priProject.procedures = this.procedures.found;
                break;
            case TEAM_STEP:

                if (!this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.physiatrist) ||
                    !this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.physiotherapist) ||
                    !this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.psychologist)) {
                    this.showToast('Attenzione! Per completare il passo Team riabilitativo è necessario aggiungere un fisiatra, uno psicologo e uno o più fisioterapisti.', StringUtils.ADVISE_TIME);
                    return;
                }
                // @ts-ignore
                let psychologistId = (this.rehabilitationTeamDataSource.data.filter((element) => {
                    if (element.role === Roles.psychologist) {
                        return element;
                    }
                }) as BasicUserInfo)[0];
                // @ts-ignore
                let physiatristId = (this.rehabilitationTeamDataSource.data.filter((element) => {
                    if (element.role === Roles.physiatrist) {
                        return element;
                    }
                }) as BasicUserInfo)[0];
                // @ts-ignore
                let physiotherapistId = [];
                physiotherapistId = physiotherapistId.concat(this.rehabilitationTeamDataSource.data.filter((element) => {
                    if (element.role === Roles.physiotherapist) {
                        return element;
                    }
                }) as BasicUserInfo[]);

                this.priProject.physiatristId = physiatristId.uuc;
                this.priProject.psychologistId = psychologistId.uuc;
                this.priProject.physiotherapistIds = physiotherapistId.map(element => element.uuc);
                break;
            case FUNCTIONAL_VAL_STEP:
                if (this.evaluationsDataSource.data.length === 0) {
                    this.showToast('Attenzione! E\' necessario inserire almeno una valutazione funzionale', StringUtils.ADVISE_TIME);
                    return;
                }
                this.priProject.functionalValutations = this.evaluationsDataSource.data;
                break;
            default:
                break;
        }
        this.newPRIStep++;
    }

    refreshUserData() {
        this.findPressed = true;
        this.noUserFound = false;
        this.userFound = false;
        const bodyData = {
            fiscalCode: this.findAssistedForm.get('fiscalCode').value,
        };
        if (!StringUtils.ITALIAN_FISCAL_CODE.test(bodyData.fiscalCode)) {
            this.showToast("Codice fiscale non valido", StringUtils.ADVISE_TIME);
            this.findPressed = false;
            return;
        }
        this.spinner.show();
        this.userService.getUserByFiscalCode(bodyData).subscribe((data) => {
            if (data.success) {
                this.findAssistedForm.get('fiscalCode').disable();
                this.foundUser = data.user;
                this.foundUser.birthDate = this.getBirthDateByFiscalCode(bodyData.fiscalCode);
                let checkPRIBody = {
                    assistedId: this.foundUser.uuc
                };
                this.priService.checkPRI(checkPRIBody).subscribe((response) => {
                    if (response.hasPRI) {
                        this.spinner.hide();
                        this.findAssistedForm.get('fiscalCode').enable();
                        this.showToast('Attenzione! L\'assistito ha già un PRI attivo.', StringUtils.ADVISE_TIME);
                        this.findPressed = false;
                    } else {
                        let drugsBody = {
                            userUuc: this.foundUser.uuc,
                        };
                        this.priService.getUserDrugs(drugsBody).subscribe((response) => {
                            this.spinner.hide();
                            if (response.success) {
                                this.drugsList = response.userDrugs.userDrugPrescriptions;
                                this.drugsDataSource = new MatTableDataSource(this.drugsList);
                                this.cdr.detectChanges();

                                this.userDataForm.get('name').setValue(this.foundUser.name);
                                this.userDataForm.get('surname').setValue(this.foundUser.surname);
                                this.userDataForm.get('birthDate').setValue(this.datePipe.transform(this.foundUser.birthDate, 'yyyy-MM-dd'));
                                this.userFound = true;
                                this.noUserFound = false;
                                this.contentReady = true;
                            } else {
                                this.noUserFound = true;
                                this.userFound = false;
                                this.contentReady = false;
                            }
                            this.findPressed = false;
                        }, error => {
                            this.spinner.hide();
                            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                            this.noUserFoundVariableReset();
                        });
                    }
                }, error => {
                    this.spinner.hide();
                    this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                    this.noUserFoundVariableReset();
                });
            } else {
                this.spinner.hide();
                this.findAssistedForm.get('fiscalCode').enable();
                this.noUserFoundVariableReset();
            }
        }, error => {
            this.spinner.hide();
            this.findAssistedForm.get('fiscalCode').enable();
            this.noUserFoundVariableReset();
        });
    }

    noUserFoundVariableReset() {
        this.noUserFound = true;
        this.findPressed = false;
        this.userFound = false;
        this.contentReady = false;
    }

    findAssisted() {
        if (this.findAssistedForm.get('fiscalCode').hasError('required')) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            this.findPressed = false;
            return;
        }
        if (!this.findAssistedForm.valid) {
            this.showToast('Attenzione! Campi non popolati correttamente.', StringUtils.ADVISE_TIME);
            this.findPressed = false;
            return;
        }
        this.refreshUserData();
    }

    getBirthDateByFiscalCode(fiscalCode: string) {
        let monthMap = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
        let day: string,
            month: string,
            year: string;
        if (fiscalCode !== null) {
            let codeDay: number = Number(fiscalCode[9] + fiscalCode[10]);
            if (codeDay > 35) {
                day = String(codeDay - 40);
            } else {
                day = String(codeDay);
            }
            month = String(monthMap.indexOf(fiscalCode.charAt(8)) + 1);

            let currentYear = String(new Date().getFullYear()).substr(2, 2);
            let codeYear: number = Number(fiscalCode[6] + fiscalCode[7]);
            if (codeYear > Number(currentYear)) {
                year = '19' + codeYear;
            } else {
                year = '20' + codeYear;
            }
            return new Date(year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0'));
        } else {
            return new Date('1900-01-01');
        }
    }

    resetTab() {
        switch (this.newPRIStep) {
            case DRUGS_STEP:
                this.resetAssistedUserForm();
                break;
            case PROJECT_DATA_STEP:
                this.resetProjectDataForm();
                break;
            case TEAM_STEP:
                this.resetTeamData();
                break;
            case FUNCTIONAL_VAL_STEP:
                this.resetEvaluationData();
                break;
            case HEALTH_STEP:
                this.resetHealthData();
                break;
            default:
                break;
        }
    }

    resetAssistedUserForm() {
        this.findAssistedForm.reset();
        this.findAssistedForm.get('fiscalCode').enable();
        this.resetInsertDrugForm();
        this.noUserFound = false;
        this.userFound = false;
        this.contentReady = false;
        this.addedDrugPrescriptions = [];
        this.deletedDrugPrescription = [];
        this.findAssistedForm.get('fiscalCode').setErrors(null);
    }

    resetProjectDataForm() {
        this.projectDataForm.reset();
        this.cleanFoundData(this.diagnosis);
        this.cleanFoundData(this.comorbidity);
        this.cleanFoundData(this.procedures);
        this, this.filteredDiagnosis = null;
        this, this.filteredProcedures = null;
        this, this.filteredComorbidity = null;
        this.filterProceduresValue = '';
        this.filterDiagnosisValue = '';
        this.filterComorbidityValue = '';
        this.diagnosisCheckBlock = true;
    }

    cleanFoundData(source: any) {
        source.available = source.available.concat(source.found);
        source.available.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
        source.found = [];
    }

    resetTeamData() {
        this.teamList = [{
            fiscalCode: this.loggedUser.fiscalCode,
            name: this.loggedUser.name,
            surname: this.loggedUser.surname,
            role: this.loggedUser.role,
            uuc: this.loggedUser.uuc
        }];
        this.rehabilitationTeamDataSource = new MatTableDataSource(this.teamList);
    }

    resetEvaluationData() {
        this.evaluationsList = [];
        this.evaluationsDataSource = new MatTableDataSource(this.evaluationsList);
    }

    resetHealthData() {
        this.healthParametersForm.reset();
    }

    insertDrugPrescrition() {
        let beginDate = new Date(this.insertDrugForm.get('beginDate').value);
        let endDate = new Date(this.insertDrugForm.get('endDate').value);
        if (beginDate.getTime() > endDate.getTime()) {
            this.showToast('Attenzione! La data fine deve essere successiva alla data inizio', StringUtils.ADVISE_TIME);
            return
        }
        this.insertDrugForm.markAllAsTouched();
        if (this.insertDrugForm.get('drugName').hasError('required') || this.insertDrugForm.get('drugDose').hasError('required') ||
            this.insertDrugForm.get('beginDate').hasError('required') || this.insertDrugForm.get('endDate').hasError('required')) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.insertDrugForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (this.drugsDataSource.data.filter((drug) => {
            let beginFilterDate: Date = new Date(this.insertDrugForm.get('beginDate').value);
            let endFilterDate: Date = new Date(this.insertDrugForm.get('endDate').value);
            let beginDate: Date = new Date(drug.beginDate);
            let endDate: Date = new Date(drug.endDate);
            return this.insertDrugForm.get('drugName').value.toLowerCase() === drug.drugName.name.toLowerCase() &&
                ((beginFilterDate.getTime() <= beginDate.getTime() &&
                    beginDate.getTime() <= endFilterDate.getTime()) ||
                    (beginFilterDate.getTime() <= endDate.getTime() &&
                        endDate.getTime() <= endFilterDate.getTime()));
        }).length > 0) {
            this.showToast('Attenzione! Prescrizione del farmaco già presente', StringUtils.ADVISE_TIME);
            return;
        }
        let drugPrescription: DrugPrescription = {
            drugName: {
                name: this.insertDrugForm.get('drugName').value
            },
            beginDate: this.insertDrugForm.get('beginDate').value,
            endDate: this.insertDrugForm.get('endDate').value,
            dose: this.insertDrugForm.get('drugDose').value,
            fiscalCode: this.findAssistedForm.get('fiscalCode').value,
            userUuc: this.foundUser.uuc
        };

        this.addedDrugPrescriptions.push(drugPrescription);
        this.drugsList.push(drugPrescription);
        this.drugsList.sort((firstValue, secondValue) => (new Date(firstValue.beginDate).getTime() > new Date(secondValue.beginDate).getTime() ? 1 : -1));
        this.drugsDataSource.data = this.drugsList;
        this.cdr.detectChanges();
        this.resetInsertDrugForm();
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'drugName':
                    return this.compare(a.drugName.name, b.drugName.name, isAsc);
                case 'beginDate':
                    return this.compare(a.beginDate, b.beginDate, isAsc);
                case 'endDate':
                    return this.compare(a.endDate, b.endDate, isAsc);
                case 'surname':
                    return this.compare(a.surname, b.surname, isAsc);
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'role':
                    return this.compare(a.role, b.role, isAsc);
                case 'acronym':
                    return this.compare(a.acronym, b.acronym, isAsc);
                case 'category':
                    return this.compare(a.category, b.category, isAsc);
                case 'attribute':
                    return this.compare(a.attribute, b.attribute, isAsc);
                case 'value':
                    return this.compare(a.value, b.value, isAsc);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    resetInsertDrugForm() {
        this.insertDrugForm.reset();
        this.addPrescritionPressed = false;
    }

    openDeleteDrugDialog(selectedDrug: DrugPrescription, index: number) {
        const dialogRef = this.dialog.open(RemoveDrugDialogComponent, {
            data: {
                drugName: selectedDrug.drugName,
                id: selectedDrug.id
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                if (selectedDrug.id === undefined || selectedDrug.id === null) {
                    this.addedDrugPrescriptions.splice(
                        this.addedDrugPrescriptions.findIndex((toFind) => {
                            return toFind.drugName === selectedDrug.drugName && toFind.beginDate === selectedDrug.beginDate;
                        })
                        , 1
                    );
                } else {
                    this.deletedDrugPrescription.push(selectedDrug.id);
                }
                this.drugsList.splice(index, 1);
                this.drugsDataSource.data = this.drugsList;
                this.cdr.detectChanges();
            }
        });
    }

    openDeleteTeamElementDialog(selectedElement: any, index: number) {
        const dialogRef = this.dialog.open(RemoveTeamElementDialog, {
            data: {
                role: selectedElement.role.toLowerCase(),
                name: selectedElement.name,
                surname: selectedElement.surname
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.removeTeamElement(index);
            }
        });
    }

    openAddUserDialog() {
        const dialogRef = this.dialog.open(AddTeamElementDialog, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined) {
                this.addTeamElement(data);
            }
        });
    }

    addTeamElement(element: BasicUserInfo) {
        if (this.teamList.some(listElement => listElement.name === element.name &&
            listElement.surname === element.surname &&
            listElement.role === element.role)) {
            this.showToast('Attenzione! Personale già presente', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.teamList.some(listElement =>
            listElement.role !== Roles.physiatrist && listElement.role !== Roles.physiotherapist && listElement.role === element.role)) {
            this.showToast('Attenzione! Ruolo già definito', StringUtils.ADVISE_TIME);
            return;
        }
        this.teamList = this.teamList.concat(element);
        this.rehabilitationTeamDataSource.data = this.teamList;
        this.sort.sort({ id: null, start: 'desc', disableClear: false });
        this.sort.sort({ id: 'surname', start: 'desc', disableClear: false });
        this.rehabilitationTeamDataSource._updateChangeSubscription();

    }

    removeTeamElement(index: number) {
        this.teamList.splice(index, 1);
        this.rehabilitationTeamDataSource.data = this.teamList;
        this.rehabilitationTeamDataSource._updateChangeSubscription();
    }

    changeSwapDirection(direction: boolean) {
        this.swapAddDirection = direction;
    }

    swapData(swapType: number) {
        switch (swapType) {
            case DIAGNOSIS_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableDiagnosisPatology.length !== 0) {
                        this.diagnosisCheckBlock = false;
                        this.swap(this.diagnosis.available, this.diagnosis.found, this.selectedAvailableDiagnosisPatology[0]);
                    }
                } else {
                    if (this.selectedFoundDiagnosisPatology.length !== 0) {
                        this.swap(this.diagnosis.found, this.diagnosis.available, this.selectedFoundDiagnosisPatology[0]);
                    }
                }
                this.filterPatologies();
                break;
            case COMORBIDITY_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableComorbidityPatology.length !== 0) {
                        this.swap(this.comorbidity.available, this.comorbidity.found, this.selectedAvailableComorbidityPatology[0]);
                    }
                } else {
                    if (this.selectedFoundComorbidityPatology.length !== 0) {
                        this.swap(this.comorbidity.found, this.comorbidity.available, this.selectedFoundComorbidityPatology[0]);
                    }
                }
                this.filterComorbidity();
                break;
            case OPERATIONS_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableOperation.length !== 0) {
                        this.swap(this.procedures.available, this.procedures.found, this.selectedAvailableOperation[0]);
                    }
                } else {
                    if (this.selectedFoundOperation.length !== 0) {
                        this.swap(this.procedures.found, this.procedures.available, this.selectedFoundOperation[0]);
                    }
                }
                this.filterProcedures();
                break;
            default:
                break;
        }

    }

    swap(from, to, value) {
        from.splice(from.indexOf(value), 1);
        to.push(value);
        to.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
    }

    openAddEvaluationDialog() {
        const dialogRef = this.dialog.open(AddEvaluationDialog, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined) {
                this.addEvaluation(data);
            }
        });
    }

    openDeleteEvaluationDialog(index: number) {
        const dialogRef = this.dialog.open(RemoveEvaluationDialogComponent, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.removeEvaluation(index);
            }
        });
    }

    private addEvaluation(data: any) {
        if (this.evaluationsList.some(listElement => listElement.attributeValutation.dimensionValutation.standardValutation.acronym === data.attributeValutation.dimensionValutation.standardValutation.acronym &&
            listElement.attributeValutation.dimensionValutation.description === data.attributeValutation.dimensionValutation.description &&
            listElement.attributeValutation.name === data.attributeValutation.name &&
            listElement.value === data.value)) {
            this.showToast('Attenzione! Valutazione funzionale già presente', StringUtils.ADVISE_TIME);
            return;
        }
        this.evaluationsList = this.evaluationsList.concat(data);
        this.evaluationsDataSource.data = this.evaluationsList;
        this.sort.sort({ id: null, start: 'desc', disableClear: false });
        this.sort.sort({ id: 'acronym', start: 'desc', disableClear: false });
        this.evaluationsDataSource._updateChangeSubscription();
    }

    private removeEvaluation(index: number) {
        this.evaluationsList.splice(index, 1);
        this.evaluationsDataSource.data = this.evaluationsList;
        this.evaluationsDataSource._updateChangeSubscription();
    }

    savePri() {
        if (this.healthParametersForm.get('diastolicBloodPressure').hasError('pressureError') ||
            this.healthParametersForm.get('systolicBloodPressure').hasError('pressureError')) {
            this.showToast('Attenzione! La pressione arteriosa diastolica non può essere superiore della Pressione arteriosa sistolica.', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.healthParametersForm.get('diastolicBloodPressure').hasError('required') || this.healthParametersForm.get('systolicBloodPressure').hasError('required') ||
            this.healthParametersForm.get('cardioFrequency').hasError('required') || this.healthParametersForm.get('cardioRhythm').hasError('required') ||
            this.healthParametersForm.get('breathFrequency').hasError('required') || this.healthParametersForm.get('arterialSaturation').hasError('required')) {
            this.healthParametersForm.markAllAsTouched();
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.healthParametersForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        this.priProject.healthParamters = {
            diastolicBloodPressure: this.healthParametersForm.get('diastolicBloodPressure').value,
            systolicBloodPressure: this.healthParametersForm.get('systolicBloodPressure').value,
            cardioFrequency: this.healthParametersForm.get('cardioFrequency').value,
            cardioRhythm: this.healthParametersForm.get('cardioRhythm').value,
            breathFrequency: this.healthParametersForm.get('breathFrequency').value,
            arterialSaturation: this.healthParametersForm.get('arterialSaturation').value,
        };
        this.priProject.physiatristNominative = this.loggedUser.surname + ' ' + this.loggedUser.name;
        const body = {
            pri: this.priProject,
            addedDrugPrescriptions: this.addedDrugPrescriptions,
            deletedDrugPrescriptions: this.deletedDrugPrescription
        };
        this.spinner.show();
        this.priService.insertPRI(body).subscribe((data: any) => {
            this.spinner.hide();
            if (data.success) {
                this.showToast('PRI inserito con successo', StringUtils.ADVISE_TIME);
                this.newPRIStep = 1;
                this.priProject = new PRI();
                this.findAssistedForm.reset();
                this.noUserFound = false;
                this.userFound = false;
                this.contentReady = false;
                this.router.navigate(['/list-assisted-users']);
            } else {
                this.spinner.hide();
                this.showToast('Attenzione! Impossibile inserire il PRI', StringUtils.ADVISE_TIME);
            }
        }, error => {
            this.spinner.hide();
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    datesCheckValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const beginDate = new Date(group.controls['beginDate'].value);
            const endDate = new Date(group.controls['endDate'].value);
            if ((group.controls['beginDate'].value !== null && group.controls['endDate'].value !== null) && (beginDate.getTime() > endDate.getTime())) {
                if (endDate.getFullYear() > 1000 && beginDate.getFullYear() > 1000) {
                    group.controls['beginDate'].setErrors({ timelineError: true });
                    group.controls['endDate'].setErrors({ timelineError: true });
                }
            } else {
                if (group.controls['beginDate'].hasError('timelineError')) {
                    group.controls['beginDate'].setErrors(null);
                    group.controls['beginDate'].markAsTouched();
                }
                if (group.controls['endDate'].hasError('timelineError')) {
                    group.controls['endDate'].setErrors(null);
                    group.controls['endDate'].markAsTouched();
                }
                return;
            }
        };
    }

    bloodPressureValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const diastolicPressure = Number(group.controls['diastolicBloodPressure'].value);
            const systolicPressure = Number(group.controls['systolicBloodPressure'].value);
            if (diastolicPressure !== 0 && systolicPressure !== 0 &&
                diastolicPressure > systolicPressure) {
                group.controls['diastolicBloodPressure'].setErrors({ pressureError: true });
                group.controls['systolicBloodPressure'].setErrors({ pressureError: true });
            } else {
                if (diastolicPressure !== 0) {
                    if (!this.healthParametersForm.get('diastolicBloodPressure').hasError("pattern") &&
                        !this.healthParametersForm.get('diastolicBloodPressure').hasError("required")) {
                        this.healthParametersForm.get('diastolicBloodPressure').setErrors(null);
                    }
                }
                if (systolicPressure !== 0) {
                    if (!this.healthParametersForm.get('systolicBloodPressure').hasError("pattern") &&
                        !this.healthParametersForm.get('systolicBloodPressure').hasError("required")) {
                        this.healthParametersForm.get('systolicBloodPressure').setErrors(null);
                    }
                }
            }
            if (diastolicPressure !== 0 && systolicPressure !== 0) {
                group.controls['diastolicBloodPressure'].markAllAsTouched();
                group.controls['systolicBloodPressure'].markAllAsTouched();
            }
            return;
        };
    }

    changePage(i: number) {
        if (i < this.newPRIStep) {
            this.newPRIStep = i;
            this.contentReady = true;
        }
    }

    projectDateChange() {
        let selectedDate = new Date(this.projectDataForm.get('startDate').value);
        if (selectedDate != undefined && selectedDate.getTime() != 0) {
            if (selectedDate.getTime() < this.todayDate.getTime()) {
                if (!this.projectDataForm.get('startDate').hasError('dateError')) {
                    this.projectDataForm.get('startDate').setErrors({ dateError: true });
                }
            } else {
                this.projectDataForm.get('startDate').setErrors(null);
            }
        }
    }

    drugDateChange() {
        let startDate = new Date(this.insertDrugForm.get('beginDate').value);
        if (startDate != undefined && startDate.getTime() != 0) {
            let date: Date = new Date();
            date.setFullYear(date.getFullYear() - StringUtils.MAX_BOUND_AGE);
            if (startDate.getTime() < date.getTime()) {
                if (!this.insertDrugForm.get('beginDate').hasError('dateError')) {
                    this.insertDrugForm.get('beginDate').setErrors({ dateError: true });
                }
            } else {
                this.insertDrugForm.get('beginDate').setErrors(null);
            }
        }
    }

    filterPatologies() {
        this.filteredDiagnosis = this.getFilteredAvailableData(this.diagnosis.available, this.filterDiagnosisValue);
    }

    filterComorbidity() {
        this.filteredComorbidity = this.getFilteredAvailableData(this.comorbidity.available, this.filterComorbidityValue);
    }

    filterProcedures() {
        this.filteredProcedures = this.getFilteredAvailableData(this.procedures.available, this.filterProceduresValue);
    }

    getFilteredAvailableData(data: any[], filter: string) {
        if (filter !== null && filter !== undefined &&
            filter !== '') {
            let keyWords: string[] = filter.split(' ');
            return data.filter((toFilter) => {
                let isValid = true;
                for (let key of keyWords) {
                    if (!toFilter.code.toLowerCase().includes(key.toLowerCase()) &&
                        !toFilter.name.toLowerCase().includes(key.toLowerCase())) {
                        isValid = false;
                        break;
                    }
                }
                return isValid;
            });
        }
    }
}

