import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { myCustomTooltipDefaults } from '../../../common/custom-tooltip';
import { ToastManager } from '../../utils/toast-manager';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX,
    BASE_REGEX,
    NUMBERS_ONLY_REGEX
} from '../../../common/commons.class';
import { DatePipe } from '@angular/common';
import { PriService } from '../../../services/pri/pri.service';
import { StringUtils } from '../../../common/string-utils';
import { createLogErrorHandler } from '@angular/compiler-cli/ngcc/src/execution/tasks/completion';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmEndPriDialogComponent } from '../../user-managment/confirm-end-pri-dialog/confirm-end-pri-dialog.component';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;

@Component({
    selector: 'end-pri',
    templateUrl: './end-pri-page.component.html',
    styleUrls: ['./end-pri-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, DatePipe
    ],
})
export class EndPriPageComponent extends ToastManager implements OnInit, OnDestroy {

    userNominative: string;
    private userUUC: string;
    loggedUser: any;
    sub: any;
    userDataForm: FormGroup;
    endPriForm: FormGroup;
    minimumDate: Date;

    hasActivePrm: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private priService: PriService,
        private spinner: NgxSpinnerService,
        private dialog: MatDialog,
    ) {
        super(snackBar);

        this.userDataForm = this._formBuilder.group({
            surname: { value: '', disabled: true },
            name: { value: '', disabled: true },
            fiscalCode: { value: '', disabled: true }
        });
        this.endPriForm = this._formBuilder.group({
            date: ['', Validators.required],
            lro: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            doneGoals: ['', [Validators.required, Validators.maxLength(1000), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]]
        });
    }

    ngOnInit(): void {
        let priDate: Date;
        let lastExecutionDate: Date;
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.hasActivePrm = params['hasActivePrm'];
            this.userNominative = params['surname'] + ' ' + params['name'];
            this.userDataForm.setValue({
                surname: params['surname'],
                name: params['name'],
                fiscalCode: params['fiscalCode'],
            });
            this.userUUC = params['userUUC'];
            priDate = new Date(params['priDate']);
            lastExecutionDate = new Date(params['lastExecutionDate']);
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        if (Number.isNaN(lastExecutionDate.getTime())) {
            this.minimumDate = priDate;
        } else {
            this.minimumDate = lastExecutionDate;
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    endPRI() {
        this.endPriForm.markAllAsTouched();
        if (this.endPriForm.get("doneGoals").hasError("required")
            || this.endPriForm.get("date").hasError("required")
            || this.endPriForm.get("lro").hasError("required")) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (this.endPriForm.get("doneGoals").hasError("pattern")
            || this.endPriForm.get("lro").hasError("pattern")) {
            this.showToast("Attenzione! Campi non popolati correttamente", StringUtils.ADVISE_TIME);
            return;
        }
        if (this.endPriForm.get("date").hasError("dateError")) {
            this.showToast("Attenzione! La data di conclusione deve essere successiva alla data del PRI o dell'ultima esecuzione (" +
                this.datePipe.transform(this.minimumDate, "dd/MM/yyyy") + ")", StringUtils.ADVISE_TIME);
            return;
        }
        const body = {
            assistedId: this.userUUC,
            doneGoals: this.endPriForm.get('doneGoals').value,
            endDate: this.endPriForm.get('date').value,
            lro: this.endPriForm.get('lro').value
        };

        if (this.hasActivePrm == 'true') {
            this.openDialog(body);
        } else {
            this.spinner.show();
            this.priService.endPRI(body).subscribe((response) => {
                this.spinner.hide();
                if (response.success) {
                    this.showToast('PRI concluso con successo', StringUtils.ADVISE_TIME);
                    this.router.navigate(['/list-assisted-users']);
                } else {
                    this.showToast('Attenzione! Impossibile concludere il PRI', StringUtils.ADVISE_TIME);
                }
                this.spinner.hide();
            }, error => {
                this.spinner.hide();
                this.showToast('Attenzione! Impossibile concludere il PRI', StringUtils.ADVISE_TIME);
            });
        }
    }

    openDialog(body: any): void {
        const dialogRef = this.dialog.open(ConfirmEndPriDialogComponent, {
            disableClose: true,
            data: {
                body
            },
        });
    }

    resetFields() {
        this.endPriForm.reset();
    }

    public goBack() {
        this.router.navigate(['/list-assisted-users']);
    }

    dateChange() {
        let selectedDate = new Date(this.endPriForm.get('date').value);
        if (selectedDate != undefined && selectedDate.getTime() != 0) {
            if (selectedDate.getTime() < this.minimumDate.getTime()) {
                if (!this.endPriForm.get('date').hasError('dateError')) {
                    this.endPriForm.get('date').setErrors({ dateError: true });
                }
            } else {
                this.endPriForm.get('date').setErrors(null);
            }
        }
    }
}

