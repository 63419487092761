<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"
></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Conclusione PRI
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userNominative}}
        </mat-label>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Dati assistito
        </div>
        <form [formGroup]="userDataForm" class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="surname">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Codice fiscale</mat-label>
                <input matInput formControlName="fiscalCode">
            </mat-form-field>
        </form>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Dati conclusione Progetto Riabilitativo Individuale
        </div>
        <form [formGroup]="endPriForm" class="inner-form" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" fxFlex="49">
                    <mat-label>Data</mat-label>
                    <input matInput formControlName="date" (change)="dateChange()" type="date" onkeydown="return false" [max]="'9999-12-31'" required>
                    <mat-error *ngIf="!this.endPriForm.get('date').hasError('required')">Data non valida</mat-error>
                    <mat-error *ngIf="this.endPriForm.get('date').hasError('required')">Data obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="49">
                    <mat-label>LRO</mat-label>
                    <input matInput formControlName="lro" maxlength="3" required>
                    <mat-error *ngIf="!this.endPriForm.get('lro').hasError('required')">LRO non valido</mat-error>
                    <mat-error *ngIf="this.endPriForm.get('lro').hasError('required')">LRO obbligatorio</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Obiettivi raggiunti</mat-label>
                <textarea matInput formControlName="doneGoals" required></textarea>
                <mat-error *ngIf="!this.endPriForm.get('doneGoals').hasError('required')">Obiettivi raggiunti non validi</mat-error>
                <mat-error *ngIf="this.endPriForm.get('doneGoals').hasError('required')">Obiettivi raggiunti obbligatori</mat-error>
            </mat-form-field>
        </form>
    </div>

    <div class="panel-buttons-container" fxLayout="row">
        <button mat-raised-button color="accent" class="save-button"
                aria-label="END" (click)="endPRI()">
            CONCLUDI
        </button>
        <button mat-raised-button color="accent" class="cancel-button"
                aria-label="CANCEL" (click)="resetFields()">
            ANNULLA
        </button>
        <button mat-raised-button color="accent" class="back-button"
                aria-label="INDIETRO" (click)="goBack()">
            INDIETRO
        </button>
    </div>
</div>