export class Roles {
    private static _admin = "Amministratore";
    private static _physiotherapist = "Fisioterapista";
    private static _physiatrist = "Fisiatra";
    private static _psychologist = "Psicologo";
    private static _assisted = "Assistito";


    static get admin(): string {
        return this._admin;
    }

    static get physiotherapist(): string {
        return this._physiotherapist;
    }

    static get physiatrist(): string {
        return this._physiatrist;
    }

    static get psychologist(): string {
        return this._psychologist;
    }

    static get assisted(): string {
        return this._assisted;
    }

    static roles(): string[] {
        let roles: string[] = [this.admin, this.physiatrist, this.physiotherapist, this.psychologist, this.assisted];
        return roles.sort((first, second) => {
            return (first.toLowerCase() < second.toLowerCase() ? -1 : 1)
        });
    }
}