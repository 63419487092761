import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Endpoints} from '../../class/endpoints';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpClient,
                ) {
    }

    public deleteFile(requestBody: any): Observable<any> {
        return this.http.request<any>("DELETE",
            Endpoints.DUEVITAB_FILE_API,
            {body:requestBody}
        ).pipe(map(deleteFile => {
            return deleteFile;
        }));
    }

    public uploadFile(requestBody: any): Observable<any> {
        return this.http.post<any>(
            Endpoints.DUEVITAB_FILE_API,
            requestBody
        ).pipe(map(uploadFile => {
            return uploadFile;
        }));
    }

    public downloadFile(endpoint: string): Observable<any> {
        return this.http.get(
            endpoint,
            {
                reportProgress: true,
                responseType: 'blob',
                headers: new HttpHeaders({
                    'Accept': 'application/octet-stream'
                })
            }
        ).pipe(map(downloadFile => {
            return downloadFile;
        }));
    }
}
