import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormControl, Validators} from '@angular/forms';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExerciseExecutionInterface} from '../../../class/interfaces/exercise-execution-interface';
import {BASE_REGEX} from '../../../common/commons.class';
import {FileService} from '../../../services/file/file.service';
import {Endpoints} from '../../../class/endpoints';
import {DomSanitizer} from '@angular/platform-browser';
import {StringUtils} from '../../../common/string-utils';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'add-evaluation-dialog',
    templateUrl: './add-execution-rating-dialog.component.html',
    styleUrls: ['./add-execution-rating-dialog.component.scss']
})
export class AddExecutionRatingDialogComponent extends ToastManager implements OnInit {

    exercise: ExerciseExecutionInterface;
    videoURL: any;

    loggedUser: any;
    rating: number;
    starColor: any;
    ratingNotes: FormControl;

    handVideo: boolean;

    constructor(
        public dialogRef: MatDialogRef<AddExecutionRatingDialogComponent>,
        private snackBar: MatSnackBar,
        private fileService: FileService,
        private spinner: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        super(snackBar);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        this.exercise = data.exercise;
        this.handVideo = data.handVideo;

        this.rating = 0;
        this.starColor = 'accent';
        this.ratingNotes = new FormControl('', [Validators.pattern(BASE_REGEX)]);
        this.loadVideo();
    }

    closeDialog(performValutation: boolean) {
        if(performValutation) {
            if (this.rating === undefined || this.rating === 0) {
                this.showToast("Attenzione! Campi obbligatori non compilati.", StringUtils.ADVISE_TIME);
                this.starColor = 'warn';
                return;
            }
            if(this.ratingNotes.hasError('pattern')) {
                this.showToast("Attenzione! Campi non compilati correttamente", StringUtils.ADVISE_TIME);
                return;
            }
            this.exercise.rating = this.rating;
            this.exercise.note = this.ratingNotes.value;
            this.dialogRef.close(this.exercise);
            return;
        }
        this.dialogRef.close(null);
    }

    onRatingChanged(rating: any) {
        this.starColor = 'accent';
        this.rating = rating;
    }

    private loadVideo() {
        this.spinner.show();
        this.fileService.downloadFile(this.getDownloadFileUrl(this.exercise.videoURL + "/file")).subscribe(response => {
            let file = response;
            let URL = window.URL;
            this.videoURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
            this.spinner.hide();
        });
    }

    getDownloadFileUrl(fileName: string) {
        const url = Endpoints.DUEVITAB_DOWNLOAD_FILE_API + '/' + fileName;
        return url;
    }
}
