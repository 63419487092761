import {PRI} from './pri';
import {PrmExercise} from './interfaces/prm-exercise';

export class Prm {
    id?: number;
    version: number = 0;
    beginDate?: Date;
    endDate?: Date;
    physiotherapistId: string;
    pri?: PRI;
    exerciseExecutions: PrmExercise[];
    assistedId?: string;
}
