import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormControl, Validators} from '@angular/forms';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExerciseExecutionInterface} from '../../../class/interfaces/exercise-execution-interface';
import {BASE_REGEX} from '../../../common/commons.class';
import {Endpoints} from '../../../class/endpoints';
import {DomSanitizer} from '@angular/platform-browser';
import {FileService} from '../../../services/file/file.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { StringUtils } from 'app/main/common/string-utils';

@Component({
    selector: 'add-evaluation-dialog',
    templateUrl: './play-execution-video-dialog.component.html',
    styleUrls: ['./play-execution-video-dialog.component.scss']
})
export class PlayExecutionVideoDialogComponent extends ToastManager implements OnInit {

    videoUrl: string;
    videoSource: any;

    handVideo: boolean;

    constructor(
        public dialogRef: MatDialogRef<PlayExecutionVideoDialogComponent>,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
        private fileService: FileService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        super(snackBar);
        this.videoUrl = data.videoUrl;
        this.handVideo = data.handVideo;
        this.loadVideo();
    }


    private loadVideo() {
        this.spinner.show();
        this.fileService.downloadFile(this.getDownloadFileUrl(this.videoUrl + "/file")).subscribe(response => {
            let file = response;
            let URL = window.URL;
            this.videoSource = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
            this.spinner.hide();
        }, error =>{
            this.spinner.hide();
            if(error.status == 404){
                this.showToast("Attenzione! Il video non è presente.", StringUtils.ADVISE_TIME)
            } else {
                this.showToast("Errore imprevisto. Riprovare più tardi.", StringUtils.ADVISE_TIME)
            }
            this.dialogRef.close();
        });
    }

    getDownloadFileUrl(fileName: string) {
        const url = Endpoints.DUEVITAB_DOWNLOAD_FILE_API + '/' + fileName;
        return url;
    }

    closeDialog() {
        this.spinner.hide();
        this.dialogRef.close();
    }
}
