import {Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';

import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, Validators} from '@angular/forms';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Users} from '../../../class/users';
import {Router} from '@angular/router';
import {FISCAL_CODE_REGEX} from '../../../common/commons.class';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../../../class/interfaces/user';

import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {UserService} from '../../../services/users/user.service';
import {PriService} from '../../../services/pri/pri.service';
import {StringUtils} from '../../../common/string-utils';
import {PriInfoPageComponent} from '../pri-info-page/pri-info-page.component';
import {PriAssociationResponse} from '../../../models/pri-association-response';
import {Roles} from '../../../authentication/util/roles';
import { NgxSpinnerService } from 'ngx-spinner';

const REMOVE_DIALOG_CODE = 2;
const NUMBER_MAX_OF_RECORDS = 10;
const FILTER_REGEX = '^[a-zA-ZA-Za-zÀ-ÖØ-öø-ÿ\\s\'-]+$';

@Component({
    selector: 'list-assisted-users',
    templateUrl: './list-assisted-users.component.html',
    styleUrls: ['./list-assisted-users.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, Users
    ],
})
export class ListAssistedUsersComponent extends ToastManager implements OnInit {
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.matSortValue = ms;
        if (this.dataSource) {
            this.dataSource.sort = ms;
        }
    }

    @ViewChild('surname') surname: ElementRef;

    @ViewChild(MatPaginator) set paginator(pg: MatPaginator) {
        this.paginatorValue = pg;
        if (this.dataSource) {
            this.dataSource.paginator = pg;
        }
    }

    private matSortValue;
    public displayedColumns = ['surname', 'name', 'fiscalCode', 'actions'];
    private userData: User[];
    private allUserData: User[];
    private paginatorValue;
    public dataSource: MatTableDataSource<any>;
    public nameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    public surnameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    public fiscalCodeFilter = new FormControl('', Validators.pattern(FISCAL_CODE_REGEX));
    private roleFilter = new FormControl('');
    private sort;
    private pageNumber = 0;
    private lastPage;
    public loggedUser: any;

    filterValues = {
        name: '',
        surname: '',
        fiscalCode: '',
        role: ''
    };

    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private users: Users,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private _sBar: MatSnackBar,
        private userService: UserService,
        private priService: PriService,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        this.spinner.show();
    }

    getFormValidation(): boolean {
        return this.nameFilter.valid && this.surnameFilter.valid && this.fiscalCodeFilter.valid;
    }

    resetFilters(): void {
        this.nameFilter.setValue('');
        this.surnameFilter.setValue('');
        this.fiscalCodeFilter.setValue('');
        this.roleFilter.setValue('');
        this.filter();
    }

    setDataSourceAttributes(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        this.userService.getAssistedUsers(this.loggedUser.uuc).subscribe((response) => {
            this.allUserData = response.users;
            this.allUserData.sort((a, b) => a.surname.localeCompare(b.surname));
            this.userData = this.allUserData;
            this.dataSource = new MatTableDataSource(this.userData);
            this.cdr.detectChanges();
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
            this.dataSource.paginator = this.paginatorValue;
            this.lastPage = (this.allUserData.length / NUMBER_MAX_OF_RECORDS);
            this.spinner.hide();
        });
    }

    setDataSource(): void {
        this.dataSource = new MatTableDataSource(this.userData);
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        this.dataSource.sort = this.matSortValue;
        this.dataSource.paginator = this.paginatorValue;
    }

    changeTablePage(i): void {
        this.pageNumber += i;
        this.userData = this.allUserData;
        this.setDataSource();
    }

    filterButton(): void {
        if (!this.nameFilter.value && !this.surnameFilter.value && !this.fiscalCodeFilter.value && !this.roleFilter.value) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
        } else {
            this.filter();
        }
    }

    filter(): void {
        this.filterValues = {
            name: '',
            surname: '',
            fiscalCode: '',
            role: ''
        };
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.filterValues.name = this.nameFilter.value.toLowerCase();
        this.filterValues.surname = this.surnameFilter.value.toLowerCase();
        this.filterValues.fiscalCode = this.fiscalCodeFilter.value.toLowerCase();
        this.filterValues.role = this.roleFilter.value.toLowerCase();
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    sortdata(sort: Sort): void {
        const data = this.dataSource.data;
        if (!sort.active || sort.direction === '') {
            this.userData = data;
            return;
        }
        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'surname':
                    return this.compare(a.surname, b.surname, isAsc);
                case 'fiscalCode':
                    return this.compare(a.fiscalCode, b.fiscalCode, isAsc);
                case 'role':
                    return this.compare(a.role, b.role, isAsc);
                default:
                    return 0;
            }
        });
        this.paginatorValue.firstPage();
        this.dataSource.paginator = this.paginatorValue;
    }

    // tslint:disable-next-line:typedef
    compare(a: string, b: string, isAsc: boolean) {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    createFilter(): (data: any, filter: string) => boolean {
        // tslint:disable-next-line:only-arrow-functions
        const filterFunction = function(data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return data.name.toLowerCase().indexOf(searchTerms.name) !== -1
                && data.surname.toString().toLowerCase().indexOf(searchTerms.surname) !== -1
                && data.fiscalCode.toLowerCase().indexOf(searchTerms.fiscalCode) !== -1
                && data.role.toLowerCase().indexOf(searchTerms.role) !== -1;
        };
        return filterFunction;
    }

    checkActivePRI(assistedId: string) {
        let checkPRIBody = {
            assistedId: assistedId
        };
        return this.priService.checkPRI(checkPRIBody);
    }

    showAssistedUserInfo(user: User) {
        this.router.navigate(['/assisted-user-info'], {
            queryParams: {
                userName: user.surname + ' ' + user.name,
                fc: user.fiscalCode
            }
        });
    }

    showAssistedUserPRI(user: User) {
        this.checkActivePRI(user.uuc).subscribe((response: PriAssociationResponse) => {
            let edit = StringUtils.EDIT_MODE_DISABLED;
            if(this.loggedUser.role === Roles.physiatrist) {
                edit = StringUtils.EDIT_MODE_PHYSIATRIST;
            }else if(this.loggedUser.role === Roles.psychologist) {
                edit = StringUtils.EDIT_MODE_PSYCHOLOGIST;
            }
            if (response.hasPRI) {
                this.router.navigate(['/pri-info'], {
                    queryParams: {
                        surname: user.surname,
                        name: user.name,
                        fiscalCode: user.fiscalCode,
                        userUUC: user.uuc,
                        edit: edit,
                        origin: StringUtils.LIST_USERS_ORIGIN
                    }
                });
                return true;
            } else {
                this.showToast(StringUtils.EMPTY_PRI_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            return false;
        });
    }

    showAssistedUserPRIHistory(user: User) {
        this.router.navigate(['/user-pri-history'], {
            queryParams: {
                userName: user.name,
                userSurname: user.surname,
                uuc: user.uuc,
                fCode: user.fiscalCode
            }
        });
        return true;
    }

    beginFollowUp(user: User) {
        this.checkActivePRI(user.uuc).subscribe((response: PriAssociationResponse) => {
            if (response.hasPRI) {
                this.router.navigate(['/follow-up-manager'], {
                    queryParams: {
                        userName: user.surname + ' ' + user.name,
                        userUUC: user.uuc,
                        estimatedLro: response.estimatedLRO
                    }
                });
                return;
            } else {
                this.showToast(StringUtils.EMPTY_PRI_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            return false;
        });
    }

    endPRI(user: User) {
        this.checkActivePRI(user.uuc).subscribe((response: PriAssociationResponse) => {
            if (response.hasPRI) {
                this.router.navigate(['/end-pri'], {
                    queryParams: {
                        surname: user.surname,
                        name: user.name,
                        fiscalCode: user.fiscalCode,
                        userUUC: user.uuc,
                        priDate: response.priDate,
                        lastExecutionDate: response.lastExecutionDate,
                        hasActivePrm: response.hasActivePrm
                    }
                });
                return;
            } else {
                this.showToast(StringUtils.EMPTY_PRI_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            return false;
        });
    }

    addReportCoping(user: User) {
        this.checkActivePRI(user.uuc).subscribe((response: PriAssociationResponse) => {
            if (response.hasPRI) {
                this.router.navigate(['/report-coping'], {
                    queryParams: {
                        surname: user.surname,
                        name: user.name,
                        fiscalCode: user.fiscalCode,
                        userUUC: user.uuc,
                        priDate: response.priDate,
                    }
                });
                return;
            } else {
                this.showToast(StringUtils.EMPTY_PRI_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            return false;
        });
    }
}

