import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/home'
            }
        ]
    }, {
        id: 'users',
        title: 'Gestione utenti',
        type: 'group',
        children: [{
            id: 'userAdd',
            title: 'Nuovo',
            type: 'item',
            icon: 'add',
            url: '/insert-user-page',

        },
            {
                id: 'userList',
                title: 'Lista',
                type: 'item',
                icon: 'list',
                url: '/list-users-page',
            }]
    },
    {
        id: 'exercises',
        title: 'Gestione esercizi',
        type: 'group',
        children: [{
            id: 'exerciseAdd',
            title: 'Nuovo',
            type: 'item',
            icon: 'add',
            url: '/insert-exercise-page',
        },
            {
                id: 'exerciseList',
                title: 'Lista',
                type: 'item',
                icon: 'list',
                url: '/list-exercises-page',
            },
            {
                id: 'exerciseExecutionValutation',
                title: 'Valutazione',
                type: 'item',
                icon: 'star',
                url: '/exercise-execution-valutation',
            }]
    },
    {
        id: 'assisted',
        title: 'Assistiti',
        type: 'group',
        children: [{
            id: 'assistedList',
            title: 'Lista',
            type: 'item',
            icon: 'list',
            url: '/list-assisted-users',
        },
            {
                id: 'newPRI',
                title: 'Nuovo PRI',
                type: 'item',
                icon: 'accessibility_new',
                url: '/new-pri',
            },
            {
                id: 'newPRM',
                title: 'Nuovo PRM',
                type: 'item',
                icon: 'accessibility_new',
                url: '/new-prm',
            }]
    },
];
