import {environment} from '../../../environments/environment';

export class Endpoints {
    public static readonly DUEVITAB_GET_AUTH_API = environment.backendurl + "/api/v1/authManager/public/login";
    public static readonly DUEVITAB_MODIFY_PASSWORD_API = environment.backendurl + "/archive/api/v1/users/accountPassword";
    public static readonly DUEVITAB_USERS_API = environment.backendurl + "/archive/api/v1/users/user";
    public static readonly DUEVITAB_USERS_STATS_API = environment.backendurl + "/archive/api/v1/users/stats";
    public static readonly DUEVITAB_GET_USER_BY_FISCAL_CODE = environment.backendurl + "/archive/api/v1/users/userByFiscalcode";
    public static readonly DUEVITAB_RECOVER_PASSWORD_ENDPOINT = environment.backendurl + "/api/v1/authManager/public/recovery";
    public static readonly DUEVITAB_EXERCISES_API = environment.backendurl + "/archive/api/v1/exercises/exercise";
    public static readonly DUEVITAB_FILE_API = environment.backendurl + "/archive/api/v1/fileManager/file";
    public static readonly DUEVITAB_DOWNLOAD_FILE_API = environment.backendurl + "/archive/api/v1/fileManager/files";
    public static readonly DUEVITAB_GET_AVAILABLE_ASSISTANTS_API = environment.backendurl + "/archive/api/v1/users/availableAssistents";
    public static readonly DUEVITAB_GET_AVAILABLE_PSYCHOLOGISTS_API = environment.backendurl + "/archive/api/v1/users/availablePsychiatrists";
    public static readonly DUEVITAB_GET_ASSISTED_USERS_API = environment.backendurl + "/archive/api/v1/users/assistedUsers";
    public static readonly DUEVITAB_DRUG_PRESCRIPTION_API = environment.backendurl + "/rehabilitation/api/v1/pri/drugPrescription";
    public static readonly DUEVITAB_PRM_API = environment.backendurl + "/rehabilitation/api/v1/prm/PRM";
    public static readonly DUEVITAB_USER_HAS_ACTIVE_PRM_API = environment.backendurl + "/rehabilitation/api/v1/prm/PRMAssociation";
    public static readonly DUEVITAB_PRI_API = environment.backendurl + "/rehabilitation/api/v1/pri/PRI";
    public static readonly DUEVITAB_GET_USER_DRUGS_LIST_API = environment.backendurl + "/rehabilitation/api/v1/pri/drugsByUser";
    public static readonly DUEVITAB_LOAD_PROJECT_DATA_LISTS_API = environment.backendurl + "/rehabilitation/api/v1/pri/PRIDiagnosisData";
    public static readonly DUEVITAB_LOAD_FUNCTIONAL_VAL_DATA_API = environment.backendurl + "/rehabilitation/api/v1/pri/functionalData";
    public static readonly DUEVITAB_GET_EXERCISES_BY_CATEGORY_API = environment.backendurl + "/archive/api/v1/exercises/exerciseByCategory";
    public static readonly DUEVITAB_USER_HAS_ACTIVE_PRI_API = environment.backendurl + "/rehabilitation/api/v1/pri/PRIAssociation";
    public static readonly DUEVITAB_FOLLOW_UP_API = environment.backendurl + "/rehabilitation/api/v1/followups/followUp";
    public static readonly DUEVITAB_GET_ASSISTED_FOLLOWUPS_API = environment.backendurl + "/rehabilitation/api/v1/followups/assistedFollowUp";
    public static readonly DUEVITAB_REPORT_COPING_API = environment.backendurl + "/rehabilitation/api/v1/reportCoping/reportCoping";
    public static readonly DUEVITAB_GET_EXERCISE_RATE_API = environment.backendurl + "/rehabilitation/api/v1/prm/exerciseRate";
    public static readonly DUEVITAB_GET_PRI_HISTORY_API = environment.backendurl + "/rehabilitation/api/v1/pri/PRI";
    public static readonly DUEVITAB_GET_ACTIVE_PRI_API = environment.backendurl + "/rehabilitation/api/v1/pri/activePRI";
    public static readonly DUEVITAB_GET_USERS_BY_UUCS_API = environment.backendurl + "/archive/api/v1/users/groupUsers";
    public static readonly DUEVITAB_GET_EXERCISE_EXECUTION_DETAILS_API = environment.backendurl + "/rehabilitation/api/v1/prm/exerciseExecution";
    public static readonly DUEVITAB_GET_EXECUTION_MOVEMENT_API = environment.backendurl + "/monitoring/api/v1/movements/bothMovements";
    public static readonly DUEVITAB_GET_ELAB_EXECUTION_MOVEMENT_API = environment.backendurl + "/monitoring/api/v1/movements/elaboratedMovements";
    public static readonly DUEVITAB_GET_ENLISTED_USERS_API = environment.backendurl + "/rehabilitation/api/v1/pri/enlisted";
}
