import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ToastManager} from '../../utils/toast-manager';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {DrugPrescription} from '../../../class/drug-prescription';
import {PriService} from '../../../services/pri/pri.service';
import {UserService} from '../../../services/users/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX, TEXT_AND_NUMBERS_REGEX} from '../../../common/commons.class';
import {StringUtils} from '../../../common/string-utils';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;

@Component({
    selector: 'assisted-user-info',
    templateUrl: './assisted-user-info-page.component.html',
    styleUrls: ['./assisted-user-info-page.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, DatePipe
    ],
})
export class AssistedUserInfoPageComponent extends ToastManager implements OnInit, OnDestroy, AfterViewInit {

    private sub: any;
    private loggedUser: any;
    assistedUserForm: FormGroup;
    phoneNumbers: any[];
    userNominative: string;

    drugsFilterForm: FormGroup;

    private filterPredicate: {
        drugName: string,
        beginDate: Date,
        endDate: Date
    };

    @ViewChild('inUsePaginator') inUsePaginator: MatPaginator;
    @ViewChild('historyPaginator') historyPaginator: MatPaginator;

    inUseDrugsDataSource: MatTableDataSource<DrugPrescription>;
    inUseDrugList: DrugPrescription[];
    inUseDisplayedColumns = ['drugName', 'dose', 'beginDate', 'endDate'];

    historyDrugsDataSource: MatTableDataSource<DrugPrescription>;
    historyDrugList: DrugPrescription[];
    historyDisplayedColumns = ['drugName', 'dose', 'beginDate', 'endDate'];

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private spinner: NgxSpinnerService,
        private snackBar: MatSnackBar,
        private priService: PriService,
        private userService: UserService
    ) {
        super(snackBar);
        this.spinner.show();
        this.phoneNumbers = [];
        this.inUseDrugList = [];
        this.inUseDrugsDataSource = new MatTableDataSource(this.inUseDrugList);
        this.historyDrugList = [];
        this.historyDrugsDataSource = new MatTableDataSource(this.historyDrugList);
        this.assistedUserForm = this._formBuilder.group({
            number: [{value: '', disabled: true}],
            name: [{value: '', disabled: true}],
            surname: [{value: '', disabled: true}],
            fiscalCode: [{value: '', disabled: true}],
            email: [{value: '', disabled: true}],
            role: [{value: '', disabled: true}]
        });
        this.drugsFilterForm = this._formBuilder.group({
            drugName: ['', [Validators.pattern(TEXT_AND_NUMBERS_REGEX)]],
            beginDate: [''],
            endDate: ['']
        });

        this.historyDrugsDataSource.filterPredicate = this.createFilter();
        this.drugsFilterForm.setValidators(this.datesCheckValidator());
    }

    ngOnInit(): void {

        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userNominative = params['userName'];
            this.initUserData(params['fc']);
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
    }

    ngAfterViewInit(): void {
        this.inUseDrugsDataSource.paginator = this.inUsePaginator;
        this.historyDrugsDataSource.paginator = this.historyPaginator;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'drugName':
                    return this.compare(a.drugName.name, b.drugName.name, isAsc);
                case 'beginDate':
                    return this.compare(a.beginDate, b.beginDate, isAsc);
                case 'endDate':
                    return this.compare(a.endDate, b.endDate, isAsc);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    filterButton(): void {
        if (this.drugsFilterForm.get('drugName').hasError('pattern')) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if ((this.drugsFilterForm.get('drugName').value === '' && this.drugsFilterForm.get('beginDate').value === '' && this.drugsFilterForm.get('endDate').value === '')
            || (this.drugsFilterForm.get('drugName').value === '' && this.drugsFilterForm.get('beginDate').value === null && this.drugsFilterForm.get('endDate').value === null)) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if((this.drugsFilterForm.get('beginDate').value !== null && this.drugsFilterForm.get('beginDate').value !== "" && (this.drugsFilterForm.get('endDate').value === null || this.drugsFilterForm.get('endDate').value === ""))
        || (this.drugsFilterForm.get('endDate').value !== null && this.drugsFilterForm.get('endDate').value !== "" && (this.drugsFilterForm.get('beginDate').value === null || this.drugsFilterForm.get('beginDate').value === ""))) {
            this.showToast('Attenzione! Per effettuare una ricerca per date, occorre popolare i campi Data da e Data a', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.drugsFilterForm.get('beginDate').hasError('timelineError') || this.drugsFilterForm.get('endDate').hasError('timelineError')) {
            this.showToast('Attenzione! La Data da deve essere inferiore alla Data a', StringUtils.ADVISE_TIME);
            return;
        }
        this.filter();
    }

    filter(): void {
        this.filterPredicate = {
            drugName: '',
            beginDate: null,
            endDate: null
        };
        this.filterPredicate.drugName = String(this.drugsFilterForm.get('drugName').value);
        this.filterPredicate.beginDate = this.drugsFilterForm.get('beginDate').value;
        this.filterPredicate.endDate = this.drugsFilterForm.get('endDate').value;
        this.historyDrugsDataSource.filterPredicate = this.createFilter();
        this.historyDrugsDataSource.filter = JSON.stringify(this.filterPredicate);
    }

    createFilter(): (data: any, filter: string) => boolean {
        // tslint:disable-next-line:only-arrow-functions
        const filterFunction = function(data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            if ((searchTerms.beginDate !== null && searchTerms.endDate !== null) && searchTerms.drugName !== null) {
                return new Date(data.beginDate).getTime() >= new Date(searchTerms.beginDate).getTime() &&
                    new Date(data.beginDate).getTime() <= new Date(searchTerms.endDate).getTime() &&
                    String(data.drugName.name).toLowerCase().indexOf(searchTerms.drugName) !== -1;
            } else if (searchTerms.beginDate !== null && searchTerms.endDate !== null) {
                return new Date(data.beginDate).getTime() >= new Date(searchTerms.beginDate).getTime() &&
                    new Date(data.beginDate).getTime() <= new Date(searchTerms.endDate).getTime();
            } else {
                return String(data.drugName.name).toLowerCase().indexOf(String(searchTerms.drugName.toLowerCase())) !== -1;
            }
        };
        return filterFunction;
    }

    resetFilters() {
        this.drugsFilterForm.reset();
        this.drugsFilterForm.get('beginDate').setValue(null);
        this.drugsFilterForm.get('endDate').setValue(null);
        this.drugsFilterForm.get('drugName').setValue('');
        this.historyDrugsDataSource.filter = '';
    }

    datesCheckValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const beginDate = new Date(group.controls['beginDate'].value);
            const endDate = new Date(group.controls['endDate'].value);
            if ((group.controls['beginDate'].value !== null || group.controls['endDate'].value !== null) && (beginDate.getTime() > endDate.getTime())) {
                group.controls['beginDate'].setErrors({timelineError: true});
                group.controls['endDate'].setErrors({timelineError: true});
            } else {
                group.controls['beginDate'].setErrors(null);
                group.controls['endDate'].setErrors(null);
                group.markAllAsTouched();
            }
            return;
        };
    }

    initUserData(fiscalCode: string) {
        const bodyData = {
            fiscalCode: fiscalCode,
        };
        this.userService.getUserByFiscalCode(bodyData).subscribe((response) => {
            if (response.success) {
                this.assistedUserForm.get('name').setValue(response.user.name);
                this.assistedUserForm.get('surname').setValue(response.user.surname);
                this.assistedUserForm.get('fiscalCode').setValue(fiscalCode);
                this.assistedUserForm.get('email').setValue(response.user.account.email);
                this.assistedUserForm.get('role').setValue(response.user.role);
                this.phoneNumbers = response.user.phoneNumbers;
            }
            let drugsBody = {
                userUuc: response.user.uuc,
            };
            this.priService.getUserDrugs(drugsBody).subscribe((response) => {
                if (response.success) {
                    this.inUseDrugList = response.userDrugs.userDrugPrescriptions.filter(this.filterInUseDrugs);
                    this.historyDrugList = response.userDrugs.userDrugPrescriptions.filter(this.filterHistoryDrugs);

                    this.inUseDrugsDataSource = new MatTableDataSource<DrugPrescription>(this.inUseDrugList);
                    this.inUseDrugsDataSource._updateChangeSubscription();
                    this.historyDrugsDataSource = new MatTableDataSource<DrugPrescription>(this.historyDrugList);
                    this.historyDrugsDataSource._updateChangeSubscription();
                }
            }, error => {
                console.log(error);
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }, () => {
                this.spinner.hide();
            });
        }, error => {
            this.showToast('Attenzione! Utente non trovato', StringUtils.ADVISE_TIME);
            this.spinner.hide();
        });
    }

    filterHistoryDrugs(drug) {
        return new Date(drug.endDate).getTime() < new Date().getTime();
    }

    filterInUseDrugs(drug) {
        return new Date(drug.endDate).getTime() >= new Date().getTime();
    }
}