import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RemovePhoneDialogComponent} from '../remove-phone-dialog/remove-phone-dialog.component';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
    FISCALCODE_REGEX,
    MAXLENGHT,
    MINLENGHT,
    NAME_REGEX,
    PHONE_NUMBER_REGEX,
    STRING_MAX_LENGHT
} from '../../../common/users-utils-constants';
import {EMAIL_REGEX} from '../../../common/commons.class';
import {UserService} from '../../../services/users/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Roles} from '../../../authentication/util/roles';
import {StringUtils} from '../../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

function fiscalCodeValidator(control: AbstractControl): { [key: string]: any } | null {
    // Perform your custom validation logic
    // Return an object with the validation error if validation fails
    if (!InsertUserPageComponent.isFiscalCodeOk(control.value)) {
        return { fiscalCodeValidation: true };
    }
    return null; // Validation passed
}

@Component({
    selector: 'app-insert-user',
    templateUrl: './insert-user-page.component.html',
    styleUrls: ['./insert-user-page.component.scss'],
})
export class InsertUserPageComponent extends ToastManager implements OnInit {
    addPressed = false;
    roles: string[];
    constructor(
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private _sBar: MatSnackBar,
        private userService: UserService,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        this.roles = Roles.roles();
        this.insertUserForm = this._formBuilder.group({
            number: ['', [ Validators.maxLength(MAXLENGHT), Validators.pattern(PHONE_NUMBER_REGEX), Validators.minLength(MINLENGHT)]],
            name: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            surname: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            fiscalCode: ['', [Validators.pattern(FISCALCODE_REGEX), fiscalCodeValidator]],
            email: ['', [Validators.required, Validators.maxLength(STRING_MAX_LENGHT), Validators.email, Validators.pattern(EMAIL_REGEX)]],
            role: ['', Validators.required],
        });
    }
    loggedUser = JSON.parse(sessionStorage.getItem('User'));
    value;
    phoneNumbers = [];
    insertUserForm: FormGroup;
    minusClicked(index): void {
        if (index > -1) {
            this.phoneNumbers.splice(index, 1);
        }
    }
    addNumber(): void {
        const formNan = !isNaN(this.insertUserForm.get('number').value);
        const alreadyWritten = !this.phoneNumbers.includes(this.insertUserForm.get('number').value);
        const emptyField = this.insertUserForm.get('number').value !== '';
        const toMuchNumber = this.phoneNumbers.length > 2;
        if (formNan && alreadyWritten && emptyField && !this.insertUserForm.get('number').invalid && !toMuchNumber) {
            this.phoneNumbers.push(this.insertUserForm.get('number').value);
            this.insertUserForm.patchValue({
                number: null
            });
        }
        if(!emptyField) {this.showToast("Attenzione! Popolare il campo Numero di Telefono", StringUtils.ADVISE_TIME);return;}
        if (!formNan) { this.showToast('Attenzione! Campo non popolato correttamente', StringUtils.ADVISE_TIME);return; }
        if (!alreadyWritten) { this.showToast('Attenzione! Numero di telefono già inserito', StringUtils.ADVISE_TIME);return; }
        if (this.insertUserForm.get('number').hasError('maxLength')) { this.showToast('Attenzione! Campo non popolato correttamente', StringUtils.ADVISE_TIME);return; }
        if (toMuchNumber) { this.showToast('Attenzione! Il numero massimo di numeri telefonici inseribili è 3', StringUtils.ADVISE_TIME);return; }
    }
    ngOnInit(): void {
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        }
        else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }

    }

    unDoClick(): void {
        this.phoneNumbers = [];
        this.insertUserForm = this._formBuilder.group({
            number: ['', [Validators.maxLength(MAXLENGHT), Validators.pattern(PHONE_NUMBER_REGEX), Validators.minLength(MINLENGHT)]],
            name: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            surname: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            fiscalCode: ['', Validators.pattern(FISCALCODE_REGEX), fiscalCodeValidator],
            email: ['', [Validators.required, Validators.maxLength(STRING_MAX_LENGHT), Validators.email, Validators.pattern(EMAIL_REGEX)]],
            role: ['', Validators.required],
        });

    }
    openPhoneRemovalDialog(phone, index): void {
        const dialogRef = this.dialog.open(RemovePhoneDialogComponent, {
            width: '350px',
            data: {
                phone,
                index
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            this.minusClicked(result);
        });
    }

    static isFiscalCodeOk(fiscalCode: string): boolean {
        const oddMap: { [key: string]: number } = {
          '0': 1,
          '1': 0,
          '2': 5,
          '3': 7,
          '4': 9,
          '5': 13,
          '6': 15,
          '7': 17,
          '8': 19,
          '9': 21,
          'A': 1,
          'B': 0,
          'C': 5,
          'D': 7,
          'E': 9,
          'F': 13,
          'G': 15,
          'H': 17,
          'I': 19,
          'J': 21,
          'K': 2,
          'L': 4,
          'M': 18,
          'N': 20,
          'O': 11,
          'P': 3,
          'Q': 6,
          'R': 8,
          'S': 12,
          'T': 14,
          'U': 16,
          'V': 10,
          'W': 22,
          'X': 25,
          'Y': 24,
          'Z': 23,
        };
      
        const evenMap: { [key: string]: number } = {
          '0': 0,
          '1': 1,
          '2': 2,
          '3': 3,
          '4': 4,
          '5': 5,
          '6': 6,
          '7': 7,
          '8': 8,
          '9': 9,
          'A': 0,
          'B': 1,
          'C': 2,
          'D': 3,
          'E': 4,
          'F': 5,
          'G': 6,
          'H': 7,
          'I': 8,
          'J': 9,
          'K': 10,
          'L': 11,
          'M': 12,
          'N': 13,
          'O': 14,
          'P': 15,
          'Q': 16,
          'R': 17,
          'S': 18,
          'T': 19,
          'U': 20,
          'V': 21,
          'W': 22,
          'X': 23,
          'Y': 24,
          'Z': 25,
        };
      
        let total = 0;
        for (let i = 0; i < 15; i += 2) {
          total += oddMap[fiscalCode[i]];
        }
        for (let i = 1; i < 15; i += 2) {
          total += evenMap[fiscalCode[i]];
        }
      
        return fiscalCode[15] === String.fromCharCode('A'.charCodeAt(0) + (total % 26));
      }

    addUser(): void {
        if (!StringUtils.ITALIAN_FISCAL_CODE.test(this.insertUserForm.get('fiscalCode').value)) {
            this.showToast("Codice fiscale non valido", StringUtils.ADVISE_TIME);
            return;
        }

        if(!InsertUserPageComponent.isFiscalCodeOk(this.insertUserForm.get('fiscalCode').value)) {
            this.showToast("Codice fiscale non valido", StringUtils.ADVISE_TIME);
            return;
        }

        this.spinner.show();
        const bodyData = {
            name: this.insertUserForm.get('name').value,
            surname: this.insertUserForm.get('surname').value,
            fiscalCode: this.insertUserForm.get('fiscalCode').value,
            email: this.insertUserForm.get('email').value,
            role: this.insertUserForm.get('role').value,
            phoneNumbers: this.phoneNumbers
        };
        this.addPressed = true;
        this.userService.insertUser(bodyData).subscribe(() => {
                this.spinner.hide();
                this.addPressed = false;
                this.showToast("Utente inserito con successo", StringUtils.ADVISE_TIME);
                this.router.navigate(["/list-users-page"]);
            }, (error: HttpErrorResponse) => {
                this.spinner.hide();
                this.addPressed = false;
                error.status === 409 ? this.showToast('Attenzione! Utente già presente', StringUtils.ADVISE_TIME) : this.showToast('Errore imprevisto', StringUtils.ADVISE_TIME); }
        );
    }
}

