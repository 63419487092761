import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-remove-evaluation-dialog',
    templateUrl: './remove-assigned-exercise-dialog.component.html',
    styleUrls: ['./remove-assigned-exercise-dialog.component.scss']
})
export class RemoveAssignedExerciseDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<RemoveAssignedExerciseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    ngOnInit(): void {
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }
}
