import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ToastManager} from '../../utils/toast-manager';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BASE_REGEX, NUMBERS_ONLY_REGEX, TEXT_AND_NUMBERS_REGEX} from '../../../common/commons.class';
import {DatePipe, DOCUMENT} from '@angular/common';
import {PriService} from '../../../services/pri/pri.service';
import {PRI} from '../../../class/pri';
import {Diagnosis} from '../../../class/interfaces/diagnosis';
import {Procedure} from '../../../class/interfaces/procedure';
import {COMORBIDITY_TYPE, DIAGNOSIS_TYPE, OPERATIONS_TYPE} from '../insert-new-pri-page/insert-new-pri-page.component';
import {Prm} from '../../../class/prm';
import {FollowUp} from '../../../class/interfaces/follow-up';
import {ReportCoping} from '../../../class/interfaces/report-coping';
import {MatTableDataSource} from '@angular/material/table';
import {BasicUserInfo} from '../../../class/interfaces/basic-user-info';
import {AddTeamElementDialog} from '../add-team-element-dialog/add-team-element-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort, Sort} from '@angular/material/sort';
import {RemoveTeamElementDialog} from '../remove-team-element-dialog/remove-team-element-dialog.component';
import {FunctionalValutation} from '../../../class/interfaces/functional-valutation';
import {AddEvaluationDialog} from '../add-evaluation-dialog/add-evaulation-dialog.component';
import {RemoveEvaluationDialogComponent} from '../remove-evaluation-dialog/remove-evaluation-dialog.component';
import {FollowUpDialogComponent} from '../follow-up-dialog/follow-up-dialog.component';
import {PrmExercise} from '../../../class/interfaces/prm-exercise';
import {UserService} from '../../../services/users/user.service';
import {EditReportCopingDialogComponent} from '../edit-report-coping-dialog/edit-report-coping-dialog.component';
import {ExerciseExecutionInterface} from '../../../class/interfaces/exercise-execution-interface';
import {MatTab, MatTabGroup, MatTabHeader, MatTabLabel} from '@angular/material/tabs';
import {Roles} from '../../../authentication/util/roles';
import {ChangeTeamElementDialog} from '../change-team-element-dialog/change-team-element-dialog.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {asyncScheduler} from 'rxjs';
import {StringUtils} from '../../../common/string-utils';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;
export const BASE_DATA_SECTION = 0;
export const HEALTH_DATA_SECTION = 3;


@Component({
    selector: 'pri-info',
    templateUrl: './pri-info-page.component.html',
    styleUrls: ['./pri-info-page.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, DatePipe
    ],
})
export class PriInfoPageComponent extends ToastManager implements OnInit, OnDestroy {

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    readonly EDIT_MODE_DISABLED = StringUtils.EDIT_MODE_DISABLED;
    readonly EDIT_AS_PHYSIATRIST = StringUtils.EDIT_MODE_PHYSIATRIST;
    readonly EDIT_AS_PSYCHOLOGIST = StringUtils.EDIT_MODE_PSYCHOLOGIST;

    userName: string;
    userSurname: string;
    userFiscalCode: string;
    priId: number;
    private userUUC: string;
    loggedUser: any;
    sub: any;
    public editMode: number;
    private originalPRI: PRI;
    private originalPRM: Prm;
    private originalFollowUps: FollowUp[];
    private originalReportCopings: ReportCoping[];
    filterDiagnosisValue: string;
    filterComorbidityValue: string;
    filterProceduresValue: string;

    userDataForm: FormGroup;
    projectDataForm: FormGroup;
    standardDiagnosis: Diagnosis[];
    standardProcedures: Procedure[];
    diagnosisHolder: Diagnosis[];
    filteredDiagnosis: Diagnosis[];
    filteredComorbidity: Diagnosis[];
    filteredProcedures: Procedure[];
    diagnosis: {
        available: Diagnosis[],
        found: Diagnosis[]
    };
    comorbidity: {
        available: Diagnosis[],
        found: Diagnosis[]
    };
    procedures: {
        available: Procedure[],
        found: Procedure[]
    };
    selectedAvailableDiagnosisPatology: string;
    selectedAvailableComorbidityPatology: string;
    selectedAvailableOperation: string;
    selectedFoundDiagnosisPatology: string;
    selectedFoundComorbidityPatology: string;
    selectedFoundOperation: string;
    swapAddDirection: boolean;

    rehabilitationTeamDataSource: MatTableDataSource<any>;
    teamList: BasicUserInfo[];
    teamDisplayedColumns = ['surname', 'name', 'role', 'actions'];

    evaluationsDataSource: MatTableDataSource<any>;
    evaluationsList: FunctionalValutation[];
    evaluationsDisplayedColumns = ['acronym', 'category', 'attribute', 'value', 'actions'];

    healthParametersForm: FormGroup;
    availableRythms: String[];

    followUpDataSource: MatTableDataSource<FollowUp>;
    followUpList: FollowUp[];
    followUpDisplayedColumns = ['date', 'lro', 'editPlan', 'actions'];

    reportCopingDataSource: MatTableDataSource<ReportCoping>;
    reportCopingList: ReportCoping[];
    reportCopingDisplayedColumns = ['date', 'valutation', 'actions'];

    exercisesList: PrmExercise[];
    exercisesSource: MatTableDataSource<PrmExercise>;
    displayedColumns = ['execOrder', 'category', 'name', 'scheduledDate', 'workingTime', 'actions'];
    prmDataForm: FormGroup;
    prmPhysiotherapist: BasicUserInfo;
    backDest: number;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private _document: Document,
        private priService: PriService,
        private spinner: NgxSpinnerService,
        private userService: UserService
    ) {
        super(snackBar);
    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userName = params['name'];
            this.backDest = Number(params['origin']);
            this.userSurname = params['surname'];
            this.userUUC = params['userUUC'];
            this.userFiscalCode = params['fiscalCode'];
            this.editMode = Number(params['edit']);
            if (this.editMode === this.EDIT_MODE_DISABLED) {
                this.priId = params['priId'];
                this.teamDisplayedColumns.pop();
                this.evaluationsDisplayedColumns.pop();
                this.reportCopingDisplayedColumns.pop();
            }else if(this.editMode === this.EDIT_AS_PHYSIATRIST) {
                this.reportCopingDisplayedColumns.pop();
            }else if(this.editMode === this.EDIT_AS_PSYCHOLOGIST) {
                this.teamDisplayedColumns.pop();
                this.evaluationsDisplayedColumns.pop();
            }
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }

        this.userDataForm = this._formBuilder.group({
            name: [{value: this.userName, disabled: true}, []],
            surname: [{value: this.userSurname, disabled: true}, []]
        });

        this.projectDataForm = this._formBuilder.group({
            name: [{
                value: '',
                disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required, Validators.pattern(TEXT_AND_NUMBERS_REGEX)]],
            startDate: [{value: '', disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required]],
            daysCount: [{
                value: '',
                disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX), Validators.max(365)]],
            anamnesis: [{
                value: '',
                disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(BASE_REGEX)]],
            synthesis: [{
                value: '',
                disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(BASE_REGEX)]],
            goals: [{
                value: '',
                disabled: this.editMode === this.EDIT_MODE_DISABLED || this.editMode === this.EDIT_AS_PSYCHOLOGIST
            }, [Validators.required, Validators.max(StringUtils.MAX_DESCRIPTION), Validators.pattern(BASE_REGEX)]],
        });
        this.healthParametersForm = this._formBuilder.group({
            id: [{value: '', disabled: this.editMode === this.EDIT_MODE_DISABLED}, []],
            diastolicBloodPressure: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            systolicBloodPressure: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioFrequency: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioRhythm: [{value: '', disabled: true}, [Validators.required]],
            breathFrequency: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            arterialSaturation: [{
                value: '',
                disabled: true
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]]
        });
        this.healthParametersForm.setValidators(this.bloodPressureValidator());
        this.prmDataForm = this._formBuilder.group({
            physiotherapist: [{value: '', disabled: true}, []],
            status: [{value: '', disabled: true}, []],
            beginDate: [{value: '', disabled: true}, []],
            endDate: [{value: '', disabled: true}, []]
        });
        this.availableRythms = ['Regolare', 'Irregolare', 'Sinusale', 'Basso', 'Alto'];
        this.tabGroup._handleClick = this.tabChangeEvent.bind(this);
        this.loadProjectDataLists();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    initData() {
        this.spinner.show();
        this.priService.getPRI(this.userUUC, this.priId).subscribe(response => {
            this.originalPRI = response.pri;
            this.originalPRM = response.prm;
            this.originalFollowUps = response.followUps;
            this.originalReportCopings = response.reportCopings;
            this.setPrmTeamData(response.pri, response.prm);
            this.setBaseData(response.pri);
            this.setFunctionalValutations(response.pri);
            this.setHealthStateData(response.pri);
            this.setFollowUps(response.followUps);
            this.setReportCopings(response.reportCopings);
            this.spinner.hide();

            if(this.originalPRM != null) {
                this.projectDataForm.get("startDate").disable();
            }

        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            this.spinner.hide();
        });
    }

    changeSwapDirection(direction: boolean) {
        this.swapAddDirection = direction;
    }

    swapData(swapType: number) {
        switch (swapType) {
            case DIAGNOSIS_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableDiagnosisPatology.length !== 0) {
                        this.swap(this.diagnosis.available, this.diagnosis.found, this.selectedAvailableDiagnosisPatology[0], false);
                    }
                } else {
                    if (this.selectedFoundDiagnosisPatology.length !== 0) {
                        this.swap(this.diagnosis.found, this.diagnosis.available, this.selectedFoundDiagnosisPatology[0], false);
                    }
                }
                this.filterPatologies();
                break;
            case COMORBIDITY_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableComorbidityPatology.length !== 0) {
                        this.swap(this.comorbidity.available, this.comorbidity.found, this.selectedAvailableComorbidityPatology[0], true);
                    }
                } else {
                    if (this.selectedFoundComorbidityPatology.length !== 0) {
                        this.swap(this.comorbidity.found, this.comorbidity.available, this.selectedFoundComorbidityPatology[0], false);
                    }
                }
                this.filterComorbidity();
                break;
            case OPERATIONS_TYPE:
                if (this.swapAddDirection) {
                    if (this.selectedAvailableOperation.length !== 0) {
                        this.swap(this.procedures.available, this.procedures.found, this.selectedAvailableOperation[0]);
                    }
                } else {
                    if (this.selectedFoundOperation.length !== 0) {
                        this.swap(this.procedures.found, this.procedures.available, this.selectedFoundOperation[0]);
                    }
                }
                this.filterProcedures();
                break;
            default:
                break;
        }

    }

    swap(from, to, value, isComorbidity?: boolean) {
        if (isComorbidity !== undefined) {
            value.comorbidity = isComorbidity;
        }
        from.splice(from.indexOf(value), 1);
        to.push(value);
        to.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
    }

    loadProjectDataLists() {
        this.priService.getProjectDataList().subscribe((data) => {
            this.standardDiagnosis = data.priDiagnosisListData.pathologies;
            this.standardProcedures = data.priDiagnosisListData.surgeryOperations;
            this.diagnosis = {available: this.standardDiagnosis.concat(), found: []};
            this.comorbidity = {available: this.standardDiagnosis.concat(), found: []};
            this.procedures = {available: this.standardProcedures.concat(), found: []};
            this.initData();
        }, error => {
            this.showToast('Errore di rete', StringUtils.ADVISE_TIME);
        });
    }

    resetData(section: number) {
        switch (section) {
            case BASE_DATA_SECTION:
                this.setBaseData(this.originalPRI);
                break;
            case HEALTH_DATA_SECTION:
                this.setHealthStateData(this.originalPRI);
                break;
            default:
                break;
        }
    }

    updateData() {
        if (this.isProjectDataEmpty() || this.isTeamInvalid() || this.evaluationsDataSource.data.length === 0 || this.isHealthParamsInvalid()) {
            this.projectDataForm.markAllAsTouched();
            this.healthParametersForm.markAllAsTouched();
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.projectDataForm.valid || !this.healthParametersForm.valid) {
            this.projectDataForm.markAllAsTouched();
            this.healthParametersForm.markAllAsTouched();
            this.showToast('Attenzione! Campi non compilati correttamente', StringUtils.ADVISE_TIME);
            return;
        }
        let physiatrist = this.teamList.filter((user) => {
            return user.role === Roles.physiatrist;
        })[0];
        let editedPRI = {
            id: this.originalPRI.id,
            prmList: null,
            procedures: this.procedures.found,
            diagnosis: this.diagnosis.found.concat(this.comorbidity.found),
            functionalValutations: this.evaluationsDataSource.data,
            name: this.projectDataForm.get('name').value,
            assignDate: this.projectDataForm.get('startDate').value,
            endDate: null,
            workingDays: this.projectDataForm.get('daysCount').value,
            anamnesis: this.projectDataForm.get('anamnesis').value,
            synthesis: this.projectDataForm.get('synthesis').value,
            goals: this.projectDataForm.get('goals').value,
            physiatristId: physiatrist.uuc,
            physiatristNominative: physiatrist.surname + ' ' + physiatrist.name,
            physiotherapistIds: this.teamList.filter((user) => {
                return user.role === Roles.physiotherapist;
            }).map(element => element.uuc),
            assistedId: this.userUUC,
            psychologistId: this.teamList.filter((user) => {
                return user.role === Roles.psychologist;
            })[0].uuc,
            healthParamters: this.originalPRI.healthParamters
        };
        const body = {
            pri: editedPRI
        };
        this.priService.editPRI(body).subscribe((response) => {
            this._document.defaultView.location.reload();
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    openAddUserDialog() {
        const dialogRef = this.dialog.open(AddTeamElementDialog, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined) {
                this.addTeamElement(data);
            }
        });
    }

    openEditReportCopingDialog(report) {
        const dialogRef = this.dialog.open(EditReportCopingDialogComponent, {
            data: {
                report: report
            },
            disableClose: true,
        });
    }

    addTeamElement(element: BasicUserInfo) {
        if (this.teamList.some(listElement => listElement.name === element.name &&
            listElement.surname === element.surname &&
            listElement.role === element.role)) {
            this.showToast('Attenzione! Personale già presente', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.teamList.some(listElement =>
            listElement.role !== Roles.physiatrist && listElement.role !== Roles.physiotherapist && listElement.role === element.role)) {
            this.showToast('Attenzione! Ruolo già definito', StringUtils.ADVISE_TIME);
            return;
        }
        this.teamList = this.teamList.concat(element);
        this.rehabilitationTeamDataSource.data = this.teamList;
        this.sort.sort({id: null, start: 'desc', disableClear: false});
        this.sort.sort({id: 'surname', start: 'desc', disableClear: false});
        this.rehabilitationTeamDataSource._updateChangeSubscription();

    }

    removeTeamElement(index: number) {
        this.teamList.splice(index, 1);
        this.rehabilitationTeamDataSource.data = this.teamList;
        this.rehabilitationTeamDataSource._updateChangeSubscription();
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'drugName':
                    return this.compare(a.drugName.name, b.drugName.name, isAsc);
                case 'beginDate':
                    return this.compare(a.beginDate, b.beginDate, isAsc);
                case 'date':
                    return this.compare(a.date, b.date, isAsc);
                case 'endDate':
                    return this.compare(a.endDate, b.endDate, isAsc);
                case 'surname':
                    return this.compare(a.surname, b.surname, isAsc);
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'role':
                    return this.compare(a.role, b.role, isAsc);
                case 'acronym':
                    return this.compare(a.acronym, b.acronym, isAsc);
                case 'category':
                    return this.compare(a.category, b.category, isAsc);
                case 'attribute':
                    return this.compare(a.attribute, b.attribute, isAsc);
                case 'value':
                    return this.compare(a.value, b.value, isAsc);
                case 'lro':
                    return this.compare(a.lro, b.lro, isAsc);
                case 'valutation':
                    return this.compare(a.valutation, b.valutation, isAsc);
                case 'execOrder':
                    return this.compare(a.sequence, b.sequence, isAsc);
                case 'scheduledDate':
                    return this.compare(a.scheduledDate, b.scheduledDate, isAsc);
                default:
                    return 0;
            }
        });
        this.cdr.detectChanges();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    openDeleteTeamElementDialog(selectedElement: any, index: number) {
        const dialogRef = this.dialog.open(RemoveTeamElementDialog, {
            data: {
                name: selectedElement.name,
                surname: selectedElement.surname,
                role: selectedElement.role
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.removeTeamElement(index);
            }
        });
    }

    openAddEvaluationDialog() {
        const dialogRef = this.dialog.open(AddEvaluationDialog, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined) {
                this.addEvaluation(data);
            }
        });
    }

    openDeleteEvaluationDialog(index: number) {
        const dialogRef = this.dialog.open(RemoveEvaluationDialogComponent, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.removeEvaluation(index);
            }
        });
    }

    private addEvaluation(data: any) {
        if (this.evaluationsList.some(listElement => listElement.attributeValutation.dimensionValutation.standardValutation.acronym === data.attributeValutation.dimensionValutation.standardValutation.acronym &&
            listElement.attributeValutation.dimensionValutation.description === data.attributeValutation.dimensionValutation.description &&
            listElement.attributeValutation.name === data.attributeValutation.name &&
            listElement.value === data.value)) {
            this.showToast('Attenzione! Valutazione già presente', StringUtils.ADVISE_TIME);
            return;
        }
        this.evaluationsList = this.evaluationsList.concat(data);
        this.evaluationsDataSource.data = this.evaluationsList;
        this.sort.sort({id: null, start: 'desc', disableClear: false});
        this.sort.sort({id: 'acronym', start: 'desc', disableClear: false});
        this.evaluationsDataSource._updateChangeSubscription();
    }

    private removeEvaluation(index: number) {
        this.evaluationsList.splice(index, 1);
        this.evaluationsDataSource.data = this.evaluationsList;
        this.evaluationsDataSource._updateChangeSubscription();
    }

    openFollowUpDialog(followUp: FollowUp, editMode: boolean) {
        const dialogRef = this.dialog.open(FollowUpDialogComponent, {
            data: {followUp: followUp, editMode: editMode},
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this._document.defaultView.location.reload();
            }
        });
    }

    private setBaseData(pri: PRI) {
        this.diagnosis.available.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
        this.comorbidity.available.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
        this.procedures.available.sort((firstValue, secondValue) => (firstValue.name.toLowerCase() > secondValue.name.toLowerCase() ? 1 : -1));
        this.diagnosis.found = [];
        this.comorbidity.found = [];
        this.procedures.found = [];
        this.projectDataForm.setValue({
            name: pri.name,
            startDate: this.datePipe.transform(new Date(pri.assignDate), 'yyyy-MM-dd'),
            daysCount: pri.workingDays,
            anamnesis: pri.anamnesis,
            synthesis: pri.synthesis,
            goals: pri.goals
        });
        for (let diagnosis of pri.diagnosis) {
            this.diagnosis.available.splice(this.diagnosis.available.findIndex(d => {
                return d.code === diagnosis.code;
            }), 1);
            if (!diagnosis.comorbidity) {
                this.diagnosis.found.push(diagnosis);
            } else {
                this.comorbidity.found.push(diagnosis);
            }
        }
        for (let procedure of pri.procedures) {
            this.procedures.available.splice(this.procedures.available.findIndex(p => {
                return p.code === procedure.code;
            }), 1);
            this.procedures.found.push(procedure);
        }
    }

    private setPrmTeamData(pri: PRI, prm: Prm) {
        let uucs: string[] = [];
        uucs = pri.physiotherapistIds;
        uucs.unshift(pri.physiatristId);
        uucs.unshift(pri.psychologistId);
        const body = {
            usersUuc: uucs
        };
        this.userService.getUsersByUuuc(body).subscribe((response) => {
            this.teamList = response.users;
            this.rehabilitationTeamDataSource = new MatTableDataSource<BasicUserInfo>(this.teamList);

            if(prm !== null) {
                this.exercisesList = prm.exerciseExecutions;
                this.exercisesSource = new MatTableDataSource<PrmExercise>(this.exercisesList);
                this.prmPhysiotherapist = this.teamList.filter((elem) => {
                    return elem.uuc === prm.physiotherapistId;
                })[0];
                this.prmDataForm.setValue({
                    physiotherapist: this.prmPhysiotherapist.surname + ' ' + this.prmPhysiotherapist.name,
                    status: prm.endDate != null ? 'Terminato' : 'Attivo',
                    beginDate: prm.beginDate != null ? this.datePipe.transform(prm.beginDate, 'dd/MM/yyyy') : 'In attesa del primo esercizio',
                    endDate: prm.endDate != null ? this.datePipe.transform(prm.endDate, 'dd/MM/yyyy') : 'In corso'
                });
            }
        });

    }

    private setFunctionalValutations(pri: PRI) {
        this.evaluationsList = pri.functionalValutations;
        this.evaluationsDataSource = new MatTableDataSource<FunctionalValutation>(this.evaluationsList);
    }

    private setHealthStateData(pri: PRI) {
        this.healthParametersForm.setValue(pri.healthParamters);
    }

    private setFollowUps(followUps: FollowUp[]) {
        this.followUpList = followUps;
        this.followUpDataSource = new MatTableDataSource<FollowUp>(this.followUpList);
    }

    private setReportCopings(reportCopings: ReportCoping[]) {
        this.reportCopingList = reportCopings;
        this.reportCopingDataSource = new MatTableDataSource<ReportCoping>(this.reportCopingList);
    }

    openExerciseExecutionInfoPage(exercise: PrmExercise) {
        this.router.navigate(['/exercise-execution-detail'], {
            queryParams: {
                userName: this.userSurname + ' ' + this.userName,
                uuc: this.userUUC,
                executionId: exercise.id,
                physiotherapistNominative: this.prmPhysiotherapist.surname + ' ' + this.prmPhysiotherapist.name
            }
        });
    }

    isProjectDataEmpty() {
        return this.diagnosis.found.length === 0 || this.projectDataForm.get('name').hasError('required') || this.projectDataForm.get('startDate').hasError('required') || this.projectDataForm.get('daysCount').hasError('required')
            || this.projectDataForm.get('anamnesis').hasError('required') || this.projectDataForm.get('synthesis').hasError('required') || this.projectDataForm.get('goals').hasError('required');
    }

    isTeamInvalid() {
        return !this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.physiatrist) ||
            !this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.physiotherapist) ||
            !this.rehabilitationTeamDataSource.data.some(teamElement => teamElement.role === Roles.psychologist);
    }

    isHealthParamsInvalid() {
        return this.healthParametersForm.get('diastolicBloodPressure').hasError('required') || this.healthParametersForm.get('systolicBloodPressure').hasError('required') ||
            this.healthParametersForm.get('cardioFrequency').hasError('required') || this.healthParametersForm.get('cardioRhythm').hasError('required') ||
            this.healthParametersForm.get('breathFrequency').hasError('required') || this.healthParametersForm.get('arterialSaturation').hasError('required');
    }

    tabChangeEvent(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
        if (this.editMode === this.EDIT_AS_PHYSIATRIST) {
            if (this.tabGroup.selectedIndex === 0 && idx !== 0) {
                this.projectDataForm.markAllAsTouched();
                if (this.isProjectDataEmpty()) {
                    this.projectDataForm.markAllAsTouched();
                    this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return false;
                }
                if (!this.projectDataForm.valid) {
                    this.projectDataForm.markAllAsTouched();
                    this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return false;
                }
            } else if (this.tabGroup.selectedIndex === 1 && idx !== 1) {
                if (this.isTeamInvalid()) {
                    this.showToast('Attenzione! Per completare il passo Team riabilitativo è necessario aggiungere un fisiatra, uno psicologo e uno o più fisioterapisti.', StringUtils.ADVISE_TIME);
                    return false;
                }
            } else if (this.tabGroup.selectedIndex === 2 && idx !== 2) {
                if (this.evaluationsDataSource.data.length === 0) {
                    this.showToast('Attenzione! Dati non completi', StringUtils.ADVISE_TIME);
                    return false;
                }
            } else if (this.tabGroup.selectedIndex === 3 && idx !== 3) {
                this.healthParametersForm.markAllAsTouched();
                if (this.isHealthParamsInvalid()) {
                    this.healthParametersForm.markAllAsTouched();
                    this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return false;
                }
                if (!this.healthParametersForm.valid) {
                    this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                    return false;
                }
            }
        }

        return MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    bloodPressureValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const diastolicPressure = Number(group.controls['diastolicBloodPressure'].value);
            const systolicPressure = Number(group.controls['systolicBloodPressure'].value);
            if (diastolicPressure !== undefined && diastolicPressure !== null &&
                systolicPressure !== undefined && systolicPressure !== null &&
                diastolicPressure > systolicPressure) {
                group.controls['diastolicBloodPressure'].setErrors({pressureError: true});
                group.controls['systolicBloodPressure'].setErrors({pressureError: true});
            } else {
                if (group.controls['diastolicBloodPressure'].hasError('pressureError')) {
                    group.controls['diastolicBloodPressure'].setErrors({pressureError: false});
                }
                if (group.controls['systolicBloodPressure'].hasError('pressureError')) {
                    group.controls['systolicBloodPressure'].setErrors({pressureError: false});
                }
            }
            if (group.controls['diastolicBloodPressure'].value !== null
                && group.controls['systolicBloodPressure'].value !== null
                && group.controls['diastolicBloodPressure'].value !== undefined
                && group.controls['systolicBloodPressure'].value !== undefined
                && group.controls['diastolicBloodPressure'].value !== ''
                && group.controls['systolicBloodPressure'].value !== '') {
                group.controls['diastolicBloodPressure'].markAllAsTouched();
                group.controls['systolicBloodPressure'].markAllAsTouched();
            }
            return;
        };
    }

    public goBack() {
        if (this.backDest === StringUtils.LIST_USERS_ORIGIN) {
            this.router.navigate(['/list-assisted-users']);
        } else if(this.backDest === StringUtils.PRI_HISTORY_ORIGIN) {
            this.router.navigate(['/user-pri-history'], {
                queryParams: {
                    userName: this.userName,
                    userSurname: this.userSurname,
                    uuc: this.userUUC,
                    fCode: this.userFiscalCode
                }
            });
        }
    }

    filterPatologies() {
        this.filteredDiagnosis = this.getFilteredAvailableData(this.diagnosis.available, this.filterDiagnosisValue);
    }

    filterComorbidity() {
        this.filteredComorbidity = this.getFilteredAvailableData(this.comorbidity.available, this.filterComorbidityValue);
    }

    filterProcedures() {
        this.filteredProcedures = this.getFilteredAvailableData(this.procedures.available, this.filterProceduresValue);
    }

    getFilteredAvailableData(data: any[], filter: string) {
        if (filter !== null && filter !== undefined &&
            filter !== '') {
            let keyWords: string[] = filter.split(' ');
            return data.filter((toFilter) => {
                let isValid = true;
                for (let key of keyWords) {
                    if (!toFilter.code.toLowerCase().includes(key.toLowerCase()) &&
                        !toFilter.name.toLowerCase().includes(key.toLowerCase())) {
                        isValid = false;
                        break;
                    }
                }
                return isValid;
            });
            return [];
        }
    }

    openChangeTeamElementDialog(i: any) {
        const dialogRef = this.dialog.open(ChangeTeamElementDialog, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined) {
                this.changeTeamElement(data, i);
            }
        });
    }

    changeTeamElement(element: BasicUserInfo, index: number) {
        this.teamList[index] = element;
        this.rehabilitationTeamDataSource.data = this.teamList;
        this.sort.sort({id: null, start: 'desc', disableClear: false});
        this.sort.sort({id: 'surname', start: 'desc', disableClear: false});
        this.rehabilitationTeamDataSource._updateChangeSubscription();
    }
}