import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { myCustomTooltipDefaults } from '../../../common/custom-tooltip';
import { ToastManager } from '../../utils/toast-manager';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NUMBERS_ONLY_REGEX } from '../../../common/commons.class';
import { MatTableDataSource } from '@angular/material/table';
import { FollowUp } from '../../../class/interfaces/follow-up';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFollowupDialogComponent } from '../remove-followup-dialog/remove-followup-dialog.component';
import { DatePipe, DOCUMENT, Time } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FollowUpDialogComponent } from '../follow-up-dialog/follow-up-dialog.component';
import { PriService } from '../../../services/pri/pri.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { StringUtils } from '../../../common/string-utils';

export const FOLLOW_UP_OK = 200;
export const DUPLICATED_FOLLOW_UP = 409;

@Component({
    selector: 'follow-up-manager',
    templateUrl: './follow-up-manager-page.component.html',
    styleUrls: ['./follow-up-manager-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, DatePipe
    ],
})
export class FollowUpManagerPageComponent extends ToastManager implements OnInit, OnDestroy {

    @ViewChild(MatSort) sort: MatSort;

    @ViewChild(MatPaginator) set paginator(pg: MatPaginator) {
        this.paginatorValue = pg;
        if (this.followUpDataSource) {
            this.followUpDataSource.paginator = pg;
        }
    }

    @ViewChild(MatExpansionPanel) newFollowUpPanel: MatExpansionPanel;

    userNominative: string;
    private userUUC: string;

    loggedUser: any;
    sub: any;
    generalDataForm: FormGroup;
    healthParametersForm: FormGroup;
    availableRythms: String[];
    private paginatorValue;
    private filterPredicate: {
        beginDate: Date,
        endDate: Date
    };
    filterForm: FormGroup;
    followUpDataSource: MatTableDataSource<any>;
    followUpList: FollowUp[];
    followUpDisplayedColumns = ['date', 'actions'];

    private selectedDate: Date;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        @Inject(DOCUMENT) private _document: Document,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private snackBar: MatSnackBar,
        private priService: PriService
    ) {
        super(snackBar);
        this.spinner.show();
        this.followUpList = [];
        this.followUpDataSource = new MatTableDataSource(this.followUpList);

        this.healthParametersForm = this._formBuilder.group({
            diastolicBloodPressure: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            systolicBloodPressure: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioFrequency: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioRhythm: ['', [Validators.required]],
            breathFrequency: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            arterialSaturation: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]]
        });
        this.healthParametersForm.valueChanges.subscribe(val => {
            if (Number(val.diastolicBloodPressure) > Number(val.systolicBloodPressure)) {
                if (val.diastolicBloodPressure !== null && val.diastolicBloodPressure !== '' &&
                    val.systolicBloodPressure !== null && val.systolicBloodPressure !== '') {
                    this.healthParametersForm.get('diastolicBloodPressure').setErrors({ sizeError: true });
                    this.healthParametersForm.get('systolicBloodPressure').setErrors({ sizeError: true });
                }
            } else {
                if (val.diastolicBloodPressure !== null && val.diastolicBloodPressure !== '') {
                    if (!this.healthParametersForm.get('diastolicBloodPressure').hasError("pattern")) {
                        this.healthParametersForm.get('diastolicBloodPressure').setErrors(null);
                    }
                }
                if (val.systolicBloodPressure !== null && val.systolicBloodPressure !== '') {
                    if (!this.healthParametersForm.get('systolicBloodPressure').hasError("pattern")) {
                        this.healthParametersForm.get('systolicBloodPressure').setErrors(null);
                    }
                }
            }
        });

        this.generalDataForm = this._formBuilder.group({
            date: ['', [Validators.required]],
            time: ['', [Validators.required]],
            lro: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            estimatedLro: [{ value: '', disabled: true }, [Validators.pattern(NUMBERS_ONLY_REGEX)]],
            editPlan: ['', [Validators.required]],
            note: ['', Validators.maxLength(1000)]
        });

        this.filterForm = this._formBuilder.group({
            beginDate: ['', []],
            beginTime: ['', [Validators.required]],
            endDate: ['', []],
            endTime: ['', [Validators.required]]
        });
        this.filterForm.setValidators(this.datesCheckValidator());
        this.availableRythms = ['Regolare', 'Irregolare', 'Sinusale', 'Basso', 'Alto'];
    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userNominative = params['userName'];
            this.userUUC = params['userUUC'];
            this.generalDataForm.controls['estimatedLro'].setValue(params['estimatedLro']);
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        this.refreshFollowUpList();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        if (!sort.active || sort.direction === '') {
            return;
        }
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'date':
                    return this.compare(a.date, b.date, isAsc);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    filterButton(): void {
        if ((!this.filterForm.get('beginDate').value || this.filterForm.get('beginDate').value == "") && (!this.filterForm.get('endDate').value || this.filterForm.get('endDate').value == "")) {
            this.showToast("Attenzione! Selezionare almeno un campo data e ora", StringUtils.ADVISE_TIME);
        } else {
            console.log(this.filterForm.get('beginDate').value)
            this.filter();
        }
    }

    filter(): void {
        this.filterPredicate = {
            beginDate: null,
            endDate: null,
        };

        let beginDate = this.filterForm.get('beginDate').value;
        let endDate = this.filterForm.get('endDate').value;

        let beginTime = this.filterForm.get('beginTime').value;
        let endTime = this.filterForm.get('endTime').value;

        if (beginTime == "" || beginTime == null) {
            beginTime = "00:00";
        }

        if (endTime == "" || endTime == null) {
            endTime = "23:59"
        }

        if (beginDate == "" || beginDate == null) {
            beginDate = new Date().toISOString().split('T')[0]
        }
        this.filterPredicate.beginDate = new Date(beginDate + "T" + beginTime);

        if (endDate == "" || endDate == null) {
            endDate = new Date().toISOString().split('T')[0]
        }
        this.filterPredicate.endDate = new Date(endDate + "T" + endTime);

        if (new Date(this.filterPredicate.beginDate).getTime() > this.filterPredicate.endDate.getTime()) {
            this.showToast('Attenzione! La Data da e l\'ora devono essere precedenti alla Data a e l\'ora.', StringUtils.ADVISE_TIME);
            return;
        }

        if (new Date(this.filterPredicate.beginDate).getTime() == this.filterPredicate.endDate.getTime()) {
            this.showToast('Attenzione! La data di inizio coincide con la data di fine!', StringUtils.ADVISE_TIME);
            return;
        }

        this.followUpDataSource.filter = JSON.stringify(this.filterPredicate);
        this.followUpDataSource.filterPredicate = this.createFilter();
        this.followUpDataSource.filter = JSON.stringify(this.filterPredicate);
    }

    createFilter(): (data: any, filter: string) => boolean {
        // tslint:disable-next-line:only-arrow-functions
        const filterFunction = function (data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return new Date(data.date).getTime() >= new Date(searchTerms.beginDate).getTime() &&
                new Date(data.date).getTime() <= new Date(searchTerms.endDate).getTime();
        };
        return filterFunction;
    }

    resetFilters(): void {
        this.filterForm.reset();
        this.followUpDataSource.filter = '';
    }

    openDeleteFollowUpDialog(id: number) {
        const dialogRef = this.dialog.open(RemoveFollowupDialogComponent, {

            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.deleteFollowUp(id);
            }
        });
    }

    openFollowUpDialog(followUp: FollowUp, editMode: boolean) {
        const dialogRef = this.dialog.open(FollowUpDialogComponent, {
            data: { followUp: followUp, editMode: editMode },
            disableClose: true,
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this._document.defaultView.location.reload();
            }
        });
    }

    deleteFollowUp(id: number) {
        const body = {
            followUpId: id
        };
        this.priService.deleteFollowUp(body).subscribe((result: any) => {
            if (result.success) {
                this._document.defaultView.location.reload();
            } else {
                this.showToast('Attenzione! Impossibile eliminare il follow-up', StringUtils.ADVISE_TIME);
            }
        }, error => {
            this.showToast('Attenzione! Servizio momentaneamente non funzionante', StringUtils.ADVISE_TIME);
        });
    }

    refreshFollowUpList() {
        this.priService.getAssistedFollowUp(this.userUUC).subscribe((response) => {
            if (response.success) {
                this.followUpList = response.followUps;
                this.followUpDataSource.data = this.followUpList;
                this.sort.sort({ id: null, start: 'desc', disableClear: false });
                this.sort.sort({ id: 'date', start: 'asc', disableClear: false });
                this.followUpDataSource._updateChangeSubscription();
            } else {
                this.showToast('Attenzione! Al momento non è possibile caricare i dati', StringUtils.ADVISE_TIME);
                this.spinner.hide();
            }
        }, error => {
            this.spinner.hide();
        }, () => {
            this.spinner.hide();
        });
    }

    packFollowUp() {
        let followUp: FollowUp;
        followUp = this.generalDataForm.value;
        followUp.date = this.selectedDate;
        followUp.estimatedLro = this.generalDataForm.get('estimatedLro').value;
        followUp.healthParamters = this.healthParametersForm.value;
        followUp.date = moment(followUp.date).toDate();
        return followUp;
    }

    saveFollowUp() {
        if (this.generalDataForm.get('date').hasError('dateError')) {
            this.showToast('Attenzione! La data deve essere precedente o uguale alla data odierna', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.healthParametersForm.get('diastolicBloodPressure').hasError('required') || this.healthParametersForm.get('systolicBloodPressure').hasError('required') ||
            this.healthParametersForm.get('cardioFrequency').hasError('required') || this.healthParametersForm.get('cardioRhythm').hasError('required') ||
            this.healthParametersForm.get('breathFrequency').hasError('required') || this.healthParametersForm.get('arterialSaturation').hasError('required') ||
            this.generalDataForm.get('date').hasError('required') || this.generalDataForm.get('lro').hasError('required') || this.generalDataForm.get('time').hasError('required') || this.generalDataForm.get('editPlan').hasError('required')) {
            this.healthParametersForm.markAllAsTouched();
            this.generalDataForm.markAllAsTouched();
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.healthParametersForm.valid || !this.generalDataForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        const body = {
            assistedId: this.userUUC,
            followUp: this.packFollowUp()
        };
        this.priService.insertFollowUp(body).subscribe((data) => {

            if (data.success) {
                this.showToast('Follow-up inserito con successo', StringUtils.ADVISE_TIME);
                this.resetFollowUp();
                this.newFollowUpPanel.close();
                this.refreshFollowUpList();
            } else {
                this.showToast('Attenzione! Follow-up già presente nella data selezionata.', StringUtils.ADVISE_TIME);
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    datesCheckValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const beginDate = group.controls['beginDate'];
            const beginTime = group.controls['beginTime'];
            const endDate = group.controls['endDate'];
            const endTime = group.controls['endTime'];
            if (beginDate !== null && endDate !== null) {
                let begTime;
                if (beginTime.value == "" || beginTime.value == null) {
                    begTime = "00:00";
                } else {
                    begTime = beginTime.value;
                }

                let enTime;
                if (endTime.value == "" || endTime.value == null) {
                    enTime = "23:59";
                } else {
                    enTime = endTime.value;
                }

                if (new Date(beginDate.value + "T" + begTime).getTime() > new Date(endDate.value + "T" + enTime).getTime()) {
                    beginDate.setErrors({ timelineError: true });
                    endDate.setErrors({ timelineError: true });
                    beginTime.setErrors({ timelineError: true });
                    endTime.setErrors({ timelineError: true });
                } else {
                    beginDate.setErrors(null);
                    endDate.setErrors(null);
                    beginTime.setErrors(null);
                    endTime.setErrors(null);
                }
            }
            return;
        };
    }

    resetFollowUp() {
        this.generalDataForm.reset();
        this.healthParametersForm.reset();
    }

    dateChange() {
        let time = "00:00";
        let date = this.generalDataForm.get('date').value;
        if (this.generalDataForm.get('time').value !== "")
            time = this.generalDataForm.get('time').value;
        this.selectedDate = new Date(date + "T" + time);
        if (date != undefined && this.selectedDate.getTime() != 0) {

            if(new Date(date).getTime() > new Date().getTime()){
                if (!this.generalDataForm.get('date').hasError('dateError')) {
                    this.generalDataForm.get('date').setErrors({ dateError: true });
                }
            } else {
                this.generalDataForm.get('date').setErrors(null);
            }

            if (this.generalDataForm.get('time').value !== ""){
                if (this.selectedDate.getTime() > new Date().getTime()) {
                    if (!this.generalDataForm.get('date').hasError('dateError')) {
                        this.generalDataForm.get('date').setErrors({ dateError: true });
                        this.generalDataForm.get('time').setErrors({ invalid: true });
                    }
                } else {
                    this.generalDataForm.get('date').setErrors(null);
                    this.generalDataForm.get('time').setErrors(null);
                }
            }
        }
    }
}

