import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Standard} from '../../../class/interfaces/standard';
import {Category} from '../../../class/interfaces/category';
import {Attribute} from '../../../class/interfaces/attribute';
import {Scale} from '../../../class/interfaces/scale';
import {FunctionalValutation} from '../../../class/interfaces/functional-valutation';
import {PriService} from '../../../services/pri/pri.service';
import {StringUtils} from '../../../common/string-utils';


export const STANDARD_LOAD = "0";
export const CATEGORY_LOAD = "1";
export const ATTRIBUTE_LOAD = "2";
export const VALUE_LOAD = "3";

@Component({
    selector: 'add-evaluation-dialog',
    templateUrl: './add-evaulation-dialog.component.html',
    styleUrls: ['./add-evaulation-dialog.component.scss']
})
export class AddEvaluationDialog extends ToastManager implements OnInit {

    availableAcronyms: Standard[];
    availableCategories: Category[];
    availableAttributes: Attribute[];
    availableValues: Scale;
    addEvaluationForm: FormGroup;

    loggedUser: any;
    scaleId: number;
    evaluation: FunctionalValutation;

    constructor(
        public dialogRef: MatDialogRef<AddEvaluationDialog>,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private priService: PriService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        super(snackBar);
        this.addEvaluationForm = this._formBuilder.group({
            acronym: ['', [Validators.required]],
            category: ['', [Validators.required]],
            attribute: ['', [Validators.required]],
            value: ['', [Validators.required]]
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));

        this.availableValues = {valueValutation: [], id: 0}
        this.availableAttributes = []
        this.availableCategories = []
        this.availableAcronyms = []
        this.loadData(STANDARD_LOAD, "");
    }
    ngOnInit(): void {
    }

    closeDialog(selectUser: boolean): void {
        if(selectUser) {
            this.addEvaluationForm.markAllAsTouched();
            if(this.addEvaluationForm.get("acronym").hasError("required") || this.addEvaluationForm.get("category").hasError("required") ||
                this.addEvaluationForm.get("attribute").hasError("required") || this.addEvaluationForm.get("value").hasError("required")) {
                this.addEvaluationForm.markAllAsTouched();
                this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
            if(!this.addEvaluationForm.valid) {
                this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }
            this.addEvaluationForm.get("attribute").value.dimensionValutation = this.addEvaluationForm.get("category").value;
            this.addEvaluationForm.get("category").value.standardValutation = this.addEvaluationForm.get("acronym").value;
            this.addEvaluationForm.get("category").value.ratingScaleValue = this.availableValues;
            this.evaluation = {
                value: this.addEvaluationForm.get("value").value.value,
                attributeValutation: this.addEvaluationForm.get("attribute").value
            }
            this.dialogRef.close(this.evaluation);
        }else{
            this.dialogRef.close();
        }
    }

    loadData(type: string, key: string) {
        this.priService.loadFunctionalValData(key, type).subscribe((response) => {
            switch (type) {
                case STANDARD_LOAD:
                    this.availableAcronyms = response.valutationStandards;
                    break;
                case CATEGORY_LOAD:
                    this.availableCategories = response.valutationCategories;
                    break;
                case ATTRIBUTE_LOAD:
                    this.addEvaluationForm.get("value").setValue("");
                    this.availableAttributes = response.valutationAttributes;
                    break;
                case VALUE_LOAD:
                    this.availableValues = response.valutationScale;
                    break;
                default:
                    break;
            }
        })
    }
}
