<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Dettaglio esercizio
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userNominative}}
        </mat-label>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Dati generali
        </div>
        <div class='general-data-wrapper' [formGroup]="generalDataForm">
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Data programma</mat-label>
                <input matInput formControlName="scheduledDate" type="date" onkeydown="return false"
                    [max]="'9999-12-31'">
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Durata programmata</mat-label>
                <input matInput formControlName="scheduledWorkingTime">
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Durata effettiva</mat-label>
                <input matInput formControlName="effectiveWorkingTime">
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Data effettiva</mat-label>
                <input matInput formControlName="executionDate" type="date" [max]="'9999-12-31'">
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Ora inizio</mat-label>
                <input matInput formControlName="executionBeginTime" type="time">
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Ora fine</mat-label>
                <input matInput formControlName="executionEndTime" type="time">
            </mat-form-field>
        </div>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Dettagli esecuzione
        </div>
        <div fxLayout="column">
            <div class="video-button-holder">
                <button mat-button (click)="openVideo()">
                    Video
                    <mat-icon>play_circle_filled</mat-icon>
                </button>
            </div>
        </div>
        <div class="graph-holder">
            <canvas baseChart [datasets]="heartRateChartData" [labels]="heartRateChartLabels"
                [options]="heartRateChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                [chartType]="lineChartType"></canvas>
        </div>
        <div class="grid-element efficacy-wrapper" fxLayout="column">
            <div class="efficacy-label">
                <label class="label-style">Efficacia</label>
            </div>
            <div class="efficacy-bar-wrapper" fxLayout="row" fxFill>
                <mat-progress-bar
                    fxFlex="100"
                    color="accent"
                    [mode]="'determinate'"
                    [value]="valutationForm.get('efficacy').value">
                </mat-progress-bar>
                <label class="percentage" fxFlex="10">{{valutationForm.get('efficacy').value}}%</label>
            </div>
        </div>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Valutazione
        </div>

        <div class='valutation-wrapper' [formGroup]="valutationForm">
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Fisioterapista</mat-label>
                <input matInput formControlName="physiotherapist">
            </mat-form-field>
            <div fxFill class="grid-element star-wrapper" fxLayout="column">
                <div class="star-label">
                    <label class="label-style">Correttezza</label>
                    <mat-icon class="info-button" (click)="openEfficacyDetail()">info
                    </mat-icon>
                </div>
                <mat-star-rating class="star-rating" [rating]="valutationForm.get('correctness').value"
                    [starCount]="5" [disabled]="true" color="accent">
                </mat-star-rating>
            </div>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Note assistito</mat-label>
                <textarea matInput formControlName="assistedNote"></textarea>
            </mat-form-field>
            <div fxFill class="grid-element star-wrapper" fxLayout="column">
                <div class="star-label">
                    <label class="label-style">Valutazione assistito</label>
                </div>
                <mat-star-rating class="star-rating" [rating]="valutationForm.get('assistedRating').value"
                    [starCount]="5" [disabled]="true" color="accent">
                </mat-star-rating>
            </div>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Note fisioterapista</mat-label>
                <textarea matInput formControlName="physiotherapistNote"></textarea>
            </mat-form-field>
            <div fxFill class="grid-element star-wrapper" fxLayout="column">
                <div class="star-label">
                    <label class="label-style">Valutazione fisioterapista</label>
                </div>
                <mat-star-rating class="star-rating" [rating]="valutationForm.get('physiotherapistRating').value"
                    [starCount]="5" [disabled]="true" color="accent">
                </mat-star-rating>
            </div>
        </div>
    </div>

    <form class="buttons-container" fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button color="accent" class="back-button" aria-label="INDIETRO" (click)="goBack()">
            INDIETRO
        </button>
    </form>
</div>