import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('auth-token');
    if (token === null || token === undefined) {
        token = '';
    }

    req = req.clone({
      setHeaders: {
        'x-auth-token': token,
      },
    });
    return next.handle(req);
  }
}
