import {Permissions} from './permissions';

export class RoleOperationCheck {

    constructor() {
    }

    public static checkRoleOperation(role: string, operation: string) {
        return Permissions[operation].filter((element) =>
            element === role
        ).length > 0;
    }
}