
export const BASE_REGEX = "^[a-zA-Z0-9 ,.'éèòàùì!?;:%]*$";
export const TEXT_AND_NUMBERS_REGEX = "^(?=.*[a-zA-Z].*)(?:[a-zA-Z0-9 ,.'éèòàùì!?;:%]*\\b\\S{1,26}\\b\\s*)+$";
export const TEXT_ONLY_REGEX = '^[a-zA-Z ]*$';
export const FISCAL_CODE_REGEX = '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$';
export const EMAIL_REGEX = '(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
export const GENERAL_NAME_REGEX = "^[a-zA-Zòàèùìé]+(([', -][a-zA-Zòàèùìé ])?[a-zA-Zòàèùìé ]*)*$";
export const PHONE_NUMBERS_ONLY_REGEX = "^[0-9]{5,11}$";
export const NUMBERS_ONLY_REGEX = "^[1-9][0-9]*$";
export const AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX = "^(?=.*[a-zA-Z].*)([a-zA-Z0-9 ,.’'éèòàùì!?;:%]+)$";
export const AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX = "^(?=.*[a-zA-Z].*)([a-zA-Z () 'éèòàùì]+)$";
export const AT_LEAST_ONE_CHARACTER_REGEX = "^(?=.*[a-zA-Z0-9].*)([a-zA-Z0-9 ,.'éèòàùì]+)$";
export const PASSWORD_REGEX = "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$";