<div fxLayout='column'>
    <div class="header mt-0 delete" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>ATTENZIONE!
        </mat-label>
    </div>
    <div class='Align-center' fxLayout='row'>
        <label class="text-center">Sei sicuro di voler eliminare il farmaco {{data.drugName.name}}?</label>
    </div>
    <div fxLayout='row-reverse'>
        <button (click)="closeDialog()" mat-raised-button color="accent" class="delete-submit-button" aria-label="ESCI">
            NO
        </button>
        <button mat-raised-button color="accent" (click)="deleteUserDrug()" class="delete-submit-button" aria-label="ESCI">
            SI
        </button>
    </div>
</div>