import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { MainModule } from 'app/main/main-routing-module/main.module';

import { LoginPageComponent } from './main/authentication/login-page/login-page.component';
import { ResetPasswordPageComponent } from './main/authentication/reset-password-page/reset-password-page.component';
import { ChangePasswordPageComponent } from './main/authentication/change-password-page/change-password-page.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ListUsersPageComponent } from './main/management/user-managment/list-users-page/list-users-page.component';
import { InsertUserPageComponent } from './main/management/user-managment/insert-user-page/insert-user-page.component';
import { UserDialogComponent } from './main/management/user-managment/user-dialog/user-dialog.component';
import { RequestResetPasswordPageComponent } from './main/authentication/request-reset-password-page/request-reset-password-page.component';
import { HomePageComponent } from './main/home/home-page/home-page.component';
import { RemovePhoneDialogComponent } from './main/management/user-managment/remove-phone-dialog/remove-phone-dialog.component';
import { MatPaginator, MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getItaMatPaginatorLabelIntl } from 'providers/itaMatPaginatorLabel';
import { ExerciseDialogComponent } from './main/management/exercise-management/exercise-dialog/exercise-dialog.component';
import { InsertExercisePageComponent } from './main/management/exercise-management/insert-exercise-page/insert-exercise-page.component';
import { ListExercisesPageComponent } from './main/management/exercise-management/list-exercises-page/list-exercises-page.component';
import { DeleteFileDialogComponent } from './main/management/exercise-management/delete-file-dialog/delete-file-dialog.component';
import {InsertNewPriPageComponent} from './main/management/assisted-management/insert-new-pri-page/insert-new-pri-page.component';
import {RemoveDrugDialogComponent} from './main/management/assisted-management/remove-drug-dialog/remove-drug-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {RemoveTeamElementDialog} from './main/management/assisted-management/remove-team-element-dialog/remove-team-element-dialog.component';
import {AddTeamElementDialog} from './main/management/assisted-management/add-team-element-dialog/add-team-element-dialog.component';
import {AddEvaluationDialog} from './main/management/assisted-management/add-evaluation-dialog/add-evaulation-dialog.component';
import {RemoveEvaluationDialogComponent} from './main/management/assisted-management/remove-evaluation-dialog/remove-evaluation-dialog.component';
import {InsertNewPrmPageComponent} from './main/management/assisted-management/insert-new-prm-page/insert-new-prm-page.component';
import {RemoveAssignedExerciseDialogComponent} from './main/management/assisted-management/remove-assigned-exercise-dialog/remove-assigned-exercise-dialog.component';
import {EditPRMDialogComponent} from './main/management/assisted-management/edit-prm-confirm-dialog/edit-prm-confirm-dialog.component';
import {ListAssistedUsersComponent} from './main/management/assisted-management/list-assisted-users/list-assisted-users.component';
import {IsEllipsisActiveDirective} from './main/management/utils/is-ellipsis-active.directive';
import {FollowUpManagerPageComponent} from './main/management/assisted-management/follow-up-manager-page/follow-up-manager-page.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {RemoveFollowupDialogComponent} from './main/management/assisted-management/remove-followup-dialog/remove-followup-dialog.component';
import {FollowUpDialogComponent} from './main/management/assisted-management/follow-up-dialog/follow-up-dialog.component';
import { ReportCopingPageComponent } from './main/management/assisted-management/report-coping-page/report-coping-page.component';
import {EndPriPageComponent} from './main/management/assisted-management/end-pri-page/end-pri-page.component';
import {AssistedUserInfoPageComponent} from './main/management/assisted-management/assisted-user-info-page/assisted-user-info-page.component';
import {ExerciseExecutionValutationPageComponent} from './main/management/exercise-management/exercise-execution-valutation-page/exercise-execution-valutation-page.component';
import {AddExecutionRatingDialogComponent} from './main/management/exercise-management/add-execution-rating-dialog/add-execution-rating-dialog.component';
import {StarRatingComponent} from './main/management/utils/star-rating/star-rating.component';
import {UserPriHistoryPageComponent} from './main/management/assisted-management/user-pri-history-page/user-pri-history-page.component';
import {AuthInterceptor} from './main/interceptors/auth-interceptor';
import {PriInfoPageComponent} from './main/management/assisted-management/pri-info-page/pri-info-page.component';
import {MatTabsModule} from '@angular/material/tabs';
import {EditReportCopingDialogComponent} from './main/management/assisted-management/edit-report-coping-dialog/edit-report-coping-dialog.component';
import {ExerciseExecutionDetailPageComponent} from './main/management/assisted-management/exercise-execution-detail-page/exercise-execution-detail-page.component';
import {ChartsModule} from 'ng2-charts';
import {PlayExecutionVideoDialogComponent} from './main/management/assisted-management/play-execution-video-dialog/play-execution-video-dialog.component';
import {EfficacyDetailsDialogComponent} from './main/management/assisted-management/efficacy-details-dialog/efficacy-details-dialog.component';
import { ExecutionDetailsDialogComponent } from './main/management/assisted-management/execution-details-dialog/execution-details-dialog.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ChangeTeamElementDialog} from './main/management/assisted-management/change-team-element-dialog/change-team-element-dialog.component';
import {GaugeModule} from "@swimlane/ngx-charts";
import {MatGridListModule} from '@angular/material/grid-list';
import { ConfirmEndPriDialogComponent } from './main/management/user-managment/confirm-end-pri-dialog/confirm-end-pri-dialog.component';

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: '/login-page'
    }
];
@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        ResetPasswordPageComponent,
        ChangePasswordPageComponent,
        ExerciseDialogComponent,
        InsertExercisePageComponent,
        ListExercisesPageComponent,
        ExerciseExecutionValutationPageComponent,
        ListUsersPageComponent,
        InsertUserPageComponent,
        InsertNewPriPageComponent,
        InsertNewPrmPageComponent,
        AddExecutionRatingDialogComponent,
        RemoveDrugDialogComponent,
        RemoveTeamElementDialog,
        EditPRMDialogComponent,
        RemoveEvaluationDialogComponent,
        RemoveAssignedExerciseDialogComponent,
        RemoveFollowupDialogComponent,
        ListAssistedUsersComponent,
        FollowUpDialogComponent,
        PriInfoPageComponent,
        ReportCopingPageComponent,
        EndPriPageComponent,
        AssistedUserInfoPageComponent,
        AddTeamElementDialog,
        ChangeTeamElementDialog,
        AddEvaluationDialog,
        UserPriHistoryPageComponent,
        FollowUpManagerPageComponent,
        EditReportCopingDialogComponent,
        ExecutionDetailsDialogComponent,
        UserDialogComponent,
        RequestResetPasswordPageComponent,
        HomePageComponent,
        StarRatingComponent,
        RemovePhoneDialogComponent,
        ExerciseExecutionDetailPageComponent,
        EfficacyDetailsDialogComponent,
        PlayExecutionVideoDialogComponent,
        IsEllipsisActiveDirective,
        DeleteFileDialogComponent,
        ConfirmEndPriDialogComponent
    ],
    imports: [
        MatGridListModule,
        BrowserModule,
        MatSortModule,
        MatDialogModule,
        MatExpansionModule,
        MatTableModule,
        MatTabsModule,
        NgxSpinnerModule,
        MatDividerModule,
        BrowserAnimationsModule,
        MatMenuModule,
        MatTooltipModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {useHash: true}),
        MatFormFieldModule,
        MatInputModule,
        TranslateModule.forRoot(),
        MatProgressBarModule,
        // Material moment date module
        MatMomentDateModule,
        MatSelectModule,
        MatPaginatorModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        MainModule,
        MatListModule,
        ChartsModule,
        GaugeModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
      { provide: MatPaginatorIntl, useValue: getItaMatPaginatorLabelIntl()},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        MatSnackBar
    ],
    exports : [IsEllipsisActiveDirective]
})
export class AppModule
{
}
