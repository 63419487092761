<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img *ngIf="this.fuseSidebarService.getSidebar('navbar').unfolded" class="logo-icon" src="assets/addedAsset/Icon/logoSimple.png" alt="simple-logo">
        <img *ngIf="!this.fuseSidebarService.getSidebar('navbar').unfolded" class="logo-icon-folded" src="assets/addedAsset/Icon/logoSimple.png" alt="simple-logo">
        <span class="logo-text secondary-text">2Vita-B</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">
            <ng-container *ngIf="user">
                {{ user.name ? user.name : '' }}{{ user.name && user.surname ? ' ' : '' }}{{ user.surname ? user.surname : '' }}
            </ng-container>
        </div>
        <div class="h5 email hint-text mt-8">{{user && user.role}}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/addedAsset/Icon/user.png" alt="user">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
