import { Component, OnInit, Inject, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { RemovePhoneDialogComponent } from '../remove-phone-dialog/remove-phone-dialog.component';
import {ToastManager} from '../../utils/toast-manager';

import {MatSnackBar} from '@angular/material/snack-bar';
import {EMAIL_REGEX} from '../../../common/commons.class';
import {UserService} from '../../../services/users/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {constants} from 'http2';
import {Roles} from '../../../authentication/util/roles';
import {StringUtils} from '../../../common/string-utils';

const MAXLENGHT = 20;
const MINLENGHT = 2;
const STRING_MAX_LENGHT = 50;
const NAME_REGEX = '^[a-zA-Z][A-Za-zÀ-ÖØ-öø-ÿ\\s\'-]+$';
const FISCALCODE_REGEX = '^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$';
const PHONE_NUMBER_REGEX = '^\\+?\\d+$';


@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent extends ToastManager implements OnInit {
    oldEmail: string;
    roles: string[];
    form: FormGroup;
    numbers = [];
    parsedNumber = [];
    loggedUser = JSON.parse(sessionStorage.getItem('User'));
    constructor(
        public dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        @Inject(DOCUMENT) private _document: Document,
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _sBar: MatSnackBar,
        private userService: UserService
    ) {
        super(_sBar);
        this.roles = Roles.roles();
        this.form = this._formBuilder.group({
            number: ['', [Validators.maxLength(MAXLENGHT), Validators.pattern(PHONE_NUMBER_REGEX), Validators.minLength(MINLENGHT)]],
            name: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            surname: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            fiscalCode: ['', Validators.pattern(FISCALCODE_REGEX)],
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            role: [{value: '', disabled: this.data.user.hasSomeActiveOperations}, Validators.required],
        });
    }

    ngOnInit(): void {
        this.numbers = JSON.parse(JSON.stringify(this.data.userNumbers));
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.numbers.length; index++) {
            // tslint:disable-next-line:variable-name
            const number = this.numbers[index].phoneNumber;
            this.parsedNumber.push(number);
        }

        this.form.setValue({
            number: '',
            name: this.data.user.name,
            surname: this.data.user.surname,
            fiscalCode: this.data.user.fiscalCode,
            email: this.data.user.account && this.data.user.account.email,
            role: this.data.user.role,
        });
        this.oldEmail = this.data.user.account.email;
        // tslint:disable-next-line:no-unused-expression
        this.data.view === 0 && this.form.disable();
    }
    unDoClick(): void {
        this.numbers = [];
        this.form = this._formBuilder.group({
            number: ['', [Validators.maxLength(MAXLENGHT), Validators.pattern(PHONE_NUMBER_REGEX), Validators.minLength(MINLENGHT)]],
            name: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            surname: ['', [Validators.required, Validators.pattern(NAME_REGEX), Validators.maxLength(STRING_MAX_LENGHT)]],
            fiscalCode: ['', Validators.pattern(FISCALCODE_REGEX)],
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            role: ['', Validators.required],
        });
    }
    closeDialog(): void {
        this.dialogRef.close();
    }
    minusClicked(index): void {
        if (index > -1) {
            this.parsedNumber.splice(index, 1);
        }
    }
    addNumber(): void {
        const formNan = !isNaN(this.form.get('number').value);
        const alreadyWritten = !this.parsedNumber.includes(this.form.get('number').value);
        const emptyField = this.form.get('number').value !== '';
        const toMuchNumber = this.parsedNumber.length > 2;
        if (formNan && alreadyWritten && emptyField && !this.form.get('number').invalid && !toMuchNumber) {
            this.parsedNumber.push(this.form.get('number').value);
            this.form.patchValue({
                number: null
            });
        }
        if(!emptyField) {this.showToast("Attenzione! Popolare il campo Numero di Telefono", StringUtils.ADVISE_TIME);return;}
        if (!formNan) { this.showToast('Attenzione! Campo non popolato correttamente', StringUtils.ADVISE_TIME);return; }
        if (!alreadyWritten) { this.showToast('Attenzione! Numero di telefono già inserito', StringUtils.ADVISE_TIME);return; }
        if (this.form.get('number').hasError('maxLength')) { this.showToast('Attenzione! Campo non popolato correttamente', StringUtils.ADVISE_TIME);return; }
        if (toMuchNumber) { this.showToast('Attenzione! Il numero massimo di numeri telefonici inseribili è 3', StringUtils.ADVISE_TIME);return; }
    }
    deleteUser(): void {
        const bodyData = {
            email: this.data.user.account && this.data.user.account.email,
        };
        this.userService.deleteUser(bodyData).subscribe(() =>
            this._document.defaultView.location.reload()
        , error => {
                console.log(error);
            });
        this.closeDialog();
    }
    openPhoneRemovalDialog(phone, index): void {
        const dialogRef = this.dialog.open(RemovePhoneDialogComponent, {
            width: '350px',
            data: {
                phone,
                index
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            this.minusClicked(result);
        });
    }
    modifyUser(): void {
        const bodyData = {
            updatePasswordDate: this.data.user.updatePasswordDate,
            resetPassword: this.data.user.resetPassword,
            uuc: this.data.user.uuc,
            phoneNumbers: this.parsedNumber,
            email: this.form.get('email').value,
            oldEmail: this.oldEmail,
            name: this.form.get('name').value,
            surname: this.form.get('surname').value,
            fiscalCode: this.form.get('fiscalCode').value,
            role: this.form.get('role').value

        };
        this.userService.updateUser(bodyData).subscribe((response) => {
                if (!response.user) {
                    this.showToast('Attenzione! Utente già presente', StringUtils.ADVISE_TIME);
                } else {
                    this._document.defaultView.location.reload();
                }
            }, (error: HttpErrorResponse) => {
            console.log(error);
                if(error.status === 409) {
                    this.showToast('Attenzione! Utente già presente', StringUtils.ADVISE_TIME);
                }
            }
        );
    }
}
