import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ToastManager} from '../../management/utils/toast-manager';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {HttpErrorResponse} from '@angular/common/http';
import {PASSWORD_REGEX} from '../../common/commons.class';
import {StringUtils} from '../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordPageComponent extends ToastManager implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;
    hide: boolean;
    resetToken: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _sBar: MatSnackBar,
        private authService: AuthenticationService,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void{
        this.route.queryParamMap.subscribe(params => {
            this.resetToken = params.get('rtoken');
            if(this.resetToken.charAt(this.resetToken.length - 1) === '.') {
                this.resetToken.slice(0, -1);
            }
        });
        this.hide = true;
        this.resetPasswordForm = this._formBuilder.group({
            password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator, Validators.pattern(PASSWORD_REGEX)]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    resetPassword(): void {
        if(this.resetPasswordForm.get('password').hasError("pattern")) {
            this.showToast("La password deve essere almeno 8 caratteri, contenere almeno una lette e almeno un numero", 8000);
            return;
        }
        let password: string = this.resetPasswordForm.get('password').value;
        if (password.includes('\'') || password.includes('"')) {
            this.showToast('la password non puo` contenere il carattere \' e il carattere "', StringUtils.ADVISE_TIME);
        } else {
            const bodyData = {
                resetToken: atob(this.resetToken),
                newPassword: password
            };
            this.spinner.show();
            this.authService.resetPassword(bodyData).subscribe((response) => {
                this.spinner.hide();
                if(response.success) {
                    this.router.navigateByUrl('[/login-page]')
                }
            }, (error: HttpErrorResponse) => {
                this.spinner.hide();
                if(error.status === 409) {
                    this.showToast("Richiesta scaduta", StringUtils.ADVISE_TIME);
                }else{
                    this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                }
            });
        }

    }

    unDoClick(): void {
        this.resetPasswordForm.reset();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return {passwordsNotMatching: true};
};
