<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div id="reset-password" fxLayout="column">
    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div>
                <img class="logo" src="../../../../assets/addedAsset/Icon/logo.png" alt="logo">
            </div>
            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Indirizzo email</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        Indirizzo email obbligatorio
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('email').hasError('required') &&
                                resetPasswordForm.get('email').hasError('pattern')">
                        Indirizzo email non valido
                    </mat-error>
                </mat-form-field>
                <div class="submit_div" fxAlign='row'>
                    <button mat-raised-button class="submit-button" (click)="sendRequest()" color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid">
                        INVIA
                    </button>
                    <button (click)="unDoClick()" [routerLink]="['/login-page']" routerLinkActive="router-link-active"
                        mat-raised-button class="submit-button" color="accent" aria-label="ANNULLA">
                        ANNULLA
                    </button>
                </div>
            </form>

        </div>

    </div>

</div>