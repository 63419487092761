<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class="background" fxLayout="row">
    <div class="main-div" fxLayout="column">
        <div class="header" fxLayout="row" fxLayoutAlign="end">
            <mat-label class='header_label'>Home
            </mat-label>
            <mat-label class='header_label'>|
            </mat-label>
            <mat-label class='header_label'>Gestione utenti
            </mat-label>
            <mat-label class='header_label'>|
            </mat-label>
            <mat-label class='header_label'>Nuovo utente
            </mat-label>
        </div>
        <div class="content" fxLayout="column">
            <form fxLayout="column " fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="insertUserForm">
                <label class='titleAdd h2'>Nuovo utente</label>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="resizable">
                    <mat-form-field class="wrapper" appearance="outline" fxFlex="50">
                        <mat-label>Cognome</mat-label>
                        <input matInput formControlName="surname" required>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="!this.insertUserForm.get('surname').hasError('required')">Cognome non valido</mat-error>
                        <mat-error *ngIf="this.insertUserForm.get('surname').hasError('required')">Cognome obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="!this.insertUserForm.get('name').hasError('required')">Nome non valido</mat-error>
                        <mat-error *ngIf="this.insertUserForm.get('name').hasError('required')">Nome obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                        <mat-label>Codice fiscale</mat-label>
                        <input matInput formControlName="fiscalCode" oninput="this.value = this.value.toUpperCase()" required>
                        <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
                        <mat-error *ngIf="!this.insertUserForm.get('fiscalCode').hasError('required')">Codice fiscale non valido</mat-error>
                        <mat-error *ngIf="this.insertUserForm.get('fiscalCode').hasError('required')">Codice fiscale obbligatorio</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" class="resizable reverse" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div fxLayout="row" fxFlex="50">
                        <mat-form-field appearance="outline" class="phone-field">
                            <mat-label>Numero di telefono</mat-label>
                            <input formControlName="number" matInput>
                            <mat-error>Numero di telefono non valido</mat-error>

                        </mat-form-field>
                        <mat-icon (click)='addNumber()' matSuffix class="secondary-text clickable-icon">
                            add_circle_outline
                        </mat-icon>
                    </div>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required>
                        <mat-icon matSuffix class="secondary-text"> mail_outline</mat-icon>
                        <mat-error *ngIf="!this.insertUserForm.get('email').hasError('required')">Email non valida</mat-error>
                        <mat-error *ngIf="this.insertUserForm.get('email').hasError('required')">Email obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                        <mat-label>Ruolo</mat-label>
                        <mat-select formControlName="role" required>
                            <mat-option *ngFor="let role of roles" [value]="role">
                                {{role}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">work_outline</mat-icon>
                    </mat-form-field>
                </div>
            </form>
            <div class='numberContainer resizable' fxLayout='row'>
                <div class='number' fxLayout="column">
                    <div class='numberminus' *ngFor="let number of phoneNumbers;let i = index">
                        <label class='numberLabel' fxLayoutAlign='top start'>{{number}}</label>
                        <mat-icon (click)='openPhoneRemovalDialog(number,i)' matSuffix fxLayoutAlign='top end' class="secondary-text minusIcon clickable-icon">
                            delete
                        </mat-icon>
                    </div>
                </div>
                <div class='submitForm' fxLayout="column" fxLayoutAlign='top end'>
                    <div class="submit-div" fxLayout='row'>
                        <button (click)='addUser()' mat-raised-button color="accent" class="submit-button"
                                aria-label="AGGIUNGI" [disabled]="insertUserForm.invalid || addPressed">
                            AGGIUNGI
                        </button>
                        <button (click)='unDoClick()' mat-raised-button color="accent" class="submit-button"
                                aria-label="ANNULLA" [disabled]="addPressed">
                            ANNULLA
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>