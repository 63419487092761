import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {PriService} from '../../../services/pri/pri.service';

@Component({
    selector: 'app-remove-drug-dialog',
    templateUrl: './remove-drug-dialog.component.html',
    styleUrls: ['./remove-drug-dialog.component.scss']
})
export class RemoveDrugDialogComponent implements OnInit {

    loggedUser: any;

    constructor(
        public dialogRef: MatDialogRef<RemoveDrugDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
    }

    ngOnInit(): void {
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    deleteUserDrug(): void {
        this.dialogRef.close(true);
    }
}
