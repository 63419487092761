export class StringUtils {

  public static readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly PHONE_NUMBER_REGEX = /^[0-9]*$/;
  public static readonly ITALIAN_FISCAL_CODE = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
  public static readonly ITALIAN_VAX_CODE = /^[0-9]{11}$/;

  public static readonly MIN_NAME = 2;
  public static readonly MIN_COMPANY_NAME = 5;
  public static readonly MAX_NAME = 20;
  public static readonly MAX_COMPANY_NAME = 50;
  public static readonly MIN_SURNAME = 2;
  public static readonly MAX_SURNAME = 20;
  public static readonly MIN_PHONE_NUMBER = 7;
  public static readonly MAX_PHONE_NUMBER = 15;
  public static readonly MIN_EMAIL = 7;
  public static readonly MAX_EMAIL = 254;
  public static readonly MIN_ADDRESS = 5;
  public static readonly MAX_ADDRESS = 254;
  public static readonly MIN_BIO = 5;
  public static readonly MAX_BIO = 254;
  public static readonly FISCAL_CODE_CHARS = 16;
  public static readonly MIN_TITLE = 5;
  public static readonly MAX_TITLE = 50;
  public static readonly MIN_DESCRIPTION = 50;
  public static readonly MAX_DESCRIPTION = 1000;
  public static readonly VAX_CODE_CHARS = 11;
  public static readonly ADVISE_TIME = 5000;
  public static readonly EMPTY_PRI_ERROR_MESSAGE = "Attenzione! L\'assistito non ha un PRI attivo";
  public static readonly CONNECTION_ERROR_MESSAGE = "Attenzione! Errore di connessione";
  public static readonly EMPTY_FIELDS_MESSAGE = "Attenzione! Campi obbligatori non compilati";
  public static readonly WRONG_FIELDS_MESSAGE = "Attenzione! Campo/i non popolati correttamente";
  public static readonly EMPTY_FILTERS_FIELDS_MESSAGE = "Attenzione! Selezionare almeno un campo per effettuare la ricerca";
  public static readonly MAX_BOUND_AGE = 100;
  public static readonly EDIT_MODE_PHYSIATRIST = 1;
  public static readonly EDIT_MODE_PSYCHOLOGIST = 2;
  public static readonly EDIT_MODE_DISABLED = 0;
  public static readonly LIST_USERS_ORIGIN = 0;
  public static readonly PRI_HISTORY_ORIGIN = 1;

  public static readonly ITALIAN_MONTHS = {
    1: 'Gennaio',
    2: 'Febbraio',
    3: 'Marzo',
    4: 'Aprile',
    5: 'Maggio',
    6: 'Giugno',
    7: 'Luglio',
    8: 'Agosto',
    9: 'Settembre',
    10: 'Ottobre',
    11: 'Novembre',
    12: 'Dicembre',
  }

  public static readonly MONTH_TO_NUMBER = {
    'january': 1,
    'february': 2,
    'march': 3,
    'april': 4,
    'may': 5,
    'june': 6,
    'july': 7,
    'august': 8,
    'september': 9,
    'october': 10,
    'november': 11,
    'december': 12,
  }
  public static readonly NUMBER_OF_MONTHS = 12;

  public static isNotEmpty(string) {
    return !(                                                           //dont start bracket from new line
      (typeof string == 'undefined')
      ||
      (string == null)
      ||
      (string == false)        //same as: !string
      ||
      (string.length == 0)
      ||
      (string == 0)            // note this line, you might not need this.
      ||
      (string == "")
      ||
      (string.replace(/\s/g, "") == "")
      ||
      (!/[^\s]/.test(string))
      ||
      (/^\s*$/.test(string))
    );
  }

  public static isEmailValid(email: string) {
    return this.EMAIL_REGEX.test(email);
  }

  public static isPhoneNumberValid(phoneNumber: string) {
    return this.PHONE_NUMBER_REGEX.test(phoneNumber);
  }

  public static isFiscalCodeValid(fiscalCode: string): boolean {
    return this.ITALIAN_FISCAL_CODE.test(fiscalCode);
  }

  public static isVaxCodeValid(vaxCode: string): boolean {
    return this.ITALIAN_VAX_CODE.test(vaxCode);
  }
}
