export class Operations {
    public static home = 'home';
    public static login = 'login';
    public static resetPassword = 'resetPassword';
    public static changePassword = 'changePassword';
    public static usersManager = 'usersManager';
    public static newUser = 'newUser';
    public static listUsers = 'listUsers';
    public static userDetail = 'userDetail';
    public static editUser = 'editUser';
    public static deleteUser = 'deleteUser';
    public static exercisesManager = 'exercisesManager';
    public static newExercise = 'newExercise';
    public static listExercises = 'listExercises';
    public static exerciseDetail = 'exerciseDetail';
    public static editExercise = 'editExercise';
    public static deleteExercise = 'deleteExercise';
    public static addIdealMovement = 'addIdealMovement';
    public static assistedUsersManager = 'assistedUsersManager';
    public static listAssistedUsers = 'listAssistedUsers';
    public static assistedUserInfo = 'assistedUserInfo';
    public static PRIInfo = 'PRIInfo';
    public static PRIHistory = 'PRIHistory';
    public static followUpManager = 'followUpManager';
    public static addReportCoping = 'addReportCoping';
    public static endPRI = 'endPRI';
    public static addPRI = 'addPRI';
    public static addPRM = 'addPRM';
    public static exerciseExecutionRating = 'exerciseExecutionRating';
    public static LRODashboard = 'LRODashboard';
}