<button mat-icon-button
        [ngClass]="disabled ? 'star-button-disabled' : 'star-button-enabled'"
        [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" (click)="onClick(i+1)">
  <mat-icon>
    {{rating > i ? 'star' : 'star_border' }}
  </mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
