<div class="background" fxLayout="row">
    <div *ngIf='data.view==1 || data.view == 0' class="main-div" fxLayout="column">
        <div class="header" fxLayout="row" fxLayoutAlign="center">
            <mat-label *ngIf='data.view==1 ' class='header_label'>Modifica esercizio
            </mat-label>
            <mat-label *ngIf='data.view==0' class='header_label'>Dettaglio esercizio
            </mat-label>
        </div>
        <div fxLayout="column">
            <form fxLayout="column" fxLayoutAlign="start" name="form" [formGroup]="form">
                <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="resizable">
                        <mat-form-field appearance="outline" fxFlex="50" class="expand mb-0" matTooltip="{{this.form.get('name').value}} "
                                        matTooltipPosition="above" [matTooltipShowDelay]="500"
                                        matTooltipClass="custom-tooltip">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="this.form.get('name').hasError('pattern')">Nome non valido</mat-error>
                            <mat-error *ngIf="this.form.get('name').hasError('required')">Nome obbligatorio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four expand mb-0">
                            <mat-label>Categoria</mat-label>
                            <mat-select formControlName="category" required>
                                <mat-option *ngFor="let category of categories" [value]="category">
                                    {{category}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" class="description">
                            <mat-label>Descrizione</mat-label>
                            <textarea formControlName="description" matInput required></textarea>
                            <mat-error *ngIf="this.form.get('description').hasError('pattern')">Descrizione non valida</mat-error>
                            <mat-error *ngIf="this.form.get('description').hasError('required')">Descrizione obbligatoria</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-upload" fxLayout="row" class="resizable Align-center-evenly">
                        <div fxLayout="row" fxFlex='30' class="expand">
                            <label>Movimento ideale</label>
                            <mat-icon *ngIf="!data.exercise.idealMovement && data.view === 1" (click)="fileInput.click()" matSuffix class="secondary-text clickable-icon">
                                cloud_upload
                            </mat-icon>
                            <mat-icon *ngIf="!data.exercise.idealMovement && data.view === 0" matSuffix class="secondary-text clickable-icon">
                                cloud_off
                            </mat-icon>
                            <mat-icon *ngIf="data.exercise.idealMovement && data.view === 1" matSuffix class="secondary-text done">
                                cloud_done
                            </mat-icon>
                            <mat-icon *ngIf="data.exercise.idealMovement && data.view === 0" (click)="downloadFile(idealMovementType + data.exercise.idealMovement, 'idealMovement')" matSuffix class="secondary-text clickable-icon done">
                                cloud_download
                            </mat-icon>
                            <mat-icon  *ngIf="data.view === 1 && data.exercise.idealMovement" (click)="openFileRemovalDialog(0)" matSuffix class="secondary-text clickable-icon">
                                delete
                            </mat-icon>
                        </div>
                        <div fxLayout="row" fxFlex='35' class="expand">
                            <label>Video dimostrativo</label>
                            <div fxLayout="row">
                                <mat-icon *ngIf="!data.exercise.video && data.view === 1" (click)="fileInput2.click()" matSuffix class="secondary-text clickable-icon">
                                    cloud_upload
                                </mat-icon>
                                <mat-icon *ngIf="!data.exercise.video && data.view === 0" matSuffix class="secondary-text clickable-icon">
                                    cloud_off
                                </mat-icon>
                                <mat-icon *ngIf="data.exercise.video && data.view === 1" matSuffix class="secondary-text done">
                                    cloud_done
                                </mat-icon>
                                <mat-icon *ngIf="data.exercise.video && data.view === 0" (click)="downloadFile(data.exercise.video, 'video')" matSuffix class="secondary-text clickable-icon done">
                                    cloud_download
                                </mat-icon>
                                <mat-icon  *ngIf="data.view === 1 && data.exercise.video" (click)="openFileRemovalDialog(1)" matSuffix class="secondary-text clickable-icon">
                                    delete
                                </mat-icon>
                            </div>
                        </div>
                        <div appearance="outline" fxFlex="20" class="padding-left-four expand mb-0">
                            <div fxLayout="row" class="expand mb-20">
                                <div>
                                    <label>Icona*</label>
                                </div>
                                <div fxLayout="row">
                                    <mat-icon *ngIf="!data.exercise.icon && data.view === 1" (click)="fileInput3.click()" matSuffix class="secondary-text clickable-icon">
                                        cloud_upload
                                    </mat-icon>
                                    <mat-icon *ngIf="!data.exercise.icon && data.view === 0" matSuffix class="secondary-text clickable-icon">
                                        cloud_off
                                    </mat-icon>
                                    <mat-icon *ngIf="data.exercise.icon && data.view === 1"  matSuffix class="secondary-text done">
                                        cloud_done
                                    </mat-icon>
                                    <mat-icon *ngIf="data.exercise.icon && data.view === 0" (click)="downloadFile(data.exercise.icon, 'icon')" matSuffix class="secondary-text clickable-icon done">
                                        cloud_download
                                    </mat-icon>
                                    <mat-icon  *ngIf="data.view === 1 && data.exercise.icon" (click)="openFileRemovalDialog(2)" matSuffix class="secondary-text clickable-icon">
                                        delete
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-progress-bar
                        class="file-progress-bar"
                        *ngIf="downloadInProgress"
                        color="accent"
                        mode="indeterminate"></mat-progress-bar>
                </div>
            </form>
            <div class='submit-container' fxLayout='row'>
                <div *ngIf="data.view === 1" class='submit-form Align-center-evenly resizable expand' fxLayout="row" fxLayoutAlign='top end'
                     fxFlex='35'>
                    <button (click)='modifyExercise()' mat-raised-button color="accent" class="modify-button expand"
                            aria-label="AGGIUNGI" [disabled]="form.invalid || data.exercise.icon === null || modifyPressed">
                        MODIFICA
                    </button>
                    <button [disabled] = "modifyPressed" (click)='unDoClick()' mat-raised-button color="accent" class="modify-button expand"
                            aria-label="ANNULLA">
                        ANNULLA
                    </button>
                </div>
                <button *ngIf="data.view === 0" (click)='closeDialog()' mat-raised-button color="accent" class="modify-button expand exit"
                        aria-label="ANNULLA">
                    ESCI
                </button>
            </div>
        </div>
    </div>
    <div *ngIf='data.view==2' class="view-container" fxLayout='column'>
        <div class="header delete" fxLayout="row" fxLayoutAlign="center">
            <mat-label class='header_label'>ATTENZIONE!
            </mat-label>
        </div>
        <div class='Align-center' fxLayout='row'>
            <label class="text-center">Sei sicuro di voler eliminare l'esercizio {{data.exercise.name}}?</label>
        </div>
        <div fxLayout='row-reverse'>
            <button (click)='closeDialog()' mat-raised-button color="accent" class="submit-button fix-margin" aria-label="ESCI">
                NO
            </button>
            <button (click)='deleteExercise(data.exercise.id)' mat-raised-button color="accent" class="submit-button" aria-label="ESCI">
                SI
            </button>
        </div>
    </div>
</div>



<span class="hidden-file-manager">
    <input type="file" #fileInput [(ngModel)]="idealMovementPath" (change)="fileChange($event,0)" placeholder="Upload file" accept=".json">
</span>
<span class="hidden-file-manager">
    <input type="file" #fileInput2 [(ngModel)]="videoPath" (change)="fileChange($event,1)" placeholder="Upload file" accept=".mp4,.mpeg,.avi">
</span>
<span class="hidden-file-manager">
    <input type="file" #fileInput3 [(ngModel)]="iconPath" (change)="fileChange($event,2)" placeholder="Upload file" accept=".jpeg,.png,.jpg">
</span>