<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Follow-up
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userNominative}}
        </mat-label>
    </div>
    <mat-accordion>
        <mat-expansion-panel #newFollowUpPanel>
            <mat-expansion-panel-header [ngClass]="newFollowUpPanel.expanded ? 'expanded-panel' : 'collapsed-panel'">
                <mat-panel-title>
                    Nuovo Follow-up
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="border-container">
                <div class="h2 div-section-title">
                    Dati generali
                </div>
                <div fxFill fxLayout="column" class="general-data-container">
                    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="generalDataForm">
                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Data</mat-label>
                            <input matInput formControlName="date" (change)="dateChange()" required type="date"
                                onkeydown="return false" [max]="'9999-12-31T23:59'">
                            <mat-error *ngIf="this.generalDataForm.get('date').hasError('required')">Data obbligatoria
                            </mat-error>
                            <mat-error *ngIf="!this.generalDataForm.get('date').hasError('required')">Data non valida
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="8" required>
                            <mat-label>Ora</mat-label>
                            <input matInput type="time" formControlName="time" (change)="dateChange()" required>
                            <mat-error *ngIf="this.generalDataForm.get('time').hasError('required')">Ora obbligatoria
                            </mat-error>
                            <mat-error *ngIf="this.generalDataForm.get('time').hasError('invalid')">Ora non valida
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="18">
                            <mat-label>LRO</mat-label>
                            <input matInput formControlName="lro" maxlength="3" required>
                            <mat-error *ngIf="!this.generalDataForm.get('lro').hasError('required')">LRO non valido
                            </mat-error>
                            <mat-error *ngIf="this.generalDataForm.get('lro').hasError('required')">LRO obbligatorio
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="18">
                            <mat-label>LRO stimato</mat-label>
                            <input matInput formControlName="estimatedLro">
                            <mat-error *ngIf="this.generalDataForm.get('estimatedLro').hasError('required')">Valore
                                obbligatorio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="18">
                            <mat-label>Modifica piano</mat-label>
                            <mat-select formControlName="editPlan" required>
                                <mat-option [value]="false">
                                    No
                                </mat-option>
                                <mat-option [value]="true">
                                    Si
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!this.generalDataForm.get('editPlan').hasError('required')">Modifica piano
                                non valido</mat-error>
                            <mat-error *ngIf="this.generalDataForm.get('editPlan').hasError('required')">Modifica piano
                                obbligatorio</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" [formGroup]="generalDataForm">
                        <mat-form-field appearance="outline" fxFlex="100" class="description">
                            <mat-label>Note</mat-label>
                            <textarea formControlName="note" matInput></textarea>
                            <mat-error *ngIf="!this.generalDataForm.get('note').hasError('required')">Note non valide
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="border-container">
                <div class="h2 div-section-title">
                    Stato di salute
                </div>
                <div class='health-parameters-container' [formGroup]="healthParametersForm">
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa diastolica (mmHg)</mat-label>
                        <input matInput formControlName="diastolicBloodPressure" maxlength="3" required>
                        <mat-error
                            *ngIf="!this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">
                            Pressione arteriosa diastolica non valida</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">
                            Pressione arteriosa diastolica obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa sistolica (mmHg)</mat-label>
                        <input matInput formControlName="systolicBloodPressure" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa sistolica non valida</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa sistolica obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza cardiaca (bpm)</mat-label>
                        <input matInput formControlName="cardioFrequency" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca non valida</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Ritmo cardiaco</mat-label>
                        <mat-select formControlName="cardioRhythm" required>
                            <mat-option *ngFor="let rythm of availableRythms" [value]="rythm">
                                {{rythm}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco non valido</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza respiratoria</mat-label>
                        <input matInput formControlName="breathFrequency" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria non valida</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Saturazione arteriosa (%)</mat-label>
                        <input matInput formControlName="arterialSaturation" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa non valida</mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa obbligatoria</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="panel-buttons-container" fxLayout="row">
                <button mat-raised-button color="accent" class="save-button" aria-label="SALVA"
                    (click)="saveFollowUp()">
                    SALVA
                </button>
                <button mat-raised-button color="accent" class="cancel-button" aria-label="ANNULLA"
                    (click)="resetFollowUp()">
                    ANNULLA
                </button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel #historyFollowUpPanel>
            <mat-expansion-panel-header
                [ngClass]="historyFollowUpPanel.expanded ? 'expanded-panel' : 'collapsed-panel'">
                <mat-panel-title>
                    Storico Follow-up
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxFill fxLayout="row" fxLayoutAlign="space-between">
                <div class="border-container" fxFlex="59">
                    <div class="h2 div-section-title">
                        Follow-up
                    </div>
                    <div class='mat-elevation-z1 tableResponsive margin-table'>
                        <table mat-table [dataSource]="followUpDataSource" matSort matSortDisableClear="true"
                            class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, followUpDataSource)">
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Data follow-up </th>
                                <td mat-cell #date *matCellDef="let followUp">
                                    {{this.datePipe.transform(followUp.date,"dd/MM/yyyy (HH:mm)")}} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni </th>
                                <td mat-cell *matCellDef="let followUp" class="column-action">
                                    <div>
                                        <mat-icon class="clickable-icon" matTooltip="Visualizza dettaglio follow-up"
                                            matTooltipPosition="above" [matTooltipShowDelay]="500"
                                            matTooltipClass="custom-tooltip"
                                            (click)="openFollowUpDialog(followUp, false)">info</mat-icon>
                                        <mat-icon class="clickable-icon" matTooltip="Modifica follow-up"
                                            matTooltipPosition="above" [matTooltipShowDelay]="500"
                                            matTooltipClass="custom-tooltip"
                                            (click)="openFollowUpDialog(followUp, true)">edit</mat-icon>
                                        <mat-icon class="clickable-icon" matTooltip="Elimina follow-up"
                                            matTooltipPosition="above" [matTooltipShowDelay]="500"
                                            matTooltipClass="custom-tooltip"
                                            (click)="openDeleteFollowUpDialog(followUp.id)">delete</mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="followUpDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: followUpDisplayedColumns;"></tr>

                        </table>
                        <div *ngIf="followUpDataSource === undefined || followUpDataSource.data.length === 0"
                            class="no-record mat-elevation-z1"><label>Nessun follow-up presente</label></div>
                        <div *ngIf="followUpDataSource !== undefined && followUpDataSource.data.length !== 0 && followUpDataSource.filteredData.length === 0"
                            class="no-record mat-elevation-z1"><label>Nessun follow-up trovato corrispondente ai criteri
                                di ricerca inseriti</label></div>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
                <div class="border-container" fxFlex="39">
                    <div class="h2 div-section-title">
                        Ricerca Follow-up
                    </div>
                    <div fxLayout="column" [formGroup]="filterForm">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <mat-form-field appearance="outline" fxFlex="60" class="search-field">
                                <mat-label>Data da</mat-label>
                                <input matInput formControlName="beginDate" type="date" onkeydown="return false" [max]="'9999-12-31T23:59'">
                                <mat-error *ngIf="!this.filterForm.get('beginDate').hasError('required')">Data da non
                                    valida</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="30" class="search-field">
                                <mat-label>Ora</mat-label>
                                <input matInput formControlName="beginTime" type="time">
                                <mat-error *ngIf="this.filterForm.get('beginTime').hasError('required')">Ora obbligatoria</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <mat-form-field appearance="outline" fxFlex="60" class="search-field">
                                <mat-label>Data a</mat-label>
                                <input matInput formControlName="endDate" type="date" onkeydown="return false" [max]="'9999-12-31T23:59'">
                                <mat-error *ngIf="!this.filterForm.get('endDate').hasError('required')">Data a non
                                    valida
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="30" class="search-field">
                                <mat-label>Ora</mat-label>
                                <input matInput formControlName="endTime" type="time">
                                <mat-error *ngIf="this.filterForm.get('endTime').hasError('required')">Ora obbligatoria</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="panel-buttons-container inner" fxLayout="row">
                        <button mat-raised-button color="accent" class="save-button" aria-label="CERCA"
                            (click)="filterButton()">
                            CERCA
                        </button>
                        <button mat-raised-button color="accent" class="cancel-button" aria-label="ANNULLA"
                            (click)="resetFilters()">
                            ANNULLA
                        </button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="panel-buttons-container" fxLayout="row">
        <button mat-raised-button color="accent" class="back-button" aria-label="BACK"
            routerLink="/list-assisted-users">
            INDIETRO
        </button>
    </div>
</div>