import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { ToastManager } from '../../management/utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { myCustomTooltipDefaults } from '../../common/custom-tooltip';
import { MatPaginator } from '@angular/material/paginator';
import { FISCAL_CODE_REGEX } from '../../common/commons.class';
import { PriService } from '../../services/pri/pri.service';
import { EnrolledPri } from '../../class/interfaces/enrolled-pri';
import { ReportCoping } from '../../class/interfaces/report-coping';
import { FollowUp } from '../../class/interfaces/follow-up';
import { PRI } from '../../class/pri';
import { Prm } from '../../class/prm';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { PrmExercise } from '../../class/interfaces/prm-exercise';
import { EfficacyDetailsDialogComponent } from '../../management/assisted-management/efficacy-details-dialog/efficacy-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ExecutionDetailsDialogComponent } from '../../management/assisted-management/execution-details-dialog/execution-details-dialog.component';
import { ExerciseService } from '../../services/exercise/exercise.service';
import { RoleOperationCheck } from '../../authentication/util/role-operation-check';
import { Operations } from '../../authentication/util/operations';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { constants } from 'http2';
import { StringUtils } from '../../common/string-utils';
import { UserService } from 'app/main/services/users/user.service';
import { Users, Assisted } from 'app/main/models/get-users-stats-response';
import { NgxSpinnerService } from 'ngx-spinner';

const FILTER_REGEX = '^[a-zA-ZA-Za-zÀ-ÖØ-öø-ÿ\\s\'-]+$';

@Component({
    selector: 'home',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, DatePipe
    ]
})
export class HomePageComponent extends ToastManager implements OnInit {

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.matSortValue = ms;
        if(this.matSortValue != undefined){
            this.matSortValue.active = "surname";
            this.matSortValue.direction = "asc";
        }  
    }

    @ViewChild('surname') surname: ElementRef;

    @ViewChild('paginator') set paginator(pg: MatPaginator) {
        this.paginatorValue = pg;
    }
    public lroStatsVisible: boolean;

    private matSortValue: MatSort;
    public displayedColumns = ['surname', 'name', 'fiscalCode', 'beginDate'];
    private enrolledPrisData: EnrolledPri[];
    private paginatorValue;
    public dataSource: MatTableDataSource<EnrolledPri>;
    public nameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    public surnameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    public fiscalCodeFilter = new FormControl('', Validators.pattern(FISCAL_CODE_REGEX));
    public loggedUser: any;
    filterValues: any;
    public selectedUser: EnrolledPri;
    public selectedPRI: PRI;
    public selectedPRM: Prm;
    public selectedFollowUps: FollowUp[];
    public selectedReportCopings: ReportCoping[];

    public lroChartData: ChartDataSets[];
    public lroChartLabels: Label[];
    public lroChartOptions: ChartOptions & { annotation: any };
    public lineChartColors: Color[];
    public lineChartLegend;
    public lineChartType;
    private lroTrack: number[];
    private lroLabels: string[];
    private maxVal: number;

    public executedExercises: number;
    public exerciseExecutionPercentage: number;
    public meanWorkingTimeForm = new FormControl({ value: 0, disabled: true }, Validators.pattern(FISCAL_CODE_REGEX));
    public meanAssistedEvaluation: number;
    public meanPhysiotherapistEvaluation: number;
    public meanEfficacy: number;
    public meanCorrectness: number;
    exerciseExecutionFormControl = new FormControl({ value: undefined, disabled: false }, Validators.required);

    maxGaugeValuesOffset = 10;

    resultsUsers = [];
    legendUsers: boolean = true;
    legendUsersTitle: string = "Utenti"
    maxGaugeValueUsers: number;
    colorSchemeUsers = {};

    resultsAssisted = [];
    legendAssisted: boolean = true;
    legendAssistedTitle: string = "Assistiti"
    maxGaugeValueAssisted: number;
    colorSchemeAssisted = {};

    constructor(private _fuseConfigService: FuseConfigService,
        private sBar: MatSnackBar,
        private datePipe: DatePipe,
        private router: Router,
        private priService: PriService,
        private exerciseService: ExerciseService,
        private dialog: MatDialog,
        private userService: UserService,
        private spinner: NgxSpinnerService) {
        super(sBar);

        this.spinner.show();

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: false
                },
                toolbar: {
                    hidden: false
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!RoleOperationCheck.checkRoleOperation(this.loggedUser.role, Operations.home)) {
            this.router.navigate(["/login-page"]);
        }
        this.lroStatsVisible = RoleOperationCheck.checkRoleOperation(this.loggedUser.role, Operations.LRODashboard);

        if (!this.lroStatsVisible) {
            this.fillAdminDashboard();
        }

        this.filterValues = {
            name: '',
            surname: '',
            fiscalCode: ''
        };
        this.lroTrack = [];
        this.lroLabels = [];
        this.exerciseExecutionPercentage = 0;
        this.executedExercises = 0;
    }

    ngOnInit(): void {
        this.initData();
    }

    sortdata(sort: Sort): void {
        const data = this.dataSource.data;
        if (!sort.active || sort.direction === '') {
            this.enrolledPrisData = data;
            return;
        }
        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.compare(a.userData.name, b.userData.name, isAsc);
                case 'surname':
                    return this.compare(a.userData.surname, b.userData.surname, isAsc);
                case 'fiscalCode':
                    return this.compare(a.userData.fiscalCode, b.userData.fiscalCode, isAsc);
                default:
                    return 0;
            }
        });
        this.paginatorValue.firstPage();
        this.dataSource.paginator = this.paginatorValue;
    }

    // tslint:disable-next-line:typedef
    compare(a: string, b: string, isAsc: boolean) {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }


    filterButton(): void {
        if (!this.nameFilter.value && !this.surnameFilter.value && !this.fiscalCodeFilter.value) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
        } else {
            this.filter();
        }
    }

    filter(): void {

        this.filterValues = {
            name: '',
            surname: '',
            fiscalCode: ''
        };
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.filterValues.name = this.nameFilter.value.toLowerCase();
        this.filterValues.surname = this.surnameFilter.value.toLowerCase();
        this.filterValues.fiscalCode = this.fiscalCodeFilter.value.toLowerCase();
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    createFilter(): (data: any, filter: string) => boolean {
        // tslint:disable-next-line:only-arrow-functions
        const filterFunction = function (data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return data.userData.name.toLowerCase().indexOf(searchTerms.name) !== -1
                && data.userData.surname.toString().toLowerCase().indexOf(searchTerms.surname) !== -1
                && data.userData.fiscalCode.toLowerCase().indexOf(searchTerms.fiscalCode) !== -1;
        };
        return filterFunction;
    }

    resetFilters(): void {
        this.nameFilter.setValue('');
        this.surnameFilter.setValue('');
        this.fiscalCodeFilter.setValue('');
        this.filter();
    }

    resetDetails(): void {
        this.lroTrack = [];
        this.lroLabels = [];
        this.exerciseExecutionPercentage = 0;
        this.executedExercises = 0;
    }

    fillAdminDashboard() {
        this.legendAssisted = false;
        this.legendUsers = false;
        this.maxGaugeValueUsers = this.maxGaugeValuesOffset;
        this.maxGaugeValueAssisted = this.maxGaugeValuesOffset;

        this.colorSchemeUsers = {
            domain: ['#4895ef', '#b5179e', '#ef476f', '#e5989b']
        };

        this.colorSchemeAssisted = {
            domain: ['#ef476f', '#06d6a0', '#ffd166']
        };

        this.userService.getUsersStats().subscribe((response) => {
            this.legendAssisted = true;
            this.legendUsers = true;

            let users: Users = response.users;
            let assisted: Assisted = response.assisted;

            this.maxGaugeValueUsers = Math.ceil(users[Object.keys(users).reduce((a, b) => users[a] > users[b] ? a : b)] / this.maxGaugeValuesOffset) * this.maxGaugeValuesOffset;
            this.maxGaugeValueAssisted = Math.ceil(assisted[Object.keys(assisted).reduce((a, b) => assisted[a] > assisted[b] ? a : b)] / this.maxGaugeValuesOffset) * this.maxGaugeValuesOffset;

            if (this.maxGaugeValueUsers == 0)
                this.maxGaugeValueUsers = this.maxGaugeValuesOffset;
            if (this.maxGaugeValueAssisted == 0)
                this.maxGaugeValueAssisted = this.maxGaugeValuesOffset;

            this.resultsUsers = [
                {
                    "name": "Amministratori",
                    "value": users.admins
                },
                {
                    "name": "Fisiatri",
                    "value": users.physiatrists
                },
                {
                    "name": "Fisioterapisti",
                    "value": users.physiotherapists
                },
                {
                    "name": "Psicologi",
                    "value": users.psychologists
                }
            ];

            this.resultsAssisted = [
                {
                    "name": "Assistiti non arruolati",
                    "value": assisted.assistedWithoutPri
                },
                {
                    "name": "Assistiti con PRI attivo",
                    "value": assisted.assistedWithActivePri
                },
                {
                    "name": "Assistiti con PRI terminato",
                    "value": assisted.assistedWithTerminatedPri
                }
            ];
            this.spinner.hide();
        }, error => {
            this.showToast('Non è stato possibile ottenere le statistiche sugli utenti, riprovare più tardi.', StringUtils.ADVISE_TIME);
            this.spinner.hide();
        });
    }

    initData() {
        this.priService.getEnrolledUsers(this.loggedUser.uuc).subscribe((response) => {
            this.enrolledPrisData = response.enrolledPRIList;
            this.dataSource = new MatTableDataSource<EnrolledPri>(this.enrolledPrisData);
            this.dataSource.paginator = this.paginatorValue;
            this.dataSource.sort = this.matSortValue;
            this.sortdata(this.dataSource.sort);
            this.spinner.hide();
        }, (error: HttpErrorResponse) => {
            this.spinner.hide();
            if (error.status !== 404) {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }
        });
    }

    userClicked(clickedUser: EnrolledPri) {
        this.resetDetails();
        this.priService.getPRI(clickedUser.userData.uuc).subscribe((response) => {
            this.selectedUser = clickedUser;
            this.selectedPRI = response.pri;
            this.selectedPRM = response.prm;
            let aux = response.followUps;
            this.selectedReportCopings = response.reportCopings;

            if (this.selectedPRM !== null) {
                this.selectedFollowUps = aux.sort((first, second) => {
                    return (first.date < second.date ? -1 : 1);
                });

                for (let followUp of this.selectedFollowUps) {
                    this.lroTrack.push(followUp.lro);
                    this.lroLabels.push(this.datePipe.transform(followUp.date, 'dd/MM/yyyy'));
                }
                this.lineChartType = 'line';
                this.lineChartLegend = true;
                this.prepareChart(this.lroTrack, this.lroLabels);
                this.setupPrmStatistics(this.selectedPRM);
            }
        }, error => {
            console.log(error);
        });
    }

    private prepareChart(track: number[], labels: string[]) {
        let lroStructure: ChartDataSets = { data: [], label: '' };
        this.lroChartData = [];
        this.lroChartLabels = [];
        this.maxVal = 100;
        lroStructure.data = track;
        this.lroChartLabels = labels;
        lroStructure.label = 'LRO';
        this.lroChartData.push(lroStructure);
        this.initChart(track);
    }

    private initChart(track: number[]): void {
        /**** options intialization ****/
        this.lroChartOptions = this.setOptions('Follow-up', 'Valore LRO', track);

        this.lineChartColors = [
            {
                // blue
                backgroundColor: 'rgba(3,155,229,0.2)',
                borderColor: 'rgba(3,155,229,0.8)',
                pointBackgroundColor: 'rgba(3,155,229,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,155,229,0.8)',
            }
        ];
    }

    private setOptions(xLabel: string, yLabel: string, track: number[]): ChartOptions & { annotation: any } {
        let options: ChartOptions & { annotation: any } = {
            elements: { line: { tension: 0.1 } },
            responsive: true,
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    },
                    ticks: {
                        callback: function (value, index) {
                            return value;
                        },
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    },
                    id: 'y-axis-1', ticks: { min: 0, max: this.maxVal }
                }]
            },
            annotation: {
                annotations: [],
            },
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                    title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                        return '';
                    },
                    label: function (tooltipItems, data) {
                        return data.datasets[tooltipItems.datasetIndex]['label'] + ': ' + +tooltipItems.value;
                    }
                }
            }
        };
        return options;
    }

    private setupPrmStatistics(prm: Prm) {
        let totalWorkingTime: number = 0;
        let totalAssistedEvaluation: number = 0;
        let totalPhysiotherapistEvaluation: number = 0;
        let totalEfficacy: number = 0;
        let totalCorrectness: number = 0;
        for (let exercise of prm.exerciseExecutions) {
            if (exercise.executionDate !== undefined && exercise.executionDate !== null) {
                this.executedExercises++;
                totalWorkingTime += (new Date(exercise.executionEndTime).getTime() - new Date(exercise.executionBeginTime).getTime());
                if (exercise.assistedUserRating !== undefined && exercise.assistedUserRating !== null) {
                    totalAssistedEvaluation += exercise.assistedUserRating;
                }
                if (exercise.physiotherapistRating !== undefined && exercise.physiotherapistRating !== null) {
                    totalPhysiotherapistEvaluation += exercise.physiotherapistRating;
                }
                if (exercise.efficacy !== undefined && exercise.efficacy !== null) {
                    totalEfficacy += exercise.efficacy;
                }
                if (exercise.correctness !== undefined && exercise.correctness !== null) {
                    totalCorrectness += exercise.correctness;
                }
            }
        }
        this.exerciseExecutionPercentage = Number((this.executedExercises * 100 / prm.exerciseExecutions.length).toFixed(0));
        let meanWorkingTime = (totalWorkingTime / this.executedExercises);
        if (totalWorkingTime !== 0) {
            this.meanWorkingTimeForm.setValue(new Date(meanWorkingTime).toISOString().substr(11, 8));
        }
        this.meanAssistedEvaluation = totalAssistedEvaluation / this.executedExercises;
        this.meanPhysiotherapistEvaluation = totalPhysiotherapistEvaluation / this.executedExercises;
        this.meanEfficacy = totalEfficacy / this.executedExercises;
        this.meanCorrectness = totalCorrectness / this.executedExercises;
    }

    executionDetails(exercise: PrmExercise) {
        if (exercise === undefined || exercise === null) {
            this.exerciseExecutionFormControl.markAllAsTouched();
            this.showToast("Attenzione! Selezionare almeno un esercizio.", StringUtils.ADVISE_TIME);
            return;
        }
        if (exercise.executionDate === undefined || exercise.executionDate === null) {
            this.showToast("Attenzione! Esercizio non ancora eseguito", StringUtils.ADVISE_TIME);
            return;
        }
        const dialogRef = this.dialog.open(ExecutionDetailsDialogComponent, {
            data: {
                exercise: exercise,
                isHandExercise: exercise.category === "Riabilitazione mano",
                isLeftHand: !exercise.name.includes('(D)')
            },
            disableClose: true,
        });
    }
}
