import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {ExerciseCategory} from 'app/main/class/exercise-category';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';

import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ExerciseExecutionInterface} from '../../../class/interfaces/exercise-execution-interface';
import {DatePipe, DOCUMENT} from '@angular/common';
import {AddExecutionRatingDialogComponent} from '../add-execution-rating-dialog/add-execution-rating-dialog.component';
import {ExerciseService} from '../../../services/exercise/exercise.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {StringUtils} from '../../../common/string-utils';

@Component({
    selector: 'exercise-execution-valutation',
    templateUrl: './exercise-execution-valutation-page.component.html',
    styleUrls: ['./exercise-execution-valutation-page.component.scss'],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: myCustomTooltipDefaults,
        },
        ExerciseCategory,
        DatePipe
    ],
})
export class ExerciseExecutionValutationPageComponent extends ToastManager implements OnInit {
    displayedColumns = ['surname', 'name', 'category', 'exerciseName', 'executionDate', 'actions'];
    exerciseData: MatTableDataSource<ExerciseExecutionInterface>;
    exerciseList: ExerciseExecutionInterface[];
    sort: MatSort;
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.sort.active = "surname";
    }
    @ViewChild("pag") set paginator(pg: MatPaginator){
        this.paginatorValue = pg;
    }
    paginatorValue;
    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private router:Router,
        private exerciseCategories:ExerciseCategory,
        private _sBar: MatSnackBar,
        @Inject(DOCUMENT) private _document: Document,
        private spinner: NgxSpinnerService,
        private datePipe: DatePipe,
        private exerciseService: ExerciseService
    ) {
        super(_sBar);
        this.spinner.show();
        this.exerciseList = [];
        this.exerciseData = new MatTableDataSource(this.exerciseList);
    }
    openDialog(exercise): void {
        const dialogRef = this.dialog.open(AddExecutionRatingDialogComponent, {
            disableClose:true,
            data: {
                exercise: exercise,
                handVideo: exercise.exercise.category === "Riabilitazione mano"
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                const body = {
                    evaluation: result
                }
                this.exerciseService.insertExerciseExecutionRating(body).subscribe(() => {
                    this._document.defaultView.location.reload();
                }, error => {
                    this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                })
            }
        })
    }

    sortdata(sort: Sort, dataToSort: MatTableDataSource<any>): void {
        const data = dataToSort.data;
        dataToSort.data = data.sort((a, b) => {
            const isAsc = sort.direction !== 'asc';
            switch (sort.active) {
                case 'surname':
                    return this.compare(a.user.surname, b.user.surname, isAsc);
                case 'name':
                    return this.compare(a.user.name, b.user.name, isAsc);
                case 'category':
                    return this.compare(a.exercise.category, b.exercise.category, isAsc);
                case 'exerciseName':
                    return this.compare(a.exercise.name, b.exercise.name, isAsc);
                case 'executionDate':
                    return this.compare(a.executionDate, b.executionDate, isAsc);
                default:
                    return 0;
            }
        });
        dataToSort._updateChangeSubscription();
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    ngOnInit(): void {
        const loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!loggedUser) {
            this.router.navigate(['/login-page']);
        }

        this.exerciseService.getExerciseExecutionRating(loggedUser.uuc).subscribe((response) => {
            this.exerciseList = response.exerciseExecutionValutations;
            this.exerciseData = new MatTableDataSource(this.exerciseList);
            this.exerciseData.paginator = this.paginatorValue;
            this.exerciseData.sort = this.sort;
            this.sortdata(this.exerciseData.sort, this.exerciseData);

        }, error => {
            console.log(error);
            this.showToast("Servizio momentaneamente non disponibile", StringUtils.ADVISE_TIME);
        }, () => {
            this.spinner.hide();
        });
    }


}
