import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginPageComponent } from '../authentication/login-page/login-page.component';
import { ResetPasswordPageComponent } from '../authentication/reset-password-page/reset-password-page.component';
import { ChangePasswordPageComponent } from '../authentication/change-password-page/change-password-page.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { InsertUserPageComponent } from '../management/user-managment/insert-user-page/insert-user-page.component';
import { ListUsersPageComponent } from '../management/user-managment/list-users-page/list-users-page.component';
import { RequestResetPasswordPageComponent } from '../authentication/request-reset-password-page/request-reset-password-page.component';
import { HomePageComponent } from '../home/home-page/home-page.component';
import { ListExercisesPageComponent } from '../management/exercise-management/list-exercises-page/list-exercises-page.component';
import { InsertExercisePageComponent } from '../management/exercise-management/insert-exercise-page/insert-exercise-page.component';
import {InsertNewPriPageComponent} from '../management/assisted-management/insert-new-pri-page/insert-new-pri-page.component';
import {InsertNewPrmPageComponent} from '../management/assisted-management/insert-new-prm-page/insert-new-prm-page.component';
import {ListAssistedUsersComponent} from '../management/assisted-management/list-assisted-users/list-assisted-users.component';
import {FollowUpManagerPageComponent} from '../management/assisted-management/follow-up-manager-page/follow-up-manager-page.component';
import {ReportCopingPageComponent} from '../management/assisted-management/report-coping-page/report-coping-page.component';
import {EndPriPageComponent} from '../management/assisted-management/end-pri-page/end-pri-page.component';
import {AssistedUserInfoPageComponent} from '../management/assisted-management/assisted-user-info-page/assisted-user-info-page.component';
import {ExerciseExecutionValutationPageComponent} from '../management/exercise-management/exercise-execution-valutation-page/exercise-execution-valutation-page.component';
import {UserPriHistoryPageComponent} from '../management/assisted-management/user-pri-history-page/user-pri-history-page.component';
import {PriInfoPageComponent} from '../management/assisted-management/pri-info-page/pri-info-page.component';
import {ExerciseExecutionDetailPageComponent} from '../management/assisted-management/exercise-execution-detail-page/exercise-execution-detail-page.component';
const routes = [
    {
        path: 'login-page',
        component: LoginPageComponent
    }, {
        path: 'reset-password',
        component: ResetPasswordPageComponent
    }, {
        path: 'change-password-page',
        component: ChangePasswordPageComponent
    },
    {
        path: 'insert-user-page',
        component: InsertUserPageComponent
    },
    {
        path: 'list-users-page',
        component: ListUsersPageComponent
    },
    {
        path: 'reset-password-page-form',
        component: RequestResetPasswordPageComponent
    },
    {
        path: 'exercise-execution-valutation',
        component: ExerciseExecutionValutationPageComponent
    },
    {
        path: 'list-exercises-page',
        component: ListExercisesPageComponent
    },
    {
        path: 'insert-exercise-page',
        component: InsertExercisePageComponent
    },
    {
        path: 'new-pri',
        component: InsertNewPriPageComponent
    },
    {
        path: 'pri-info',
        component: PriInfoPageComponent
    },
    {
        path: 'new-prm',
        component: InsertNewPrmPageComponent
    },
    {
        path: 'list-assisted-users',
        component: ListAssistedUsersComponent
    },
    {
        path: 'user-pri-history',
        component: UserPriHistoryPageComponent
    },
    {
        path: 'follow-up-manager',
        component: FollowUpManagerPageComponent
    },
    {
        path: 'report-coping',
        component: ReportCopingPageComponent
    },
    {
        path: 'end-pri',
        component: EndPriPageComponent
    },
    {
        path: 'assisted-user-info',
        component: AssistedUserInfoPageComponent
    },
    {
        path: 'exercise-execution-detail',
        component: ExerciseExecutionDetailPageComponent
    },
    {
        path: 'home',
        component: HomePageComponent
    }
];

@NgModule({
    imports     : [
        RouterModule.forChild(routes),
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule, MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        MatDividerModule,
        MatSortModule,
        MatTableModule,
        TranslateModule,
        FuseSharedModule,
        MatProgressBarModule,
    ]
})

export class MainModule
{
}
