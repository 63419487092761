import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GetAssistedPriHistoryResponse} from '../../models/get-assisted-pri-history-response';
import {GetProjectDataResponse} from '../../models/get-project-data-response';
import {GetUserDrugsResponse} from '../../models/get-user-drugs-response';
import {GetAssistedFollowUpResponse} from '../../models/get-assisted-follow-up-response';
import {LoadFunctionalValDataResponse} from '../../models/load-functional-val-data-response';
import {GetActivePriResponse} from '../../models/get-active-pri-response';
import {GetEnrolledUsersResponse} from '../../models/get-enrolled-users-response';
import {Endpoints} from '../../class/endpoints';
import {PriAssociationResponse} from '../../models/pri-association-response';

@Injectable({
  providedIn: 'root'
})
export class PriService {

    constructor(private http: HttpClient,
                ) { }

    public checkPRI(requestBody: any): Observable<PriAssociationResponse>{
        return this.http.post<PriAssociationResponse>(
            Endpoints.DUEVITAB_USER_HAS_ACTIVE_PRI_API,
            requestBody
        ).pipe(map(checkPRI => {
            return checkPRI;
        }));
    }

    public getPRIHistory(assistedUuc: string): Observable<GetAssistedPriHistoryResponse>{
        return this.http.get<GetAssistedPriHistoryResponse>(
            Endpoints.DUEVITAB_GET_PRI_HISTORY_API,
            {
                headers: new HttpHeaders({
                    'assisted-id': assistedUuc
                })
            }
        ).pipe(map(getPRIHistory => {
            return getPRIHistory;
        }));
    }

    public insertReportCoping(requestBody: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_REPORT_COPING_API,
            requestBody
        ).pipe(map(insertReportCoping => {
            return insertReportCoping;
        }));
    }

    public editReportCoping(requestBody: any): Observable<any>{
        return this.http.put<any>(
            Endpoints.DUEVITAB_REPORT_COPING_API,
            requestBody
        ).pipe(map(editReportCoping => {
            return editReportCoping;
        }));
    }

    public deleteDrugPrescription(requestBody: any): Observable<any>{
        return this.http.request<any>("DELETE",
            Endpoints.DUEVITAB_DRUG_PRESCRIPTION_API,
            {body: requestBody}
        ).pipe(map(deleteDrugPrescription => {
            return deleteDrugPrescription;
        }));
    }

    public getProjectDataList(): Observable<GetProjectDataResponse>{
        return this.http.get<GetProjectDataResponse>(
            Endpoints.DUEVITAB_LOAD_PROJECT_DATA_LISTS_API
        ).pipe(map(getProjectDataList => {
            return getProjectDataList;
        }));
    }

    public getUserDrugs(body: any): Observable<GetUserDrugsResponse>{
        return this.http.post<GetUserDrugsResponse>(
            Endpoints.DUEVITAB_GET_USER_DRUGS_LIST_API,
            body
        ).pipe(map(getUserDrugs => {
            return getUserDrugs;
        }));
    }

    public insertDrugPrescription(body: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_DRUG_PRESCRIPTION_API,
            body
        ).pipe(map(insertDrugPrescription => {
            return insertDrugPrescription;
        }));
    }

    public insertPRI(body: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_PRI_API,
            body
        ).pipe(map(insertPRI => {
            return insertPRI;
        }));
    }

    public deleteFollowUp(body: any): Observable<any>{
        return this.http.request<any>("DELETE",
            Endpoints.DUEVITAB_FOLLOW_UP_API,
            {body: body}
        ).pipe(map(deleteFollowUp => {
            return deleteFollowUp;
        }));
    }

    public getAssistedFollowUp(assistedUuc: string): Observable<GetAssistedFollowUpResponse>{
        return this.http.get<GetAssistedFollowUpResponse>(
            Endpoints.DUEVITAB_GET_ASSISTED_FOLLOWUPS_API,
            {
                headers: new HttpHeaders({
                    'assisted-id': assistedUuc
                })
            }
        ).pipe(map(getAssistedFollowUp => {
            return getAssistedFollowUp;
        }));
    }

    public insertFollowUp(body: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_FOLLOW_UP_API,
            body
        ).pipe(map(insertFollowUp => {
            return insertFollowUp;
        }));
    }

    public editFollowUp(body: any): Observable<any>{
        return this.http.put<any>(
            Endpoints.DUEVITAB_FOLLOW_UP_API,
            body
        ).pipe(map(editFollowUp => {
            return editFollowUp;
        }));
    }

    public endPRI(body: any): Observable<any>{
        return this.http.request<any>("DELETE",
            Endpoints.DUEVITAB_PRI_API,
            {body: body}
        ).pipe(map(endPRI => {
            return endPRI;
        }));
    }

    public editPRI(body: any): Observable<any>{
        return this.http.put<any>(
            Endpoints.DUEVITAB_PRI_API,
            body
        ).pipe(map(editPRI => {
            return editPRI;
        }));
    }

    public loadFunctionalValData(key: string, type: string): Observable<LoadFunctionalValDataResponse>{
        return this.http.get<LoadFunctionalValDataResponse>(
            Endpoints.DUEVITAB_LOAD_FUNCTIONAL_VAL_DATA_API,
            {
                headers: new HttpHeaders({
                    'functional-data-key': String(key),
                    'functional-data-type': type
                })
            }
        ).pipe(map(loadFunctionalValData => {
            return loadFunctionalValData;
        }));
    }

    public getPRI(assistedId: string, priId?: number): Observable<GetActivePriResponse>{
        let id: string = '0';
        if(priId !== undefined) {
            id = String(priId);
        }
        return this.http.get<GetActivePriResponse>(
            Endpoints.DUEVITAB_GET_ACTIVE_PRI_API,
            {
                headers: new HttpHeaders({
                    'assisted-id': assistedId,
                    'pri-id': id
                })
            }
        ).pipe(map(getActivePRI => {
            return getActivePRI;
        }));
    }

    public getEnrolledUsers(uuc: string): Observable<GetEnrolledUsersResponse>{
        return this.http.get<GetEnrolledUsersResponse>(
            Endpoints.DUEVITAB_GET_ENLISTED_USERS_API,
            {
                headers: new HttpHeaders({
                    'uuc': uuc
                })
            }
        ).pipe(map(getEnrolledUsers => {
            return getEnrolledUsers;
        }));
    }
}
