<mat-dialog-content fxLayout='column' class="efficacy-dialog">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Esercizio: {{this.exerciseExecution.name}} del
            {{this.datePipe.transform(this.exerciseExecution.executionDate, 'dd/MM/yyyy')}}
        </mat-label>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Valutazioni
        </div>
        <div class="rating-container">
            <div class="grid-element" fxLayout="column">
                <mat-label fxFlex="30" class="rating-label">Assistito</mat-label>
                <div fxFlex="70" class="rating-label">
                    <mat-star-rating class="star-rating" [rating]="this.exerciseExecution.assistedUserRating"
                        [starCount]="5" [disabled]="true" color="accent"></mat-star-rating>
                </div>
            </div>
            <div class="grid-element" fxLayout="column">
                <mat-label fxFlex="30" class="rating-label">Fisioterapista</mat-label>
                <div fxFlex="70" class="rating-label">
                    <mat-star-rating class="star-rating" [rating]="this.exerciseExecution.physiotherapistRating"
                        [starCount]="5" [disabled]="true" color="accent"></mat-star-rating>
                </div>
            </div>
            <div class="grid-element" fxLayout="column">
                <mat-label fxFlex="30" class="rating-label">Efficacia</mat-label>
                <div fxFlex="70" class="rating-label">
                    <mat-progress-bar
                    fxFlex="50"
                    color="accent"
                    [mode]="'determinate'"
                    [value]="this.exerciseExecution.efficacy">
                </mat-progress-bar>
                <label class="percentage" fxFlex="10">{{this.exerciseExecution.efficacy}}%</label>
                </div>
            </div>
            <div class="grid-element" fxLayout="column">
                <mat-label fxFlex="30" class="rating-label">Correttezza</mat-label>
                <div fxFlex="70" class="rating-label">
                    <mat-star-rating class="star-rating" [rating]="this.exerciseExecution.correctness"
                        [starCount]="5" [disabled]="true" color="accent"></mat-star-rating>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!elabMovementsMod">
        <div fxFill fxLayout="column" *ngIf="bonesReady">
            <div class="border-container" *ngFor="let relevantExecutionBone of relevantExecutionBones; let i = index">
                <div class="h2 div-section-title">
                    {{relevantBoneNames[i]}}
                </div>
                <div class="bone-item">
                    <img [ngClass]="getLegendCss()" [src]="getLegendImage(i)" alt="avatar-legend">
                    <canvas *ngIf="bonesMovementChartData[i] !== undefined && bonesMovementsOptions[i] !== undefined"
                        baseChart [datasets]="bonesMovementChartData[i]" [labels]="bonesMovementsLabels"
                        [options]="bonesMovementsOptions[i]" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="elabMovementsMod && bonesReady">
        <div fxFill fxLayout="column">
            <div class="border-container" *ngFor="let bone of relevantBonesMovementsTrack | keyvalue : unsorted; index as i">
                <div class="h2 div-section-title">
                    {{isHandExercise ? handBoneNames[bone.key] : boneNames[bone.key]}}
                </div>
                <div class="bone-item">
                    <img [ngClass]="getLegendCss()" [src]="getLegendImageElabMov(bone.key)" alt="avatar-legend">
                    <canvas *ngIf="bonesMovementChartData[i] !== undefined && bonesMovementsOptions[i] !== undefined"
                        baseChart [datasets]="bonesMovementChartData[i]" [labels]="bonesMovementsLabels"
                        [options]="bonesMovementsOptions[i]" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"></canvas>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
    <div fxLayout='row-reverse' class="button-container">
        <button (click)="closeDialog()" mat-raised-button color="accent" class="back-button" aria-label="ESCI">
            Esci
        </button>
    </div>
</mat-dialog-actions>