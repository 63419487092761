<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"
></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Nuovo PRI
        </mat-label>
    </div>
    <div class="navigation-div" fxLayout='column'>
        <div class="h2 div-section-title">
            Piano Riabilitativo Individuale
        </div>
        <div class="story-line-container" fxLayout='row'>
            <div class="waypoint-container" *ngFor="let i of [1, 2, 3, 4, 5]" fxLayout="row">
                <div
                    [ngClass]="this.newPRIStep > i ? 'waypoint-wrapper clickable-icon' : 'waypoint-wrapper disabled-icon'" fxLayout="column" (click)="changePage(i)">
                    <div [ngClass]="newPRIStep >= i ? 'waypoint done-waypoint' : 'waypoint todo-waypoint' ">
                        <span class="step-number">{{i}}</span>
                    </div>
                    <span [ngClass]="newPRIStep >= i ? 'done-step-name' : 'todo-step-name' ">{{stepNames[i - 1]}}</span>
                </div>
                <img class="arrow" src="../../../../../assets/addedAsset/arrows/arrow-done.svg"
                     *ngIf="i != totalStepCount && newPRIStep >= i" alt="done">
                <img class="arrow" src="../../../../../assets/addedAsset/arrows/arrow-todo.svg"
                     *ngIf="i != totalStepCount && newPRIStep < i" alt="todo">
            </div>
        </div>
    </div>
    <div class="content-div" [ngSwitch]="newPRIStep">
        <div *ngSwitchCase="1">
            <!-- Assisted data case -->
            <div class="border-container">
                <div class="h2 div-section-title">
                    Ricerca assistito
                </div>
                <form [formGroup]="findAssistedForm" class="inner-form" #form="ngForm" (ngSubmit)="findAssisted()">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Codice fiscale assistito</mat-label>
                        <input matInput formControlName="fiscalCode" oninput="this.value = this.value.toUpperCase()"
                               required>
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                        <mat-error *ngIf="!this.findAssistedForm.get('fiscalCode').hasError('required')">Codice fiscale
                            assistito non valido
                        </mat-error>
                        <mat-error *ngIf="this.findAssistedForm.get('fiscalCode').hasError('required')">Codice fiscale
                            assistito obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="accent" class="search-button" type="submit"
                            aria-label="CERCA" [disabled]="findPressed || userFound">
                        CERCA
                    </button>
                    <button mat-raised-button color="accent" class="cancel-button" type="reset"
                            aria-label="ANNULLA" (click)="resetAssistedUserForm()">
                        ANNULLA
                    </button>
                </form>
            </div>

            <div class="border-container" *ngIf="!noUserFound && userFound">
                <div class="h2 div-section-title">
                    Anagrafica assistito
                </div>
                <form [formGroup]="userDataForm" class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="38">
                        <mat-label>Cognome</mat-label>
                        <input matInput formControlName="surname">
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="38">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name">
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="19" disabled>
                        <mat-label>Data di nascita</mat-label>
                        <input matInput formControlName="birthDate" type="date" [max]="'9999-12-31'">
                    </mat-form-field>
                </form>
            </div>
            <div class="border-container" *ngIf="!noUserFound && userFound">
                <div class="h2 div-section-title">
                    Farmaci
                </div>

                <div class="border-container border-container-top-header" fxLayout="column">
                    <div class="header-div">
                        Inserisci farmaco
                    </div>
                    <form [formGroup]="insertDrugForm" class="inner-form" fxLayout="row"
                          fxLayoutAlign="space-between center">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Farmaco</mat-label>
                            <input matInput formControlName="drugName" required>
                            <mat-error *ngIf="!this.insertDrugForm.get('drugName').hasError('required')">Farmaco non
                                valido
                            </mat-error>
                            <mat-error *ngIf="this.insertDrugForm.get('drugName').hasError('required')">Farmaco
                                obbligatorio
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="22.5">
                            <mat-label>Data inizio</mat-label>
                            <input matInput formControlName="beginDate" (change)="drugDateChange()" type="date" onkeydown="return false" [max]="'9999-12-31'" required>
                            <mat-error *ngIf="!this.insertDrugForm.get('beginDate').hasError('required')">Data inizio
                                non valida
                            </mat-error>
                            <mat-error *ngIf="this.insertDrugForm.get('beginDate').hasError('required')">Data inizio
                                obbligatoria
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="22.5">
                            <mat-label>Data fine</mat-label>
                            <input matInput formControlName="endDate" type="date" onkeydown="return false" [max]="'9999-12-31'" required>
                            <mat-error *ngIf="!this.insertDrugForm.get('endDate').hasError('required')">Data fine non
                                valida
                            </mat-error>
                            <mat-error *ngIf="this.insertDrugForm.get('endDate').hasError('required')">Data fine
                                obbligatoria
                            </mat-error>
                        </mat-form-field>
                    </form>
                    <form [formGroup]="insertDrugForm" class="inner-form" fxLayout="row"
                          fxLayoutAlign="space-between center">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Dose</mat-label>
                            <textarea matInput formControlName="drugDose" required></textarea>
                            <mat-error *ngIf="!this.insertDrugForm.get('drugDose').hasError('required')">Dose non
                                valida
                            </mat-error>
                            <mat-error *ngIf="this.insertDrugForm.get('drugDose').hasError('required')">Dose
                                obbligatoria
                            </mat-error>
                        </mat-form-field>
                    </form>
                    <form [formGroup]="insertDrugForm" class="inner-form" fxLayout="row"
                          fxLayoutAlign="space-between center" (ngSubmit)="insertDrugPrescrition()">
                        <button mat-raised-button color="accent" class="search-button" type="submit"
                                aria-label="AGGIUNGI" [disabled]="addPrescritionPressed">
                            AGGIUNGI
                        </button>
                        <button mat-raised-button color="accent" class="cancel-button" type="reset"
                                aria-label="ANNULLA" [disabled]="addPrescritionPressed" (click)="resetInsertDrugForm()">
                            ANNULLA
                        </button>
                    </form>
                    <div class='mat-elevation-z1 tableResponsive margin-table' *ngIf="this.drugsList.length !== 0">
                        <table mat-table [dataSource]="drugsDataSource" matSort matSortDisableClear="true"
                               class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, drugsDataSource)">
                            <ng-container matColumnDef="drugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="drugName"
                                    class="column-drug-name"> Farmaco
                                </th>
                                <td mat-cell #name isEllipsisActive *matCellDef="let drug"
                                    class="column-drug-name"> {{drug.drugName.name}} </td>
                            </ng-container>
                            <ng-container matColumnDef="dose">
                                <th mat-header-cell *matHeaderCellDef class="column-drug-dose"> Dose</th>
                                <td mat-cell #surname isEllipsisActive *matCellDef="let drug; let i = index"
                                    class="column-drug-dose"> {{drug.dose}} </td>
                            </ng-container>
                            <ng-container matColumnDef="beginDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate"> Data inizio
                                </th>
                                <td mat-cell
                                    *matCellDef="let drug"> {{this.datePipe.transform(drug.beginDate, "dd/MM/yyyy")}} </td>
                            </ng-container>
                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate"> Data fine</th>
                                <td mat-cell
                                    *matCellDef="let drug"> {{this.datePipe.transform(drug.endDate, "dd/MM/yyyy")}} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                                <td mat-cell *matCellDef="let drug; let i = index;" class="column-action">
                                    <div>
                                        <mat-icon class="clickable-icon"
                                                  matTooltip="Elimina farmaco {{drug.drugName.name}}"
                                                  matTooltipPosition="above" [matTooltipShowDelay]="500"
                                                  matTooltipClass="custom-tooltip" (click)="openDeleteDrugDialog(drug, i)">
                                            delete
                                        </mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>
                        <div *ngIf="drugsDataSource !== undefined && drugsDataSource.filteredData.length === 0"
                             class="no-record mat-elevation-z1"><label>Nessun farmaco trovato per l'utente</label></div>
                    </div>
                </div>
            </div>
            <div *ngIf="noUserFound" class="no-record-label mat-elevation-z1"><label>Nessun assistito trovato
                corrispondente al criterio di ricerca inserito</label></div>
        </div>
        <div *ngSwitchCase="2">
            <!-- Project data case -->
            <div class="border-container" *ngIf="diagnosis !== undefined && procedures !== undefined">
                <div class="h2 div-section-title">
                    Dati progetto
                </div>
                <form [formGroup]="projectDataForm" class="inner-form" fxLayout="row"
                      fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" fxFlex="56">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                        <mat-error *ngIf="!this.projectDataForm.get('name').hasError('required')">Nome non valido
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('name').hasError('required')">Nome obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="20">
                        <mat-label>Data assegnazione</mat-label>
                        <input matInput formControlName="startDate" type="date" onkeydown="return false" (change)="projectDateChange()" min="{{todayDate | date:'yyyy-MM-dd'}}" [max]="'9999-12-31'" required>
                        <mat-error *ngIf="!this.projectDataForm.get('startDate').hasError('required')">Data assegnazione
                            non valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('startDate').hasError('required')">Data assegnazione
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="19" disabled>
                        <mat-label>Tempi previsti (giorni)</mat-label>
                        <input matInput formControlName="daysCount" required>
                        <mat-error *ngIf="!this.projectDataForm.get('daysCount').hasError('required')">Tempi previsti
                            non validi
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('daysCount').hasError('required')">Tempi previsti
                            obbligatorio
                        </mat-error>
                    </mat-form-field>
                </form>
                <form [formGroup]="projectDataForm" class="inner-form" fxLayout="column">
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Anamnesi</mat-label>
                        <textarea matInput formControlName="anamnesis" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('anamnesis').hasError('required')">Anamnesi non
                            valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('anamnesis').hasError('required')">Anamnesi
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Sintesi</mat-label>
                        <textarea matInput formControlName="synthesis" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('synthesis').hasError('required')">Sintesi non
                            valida
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('synthesis').hasError('required')">Sintesi
                            obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="project-data-textarea" fxFlex>
                        <mat-label>Obiettivi</mat-label>
                        <textarea matInput formControlName="goals" required></textarea>
                        <mat-error *ngIf="!this.projectDataForm.get('goals').hasError('required')">Obiettivi non
                            valido
                        </mat-error>
                        <mat-error *ngIf="this.projectDataForm.get('goals').hasError('required')">Obiettivi
                            obbligatorio
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Diagnosi
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Patologie</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Patologie riscontrate *</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="filteredDiagnosis === undefined ||
                    filteredDiagnosis === null">Inserisci almeno una parola chiave</div>
                    <div class="list-info" *ngIf="filteredDiagnosis !== undefined && filteredDiagnosis !== null &&
                    filteredDiagnosis.length === 0">Nessun elemento trovato</div>
                    <mat-selection-list *ngIf="diagnosis !== undefined"
                                        #availableDiagnosisPatologies
                                        (selectionChange)="foundDiagnosisPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedAvailableDiagnosisPatology">
                        <mat-list-option *ngFor="let pat of filteredDiagnosis" (click)="changeSwapDirection(true)"
                                         [value]="pat">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div *ngIf="diagnosis !== undefined" fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(0)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxFlex="45" >
                        <mat-selection-list *ngIf="diagnosis !== undefined"
                                            #foundDiagnosisPatologies
                                            (selectionChange)="availableDiagnosisPatologies.deselectAll()"
                                            [ngClass]="diagnosisCheckBlock || diagnosis.found.length!==0 ? 'lists' : 'lists-with-error'"
                                            multiple="false"[(ngModel)]="selectedFoundDiagnosisPatology">
                            <mat-list-option *ngFor="let pat of diagnosis.found" (click)="changeSwapDirection(false)"
                                             [value]="pat">
                                {{pat.name}}
                            </mat-list-option>
                        </mat-selection-list>
                        <mat-label class="custom-error-label"
                            *ngIf="!diagnosisCheckBlock && diagnosis.found.length===0">
                            Patologie riscontrate obbligatorie
                        </mat-label>
                    </div>
                </div>
                <div class="inner-form" fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca diagnosi</mat-label>
                        <input matInput [(ngModel)]="filterDiagnosisValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                        (click)="filterPatologies()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Comorbidità
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Patologie</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Patologie riscontrate</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="filteredComorbidity === undefined ||
                    filteredComorbidity === null">Inserisci almeno una parola chiave</div>
                    <div class="list-info" *ngIf="filteredComorbidity !== undefined && filteredComorbidity !== null &&
                    filteredComorbidity.length === 0">Nessun elemento trovato</div>
                    <mat-selection-list *ngIf="comorbidity !== undefined"
                                        #availableComorbidityPatologies
                                        (selectionChange)="foundComorbidityPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedAvailableComorbidityPatology">
                        <mat-list-option *ngFor="let pat of filteredComorbidity" (click)="changeSwapDirection(true)"
                                         [value]="pat">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div *ngIf="comorbidity !== undefined" fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(1)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <mat-selection-list *ngIf="comorbidity !== undefined"
                                        #foundComorbidityPatologies
                                        (selectionChange)="availableComorbidityPatologies.deselectAll()" class="lists"
                                        multiple="false" fxFlex="45" [(ngModel)]="selectedFoundComorbidityPatology">
                        <mat-list-option *ngFor="let pat of comorbidity.found" (click)="changeSwapDirection(false)"
                                         [value]="pat">
                            {{pat.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="inner-form" fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca diagnosi</mat-label>
                        <input matInput [(ngModel)]="filterComorbidityValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                                (click)="filterComorbidity()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>

            <div class="border-container">
                <div class="h2 div-section-title">
                    Tipo operazione
                </div>
                <div class="inner-form" fxLayout="row">
                    <div fxFlex="45">Operazioni</div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45">Operazioni subite</div>
                </div>
                <div class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="list-info" *ngIf="filteredProcedures === undefined ||
                    filteredProcedures === null">Inserisci almeno una parola chiave</div>
                    <div class="list-info" *ngIf="filteredProcedures !== undefined && filteredProcedures !== null &&
                    filteredProcedures.length === 0">Nessun elemento trovato</div>
                    <mat-selection-list *ngIf="procedures !== undefined"
                                        #availableOperations (selectionChange)="foundOperations.deselectAll()"
                                        class="lists" multiple="false" fxFlex="45"
                                        [(ngModel)]="selectedAvailableOperation">
                        <mat-list-option *ngFor="let op of filteredProcedures" (click)="changeSwapDirection(true)"
                                         [value]="op">
                            {{op.name}}
                        </mat-list-option>
                    </mat-selection-list>
                    <div *ngIf="procedures !== undefined" fxFlex="10" class="swap-button-container">
                        <button mat-button class="swap-button" (click)="swapData(2)">
                            <mat-icon class="icon">swap_horiz</mat-icon>
                        </button>
                    </div>
                    <mat-selection-list *ngIf="procedures !== undefined"
                                        #foundOperations (selectionChange)="availableOperations.deselectAll()"
                                        class="lists" multiple="false" fxFlex="45" [(ngModel)]="selectedFoundOperation">
                        <mat-list-option *ngFor="let op of procedures.found" (click)="changeSwapDirection(false)"
                                         [value]="op">
                            {{op.name}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="inner-form" fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="45">
                        <mat-label>Cerca procedure</mat-label>
                        <input matInput [(ngModel)]="filterProceduresValue">
                        <button mat-mini-fab matSuffix color="accent" class="inner-button" aria-label="CERCA"
                                (click)="filterProcedures()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <div fxFlex="55"></div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="3">
            <!-- Rehabilitation team case -->
            <div class="border-container">
                <div class="h2 div-section-title">
                    Team riabilitativo
                </div>
                <a class="add-text" (click)="openAddUserDialog()">+ aggiungi</a>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="rehabilitationTeamDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, rehabilitationTeamDataSource)">
                        <ng-container matColumnDef="surname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="surname" class="column-team-surname">
                                Cognome
                            </th>
                            <td mat-cell #surname isEllipsisActive *matCellDef="let element"
                                class="column-team-surname"> {{element.surname}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-team-name">
                                Nome
                            </th>
                            <td mat-cell #name isEllipsisActive *matCellDef="let element"
                                class="column-team-name"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Ruolo
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.role}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let element; let i = index" class="column-action">
                                <div>
                                    <mat-icon *ngIf="element.role !== this.loggedUser.role" class="clickable-icon"
                                              matTooltip="Elimina {{element.role.toLowerCase() + ' ' + element.surname + ' ' + element.name}}"
                                              matTooltipPosition="above" [matTooltipShowDelay]="500"
                                              matTooltipClass="custom-tooltip"
                                              (click)="openDeleteTeamElementDialog(element, i)">delete
                                    </mat-icon>
                                    <mat-icon *ngIf="element.role === this.loggedUser.role" class="disabled-icon">
                                        delete
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="teamDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: teamDisplayedColumns;"></tr>

                    </table>
                    <div
                        *ngIf="rehabilitationTeamDataSource === undefined || rehabilitationTeamDataSource.data.length === 0"
                        class="no-record mat-elevation-z1"><label>Nessun utente inserito</label></div>
                </div>
            </div>

        </div>
        <div *ngSwitchCase="4">
            <!-- Functional valutation case -->
            <div class="border-container">
                <div class="h2 div-section-title">
                    Valutazione personale
                </div>
                <a class="add-text" (click)="openAddEvaluationDialog()">+ aggiungi</a>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="evaluationsDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, evaluationsDataSource)">
                        <ng-container matColumnDef="acronym">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="acronym" class="column-acronym">
                                Sigla
                            </th>
                            <td mat-cell #acronym isEllipsisActive *matCellDef="let evaluation"
                                class="column-acronym"> {{evaluation.attributeValutation.dimensionValutation.standardValutation.acronym}} </td>
                        </ng-container>
                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="category" class="column-category">
                                Categoria
                            </th>
                            <td mat-cell #category isEllipsisActive *matCellDef="let evaluation"
                                class="column-category"> {{evaluation.attributeValutation.dimensionValutation.description}} </td>
                        </ng-container>
                        <ng-container matColumnDef="attribute">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="attribute"> Attributo
                            </th>
                            <td mat-cell *matCellDef="let evaluation"> {{evaluation.attributeValutation.name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="value"> Valore
                            </th>
                            <td mat-cell *matCellDef="let evaluation">{{evaluation.value}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                            <td mat-cell *matCellDef="let evaluation; let i = index" class="column-action">
                                <div>
                                    <mat-icon class="clickable-icon" matTooltip="Elimina valutazione funzionale"
                                              matTooltipPosition="above" [matTooltipShowDelay]="500"
                                              matTooltipClass="custom-tooltip" (click)="openDeleteEvaluationDialog(i)">
                                        delete
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="evaluationsDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: evaluationsDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="evaluationsDataSource === undefined || evaluationsDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessuna valutazione funzionale</label></div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="5">
            <!-- Healt state case -->
            <div class="border-container">
                <div class="h2 div-section-title">
                    Parametri vitali
                </div>
                <div class='health-parameters-container' [formGroup]="healthParametersForm">
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa diastolica (mmHg)</mat-label>
                        <input matInput formControlName="diastolicBloodPressure" maxlength="3" required>
                        <mat-error
                            *ngIf="!this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">
                            Pressione arteriosa diastolica non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">
                            Pressione arteriosa diastolica obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Pressione arteriosa sistolica (mmHg)</mat-label>
                        <input matInput formControlName="systolicBloodPressure" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa sistolica non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('systolicBloodPressure').hasError('required')">
                            Pressione arteriosa sistolica obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza cardiaca (bpm)</mat-label>
                        <input matInput formControlName="cardioFrequency" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioFrequency').hasError('required')">
                            Frequenza cardiaca obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Ritmo cardiaco</mat-label>
                        <mat-select formControlName="cardioRhythm" required>
                            <mat-option *ngFor="let rythm of availableRythms" [value]="rythm">
                                {{rythm}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco non valido
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo
                            cardiaco obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Frequenza respiratoria</mat-label>
                        <input matInput formControlName="breathFrequency" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('breathFrequency').hasError('required')">
                            Frequenza respiratoria obbligatoria
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="grid-element">
                        <mat-label>Saturazione arteriosa (%)</mat-label>
                        <input matInput formControlName="arterialSaturation" maxlength="3" required>
                        <mat-error *ngIf="!this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa non valida
                        </mat-error>
                        <mat-error *ngIf="this.healthParametersForm.get('arterialSaturation').hasError('required')">
                            Saturazione arteriosa obbligatoria
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngSwitchDefault></div>
        <div class="navigation-button-container" fxLayout="row" *ngIf="contentReady && userFound">
            <button mat-raised-button color="accent" class="next-button" *ngIf="newPRIStep === 5"
                    aria-label="SALVA" (click)="savePri()">
                SALVA
            </button>
            <button mat-raised-button color="accent" class="next-button" *ngIf="newPRIStep < 5"
                    aria-label="CONTINUA" (click)="nextStep()">
                CONTINUA
            </button>
            <button mat-raised-button color="accent" class="reset-button"
                    aria-label="ANNULLA" [disabled]="!contentReady" (click)="resetTab()">
                ANNULLA
            </button>
        </div>
    </div>
</div>