<div fxLayout='column' class="add-element-panel">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Aggiungi personale
        </mat-label>
    </div>
    <div class='Align-center' fxLayout='row' fxLayoutAlign="space-between center" [formGroup]="addTeamElementForm">
        <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Persona</mat-label>
            <mat-select formControlName="teamElement" required (selectionChange)="updateForm($event)">
                <mat-option *ngFor="let element of availableTeamElements" [value]="element">
                    {{element.surname + ' ' + element.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="this.addTeamElementForm.get('teamElement').hasError('required')">Persona obbligatoria</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="48">
            <mat-label>Ruolo</mat-label>
            <input matInput formControlName="role" required>
            <mat-error *ngIf="this.addTeamElementForm.get('role').hasError('required')">Ruolo obbligatorio</mat-error>
        </mat-form-field>
    </div>
    <div fxLayout='row-reverse'>
        <button (click)="closeDialog(false)" mat-raised-button color="accent" class="delete-submit-button" aria-label="ESCI">
            Annulla
        </button>
        <button (click)="closeDialog(true)" mat-raised-button color="accent" class="delete-submit-button" aria-label="ESCI">
            Aggiungi
        </button>
    </div>
</div>