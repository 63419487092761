import {MatPaginatorIntl} from '@angular/material/paginator';

const itaMatPaginatorLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
        return `0 di ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} di ${length}`;
}


export function getItaMatPaginatorLabelIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Elementi per pagina:';
    paginatorIntl.nextPageLabel = 'Pagina seguente';
    paginatorIntl.lastPageLabel = 'Ultima pagina';
    paginatorIntl.firstPageLabel = 'Prima pagina';
    paginatorIntl.previousPageLabel = 'Pagina precedente';
    paginatorIntl.getRangeLabel = itaMatPaginatorLabel;

    return paginatorIntl;
}