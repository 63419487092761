import {Component, OnInit, ViewChild} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, Validators} from '@angular/forms';
import {ExerciseDialogComponent} from '../exercise-dialog/exercise-dialog.component';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {ExerciseCategory} from 'app/main/class/exercise-category';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';

import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ExerciseService} from '../../../services/exercise/exercise.service';
import {RoleOperationCheck} from '../../../authentication/util/role-operation-check';
import {Operations} from '../../../authentication/util/operations';
import {NgxSpinnerService} from 'ngx-spinner';
import {AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX} from '../../../common/commons.class';
import {StringUtils} from '../../../common/string-utils';

@Component({
    selector: 'app-view-exercise',
    templateUrl: './list-exercises-page.component.html',
    styleUrls: ['./list-exercises-page.component.scss'],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: myCustomTooltipDefaults,
        },
        ExerciseCategory
    ],
})
export class ListExercisesPageComponent extends ToastManager implements OnInit {
    canEdit: boolean;
    loggedUser;
    displayedColumns = ['name', 'category', 'actions'];
    exerciseData;
    dataSource;
    nameFilter = new FormControl('', [Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]);
    categoryFilter = new FormControl('');
    sort;
    categories;
    filterValues = {
        name: '',
        category: '',
    };

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.dataSource && this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set paginator(pg: MatPaginator) {
        this.paginatorValue = pg;
        if (this.dataSource) {
            this.dataSource.paginator = pg;
        }
    }

    paginatorValue;

    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private exerciseCategories: ExerciseCategory,
        private _sBar: MatSnackBar,
        private spinner: NgxSpinnerService,
        private exerciseService: ExerciseService
    ) {
        super(_sBar);
        this.spinner.show();
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        this.canEdit = RoleOperationCheck.checkRoleOperation(this.loggedUser.role, Operations.editExercise);
    }

    sortClick(): void {
        this.paginatorValue.firstPage();
    }

    openDialog(exercise, type): void {
        const dialogRef = this.dialog.open(ExerciseDialogComponent, {
            disableClose: true,
            data: {
                view: type,
                exercise: exercise,
            },
        });
    }

    resetFilters(): void {
        this.nameFilter.setValue('');
        this.categoryFilter.setValue('');
        this.filter();
    }

    setDataSourceAttributes(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.categories = this.exerciseCategories.getCategories();
        const loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!loggedUser) {
            this.router.navigate(['/login-page']);
        }

        this.exerciseService.getAllExercises().subscribe((response) => {
                let exercises = response.exercises.sort((a,b) => a.name.localeCompare(b.name));  
                this.dataSource = new MatTableDataSource(exercises);
                this.dataSource.filterPredicate = this.createFilter();
                this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
                this.setDataSourceAttributes();
            }, error => {
            },
            () => {
                this.spinner.hide();
            });

    }

    filter(): void {
        this.filterValues = {
            name: '',
            category: '',
        };
        this.filterValues.name = this.nameFilter.value.toLowerCase();
        this.filterValues.category = this.categoryFilter.value.toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    filterButton(): void {
        if (!this.nameFilter.value && !this.categoryFilter.value) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        this.filter();
    }

    createFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function(data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return (
                data.name.toLowerCase().indexOf(searchTerms.name) !== -1 &&
                data.category
                    .toString()
                    .toLowerCase()
                    .indexOf(searchTerms.category) !== -1
            );
        };
        return filterFunction;
    }

    getFormValidation(): boolean {
        return this.nameFilter.valid && this.categoryFilter.valid;
    }
}
