<div fxLayout='column' class="add-element-panel">
    <div class="header mt-0 add-element" fxLayout="row" fxLayoutAlign="center">
        <mat-label class='header_label'>Aggiungi valutazione funzionale
        </mat-label>
    </div>
    <div class='Align-center' fxLayout='column' fxLayoutAlign="space-between center" [formGroup]="addEvaluationForm">
        <div fxFill fxFlex fxLayout='row' fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="48">
                <mat-label>Sigla</mat-label>
                <mat-select formControlName="acronym" required (selectionChange)="loadData('1',$event.value.acronym)">
                    <mat-option *ngFor="let acronym of availableAcronyms" [value]="acronym">
                        {{acronym.acronym}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.addEvaluationForm.get('acronym').hasError('required')">Sigla obbligatoria</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="48">
                <mat-label>Categoria</mat-label>
                <mat-select formControlName="category" required [disabled]="availableAcronyms.length === 0" (selectionChange)="loadData('2',$event.value.id); this.scaleId = $event.value.scaleId">
                    <mat-option *ngFor="let category of availableCategories" [value]="category">
                        {{category.description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.addEvaluationForm.get('category').hasError('required')">Categoria obbligatoria</mat-error>
            </mat-form-field>
        </div>
        <div fxFill fxFlex fxLayout='row' fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="48">
                <mat-label>Attributo</mat-label>
                <mat-select formControlName="attribute" required [disabled]="availableCategories.length === 0" (selectionChange)="loadData('3',scaleId.toString())">
                    <mat-option *ngFor="let attribute of availableAttributes" [value]="attribute">
                        {{attribute.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.addEvaluationForm.get('attribute').hasError('required')">Attributo obbligatorio</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="48">
                <mat-label>Valore</mat-label>
                <mat-select formControlName="value" required [disabled]="availableAttributes.length === 0">
                    <mat-option *ngFor="let value of availableValues.valueValutation" [value]="value">
                        {{value.value + " - " + value.description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.addEvaluationForm.get('value').hasError('required')">Valore obbligatorio</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout='row-reverse'>
        <button (click)="closeDialog(false)" mat-raised-button color="accent" class="delete-submit-button" aria-label="ESCI">
            Annulla
        </button>
        <button (click)="closeDialog(true)" mat-raised-button color="accent" class="delete-submit-button" aria-label="ESCI">
            Aggiungi
        </button>
    </div>
</div>