import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {RemovePhoneDialogComponent} from '../../user-managment/remove-phone-dialog/remove-phone-dialog.component';

@Component({
    selector: 'app-delete-file-dialog',
    templateUrl: './delete-file-dialog.component.html',
    styleUrls: ['./delete-file-dialog.component.scss']
})
export class DeleteFileDialogComponent implements OnInit {


    constructor(
        public dialogRef: MatDialogRef<RemovePhoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    ngOnInit(): void {
    }
    closeDialog(): void {
        this.dialogRef.close();
    }
}
