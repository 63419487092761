import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Standard} from '../../../class/interfaces/standard';
import {Category} from '../../../class/interfaces/category';
import {Attribute} from '../../../class/interfaces/attribute';
import {Scale} from '../../../class/interfaces/scale';
import {FunctionalValutation} from '../../../class/interfaces/functional-valutation';
import {PriService} from '../../../services/pri/pri.service';
import {BASE_REGEX} from '../../../common/commons.class';
import {ReportCoping} from '../../../class/interfaces/report-coping';
import {StringUtils} from '../../../common/string-utils';

@Component({
    selector: 'add-evaluation-dialog',
    templateUrl: './edit-report-coping-dialog.component.html',
    styleUrls: ['./edit-report-coping-dialog.component.scss']
})
export class EditReportCopingDialogComponent extends ToastManager implements OnInit {

    loggedUser: any;
    scaleId: number;
    evaluation: ReportCoping;

    reportCopingForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<EditReportCopingDialogComponent>,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private priService: PriService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        super(snackBar);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        this.evaluation = data.report;
    }
    ngOnInit(): void {
        this.reportCopingForm = this._formBuilder.group({
            evaluation: [this.data.report.valutation, [Validators.required, Validators.maxLength(1000), Validators.pattern(BASE_REGEX)]]
        });
    }

    closeDialog(save:boolean): void {
        if(save) {
            if(this.reportCopingForm.get('evaluation').hasError('required') || this.reportCopingForm.get('evaluation').value.trim() == ''){
                this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
                return;
            }

            const body = {
                reportCoping: this.evaluation
            }
            this.priService.editReportCoping(body).subscribe((response) => {
                if(response.success) {
                    this.evaluation.valutation = this.reportCopingForm.get("evaluation").value;
                    this.showToast("Report coping modificato", StringUtils.ADVISE_TIME);
                    this.dialogRef.close();
                }
            }, error => {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            })
        }else{
            this.dialogRef.close();
        }
    }
}
