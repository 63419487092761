import {Component, Inject, OnInit,} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ToastManager} from '../../utils/toast-manager';

import {MatSnackBar} from '@angular/material/snack-bar';
import {NUMBERS_ONLY_REGEX} from '../../../common/commons.class';
import {DUPLICATED_FOLLOW_UP, FOLLOW_UP_OK} from '../follow-up-manager-page/follow-up-manager-page.component';
import {PriService} from '../../../services/pri/pri.service';
import * as moment from 'moment';
import {StringUtils} from '../../../common/string-utils';

@Component({
    selector: 'app-followup-dialog',
    templateUrl: './follow-up-dialog.component.html',
    styleUrls: ['./follow-up-dialog.component.scss'],
    providers: [
        DatePipe
    ]
})
export class FollowUpDialogComponent extends ToastManager implements OnInit {

    generalDataForm: FormGroup;
    healthParametersForm: FormGroup;
    availableRythms: String[];
    private loggedUser: any;

    private selectedDate: Date;

    constructor(
        public dialogRef: MatDialogRef<FollowUpDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        @Inject(DOCUMENT) private _document: Document,
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private _sBar: MatSnackBar,
        private priService: PriService
    ) {
        super(_sBar);

        this.healthParametersForm = this._formBuilder.group({
            diastolicBloodPressure: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            systolicBloodPressure: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioFrequency: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            cardioRhythm: [{value: '', disabled: !data.editMode}, [Validators.required]],
            breathFrequency: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            arterialSaturation: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]]
        });

        this.generalDataForm = this._formBuilder.group({
            date: [{value: '', disabled: !data.editMode}, [Validators.required]],
            time: [{value: '', disabled: !data.editMode}, [Validators.required]],
            lro: [{
                value: '',
                disabled: !data.editMode
            }, [Validators.required, Validators.pattern(NUMBERS_ONLY_REGEX)]],
            estimatedLro: [{value: '', disabled: true}, [Validators.pattern(NUMBERS_ONLY_REGEX)]],
            editPlan: [{value: '', disabled: !data.editMode}, [Validators.required]],
            note: [{value: '', disabled: !data.editMode}, Validators.maxLength(1000)]
        });
        this.availableRythms = ['Regolare', 'Irregolare', 'Sinusale', 'Basso', 'Alto'];
    }

    ngOnInit(): void {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));

        this.healthParametersForm.setValue({
            diastolicBloodPressure: this.data.followUp.healthParamters.diastolicBloodPressure,
            systolicBloodPressure: this.data.followUp.healthParamters.systolicBloodPressure,
            cardioFrequency: this.data.followUp.healthParamters.cardioFrequency,
            cardioRhythm: this.data.followUp.healthParamters.cardioRhythm,
            breathFrequency: this.data.followUp.healthParamters.breathFrequency,
            arterialSaturation: this.data.followUp.healthParamters.arterialSaturation
        });
        this.healthParametersForm.valueChanges.subscribe(val => {
            if (Number(val.diastolicBloodPressure) > Number(val.systolicBloodPressure)) {
                if (val.diastolicBloodPressure !== null && val.diastolicBloodPressure !== '' &&
                    val.systolicBloodPressure !== null && val.systolicBloodPressure !== '') {
                    this.healthParametersForm.get('diastolicBloodPressure').setErrors({sizeError: true});
                    this.healthParametersForm.get('systolicBloodPressure').setErrors({sizeError: true});
                }
            } else {
                if (val.diastolicBloodPressure !== null && val.diastolicBloodPressure !== '') {
                    if(!this.healthParametersForm.get('diastolicBloodPressure').hasError("pattern")) {
                        this.healthParametersForm.get('diastolicBloodPressure').setErrors(null);
                    }
                }
                if (val.systolicBloodPressure !== null && val.systolicBloodPressure !== '') {
                    if(!this.healthParametersForm.get('systolicBloodPressure').hasError("pattern")) {
                        this.healthParametersForm.get('systolicBloodPressure').setErrors(null);
                    }
                }
            }
        });
        this.generalDataForm.setValue({
            date: this.datePipe.transform(this.data.followUp.date, 'yyyy-MM-dd'),
            time: this.datePipe.transform(this.data.followUp.date, 'HH:mm'),
            lro: this.data.followUp.lro,
            estimatedLro: this.data.followUp.estimatedLro,
            editPlan: this.data.followUp.editPlan,
            note: this.data.followUp.note,
        });
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    packFollowUp() {
        let followUpId = this.data.followUp.id;
        let estimatedLro = this.data.followUp.estimatedLro;
        let healthId = this.data.followUp.healthParamters.id;
        this.data.followUp = this.generalDataForm.value;
        this.data.followUp.healthParamters = this.healthParametersForm.value;
        this.data.followUp.id = followUpId;
        this.data.followUp.estimatedLro = estimatedLro;
        this.data.followUp.healthParamters.id = healthId;
        this.data.followUp.date = this.selectedDate;
        this.data.followUp.date = moment(this.data.followUp.date).toDate();
    }

    editFollowUp() {
        if (this.generalDataForm.get('date').hasError('dateError')) {
            this.showToast('Attenzione! La data deve essere precedente o uguale alla data odierna', StringUtils.ADVISE_TIME);
            return;
        }
        if (this.healthParametersForm.get('diastolicBloodPressure').hasError('required') || this.healthParametersForm.get('systolicBloodPressure').hasError('required') ||
            this.healthParametersForm.get('cardioFrequency').hasError('required') || this.healthParametersForm.get('cardioRhythm').hasError('required') ||
            this.healthParametersForm.get('breathFrequency').hasError('required') || this.healthParametersForm.get('arterialSaturation').hasError('required') ||
            this.generalDataForm.get('date').hasError('required') || this.generalDataForm.get('lro').hasError('required') || this.generalDataForm.get('time').hasError('required') || this.generalDataForm.get('editPlan').hasError('required')) {
            this.healthParametersForm.markAllAsTouched();
            this.generalDataForm.markAllAsTouched();
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.healthParametersForm.valid || !this.generalDataForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        this.packFollowUp();
        if (this.data.editMode) {
            const body = {
                followUp: this.data.followUp
            };
            this.priService.editFollowUp(body).subscribe((response) => {
                if (response.success) {
                    this.showToast('Follow-up modificato con successo', StringUtils.ADVISE_TIME);
                    this.dialogRef.close(true);

                } else {
                    this.showToast('Attenzione! Follow-up già presente nella data selezionata.', StringUtils.ADVISE_TIME);

                }
            }, error => {
                this.showToast('Attenzione! Servizio momentaneamente non raggiungibile', StringUtils.ADVISE_TIME);
            });
        } else {
            this.showToast('Attenzione! Aprire il follow-up in modalità modifica', StringUtils.ADVISE_TIME);
        }
    }

    dateChange() {
        let time = "00:00";
        let date = this.generalDataForm.get('date').value;
        if (this.generalDataForm.get('time').value !== "")
            time = this.generalDataForm.get('time').value;
        this.selectedDate = new Date(date + "T" + time);
        if (date != undefined && this.selectedDate.getTime() != 0) {

            if(new Date(date).getTime() > new Date().getTime()){
                if (!this.generalDataForm.get('date').hasError('dateError')) {
                    this.generalDataForm.get('date').setErrors({ dateError: true });
                }
            } else {
                this.generalDataForm.get('date').setErrors(null);
            }

            if (this.generalDataForm.get('time').value !== ""){
                if (this.selectedDate.getTime() > new Date().getTime()) {
                    if (!this.generalDataForm.get('date').hasError('dateError')) {
                        this.generalDataForm.get('date').setErrors({ dateError: true });
                        this.generalDataForm.get('time').setErrors({ invalid: true });
                        this.generalDataForm.get('time').markAsTouched();
                    }
                } else {
                    this.generalDataForm.get('date').setErrors(null);
                    this.generalDataForm.get('time').setErrors(null);
                }
            }
        }
    }
}
