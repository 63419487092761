import {Roles} from './roles';

export class Permissions {
    public static home = [Roles.admin, Roles.physiotherapist, Roles.physiatrist, Roles.psychologist, Roles.assisted];
    public static login = [Roles.admin, Roles.physiotherapist, Roles.physiatrist, Roles.psychologist, Roles.assisted];
    public static resetPassword = [Roles.admin, Roles.physiotherapist, Roles.physiatrist, Roles.psychologist, Roles.assisted];
    public static changePassword = [Roles.admin, Roles.physiotherapist, Roles.physiatrist, Roles.psychologist, Roles.assisted];
    public static usersManager = [Roles.admin];
    public static newUser = [Roles.admin];
    public static listUsers = [Roles.admin];
    public static userDetail = [Roles.admin];
    public static editUser = [Roles.admin];
    public static deleteUser = [Roles.admin];
    public static exercisesManager = [Roles.admin, Roles.physiotherapist];
    public static newExercise = [Roles.admin];
    public static listExercises = [Roles.admin, Roles.physiotherapist];
    public static exerciseDetail = [Roles.admin, Roles.physiotherapist];
    public static editExercise = [Roles.admin];
    public static deleteExercise = [Roles.admin];
    public static addIdealMovement = [Roles.physiotherapist];
    public static assistedUsersManager = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
    public static listAssistedUsers = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
    public static assistedUserInfo = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
    public static PRIInfo = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
    public static PRIHistory = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
    public static followUpManager = [Roles.physiatrist];
    public static addReportCoping = [Roles.psychologist];
    public static endPRI = [Roles.physiatrist];
    public static addPRI = [Roles.physiatrist];
    public static addPRM = [Roles.physiotherapist];
    public static exerciseExecutionRating = [Roles.physiotherapist];
    public static LRODashboard = [Roles.physiotherapist, Roles.physiatrist, Roles.psychologist];
}