import {Component, OnDestroy, OnInit} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ToastManager} from '../../utils/toast-manager';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX,
    AT_LEAST_ONE_CHARACTER_REGEX,
    BASE_REGEX
} from '../../../common/commons.class';
import {DatePipe} from '@angular/common';
import {ReportCoping} from '../../../class/interfaces/report-coping';
import {PriService} from '../../../services/pri/pri.service';
import {StringUtils} from '../../../common/string-utils';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;

@Component({
    selector: 'report-coping',
    templateUrl: './report-coping-page.component.html',
    styleUrls: ['./report-coping-page.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, DatePipe
    ],
})
export class ReportCopingPageComponent extends ToastManager implements OnInit, OnDestroy {

    userNominative: string;
    private userUUC: string;
    loggedUser: any;
    sub: any;
    userDataForm: FormGroup;
    reportCopingForm: FormGroup;
    minimumDate: Date;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private priService: PriService
    ) {
        super(snackBar);
        this.userDataForm = this._formBuilder.group({
            surname: {value: '', disabled: true},
            name: {value: '', disabled: true},
            fiscalCode: {value: '', disabled: true}
        });
        this.reportCopingForm = this._formBuilder.group({
            date: ['', [Validators.required]],
            evaluation: ['', [Validators.required, Validators.maxLength(1000), Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]]
        });
    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userNominative = params['surname'] + ' ' + params['name'];
            this.userDataForm.setValue({
                surname: params['surname'],
                name: params['name'],
                fiscalCode: params['fiscalCode']
            });
            this.minimumDate = new Date(params['priDate']);
            this.userUUC = params['userUUC'];
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    resetFields() {
        this.reportCopingForm.reset();
    }

    packReportCoping() {
        let reportCoping: ReportCoping;
        reportCoping = {
            date: this.reportCopingForm.get('date').value,
            valutation: this.reportCopingForm.get('evaluation').value
        };
        return reportCoping;
    }

    saveReport() {
        this.reportCopingForm.markAllAsTouched();
        if (this.reportCopingForm.get('date').hasError('required') || this.reportCopingForm.get('evaluation').hasError('required')) {
            this.showToast(StringUtils.EMPTY_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        if (!this.reportCopingForm.valid) {
            this.showToast(StringUtils.WRONG_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
            return;
        }
        const body = {
            reportCoping: this.packReportCoping(),
            assistedId: this.userUUC
        };
        this.priService.insertReportCoping(body).subscribe((response: any) => {
            if (response.success) {
                this.showToast('Report coping inserito con successo', StringUtils.ADVISE_TIME);
                this.router.navigate(['/list-assisted-users']);
            } else {
                this.showToast('Attenzione! Report già presente nella data selezionata.', StringUtils.ADVISE_TIME);
            }
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    public goBack() {
        this.router.navigate(['/list-assisted-users']);
    }

    fixDate() {
        let selectedDate: Date = new Date(this.reportCopingForm.get("date").value);
        if(selectedDate.getTime() < this.minimumDate.getTime()) {
            this.reportCopingForm.get("date").setValue(this.datePipe.transform(this.minimumDate, "yyyy-MM-dd"));
        }
        if(selectedDate.getTime() > new Date().getTime()) {
            this.reportCopingForm.get("date").setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
        }
    }
}

