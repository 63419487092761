<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione esercizi
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Lista esercizi
        </mat-label>
    </div>
    <div class="filterDiv" fxLayout='column'>
        <div class="h2 filterTitle">
            Filtri di ricerca</div>
        <div class="resizable" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>Nome</mat-label>
                <input [formControl]="nameFilter" matInput>
                <mat-error>Campo non popolato correttamente</mat-error>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                <mat-label>Categoria</mat-label>
                <mat-select [formControl]="categoryFilter">
                    <mat-option *ngFor="let category of categories" [value]="category">
                        {{category}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">category</mat-icon>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center">
            <div class='buttonDiv'>
                <button (click)='filterButton()' class='submitButton' mat-raised-button color="accent"
                    aria-label="RESET MY PASSWORD" [disabled]="!getFormValidation()">
                    Filtra
                </button>
                <button (click)='resetFilters()' class='submitButton' mat-raised-button color="accent"
                    aria-label="RESET MY PASSWORD">
                    Annulla
                </button>
            </div>
        </div>
    </div>
    <div class="filterDiv">
        <div class="h2 filterTitle">Lista esercizi</div>
        <div class='mat-elevation-z1 tableResponsive'>
            <table *ngIf="dataSource" mat-table [dataSource]="dataSource" matSort matSortDisableClear="true"
                class="mat-elevation-z1 tableStyle">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortClick()"> Nome </th>
                    <td mat-cell *matCellDef="let exercise" class="column-name" isEllipsisActive> {{exercise.name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th class='category-column' mat-header-cell *matHeaderCellDef class="column-category"> Categoria
                    </th>
                    <td mat-cell *matCellDef="let exercise" class="column-category-content"> {{exercise.category}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni </th>
                    <td mat-cell *matCellDef="let exercise" class="column-action-content">
                        <div>
                            <mat-icon class="clickable-icon"
                                matTooltip="Visualizza dettaglio esercizio {{exercise.name}} "
                                (click)="openDialog(exercise,0)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                matTooltipClass="custom-tooltip">info</mat-icon>

                            <mat-icon *ngIf="canEdit && !exercise.inUse" class='clickable-icon'
                                matTooltip="Modifica esercizio {{exercise.name}}  " (click)="openDialog(exercise,1)"
                                matTooltipPosition="above" [matTooltipShowDelay]="500" matTooltipClass="custom-tooltip">
                                create</mat-icon>
                            <mat-icon *ngIf="canEdit && !exercise.inUse" class="clickable-icon"
                                matTooltip="Elimina esercizio {{exercise.name}}  " (click)="openDialog(exercise,2)"
                                matTooltipPosition="above" [matTooltipShowDelay]="500" matTooltipClass="custom-tooltip">
                                delete</mat-icon>

                            <mat-icon *ngIf="canEdit && exercise.inUse" class='disabled-icon'>create</mat-icon>
                            <mat-icon *ngIf="canEdit && exercise.inUse" class="disabled-icon">delete</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource !== undefined && dataSource.data.length !== 0 && dataSource.filteredData.length === 0"
                class="no-record mat-elevation-z1"><label>Nessun esercizio trovato corrispondente ai criteri di ricerca
                    inseriti</label></div>
            <div *ngIf="dataSource !== undefined && dataSource.data.length === 0" class="no-record mat-elevation-z1"><label>Nessun esercizio
                    presente.</label></div>
            <div>
                <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons></mat-paginator>
            </div>

        </div>
    </div>
</div>