import {BasicUserInfo} from './interfaces/basic-user-info';
import {FunctionalValutationContainer} from './interfaces/functional-valutation-container';
import {HealthState} from './interfaces/health-state';
import {Diagnosis} from './interfaces/diagnosis';
import {Procedure} from './interfaces/procedure';
import {FunctionalValutation} from './interfaces/functional-valutation';

export class PRI {

    id?: number;
    assistedId: string;
    name: string;
    assignDate: Date;
    workingDays: number;
    anamnesis: string;
    synthesis: string;
    goals: string;
    diagnosis: Diagnosis[];
    procedures: Procedure[];
    physiatristId: string;
    physiatristNominative: string;
    psychologistId: string;
    physiotherapistIds: string[];
    functionalValutations: FunctionalValutation[];
    healthParamters: HealthState;


}
