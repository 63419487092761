import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import {interval, Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {RoleOperationCheck} from './main/authentication/util/role-operation-check';
import {Operations} from './main/authentication/util/operations';
import {Permissions} from './main/authentication/util/permissions';
import {environment} from '../environments/environment';
import {TOAST_HORIZONTAL_POSITION, TOAST_VERTICAL_POSITION, ToastManager} from './main/management/utils/toast-manager';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    intervalSubscription: Subscription;
    private static _storageManipulated: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private router: Router,
        private _snackBar: MatSnackBar,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private primengConfig: PrimeNGConfig
    )
    {
        this.primengConfig.ripple = true;

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        let user = JSON.parse(sessionStorage.getItem('User'));
        this._fuseNavigationService.updateNavigationItem('users', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.usersManager)))
        });
        this._fuseNavigationService.updateNavigationItem('userAdd', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.newUser)))
        });
        this._fuseNavigationService.updateNavigationItem('userList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listUsers)))
        });
        this._fuseNavigationService.updateNavigationItem('exercises', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.exercisesManager)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseAdd', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.newExercise)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listExercises)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseExecutionValutation', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.exerciseExecutionRating)))
        });
        this._fuseNavigationService.updateNavigationItem('assisted', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.assistedUsersManager)))
        });
        this._fuseNavigationService.updateNavigationItem('assistedList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listAssistedUsers)))
        });
        this._fuseNavigationService.updateNavigationItem('newPRI', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.addPRI)))
        });
        this._fuseNavigationService.updateNavigationItem('newPRM', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.addPRM)))
        });
        this.matIconRegistry.addSvgIcon(
            "priHistory",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/addedAsset/archive.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "userInfo",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/addedAsset/id.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "priIcon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/addedAsset/prm.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "followUpIcon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/addedAsset/follow-up-icon.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "reportCopingIcon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/addedAsset/report-coping-icon.svg")
        );
        window.addEventListener("storage", function () {
            AppComponent.storageManipulated = true;
            sessionStorage.clear();
        }, false);
        const checkSessionExpiration = interval(environment.tokenExpirationCheckPeriod);
        this.intervalSubscription = checkSessionExpiration.subscribe(val => {
            if(AppComponent.storageManipulated) {
                this._snackBar.open("Attenzione! La manipolazione dei dati di accesso non è consentita.", null, {
                    panelClass: ['snack-bar'],
                    duration: 8000,
                    horizontalPosition: TOAST_HORIZONTAL_POSITION,
                    verticalPosition: TOAST_VERTICAL_POSITION,
                });
                this.router.navigate(["/login-page"]);
                sessionStorage.clear();
                AppComponent.storageManipulated = false;
                return;
            }
            user = JSON.parse(sessionStorage.getItem('User'));
            let loginTimestamp: number = Number(sessionStorage.getItem("loginTimestamp"));
            if(user !== null && user !== undefined && loginTimestamp != undefined && loginTimestamp !== 0) {
                const timestamp = new Date().getTime();
                if(timestamp - loginTimestamp > environment.tokenExpirationPeriod) {
                    this._snackBar.open("Sessione scaduta, eseguire nuovamente l'accesso.", null, {
                        panelClass: ['snack-bar'],
                        duration: 5000,
                        horizontalPosition: TOAST_HORIZONTAL_POSITION,
                        verticalPosition: TOAST_VERTICAL_POSITION,
                    });
                    this.router.navigate(["/login-page"]);
                    sessionStorage.clear();
                }
            }
        })
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: false
                },
                toolbar: {
                    hidden: false
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.intervalSubscription.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    static get storageManipulated(): boolean {
        return this._storageManipulated;
    }

    static set storageManipulated(value: boolean) {
        this._storageManipulated = value;
    }


}
