import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {GetExerciseByCategoryResponse} from '../../models/get-exercise-by-category-response';
import {map} from 'rxjs/operators';
import {Endpoints} from '../../class/endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient,
                ) { }

    public login(body: any): Observable<HttpResponse<any>>{
        return this.http.post<HttpResponse<any>>(
            Endpoints.DUEVITAB_GET_AUTH_API,
            body,
            {
                observe: 'response'
            }
        ).pipe(map(login => {
            return login;
        }));
    }

    public editPassword(body: any): Observable<any>{
        return this.http.post<any>(
            Endpoints.DUEVITAB_MODIFY_PASSWORD_API,
            body
        ).pipe(map(editPassword => {
            return editPassword;
        }));
    }

    public requestResetEmail(body: any): Observable<any> {
        return this.http.put<any>(
            Endpoints.DUEVITAB_RECOVER_PASSWORD_ENDPOINT,
            body
        ).pipe(map(requestResetEmail => {
            return requestResetEmail;
        }));
    }

    public resetPassword(body: any): Observable<any> {
        return this.http.post<any>(
            Endpoints.DUEVITAB_RECOVER_PASSWORD_ENDPOINT,
            body
        ).pipe(map(resetPassword => {
            return resetPassword;
        }));
    }
}
