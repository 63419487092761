import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-phone-dialog',
  templateUrl: './remove-phone-dialog.component.html',
  styleUrls: ['./remove-phone-dialog.component.scss']
})
export class RemovePhoneDialogComponent implements OnInit {

  constructor( 
    public dialogRef: MatDialogRef<RemovePhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, 
  ) {
   }

  ngOnInit(): void {
  }
  closeDialog(): void{
    this.dialogRef.close();
  }
}
