    <mat-dialog-content class="main-div" fxLayout="column">
        <div class="header mt-0" fxLayout="row" fxLayoutAlign="center">
            <mat-label *ngIf="data.editMode" class='header_label'>Modifica follow-up del {{this.datePipe.transform(this.data.followUp.date,"dd/MM/yyyy")}}
            </mat-label>
            <mat-label *ngIf="!data.editMode" class='header_label'>Follow-up del {{this.datePipe.transform(this.data.followUp.date,"dd/MM/yyyy")}}
            </mat-label>
        </div>
        <div class="border-container">
            <div class="h2 div-section-title">
                Dati generali
            </div>
            <div fxFill fxLayout="column" class="general-data-container">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="generalDataForm">
                    <mat-form-field appearance="outline" fxFlex="20">
                        <mat-label>Data</mat-label>
                        <input matInput formControlName="date" required (change)="dateChange()" type="date" onkeydown="return false" [max]="'9999-12-31T23:59'">
                        <mat-error *ngIf="this.generalDataForm.get('date').hasError('required')">Data obbligatoria</mat-error>
                        <mat-error *ngIf="!this.generalDataForm.get('date').hasError('required')">Data non valida</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="12" required>
                        <mat-label>Ora</mat-label>
                        <input matInput type="time" formControlName="time" (change)="dateChange()" required>
                        <mat-error *ngIf="this.generalDataForm.get('time').hasError('invalid')">Ora non valida
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="18">
                        <mat-label>LRO</mat-label>
                        <input matInput formControlName="lro" maxlength="3" required>
                        <mat-error *ngIf="!this.generalDataForm.get('lro').hasError('required')">LRO non valido</mat-error>
                        <mat-error *ngIf="this.generalDataForm.get('lro').hasError('required')">LRO obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline"fxFlex="18">
                        <mat-label>LRO stimato</mat-label>
                        <input matInput formControlName="estimatedLro">
                        <mat-error *ngIf="this.generalDataForm.get('estimatedLro').hasError('required')">Valore obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline"fxFlex="18">
                        <mat-label>Modifica piano</mat-label>
                        <mat-select formControlName="editPlan" required>
                            <mat-option [value]="false">
                                No
                            </mat-option>
                            <mat-option [value]="true">
                                Si
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!this.generalDataForm.get('editPlan').hasError('required')">Modifica piano non valido</mat-error>
                        <mat-error *ngIf="this.generalDataForm.get('editPlan').hasError('required')">Modifica piano obbligatorio</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50" [formGroup]="generalDataForm">
                    <mat-form-field appearance="outline" fxFlex="100" class="description">
                        <mat-label>Note</mat-label>
                        <textarea formControlName="note" matInput></textarea>
                        <mat-error *ngIf="!this.generalDataForm.get('note').hasError('required')">Note non valide</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="border-container">
            <div class="h2 div-section-title">
                Stato di salute
            </div>
            <div class='health-parameters-container' [formGroup]="healthParametersForm">
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Pressione arteriosa diastolica (mmHg)</mat-label>
                    <input matInput formControlName="diastolicBloodPressure" maxlength="3" required>
                    <mat-error *ngIf="!this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">Pressione arteriosa diastolica non valida</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('diastolicBloodPressure').hasError('required')">Pressione arteriosa diastolica obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Pressione arteriosa sistolica (mmHg)</mat-label>
                    <input matInput formControlName="systolicBloodPressure" maxlength="3" required>
                    <mat-error *ngIf="!this.healthParametersForm.get('systolicBloodPressure').hasError('required')">Pressione arteriosa sistolica non valida</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('systolicBloodPressure').hasError('required')">Pressione arteriosa sistolica obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Frequenza cardiaca (bpm)</mat-label>
                    <input matInput formControlName="cardioFrequency" maxlength="3" required>
                    <mat-error *ngIf="!this.healthParametersForm.get('cardioFrequency').hasError('required')">Frequenza cardiaca non valida</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('cardioFrequency').hasError('required')">Frequenza cardiaca obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Ritmo cardiaco</mat-label>
                    <mat-select formControlName="cardioRhythm" required>
                        <mat-option *ngFor="let rythm of availableRythms" [value]="rythm">
                            {{rythm}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo cardiaco non valido</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('cardioRhythm').hasError('required')">Ritmo cardiaco obbligatorio</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Frequenza respiratoria</mat-label>
                    <input matInput formControlName="breathFrequency" maxlength="3" required>
                    <mat-error *ngIf="!this.healthParametersForm.get('breathFrequency').hasError('required')">Frequenza respiratoria non valida</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('breathFrequency').hasError('required')">Frequenza respiratoria obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="grid-element">
                    <mat-label>Saturazione arteriosa (%)</mat-label>
                    <input matInput formControlName="arterialSaturation" maxlength="3" required>
                    <mat-error *ngIf="!this.healthParametersForm.get('arterialSaturation').hasError('required')">Saturazione arteriosa non valida</mat-error>
                    <mat-error *ngIf="this.healthParametersForm.get('arterialSaturation').hasError('required')">Saturazione arteriosa obbligatoria</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="panel-buttons-container" fxLayout="row" *ngIf="data.editMode">
            <button mat-raised-button color="accent" class="save-button"
                    aria-label="SALVA" (click)="editFollowUp()">
                SALVA
            </button>
            <button mat-raised-button color="accent" class="cancel-button"
                    aria-label="ANNULLA" (click)="closeDialog()">
                ANNULLA
            </button>
        </div>
        <div class="panel-buttons-container" fxLayout="row" *ngIf="!data.editMode">
            <button mat-raised-button color="accent" class="back-button"
                    aria-label="ESCI" (click)="closeDialog()">
                ESCI
            </button>
        </div>
    </mat-dialog-content>