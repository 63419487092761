import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ToastManager} from '../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IdealMovement} from '../../../class/interfaces/ideal-movement';
import {ExecutionMovement} from '../../../class/interfaces/execution-movement';
import {RelevantBoneTrack} from '../../../class/interfaces/relevant-bone-track';
import {Bone} from '../../../class/interfaces/bone';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import * as THREE from 'three';
import {Quaternion} from 'three';
import {PrmExercise} from '../../../class/interfaces/prm-exercise';
import {ExerciseService} from '../../../services/exercise/exercise.service';
import {DatePipe} from '@angular/common';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {NgxSpinnerService} from 'ngx-spinner';
import {StringUtils} from '../../../common/string-utils';
import { BoneTracksPacks } from 'app/main/models/get-elab-movements-response';
import { List } from 'lodash';

export const PI = 3.14;

@Component({
    selector: 'execution-dialog',
    templateUrl: './execution-details-dialog.component.html',
    styleUrls: ['./execution-details-dialog.component.scss'],
    providers: [
        DatePipe
    ]
})
export class ExecutionDetailsDialogComponent extends ToastManager implements OnInit {
    public elabMovementsMod = true;

    relevantBonesMovementsTrack = {};
    movementsTrack: List<BoneTracksPacks>;
    relevantBonesTrack = []

    exerciseExecution: PrmExercise;
    idealMovement: IdealMovement;
    executionMovement: ExecutionMovement;
    relevantExecutionBones: RelevantBoneTrack[];
    relevantIdealBones: RelevantBoneTrack[];
    executionTimes: number;
    bonesReady: boolean;

    public bonesMovementChartData: ChartDataSets[][];
    public bonesMovementsLabels: Label[];
    public bonesMovementsOptions: ChartOptions[];
    public lineChartColors: Color[];
    public lineChartLegend;
    public lineChartType;

    public relevantBoneIndexesForLegend = []
    public relevantBoneNames = []

    public isHandExercise;
    public isLeftHand;

    public boneNames = [
        "Testa",
        "Collo",
        "Torace",
        "Addome",
        "Bacino",
        "Spalla destra",
        "Braccio destro",
        "Avambraccio destro",
        "Mano destra",
        "Spalla sinistra",
        "Braccio sinistro",
        "Avambraccio sinistro",
        "Mano sinistra",
        "Coscia destra",
        "Gamba destra",
        "Piede destro",
        "Coscia sinistra",
        "Gamba sinistra",
        "Piede sinistro",
    ]

    public handBoneNames = [
        "Palmo",
        "Pollice metacarpale",
        "Pollice prossimale",
        "Pollice distale",
        "Indice metacarpale",
        "Indice prossimale",
        "Indice intermedio",
        "Indice distale",
        "Medio metacarpale",
        "Medio prossimale",
        "Medio intermedio",
        "Medio distale",
        "Anulare metacarpale",
        "Anulare prossimale",
        "Anulare intermedio",
        "Anulare distale",
        "Mignolo metacarpale",
        "Mignolo prossimale",
        "Mignolo intermedio",
        "Mignolo distale",
    ]

    private bonesQuatRotationOrder = [
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "ZXY",
        "XYZ",
        "XYZ",
        "XYZ",
        "ZXY",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
    ];

    private handBonesQuatRotationOrder = [
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ",
        "XYZ"
    ];

    constructor(
        public dialogRef: MatDialogRef<ExecutionDetailsDialogComponent>,
        private snackBar: MatSnackBar,
        private exerciseService: ExerciseService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data,
        public datePipe: DatePipe
    ) {
        super(snackBar);
        this.spinner.show();
        this.exerciseExecution = data.exercise;
        this.executionTimes = data.exercise.scheduledWorkingTime;

        this.bonesMovementsOptions = [];
        this.bonesMovementChartData = [];
        this.isHandExercise = data.isHandExercise;
        this.isLeftHand = data.isLeftHand;

        if (this.elabMovementsMod) {
            this.exerciseService.getElaboratedMovements(this.exerciseExecution.elaboratedMovementsURL).subscribe((response) => {
                this.movementsTrack = response['elaboratedExecution'].movementsTracks;
                    for (let boneIndex in this.movementsTrack){
                        if(this.movementsTrack[boneIndex].relevant)
                            this.relevantBonesMovementsTrack[Number(boneIndex)] = this.movementsTrack[boneIndex];
                    }
                    for (let boneIndex in this.relevantBonesMovementsTrack) {
                        this.elabMovementBoneChart(boneIndex);
                    }
                    this.bonesReady = true;  
            }, error => {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }, () => {
                this.spinner.hide();
            });
        } else {
            this.exerciseService.getExecutionMovement(this.exerciseExecution.movementsURL).subscribe((response) => {
                this.idealMovement = response.idealMovement;
                this.executionMovement = response.executionMovement;
                this.relevantIdealBones = [];
                this.relevantExecutionBones = [];
                this.bonesReady = false;
                this.initRelevantBones();
            }, error => {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }, () => {
                this.spinner.hide();
            })
        }
    }

    unsorted(){ return 0; }

    getLegendCss(){
        if (!this.isHandExercise || this.isLeftHand){
            return 'avatar-legend';
        } else {
            return 'avatar-legend-right';
        }
    }

    getLegendImageElabMov(boneIndex: number) {
        if (this.isHandExercise) {
            return 'assets/addedAsset/handLegend/' + boneIndex + '.png';
        }
        else {
            return 'assets/addedAsset/avatarLegend/' + boneIndex + '.png';
        }
    }

    getLegendImage(boneIndex: number) {
        if (this.isHandExercise) {
            return 'assets/addedAsset/handLegend/' + this.relevantBoneIndexesForLegend[boneIndex] + '.png';
        }
        else {
            return 'assets/addedAsset/avatarLegend/' + this.relevantBoneIndexesForLegend[boneIndex] + '.png';
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private elabMovementBoneChart(boneIndex) {
        let execRoll: number[] = [];
        let execPitch: number[] = [];
        let execYaw: number[] = [];

        let idealRoll: number[] = [];
        let idealPitch: number[] = [];
        let idealYaw: number[] = [];

        this.bonesMovementsLabels = [];
        let maxVal = 40;
        let minVal = -40;

        let trackLength = this.movementsTrack[boneIndex].xExecutionTrack.length;

        for (let frameIndex = 0; frameIndex < trackLength; frameIndex++) {
            this.bonesMovementsLabels.unshift("");
            let execQuat: Quaternion = new THREE.Quaternion(
                this.movementsTrack[boneIndex].xExecutionTrack[frameIndex],
                this.movementsTrack[boneIndex].yExecutionTrack[frameIndex],
                this.movementsTrack[boneIndex].zExecutionTrack[frameIndex],
                this.movementsTrack[boneIndex].wExecutionTrack[frameIndex]
            );

            let execEulerRotation = new THREE.Euler().setFromQuaternion(
                execQuat,
                this.isHandExercise ? this.handBonesQuatRotationOrder[boneIndex] : this.bonesQuatRotationOrder[boneIndex]
            );            execRoll.push((execEulerRotation.x * 180) / PI);
            execPitch.push((execEulerRotation.y * 180) / PI);
            execYaw.push((execEulerRotation.z * 180) / PI);

            let idealQuat: Quaternion = new THREE.Quaternion(
                this.movementsTrack[boneIndex].xIdealTrack[frameIndex],
                this.movementsTrack[boneIndex].yIdealTrack[frameIndex],
                this.movementsTrack[boneIndex].zIdealTrack[frameIndex],
                this.movementsTrack[boneIndex].wIdealTrack[frameIndex]
            );

            let idealEulerRotation = new THREE.Euler().setFromQuaternion(
                idealQuat,
                this.isHandExercise ? this.handBonesQuatRotationOrder[boneIndex] : this.bonesQuatRotationOrder[boneIndex]

            );
            idealRoll.push((idealEulerRotation.x * 180) / PI);
            idealPitch.push((idealEulerRotation.y * 180) / PI);
            idealYaw.push((idealEulerRotation.z * 180) / PI);

            maxVal = Math.max(maxVal, Math.max((execEulerRotation.x * 180) / PI, (execEulerRotation.y * 180) / PI, (execEulerRotation.z * 180) / PI))
            minVal = Math.min(minVal, Math.min((execEulerRotation.x * 180) / PI, (execEulerRotation.y * 180) / PI, (execEulerRotation.z * 180) / PI));
            maxVal = Math.max(maxVal, Math.max((idealEulerRotation.x * 180) / PI, (idealEulerRotation.y * 180) / PI, (idealEulerRotation.z * 180) / PI))
            minVal = Math.min(minVal, Math.min((idealEulerRotation.x * 180) / PI, (idealEulerRotation.y * 180) / PI, (idealEulerRotation.z * 180) / PI));
        }

        let executionMovementRoll: ChartDataSets = { data: execRoll.concat(), label: 'M.Esecuzione: oscillazione verticale' };
        let executionMovementPitch: ChartDataSets = { data: execPitch.concat(), label: 'M.Esecuzione: oscillazione orizzontale' };
        let executionMovementYaw: ChartDataSets = { data: execYaw.concat(), label: 'M.Esecuzione: torsione' };

        let idealMovementRoll: ChartDataSets = { data: idealRoll.concat(), label: 'M.Ideale: oscillazione verticale', borderDash: [10, 10] };
        let idealMovementPitch: ChartDataSets = { data: idealPitch.concat(), label: 'M.Ideale: oscillazione orizzontale', borderDash: [10, 10] };
        let idealMovementYaw: ChartDataSets = { data: idealYaw.concat(), label: 'M.Ideale: torsione', borderDash: [10, 10] };

        let movementsDataset: ChartDataSets[] = [];

        movementsDataset.push(executionMovementRoll, executionMovementPitch, executionMovementYaw, idealMovementRoll, idealMovementPitch, idealMovementYaw);
        this.bonesMovementChartData.push(movementsDataset);

        maxVal += 5;
        minVal -= 5;
        this.initChart(maxVal, minVal);
    }

    initRelevantBones() {
        let relevantIndex: number = 0;
        for(let index = 0; index < this.idealMovement.relevantBone.length; index++) {
            if(this.idealMovement.relevantBone[index]) {
                let relevantBone: Bone[] = [];
                for(let j = 0; j < this.idealMovement.movement.motionTrack.length; j ++) {
                    relevantBone.push(this.idealMovement.movement.motionTrack[j].boneList[index]);
                }
                this.relevantIdealBones[relevantIndex] = {relevantBone: relevantBone};
                relevantBone = [];
                for(let j = 0; j < this.executionMovement.movement.motionTrack.length; j ++) {
                    relevantBone.push(this.executionMovement.movement.motionTrack[j].boneList[index]);
                }
                this.relevantExecutionBones[relevantIndex] = {relevantBone: relevantBone};
                this.prepareChart(relevantIndex, index);
                this.relevantBoneIndexesForLegend.push(index);
                this.relevantBoneNames.push(this.isHandExercise ? this.handBoneNames[index] : this.boneNames[index]);
                relevantIndex ++;
            }
        }
        this.bonesReady = true;
    }

    private prepareChart(index: number, originalIndex: number) {
        let roll: number[] = [];
        let pitch: number[] = [];
        let yaw: number[] = [];
        let boneMultiplier: Bone[] = this.relevantIdealBones[index].relevantBone;
        this.bonesMovementsLabels = [];
        let maxVal = 40;
        let minVal = -40;
        for(let i = 0; i < this.executionTimes; i++) {
            this.relevantIdealBones[index].relevantBone = this.relevantIdealBones[index].relevantBone.concat(boneMultiplier);
        }

        for(let bone of this.relevantExecutionBones[index].relevantBone) {
            this.bonesMovementsLabels.unshift("");
            let quat: Quaternion = new THREE.Quaternion(bone.rotation.x,
                bone.rotation.y,
                bone.rotation.z,
                bone.rotation.w);
            let eulerRotation = new THREE.Euler().setFromQuaternion(
                quat,
                this.isHandExercise ? this.handBonesQuatRotationOrder[originalIndex] : this.bonesQuatRotationOrder[originalIndex]
            );
            roll.push((eulerRotation.x * 180) / PI);
            pitch.push((eulerRotation.y * 180) / PI);
            yaw.push((eulerRotation.z * 180) / PI);
            maxVal = Math.max(maxVal, Math.max((eulerRotation.x * 180) / PI, (eulerRotation.y * 180) / PI, (eulerRotation.z * 180) / PI, maxVal))
            minVal = Math.min(minVal, Math.min((eulerRotation.x * 180) / PI, (eulerRotation.y * 180) / PI, (eulerRotation.z * 180) / PI, minVal));
        }
        let executionMovementRoll: ChartDataSets = {data: roll.concat(), label: 'M.Esecuzione: oscillazione verticale'};
        let executionMovementPitch: ChartDataSets = {data: pitch.concat(), label: 'M.Esecuzione: oscillazione orizzontale'};
        let executionMovementYaw: ChartDataSets = {data: yaw.concat(), label: 'M.Esecuzione: torsione'};

        roll = [];
        pitch = [];
        yaw = [];


        for(let bone of this.relevantIdealBones[index].relevantBone) {
            let quat: Quaternion = new THREE.Quaternion(bone.rotation.x,
                bone.rotation.y,
                bone.rotation.z,
                bone.rotation.w);
            let eulerRotation = new THREE.Euler().setFromQuaternion(
                quat,
                this.isHandExercise ? this.handBonesQuatRotationOrder[originalIndex] : this.bonesQuatRotationOrder[originalIndex]
            );
            roll.push((eulerRotation.x * 180) / PI);
            pitch.push((eulerRotation.y * 180) / PI);
            yaw.push((eulerRotation.z * 180) / PI);
            maxVal = Math.max(maxVal, Math.max((eulerRotation.x * 180) / PI, (eulerRotation.y * 180) / PI, (eulerRotation.z * 180) / PI, maxVal))
            minVal = Math.min(minVal, Math.min((eulerRotation.x * 180) / PI, (eulerRotation.y * 180) / PI, (eulerRotation.z * 180) / PI, minVal));
        }
        let idealMovementRoll: ChartDataSets = {data: roll.concat(), label: 'M.Ideale: oscillazione verticale', borderDash: [10, 10]};
        let idealMovementPitch: ChartDataSets = {data: pitch.concat(), label: 'M.Ideale: oscillazione orizzontale', borderDash: [10, 10]};
        let idealMovementYaw: ChartDataSets = {data: yaw.concat(), label: 'M.Ideale: torsione', borderDash: [10, 10]};
        let movementsDataset: ChartDataSets[] = [];
        maxVal += 5;
        minVal -= 5;

        movementsDataset.push(executionMovementRoll, executionMovementPitch, executionMovementYaw,
            idealMovementRoll, idealMovementPitch, idealMovementYaw);
        this.bonesMovementChartData.push(movementsDataset);
        this.initChart(maxVal, minVal);
    }

    private initChart(maxVal: number, minVal: number): void {
        /**** options intialization ****/
        this.bonesMovementsOptions.push(this.setOptions('Tempo', 'Angoli', maxVal, minVal));

        this.lineChartColors = [
            {
                // blue
                backgroundColor: 'rgba(3,155,229,0.2)',
                borderColor: 'rgba(3,155,229,0.8)',
                pointBackgroundColor: 'rgba(3,155,229,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,155,229,0.8)',
            },
            {
                // green
                backgroundColor: 'rgba(3,229,115,0.2)',
                borderColor: 'rgba(3,229,115,0.8)',
                pointBackgroundColor: 'rgba(3,229,115,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,229,115,0.8)',
            },
            {
                // dark grey
                backgroundColor: 'rgba(77,83,96,0.2)',
                borderColor: 'rgba(77,83,96,0.8)',
                pointBackgroundColor: 'rgba(77,83,96,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(77,83,96,1)',
            },{
                // blue
                backgroundColor: 'rgba(3,155,229,0.2)',
                borderColor: 'rgba(3,155,229,0.8)',
                pointBackgroundColor: 'rgba(3,155,229,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,155,229,0.8)',
            },
            {
                // green
                backgroundColor: 'rgba(3,229,115,0.2)',
                borderColor: 'rgba(3,229,115,0.8)',
                pointBackgroundColor: 'rgba(3,229,115,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,229,115,0.8)',
            },
            {
                // dark grey
                backgroundColor: 'rgba(77,83,96,0.2)',
                borderColor: 'rgba(77,83,96,0.8)',
                pointBackgroundColor: 'rgba(77,83,96,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(77,83,96,1)',
            }
        ];
    }

    private setOptions(xLabel: string, yLabel: string, maxVal: number, minVal: number): ChartOptions {
        this.lineChartType = 'line';
        this.lineChartLegend = true;
        let options: ChartOptions = {
            elements: {line: {tension: 0.1},
                point: {
                    radius: 0
                }},
            responsive: true,
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    },
                    ticks: {
                        callback: function(value, index) {
                            return index;
                        },
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    },
                    id: 'y-axis-1', ticks: {min: minVal, max: maxVal}
                }]
            },
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                    title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                        return '';
                    },
                    label: function(tooltipItems, data) {
                        return data.datasets[tooltipItems.datasetIndex]['label'] + ': ' + +tooltipItems.value;
                    }
                }
            }
        };
        return options;
    }

}
