import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Directive} from "@angular/core";

/*** Toast loaction ***/
export const TOAST_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition = 'center';
export const TOAST_VERTICAL_POSITION: MatSnackBarVerticalPosition = 'bottom';

@Directive()
export class ToastManager {

    constructor(private _snackBar: MatSnackBar){

    }

    /**
     * On init
     */
    ngOnInit(): void {

    }

    public showToast(message: string, duration: number) {
        if (message !== '') {
            this._snackBar.open(message, null, {
                panelClass: ['snack-bar'],
                duration: duration,
                horizontalPosition: TOAST_HORIZONTAL_POSITION,
                verticalPosition: TOAST_VERTICAL_POSITION,
            });
        }
    }
}
