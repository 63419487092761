<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Nuovo PRM
        </mat-label>
    </div>

    <div class="border-container">
        <div class="h2 div-section-title">
            Ricerca assistito
        </div>
        <form [formGroup]="findAssistedForm" class="inner-form" #form="ngForm" (ngSubmit)="findAssisted()">
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>Codice fiscale assistito</mat-label>
                <input matInput formControlName="fiscalCode" oninput="this.value = this.value.toUpperCase()" required>
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
                <mat-error *ngIf="!this.findAssistedForm.get('fiscalCode').hasError('required')">Codice fiscale
                    assistito non valido
                </mat-error>
                <mat-error *ngIf="this.findAssistedForm.get('fiscalCode').hasError('required')">Codice fiscale assistito
                    obbligatorio
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent" class="search-button" type="submit" aria-label="CERCA"
                [disabled]="findPressed || userFound">
                CERCA
            </button>
            <button mat-raised-button color="accent" class="cancel-button" type="reset" aria-label="ANNULLA" (click)="resetForm()">
                ANNULLA
            </button>
        </form>
    </div>
    <div class="border-container" *ngIf="!noUserFound && userFound">
        <div class="h2 div-section-title">
            Anagrafica assistito
        </div>
        <form [formGroup]="userDataForm" class="inner-form" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="38">
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="surname">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="38">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name">
                <mat-icon matSuffix class="secondary-text">perm_identity</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="19" disabled>
                <mat-label>Data di nascita</mat-label>
                <input matInput formControlName="birthDate" type="date" [max]="'9999-12-31'">
            </mat-form-field>
        </form>
    </div>
    <div class="border-container" *ngIf="!noUserFound && userFound">
        <div class="h2 div-section-title">
            Esercizi
        </div>

        <div class="border-container border-container-top-header" fxLayout="column">
            <div class="header-div">
                Assegna esercizio
            </div>
            <form [formGroup]="insertExerciseForm" class="inner-form" fxLayout="row"
                fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" fxFlex="25">
                    <mat-select formControlName="category" required
                        (selectionChange)="updateAvailableExercises($event.value)">
                        <mat-option *ngFor="let category of exercisesCategories" [value]="category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                    <mat-label>Categoria</mat-label>
                    <mat-error *ngIf="!this.insertExerciseForm.get('category').hasError('required')">Categoria non
                        valida
                    </mat-error>
                    <mat-error *ngIf="this.insertExerciseForm.get('category').hasError('required')">Categoria
                        obbligatoria
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="25">
                    <mat-select formControlName="name" required
                        [disabled]="insertExerciseForm.get('category').value === undefined || insertExerciseForm.get('category').value === ''">
                        <mat-option *ngFor="let exercise of availableExercises" [value]="exercise">
                            {{exercise.name}}
                        </mat-option>
                    </mat-select>
                    <mat-label>Nome</mat-label>
                    <mat-error *ngIf="!this.insertExerciseForm.get('name').hasError('required')">Nome non valido
                    </mat-error>
                    <mat-error *ngIf="this.insertExerciseForm.get('name').hasError('required')">Nome obbligatorio
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="15">
                    <mat-label>Data programmata</mat-label>
                    <input matInput formControlName="scheduledDate" type="date" onkeydown="return false" [max]="'9999-12-31T23:59'" required>
                    <mat-error *ngIf="!this.insertExerciseForm.get('scheduledDate').hasError('required')">Data
                        programmata non valida
                    </mat-error>
                    <mat-error *ngIf="this.insertExerciseForm.get('scheduledDate').hasError('required')">Data
                        programmata obbligatoria
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="8" class="search-field">
                    <mat-label>Ora</mat-label>
                    <input matInput formControlName="scheduledTime" type="time">
                    <mat-error *ngIf="this.insertExerciseForm.get('scheduledTime').hasError('required')">Ora
                        obbligatoria</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="12">
                    <mat-label>Ripetizioni</mat-label>
                    <input matInput type="number" min="1" formControlName="workingTime" required>
                    <mat-error *ngIf="!this.insertExerciseForm.get('workingTime').hasError('required')">Ripetizioni non
                        valide
                    </mat-error>
                    <mat-error *ngIf="this.insertExerciseForm.get('workingTime').hasError('required')">Ripetizioni
                        obbligatorie
                    </mat-error>
                </mat-form-field>
            </form>
            <form [formGroup]="insertExerciseForm" class="inner-form" fxLayout="row"
                fxLayoutAlign="space-between center">
                <button mat-raised-button color="accent" class="search-button" type="submit" aria-label="AGGIUNGI"
                    [disabled]="addExercisePressed" (click)="assignExercise()">
                    AGGIUNGI
                </button>
                <button mat-raised-button color="accent" class="cancel-button" aria-label="ANNULLA"
                    [disabled]="addExercisePressed" (click)="resetExerciseForm()">
                    ANNULLA
                </button>
            </form>
            <div *ngIf="exercisesList.length != 0" class='mat-elevation-z1 tableResponsive margin-table'>
                <table mat-table [dataSource]="exercisesSource" matSort matSortDisableClear="true"
                    class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, exercisesSource)">
                    <ng-container matColumnDef="execOrder">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="execOrder"> Ordine esecuzione</th>
                        <td mat-cell #execOrder isEllipsisActive *matCellDef="let exercise"
                            [ngClass]="exercise.hasError ? 'row-error' : ''"> {{exercise.sequence}} </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="category"> Categoria</th>
                        <td mat-cell #category isEllipsisActive *matCellDef="let exercise; let i = index"
                            [ngClass]="exercise.hasError ? 'row-error' : ''"> {{exercise.category}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Nome
                        </th>
                        <td mat-cell *matCellDef="let exercise" [ngClass]="exercise.hasError ? 'row-error' : ''">
                            {{exercise.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="scheduledDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="scheduledDate"> Data programmata</th>
                        <td mat-cell *matCellDef="let exercise" [ngClass]="exercise.hasError ? 'row-error' : ''">
                            <mat-form-field>
                                <input matInput [ngModel]="exercise.scheduledDate | date:'yyyy-MM-dd'"
                                    (ngModelChange)="updateScheduledDate(exercise, $event)" type="date" onkeydown="return false" 
                                    [disabled]="exercise.executionDate !== null && exercise.executionDate !== undefined">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="scheduledTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="scheduledTime"> Ora</th>
                        <td mat-cell *matCellDef="let exercise" [ngClass]="exercise.hasError ? 'row-error' : ''">
                            <mat-form-field>
                                <input matInput [ngModel]="exercise.scheduledDate | date:'HH:mm'"
                                    (ngModelChange)="updateScheduledTime(exercise, $event)" type="time"
                                    [disabled]="exercise.executionDate !== null && exercise.executionDate !== undefined">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="workingTime">
                        <th mat-header-cell *matHeaderCellDef> Ripetizioni</th>
                        <td mat-cell *matCellDef="let exercise" [ngClass]="exercise.hasError ? 'row-error' : ''">
                            <mat-form-field>
                                <input matInput type="number" min="1" [ngModel]="exercise.scheduledWorkingTime"
                                    (ngModelChange)="exercise.scheduledWorkingTime = $event"
                                    [disabled]="exercise.executionDate !== null && exercise.executionDate !== undefined">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="execDate">
                        <th mat-header-cell *matHeaderCellDef> Data esecuzione - Ora</th>
                        <td mat-cell *matCellDef="let exercise" [ngClass]="exercise.hasError ? 'row-error' : ''">
                            {{this.datePipe.transform(exercise.executionDate, "dd/MM/yyyy (HH:mm)")}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni</th>
                        <td mat-cell *matCellDef="let exercise; let i = index"
                            [ngClass]="exercise.hasError ? 'column-action row-error' : 'column-action'">
                            <div>
                                <mat-icon class="clickable-icon" matTooltip="Elimina esercizio {{exercise.name}}"
                                    matTooltipPosition="above" [matTooltipShowDelay]="500"
                                    matTooltipClass="custom-tooltip" (click)="openDeleteExerciseDialog(exercise, i)"
                                    *ngIf="!(exercise.executionDate !== null && exercise.executionDate !== undefined)">
                                    delete
                                </mat-icon>
                                <mat-icon class="disabled-icon"
                                    *ngIf="exercise.executionDate !== null && exercise.executionDate !== undefined">
                                    delete
                                </mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
                <div *ngIf="exercisesSource === undefined || exercisesSource.data.length === 0"
                    class="no-record mat-elevation-z1"><label>Nessun esercizio assegnato all'utente</label></div>
            </div>
        </div>
    </div>
    <div *ngIf="noUserFound" class="no-record-label mat-elevation-z1"><label>Nessun assistito trovato corrispondente al
            criterio di ricerca inserito</label></div>

    <div class="navigation-button-container" fxLayout="row" *ngIf="userFound">
        <button mat-raised-button color="accent" class="next-button" aria-label="SALVA" (click)="savePRM()">
            SALVA
        </button>
        <button mat-raised-button color="accent" class="reset-button" aria-label="ANNULLA" (click)="resetPRM()">
            ANNULLA
        </button>
    </div>
</div>