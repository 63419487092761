<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div id="reset-password" fxLayout="column">
    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div>
                <img class="logo" src="../../../../assets/addedAsset/Icon/logo.png" alt="logo">
            </div>
            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Nuova password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                    <mat-icon matSuffix *ngIf="!resetPasswordForm.get('password').hasError('pattern')"
                        class="clickable-icon" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-icon matSuffix *ngIf="resetPasswordForm.get('password').hasError('pattern')"
                        class="clickable-icon" color="warn" (click)="hide = !hide"
                        matTooltip="La password deve essere almeno di 8 caratteri e contenere almeno una lettera e un numero"
                        matTooltipPosition="above" [matTooltipShowDelay]="500" matTooltipClass="custom-tooltip">warning
                    </mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
                        Nuova password obbligatorio
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('pattern')">
                        Nuova password non valida
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Conferma password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="passwordConfirm">
                    <mat-icon matSuffix (click)="hide = !hide" class="clickable-icon">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Conferma password obbligatorio
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Attenzione! I campi Nuova Password e Conferma Password devono coincidere

                    </mat-error>
                </mat-form-field>
                <div class="submit_div" fxAlign='row'>
                    <button (click)="resetPassword()" mat-raised-button class="submit-button" color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid">
                        SALVA
                    </button>
                    <button (click)="unDoClick()" mat-raised-button class="submit-button" color="accent"
                        aria-label="ANNULLA">
                        ANNULLA
                    </button>
                </div>
            </form>

        </div>

    </div>

</div>