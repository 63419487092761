<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<div class="background">
    <div class="main-div" fxLayout="column">
        <div class="header" fxLayout="row" fxLayoutAlign="end">
            <mat-label class='header_label'>Home
            </mat-label>
            <mat-label class='header_label'>|
            </mat-label>
            <mat-label class='header_label'>Gestione esercizi
            </mat-label>
            <mat-label class='header_label'>|
            </mat-label>
            <mat-label class='header_label'>Nuovo esercizio
            </mat-label>
        </div>
        <div class="content" fxLayout="column">
            <form fxLayout="column" fxLayoutAlign="start" name="form" [formGroup]="form">
                <label class='titleAdd h2'>Nuovo esercizio</label>
                <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="resizable">
                        <mat-form-field appearance="outline" fxFlex="45">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="name" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error *ngIf="this.form.get('name').hasError('pattern') || this.form.get('name').hasError('maxlength')">Nome non valido</mat-error>
                            <mat-error *ngIf="this.form.get('name').hasError('required')">Nome obbligatorio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="45" class="pl-4">
                            <mat-label>Categoria</mat-label>
                            <mat-select formControlName="category" required>
                                <mat-option *ngFor="let exerciseCategory of exerciseCategories" [value]="exerciseCategory">
                                    {{exerciseCategory}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="this.form.get('category').hasError('required')">Categoria obbligatoria</mat-error>
                            <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                        </mat-form-field>
                        <mat-form-field *ngIf="isHandExerciseSelected()" appearance="outline" fxFlex="10" class="pl-4">
                            <mat-select formControlName="targetHand" required>
                                <mat-option value="Sinistra">Sinistra</mat-option>
                                <mat-option value="Destra">Destra</mat-option>
                            </mat-select>
                            <mat-label>Mano</mat-label>
                            <mat-error *ngIf="!this.form.get('targetHand').hasError('required')">Selezione non valida</mat-error>
                            <mat-error *ngIf="this.form.get('targetHand').hasError('required')">Mano obbligatoria</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                            <mat-label>Descrizione</mat-label>
                            <textarea formControlName="description" matInput required></textarea>
                            <mat-error *ngIf="this.form.get('description').hasError('pattern')">Descrizione non valida</mat-error>
                            <mat-error *ngIf="this.form.get('description').hasError('required')">Descrizione obbligatoria</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="form-upload resizable" fxLayout="row">
                <div fxLayout="row" fxFlex="100" class="Align-center-evenly resizable">
                    <div fxLayout="row" fxFlex='30' class="expand">
                        <label>Movimento ideale</label>
                        <mat-icon *ngIf="!idealMovementURL" (click)="fileInput.click()" matSuffix class="secondary-text clickable-icon">
                            cloud_upload
                        </mat-icon>
                        <mat-icon *ngIf="idealMovementURL" matSuffix class="secondary-text clickable-icon done">
                            cloud_done
                        </mat-icon>
                        <mat-icon *ngIf="idealMovementURL" (click)="openFileRemovalDialog(0)" matSuffix class="secondary-text clickable-icon">
                            delete
                        </mat-icon>
                    </div>
                    <div fxLayout="row" fxFlex='35' class="expand">
                        <label>Video dimostrativo</label>
                        <mat-icon *ngIf="!videoData" (click)="fileInput2.click()" matSuffix class="secondary-text clickable-icon">
                            cloud_upload
                        </mat-icon>
                        <mat-icon *ngIf="videoData" matSuffix class="secondary-text clickable-icon done">
                            cloud_done
                        </mat-icon>
                        <mat-icon *ngIf="videoData" (click)="openFileRemovalDialog(1)" matSuffix class="secondary-text clickable-icon">
                            delete
                        </mat-icon>

                    </div>
                    <div appearance="outline" fxFlex="20" class="padding-left-four expand mb-0">
                        <div fxLayout="row" class="expand mb-0">
                            <div>
                                <label>Icona*</label>
                            </div>
                            <mat-icon *ngIf="!iconData" (click)="fileInput3.click()" matSuffix class="secondary-text clickable-icon">
                                cloud_upload
                            </mat-icon>
                            <mat-icon *ngIf="iconData" matSuffix class="secondary-text clickable-icon done">
                                cloud_done
                            </mat-icon>
                            <mat-icon *ngIf="iconData" (click)="openFileRemovalDialog(2)" matSuffix class="secondary-text clickable-icon">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxFlex='30' class="submit-form">
                    <button (click)="addExercise()" mat-raised-button color="accent" class="submit-button mr-40 mb-20" aria-label="AGGIUNGI"
                            [disabled]="form.invalid || !iconData || addPressed">
                        AGGIUNGI
                    </button>
                    <button [disabled]="addPressed" (click)='unDoClick()' mat-raised-button class="submit-button mb-20" color="accent"
                            aria-label="ANNULLA">
                        ANNULLA
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>



<span class="hidden-file-manager">
    <input type="file" #fileInput [(ngModel)]="idealMovementPath"  (change)="fileChange($event,0)" placeholder="Upload file" accept=".json">
</span>
<span class="hidden-file-manager">
    <input type="file" #fileInput2 [(ngModel)]="videoPath" (change)="fileChange($event,1)" placeholder="Upload file" accept=".mp4,.mpeg,.avi">
</span>
<span class="hidden-file-manager">
    <input type="file" #fileInput3 [(ngModel)]="iconPath" (change)="fileChange($event,2)" placeholder="Upload file" accept=".jpeg,.jpg">
</span>