<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="center center">

            <button mat-button class="user-button" (click)="openHelp()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-12" fxHide fxShow.gt-sm>Guida in linea</span>
                    <mat-icon class="s-36" fxHide.xs>help_outline</mat-icon>
                </div>
            </button>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-12" fxHide fxShow.gt-sm>
                        <ng-container *ngIf="user">
                            {{ user.name ? user.name : '' }}{{ user.name && user.surname ? ' ' : '' }}{{ user.surname ? user.surname : '' }} - {{user
                                && user.role}}
                        </ng-container>
                    </span>
                    <mat-icon class="s-36" fxHide.xs>account_circle</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button [routerLink]="['/login-page']" (click)="logout()" routerLinkActive="router-link-active"
                    mat-menu-item class="">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>


<p-dialog *ngIf="display" header="Guida online" [(visible)]="display" [style]="{width: '90vw'}" [showHeader]="true"
    (contextmenu)="onRightClick($event)">
    <ngx-extended-pdf-viewer src="../../../../assets/online-help/help.pdf" useBrowserLocale="true" [enablePrint]="false"
        [enablePinchOnMobile]="true" [showDownloadButton]="false" [showBookmarkButton]="false" [showFindButton]="true"
        [showHandToolButton]="false" [showPropertiesButton]="false" [showOpenFileButton]="false" height="100%">
    </ngx-extended-pdf-viewer>
</p-dialog>