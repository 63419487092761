import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { EMAIL_REGEX } from "../../common/commons.class";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastManager } from '../../management/utils/toast-manager';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { HttpErrorResponse, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { RoleOperationCheck } from '../util/role-operation-check';
import { Roles } from '../util/roles';
import { Operations } from '../util/operations';
import { environment } from '../../../../environments/environment';
import { StringUtils } from '../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

const SIXTY_DAYS_IN_SECOND = 5184000;
@Component({
    selector: 'login',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginPageComponent extends ToastManager implements OnInit {
    loginForm: FormGroup;
    hide: boolean;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private _fuseNavigationService: FuseNavigationService,
        private _sBar: MatSnackBar,
        private authService: AuthenticationService,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }
    unDoClick(): void {
        this.loginForm.reset();
    }
    ngOnInit(): void {
        this.hide = true;

        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            password: ['', Validators.required]
        });
    }
    login(): void {
        this.spinner.show();
        const body = {
            username: this.loginForm.get('email').value,
            password: this.loginForm.get('password').value
        };
        this.authService.login(body).subscribe((response) => {
            this.spinner.hide();
            sessionStorage.setItem('auth-token', response.headers.get('x-auth-token'));
            if (response.body.user) {
                this.loginControls(response.body.user);
            } else {
                this.showToast('Attenzione! Username e/o password errati', StringUtils.ADVISE_TIME);
            }
        }, (error: HttpErrorResponse) => {
            this.spinner.hide();
            switch (error.status) {
                case 401:
                    this.showToast('Attenzione! Username e/o password errati', StringUtils.ADVISE_TIME);
                    break;
                case 403:
                    this.showToast('L’account è stato sospeso. L’accesso al sistema non è consentito.', StringUtils.ADVISE_TIME);
                    break;
                default:
                    this.showToast('Errore di connessione.', StringUtils.ADVISE_TIME);
                    break;
            }
        });
    }
    loginControls(user): void {
        const updatePasswordDate = new Date(user.updatePasswordDate);
        const currentDate = new Date();
        let expiredPassword = false;
        if (Math.abs(updatePasswordDate.getTime() - currentDate.getTime()) > SIXTY_DAYS_IN_SECOND * 1000) {
            user.resetPassword = true;
            expiredPassword = true;
        }
        sessionStorage.setItem('User', JSON.stringify(user));
        this._fuseNavigationService.updateNavigationItem('users', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.usersManager)))
        });
        this._fuseNavigationService.updateNavigationItem('userAdd', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.newUser)))
        });
        this._fuseNavigationService.updateNavigationItem('userList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listUsers)))
        });
        this._fuseNavigationService.updateNavigationItem('exercises', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.exercisesManager)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseAdd', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.newExercise)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listExercises)))
        });
        this._fuseNavigationService.updateNavigationItem('exerciseExecutionValutation', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.exerciseExecutionRating)))
        });
        this._fuseNavigationService.updateNavigationItem('assisted', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.assistedUsersManager)))
        });
        this._fuseNavigationService.updateNavigationItem('assistedList', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.listAssistedUsers)))
        });
        this._fuseNavigationService.updateNavigationItem('newPRI', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.addPRI)))
        });
        this._fuseNavigationService.updateNavigationItem('newPRM', {
            hidden: !(user && (RoleOperationCheck.checkRoleOperation(user.role, Operations.addPRM)))
        });
        if (user.resetPassword) {
            if (expiredPassword) {
                this.showToast("Attenzione! La password è scaduta. Occorre scegliere una nuova password per l’accesso al sistema.", StringUtils.ADVISE_TIME);
            }
            this.router.navigate(['/change-password-page']);
        } else {
            if(user.role == "Assistito"){
                this.showToast("Attenzione! Gli assistiti non hanno accesso al portale web.", StringUtils.ADVISE_TIME);
            } else{
                this.router.navigate(['/home']);
                sessionStorage.setItem("loginTimestamp", String(new Date().getTime()));
            }
        }
    }
}
