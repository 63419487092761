<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"
></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione assistiti
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Scheda assistito
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>{{userNominative}}
        </mat-label>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Anagrafica
        </div>
        <form class='user-basic-info-container' [formGroup]="assistedUserForm">
            <mat-form-field class="grid-element" appearance="outline">
                <mat-label>Cognome</mat-label>
                <input matInput formControlName="name">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="surname">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Codice fiscale</mat-label>
                <input matInput formControlName="fiscalCode" oninput="this.value = this.value.toUpperCase()">
                <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
            </mat-form-field>
            <div class="grid-phone-numbers-element" fxLayout="column">
                <label class="number-phone-title">Numeri di telefono</label>
                <div *ngFor="let number of phoneNumbers;let i = index" class="number-field"><label>{{number.phoneNumber}}</label></div>
            </div>
            <mat-form-field appearance="outline" class="grid-element">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-icon matSuffix class="secondary-text"> mail_outline</mat-icon>
            </mat-form-field>
            <div class="grid-element"></div>
        </form>
    </div>
    <div class="border-container">
        <div class="h2 div-section-title">
            Farmaci
        </div>
        <mat-accordion [multi]="true">
            <mat-expansion-panel #inUseDrugs [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Farmaci in uso
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="inUseDrugsDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle" (matSortChange)="sortdata($event, inUseDrugsDataSource)">
                        <ng-container matColumnDef="drugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="drugName" class="columns"> Farmaco
                            </th>
                            <td mat-cell isEllipsisActive *matCellDef="let drug"
                                class="columns"> {{drug.drugName.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dose">
                            <th mat-header-cell *matHeaderCellDef class="columns"> Dose</th>
                            <td mat-cell isEllipsisActive *matCellDef="let drug; let i = index"
                                class="columns"> {{drug.dose}} </td>
                        </ng-container>
                        <ng-container matColumnDef="beginDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate" class="date-columns"> Data
                                inizio
                            </th>
                            <td mat-cell *matCellDef="let drug"
                                class="date-columns"> {{this.datePipe.transform(drug.beginDate, "dd/MM/yyyy")}} </td>
                        </ng-container>
                        <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate" class="date-columns"> Data
                                fine
                            </th>
                            <td mat-cell *matCellDef="let drug"
                                class="date-columns"> {{this.datePipe.transform(drug.endDate, "dd/MM/yyyy")}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="inUseDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: inUseDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="inUseDrugsDataSource !== undefined && inUseDrugsDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessun farmaco attualmente in uso</label></div>
                    <mat-paginator #inUsePaginator [pageSizeOptions]="[5, 10, 20,50,100,200]"
                                   showFirstLastButtons></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #drugsHistory [expanded]="false">
                <mat-expansion-panel-header class="history-panel">
                    <mat-panel-title>
                        Storico farmaci
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="filterDiv" fxLayout='column'>
                    <div class="h2 filterTitle">
                        Ricerca farmaco
                    </div>
                    <div class="resizable filter-container" fxLayout="row" fxLayoutAlign="space-between center"
                         fxFlex="1 0 auto" [formGroup]="drugsFilterForm">
                        <mat-form-field appearance="outline" fxFlex="32">
                            <mat-label>Farmaco</mat-label>
                            <input formControlName="drugName" matInput>
                            <mat-error *ngIf="!this.drugsFilterForm.get('drugName').hasError('required')">Farmaco non
                                valido
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="32">
                            <mat-label>Data da</mat-label>
                            <input formControlName="beginDate" type="date" onkeydown="return false" [max]="'9999-12-31'" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="32">
                            <mat-label>Data a</mat-label>
                            <input formControlName="endDate" type="date" onkeydown="return false" [max]="'9999-12-31'" matInput>
                        </mat-form-field>
                    </div>
                    <div fxLayoutAlign="end center">
                        <div class='buttonDiv'>
                            <button (click)='resetFilters()' class='cancel-button' mat-raised-button color="accent"
                                    aria-label="RESET MY PASSWORD">
                                Annulla
                            </button>
                            <button (click)='filterButton()' class='submitButton' mat-raised-button color="accent"
                                    aria-label="RESET MY PASSWORD">
                                Filtra
                            </button>
                        </div>
                    </div>
                </div>
                <div class='mat-elevation-z1 tableResponsive margin-table'>
                    <table mat-table [dataSource]="historyDrugsDataSource" matSort matSortDisableClear="true"
                           class="mat-elevation-z1 tableStyle"
                           (matSortChange)="sortdata($event, historyDrugsDataSource)">
                        <ng-container matColumnDef="drugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="drugName" class="columns"> Farmaco
                            </th>
                            <td mat-cell isEllipsisActive *matCellDef="let drug"
                                class="columns"> {{drug.drugName.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dose">
                            <th mat-header-cell *matHeaderCellDef class="columns"> Dose</th>
                            <td mat-cell isEllipsisActive *matCellDef="let drug; let i = index"
                                class="columns"> {{drug.dose}} </td>
                        </ng-container>
                        <ng-container matColumnDef="beginDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate" class="date-columns"> Data
                                inizio
                            </th>
                            <td mat-cell *matCellDef="let drug"
                                class="date-columns"> {{this.datePipe.transform(drug.beginDate, "dd/MM/yyyy")}} </td>
                        </ng-container>
                        <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate" class="date-columns"> Data
                                fine
                            </th>
                            <td mat-cell *matCellDef="let drug"
                                class="date-columns"> {{this.datePipe.transform(drug.endDate, "dd/MM/yyyy")}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="historyDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;"></tr>

                    </table>
                    <div *ngIf="historyDrugsDataSource !== undefined && historyDrugsDataSource.data.length === 0"
                         class="no-record mat-elevation-z1"><label>Nessun farmaco nello storico</label></div>
                    <div
                        *ngIf="historyDrugsDataSource !== undefined && historyDrugsDataSource.data.length !== 0 && historyDrugsDataSource.filteredData.length === 0"
                        class="no-record mat-elevation-z1"><label>Nessun farmaco trovato corrispondente ai criteri di
                        ricerca inseriti</label></div>
                    <mat-paginator #historyPaginator [pageSizeOptions]="[5, 10, 20,50,100,200]"
                                   showFirstLastButtons></mat-paginator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="panel-buttons-container" fxLayout="row">
        <button mat-raised-button color="accent" class="back-button"
                aria-label="BACK" routerLink="/list-assisted-users">
            INDIETRO
        </button>
    </div>
</div>