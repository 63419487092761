import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PriService } from 'app/main/services/pri/pri.service';
import { Router } from '@angular/router';
import { ToastManager } from '../../utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StringUtils } from 'app/main/common/string-utils';

@Component({
  selector: 'app-confirm-end-pri-dialog',
  templateUrl: './confirm-end-pri-dialog.component.html',
  styleUrls: ['./confirm-end-pri-dialog.component.scss']
})
export class ConfirmEndPriDialogComponent extends ToastManager implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmEndPriDialogComponent>,
    private priService: PriService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data, ) {
    super(snackBar);
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  endPri() {
    this.dialogRef.close();
    this.spinner.show();
    this.priService.endPRI(this.data.body).subscribe((response) => {
      this.spinner.hide();
      if (response.success) {
        this.showToast('PRI concluso con successo', StringUtils.ADVISE_TIME);
        this.router.navigate(['/list-assisted-users']);
      } else {
        this.showToast('Attenzione! Impossibile concludere il PRI', StringUtils.ADVISE_TIME);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.showToast('Attenzione! Impossibile concludere il PRI', StringUtils.ADVISE_TIME);
    });
  }

}
