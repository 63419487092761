import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-remove-evaluation-dialog',
    templateUrl: './remove-followup-dialog.component.html',
    styleUrls: ['./remove-followup-dialog.component.scss']
})
export class RemoveFollowupDialogComponent implements OnInit {

    loggedUser: any;

    constructor(
        public dialogRef: MatDialogRef<RemoveFollowupDialogComponent>,

    ) {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
    }

    ngOnInit(): void {
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }
}
