import {Component, OnDestroy, OnInit} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {myCustomTooltipDefaults} from '../../../common/custom-tooltip';
import {ToastManager} from '../../utils/toast-manager';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {ExerciseService} from '../../../services/exercise/exercise.service';
import {PrmExercise} from '../../../class/interfaces/prm-exercise';
import {MatDialog} from '@angular/material/dialog';
import {PlayExecutionVideoDialogComponent} from '../play-execution-video-dialog/play-execution-video-dialog.component';
import {IdealMovement} from '../../../class/interfaces/ideal-movement';
import {ExecutionMovement} from '../../../class/interfaces/execution-movement';
import {EfficacyDetailsDialogComponent} from '../efficacy-details-dialog/efficacy-details-dialog.component';
import {Location} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {StringUtils} from '../../../common/string-utils';
import { List, trimEnd } from 'lodash';
import { BoneTracksPacks } from 'app/main/models/get-elab-movements-response';

export const REPORT_OK = 200;
export const DUPLICATED_REPORT = 409;

@Component({
    selector: 'exercise-execution-detail',
    templateUrl: './exercise-execution-detail-page.component.html',
    styleUrls: ['./exercise-execution-detail-page.component.scss'],
    providers: [
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}, DatePipe
    ],
})
export class ExerciseExecutionDetailPageComponent extends ToastManager implements OnInit, OnDestroy {

    userNominative: string;
    private userUUC: string;
    private executionId: string;
    private physiotherapistNominative: string;
    loggedUser: any;
    sub: any;
    generalDataForm: FormGroup;
    valutationForm: FormGroup;
    exerciseExecution: PrmExercise;

    //Elab movements
    private movementsTracks: List<BoneTracksPacks> 

    //Raw movements
    private idealMovement: IdealMovement;
    private executionMovement: ExecutionMovement;

    public heartRateChartData: ChartDataSets[];
    public heartRateChartLabels: Label[];
    public heartRateChartOptions: ChartOptions & { annotation: any };
    public lineChartColors: Color[];
    public lineChartLegend;
    public lineChartType;
    private maxVal: number;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        public datePipe: DatePipe,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private location: Location,
        private exerciseService: ExerciseService
    ) {
        super(snackBar);
        this.spinner.show();
    }

    ngOnInit(): void {
        this.sub = this.activatedRoute.queryParams.subscribe(params => {
            this.userNominative = params['userName'];
            this.userUUC = params['userUUC'];
            this.executionId = params['executionId'];
            this.physiotherapistNominative = params['physiotherapistNominative'];
        });
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            this.loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        this.generalDataForm = this._formBuilder.group({
            scheduledDate: [{value: '', disabled: true}, []],
            scheduledWorkingTime: [{value: '', disabled: true}, []],
            executionDate: [{value: '', disabled: true}, []],
            executionBeginTime: [{value: '', disabled: true}, []],
            executionEndTime: [{value: '', disabled: true}, []],
            effectiveWorkingTime: [{value: '', disabled: true}, []]
        });
        this.valutationForm = this._formBuilder.group({
            assistedRating: [{value: 0, disabled: true}, []],
            assistedNote: [{value: '', disabled: true}, []],
            physiotherapistNote: [{value: '', disabled: true}, []],
            physiotherapistRating: [{value: 0, disabled: true}, []],
            physiotherapist: [{value: '', disabled: true}, []],
            efficacy: [{value: 60, disabled: true}, []],
            correctness: [{value: 0, disabled: true}, []]
        });
        this.initExecutionData();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    openVideo() {
        const dialogRef = this.dialog.open(PlayExecutionVideoDialogComponent, {
            data: {
                videoUrl: this.exerciseExecution.executionVideoURL,
                handVideo: this.exerciseExecution.category === "Riabilitazione mano"
            },
            disableClose: true,
        });

    }

    private prepareChart(track: number[]) {
        let heartRateLabels: Label[] = [];
        let heartRateStructure: ChartDataSets = {data: [], label: ''};
        let heartRateDataset: ChartDataSets[] = [];
        this.maxVal = 200;
        for (let heartRateFrame of track) {
            let numericHeartRate = Number(heartRateFrame);
            heartRateLabels.unshift(String(heartRateFrame));
            heartRateStructure.data.push(numericHeartRate);
        }
        this.heartRateChartLabels = heartRateLabels;
        heartRateStructure.label = 'Frequenza cardiaca';

        heartRateDataset.push(heartRateStructure);
        this.heartRateChartData = heartRateDataset;
        this.initChart(track);
    }

    private initChart(track: number[]): void {
        /**** options intialization ****/
        this.heartRateChartOptions = this.setOptions('Tempo (secondi)', 'Frequenza cardiaca (bpm)', track);

        this.lineChartColors = [
            {
                // blue
                backgroundColor: 'rgba(3,155,229,0.2)',
                borderColor: 'rgba(3,155,229,0.8)',
                pointBackgroundColor: 'rgba(3,155,229,0.8)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(3,155,229,0.8)',
            }
        ];
    }

    private setOptions(xLabel: string, yLabel: string, track: number[]): ChartOptions & { annotation: any } {
        let options: ChartOptions & { annotation: any } = {
            elements: {
                line: {tension: 0.1},
                point: {radius: 0}
            },
            responsive: true,
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    },
                    ticks: {
                        callback: function(value, index) {
                            return index;
                        },
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    },
                    id: 'y-axis-1', ticks: {min: 0, max: this.maxVal}
                }]
            },
            annotation: {
                annotations: [],
            },
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                    title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                        return '';
                    },
                    label: function(tooltipItems, data) {
                        return data.datasets[tooltipItems.datasetIndex]['label'] + ': ' + +tooltipItems.value;
                    }
                }
            }
        };
        return options;
    }

    openEfficacyDetail() {
        const dialogRef = this.dialog.open(EfficacyDetailsDialogComponent, {
            data: {
                movementsTracks: this.movementsTracks,
                idealMovement: this.idealMovement,
                executionMovement: this.executionMovement,
                executionTimes: this.exerciseExecution.scheduledWorkingTime,
                elabMovementsMod: true,
                isHandExercise: this.exerciseExecution.category === 'Riabilitazione mano',
                isLeftHand: !this.exerciseExecution.name.includes('(D)')
            },
            disableClose: true,
        });
    }

    initExecutionData() {
        this.exerciseService.getExerciseExecutionDetails(this.executionId).subscribe((response) => {
            this.exerciseExecution = response.exerciseExecution;
            this.initComponentsData();
        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            this.spinner.hide();
        });
    }


    initComponentsData() {
        this.generalDataForm.setValue({
            scheduledDate: this.datePipe.transform(this.exerciseExecution.scheduledDate, 'yyyy-MM-dd'),
            scheduledWorkingTime: "",
            executionDate: this.datePipe.transform(this.exerciseExecution.executionDate, 'yyyy-MM-dd'),
            executionBeginTime: this.datePipe.transform(this.exerciseExecution.executionBeginTime, 'hh:mm:ss'),
            executionEndTime: this.datePipe.transform(this.exerciseExecution.executionEndTime, 'hh:mm:ss'),
            effectiveWorkingTime: this.datePipe.transform(new Date(Number(this.exerciseExecution.effectiveWorkingTime) * 1000), 'mm:ss')
        });
        this.valutationForm.setValue({
            assistedRating: Number(this.exerciseExecution.assistedUserRating),
            assistedNote: this.exerciseExecution.assistedUserNotes,
            physiotherapistNote: this.exerciseExecution.physiotherapistNotes,
            physiotherapistRating: Number(this.exerciseExecution.physiotherapistRating),
            physiotherapist: this.physiotherapistNominative,
            efficacy: Number(this.exerciseExecution.efficacy),
            correctness: Number(this.exerciseExecution.correctness)
        });

        this.loadMovementsData();
    }

    loadMovementsData() {
        this.exerciseService.getExecutionMovement(this.exerciseExecution.movementsURL).subscribe((response) => {
            this.lineChartType = 'line';
            this.lineChartLegend = true;
            this.idealMovement = response.idealMovement;
            this.executionMovement = response.executionMovement;
            if (this.executionMovement.healthParameters.heartRateTrack !== undefined) {
                // @ts-ignore
                this.prepareChart(<number[]> (this.executionMovement.healthParameters.heartRateTrack.track));
            }
            this.generalDataForm.get("scheduledWorkingTime").setValue(
                this.datePipe.transform(new Date((Number(this.exerciseExecution.scheduledWorkingTime)  * this.idealMovement.incidenceTrack.length) / 30 * 1000), 'mm:ss')
            );

            this.exerciseService.getElaboratedMovements(this.exerciseExecution.elaboratedMovementsURL).subscribe((response) => {
                this.movementsTracks = response['elaboratedExecution'].movementsTracks;
            }, error => {
                this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
            }, () => {
                this.spinner.hide();
            });

        }, error => {
            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
        });
    }

    goBack() {
        this.location.back();
    }
}

