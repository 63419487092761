import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { GetExerciseByCategoryResponse } from '../../models/get-exercise-by-category-response';
import { map } from 'rxjs/operators';
import { GetUserResponse } from '../../models/get-user-response';
import { GetAllUsersResponse } from '../../models/get-all-users-response';
import { GetAssistentUsersResponse } from '../../models/get-assistent-users-response';
import { log } from 'util';
import { GetUsersByUucsResponse } from '../../models/get-users-by-uucs-response';
import { Endpoints } from '../../class/endpoints';
import { GetPsychologistUsersResponse } from '../../models/get-psychologists-users-response';
import { GetUsersStatsResponse } from 'app/main/models/get-users-stats-response';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient,
    ) { }

    public getUserByFiscalCode(body: any): Observable<GetUserResponse> {
        return this.http.post<GetUserResponse>(
            Endpoints.DUEVITAB_GET_USER_BY_FISCAL_CODE,
            body
        ).pipe(map(getUserByFiscalCode => {
            return getUserByFiscalCode;
        }));
    }

    public deleteUser(body: any): Observable<any> {
        return this.http.request<any>("DELETE",
            Endpoints.DUEVITAB_USERS_API,
            {
                body: body
            }
        ).pipe(map(deleteUser => {
            return deleteUser;
        }));
    }

    public updateUser(body: any): Observable<GetUserResponse> {
        return this.http.put<GetUserResponse>(
            Endpoints.DUEVITAB_USERS_API,
            body
        ).pipe(map(updateUser => {
            return updateUser;
        }));
    }

    public getAllUsers(): Observable<GetAllUsersResponse> {
        return this.http.get<GetAllUsersResponse>(
            Endpoints.DUEVITAB_USERS_API
        ).pipe(map(getAllUsers => {
            return getAllUsers;
        }));
    }

    public getUsersStats(): Observable<GetUsersStatsResponse> {
        return this.http.get<GetUsersStatsResponse>(
            Endpoints.DUEVITAB_USERS_STATS_API
        ).pipe(map(getUsersStats => {
            return getUsersStats;
        }));
    }

    public insertUser(body: any): Observable<any> {
        return this.http.post<any>(
            Endpoints.DUEVITAB_USERS_API,
            body
        ).pipe(map(insertUser => {
            return insertUser;
        }));
    }

    public getAssistedUsers(uuc: string): Observable<GetAllUsersResponse> {
        return this.http.get<GetAllUsersResponse>(
            Endpoints.DUEVITAB_GET_ASSISTED_USERS_API,
            {
                headers: new HttpHeaders({
                    'uuc': uuc
                })
            }
        ).pipe(map(getAssistedUsers => {
            return getAssistedUsers;
        }));
    }

    public getAssistentsUsers(): Observable<GetAssistentUsersResponse> {
        return this.http.get<GetAssistentUsersResponse>(
            Endpoints.DUEVITAB_GET_AVAILABLE_ASSISTANTS_API
        ).pipe(map(getAssistentsUsers => {
            return getAssistentsUsers;
        }));
    }

    public getPsychiatristUsers(): Observable<GetPsychologistUsersResponse> {
        return this.http.get<GetPsychologistUsersResponse>(
            Endpoints.DUEVITAB_GET_AVAILABLE_PSYCHOLOGISTS_API
        ).pipe(map(getPsychologistsUsers => {
            return getPsychologistsUsers;
        }));
    }

    public getUsersByUuuc(body): Observable<GetUsersByUucsResponse> {
        return this.http.post<GetUsersByUucsResponse>(
            Endpoints.DUEVITAB_GET_USERS_BY_UUCS_API,
            body
        ).pipe(map(getUsersByUuuc => {
            return getUsersByUuuc;
        }));
    }
}
