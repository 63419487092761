import { Injectable } from "@angular/core";
@Injectable()
export class Users {
    numeri = [123567, 456567];

    userData = [
        {
            id: 1,
            name: 'Mario',
            surname: 'Rossi',
            fiscalCode: 'MRORSS19B30P519K',
            tipology: 'Fisioterapista',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        },
        {
            id: 1,
            name: 'Giovanni',
            surname: 'Mosato',
            fiscalCode: 'GVNMSA86G78B567L',
            tipology: 'Psicologo',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        }, {
            id: 1,
            name: 'Giuseppe',
            surname: 'Rossi',
            fiscalCode: 'GSSRSS12L34N789K',
            tipology: 'Fisioterapista',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        }, {
            id: 1,
            name: 'Antonio',
            surname: 'Venezia',
            fiscalCode: 'ANTVNZ12C85U815Q',
            tipology: 'Fisioterapista',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        }, {
            id: 1,
            name: 'Mario',
            surname: 'Salerno',
            fiscalCode: 'MROSLR19B30P519K',
            tipology: 'Amministratore',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        }, {
            id: 1,
            name: 'Paolo',
            surname: 'Rossi',
            fiscalCode: 'MRORSS19B30P519g',
            tipology: 'Fisioterapista',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        }, {
            id: 1,
            name: 'Giovanni',
            surname: 'Paolo',
            fiscalCode: 'GVNPLO19B30P519K',
            tipology: 'Assistito',
            email: 'Mario@Mail.com',
            phoneNumbers: this.numeri,
        },
    ];
    getUserData() {
        return this.userData;
    }
}
