import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-remove-drug-dialog',
    templateUrl: './edit-prm-confirm-dialog.component.html',
    styleUrls: ['./edit-prm-confirm-dialog.component.scss']
})
export class EditPRMDialogComponent implements OnInit {

    loggedUser: any;

    constructor(
        public dialogRef: MatDialogRef<EditPRMDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }

    ngOnInit(): void {
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }
}
