import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'app-remove-team-element-dialog',
    templateUrl: './remove-team-element-dialog.component.html',
    styleUrls: ['./remove-team-element-dialog.component.scss']
})
export class RemoveTeamElementDialog implements OnInit {

    loggedUser: any;

    constructor(
        public dialogRef: MatDialogRef<RemoveTeamElementDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
    }

    ngOnInit(): void {
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }
}
