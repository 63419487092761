import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteFileDialogComponent } from '../delete-file-dialog/delete-file-dialog.component';
import { ExerciseCategory } from 'app/main/class/exercise-category';
import {
    AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX,
    AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX,
    BASE_REGEX,
    TEXT_ONLY_REGEX
} from '../../../common/commons.class';
import { ToastManager } from '../../utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ExerciseService } from '../../../services/exercise/exercise.service';
import { FileService } from '../../../services/file/file.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { StringUtils } from '../../../common/string-utils';

const INSERT_SUCCESFULLY_DIALOG_CODE = 3;
const MAXLENGHT = 60;
const IDEAL_MOVEMENT_TYPE = 0;
const VIDEO_TYPE = 1;
const ICON_TYPE = 2;

@Component({
    selector: 'app-insert-exercise',
    templateUrl: './insert-exercise-page.component.html',
    styleUrls: ['./insert-exercise-page.component.scss'],
    providers: [ExerciseCategory]
})
export class InsertExercisePageComponent extends ToastManager implements OnInit {
    idealMovementURL;
    iconData;
    videoData;
    idealMovementPath;
    iconPath;
    videoPath;
    addPressed = false;
    idealMovementLength: number;
    loggedUser;
    exerciseCategories;
    value;
    form: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private exerciseService: ExerciseService,
        private fileService: FileService,
        private router: Router,
        private dialog: MatDialog,
        private categories: ExerciseCategory,
        private _sBar: MatSnackBar,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        this.initForm();
        this.idealMovementLength = 0;
    }

    initForm(){
        this.form = this._formBuilder.group({
            description: ['', [Validators.required, Validators.pattern(AT_LEAST_ONE_ALPHABETIC_NUMBERS_CHARACTER_REGEX)]],
            name: ['', [Validators.required, Validators.pattern(AT_LEAST_ONE_ALPHABETIC_CHARACTER_REGEX), Validators.maxLength(MAXLENGHT)]],
            category: ['', Validators.required],
            targetHand: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.exerciseCategories = this.categories.getCategories();
        this.exerciseCategories.sort((first, second) => (first.toLowerCase() < second.toLowerCase()) ? -1 : (first.toLowerCase() > second.toLowerCase()) ? 1 : 0);
        this.loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!this.loggedUser) {
            this.router.navigate(['/login-page']);
        }
    }

    addExercise(): void {
        this.addPressed = true;

        const isHandExercise = this.form.get('category').value === 'Riabilitazione mano';

        const bodyData = {
            category: this.form.get('category').value,
            description: this.form.get('description').value,
            icon: this.iconData ? this.iconData.fileDownloadURL : null,
            idealMovement: this.idealMovementURL ? this.idealMovementURL.fileDownloadURL : null,
            idealMovementLength: this.idealMovementLength,
            name: isHandExercise ? this.form.get('name').value + this.getTargetHandLabel() : this.form.get('name').value,
            video: this.videoData ? this.videoData.fileDownloadURL : null
        };
        this.spinner.show();
        this.exerciseService.insertExercise(bodyData).subscribe(() => {
            this.spinner.hide();
            this.showToast('Esercizio inserito con successo', StringUtils.ADVISE_TIME);
            this.router.navigate(['/list-exercises-page']);
        }, error => {
            this.spinner.hide();
            this.showToast('Attenzione! Esercizio già presente', StringUtils.ADVISE_TIME);
            this.addPressed = false;
        });
    }

    getTargetHandLabel(){
        return ' (' + this.form.get('targetHand').value[0] + ')';
    }

    openFileRemovalDialog(type) {
        const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
            width: '350px',
            data: {
                type,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            this.deleteFile(result);
        });
    }

    public deleteFile(type) {
        let bodyData = {};
        switch (type) {
            case IDEAL_MOVEMENT_TYPE:
                bodyData = {
                    name: this.idealMovementURL.fileDownloadURL.split('/').pop(),
                    fileType: 'excercises/idealMovements',

                };
                this.spinner.show();
                this.fileService.deleteFile(bodyData).subscribe(() => {
                    this.spinner.hide();
                    this.idealMovementURL = null;
                    this.idealMovementPath = null;
                });

                break;
            case VIDEO_TYPE:
                bodyData = {
                    name: this.videoData.fileDownloadURL.split('/').pop(),
                    fileType: 'excercises/videos',

                };
                this.spinner.show();
                this.fileService.deleteFile(bodyData).subscribe((data) => {
                    this.spinner.hide();
                    this.videoData = null;
                    this.videoPath = null;
                });
                break;
            case ICON_TYPE:
                bodyData = {
                    name: this.iconData.fileDownloadURL.split('/').pop(),
                    fileType: 'excercises/icons',

                };
                this.spinner.show();
                this.fileService.deleteFile(bodyData).subscribe((data) => {
                    this.spinner.hide();
                    this.iconData = null;
                    this.iconPath = null;
                });
                break;
            default:
                break;
        }
    }

    fileChange(event, type): void {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            let uploadedFile: any;
            let blob = file.slice(0, file.size, file.type);
            uploadedFile = new File([blob], Math.floor(Date.now() / 1000) + '.' + file.name.split('.').pop(), { type: file.type });
            const formData: FormData = new FormData();
            formData.append('file', uploadedFile, uploadedFile.name);
            switch (type) {
                case IDEAL_MOVEMENT_TYPE:
                    if (event.target.files[0].type !== 'application/json') {
                        this.showToast('Attenzione! File non valido.', StringUtils.ADVISE_TIME);
                        return;
                    }
                    formData.append('fileType', 'excercises/idealMovements');
                    break;
                case VIDEO_TYPE:
                    if (event.target.files[0].type !== 'video/mp4' && event.target.files[0].type !== 'video/avi' && event.target.files[0].type !== 'video/mpeg') {
                        this.showToast('Attenzione! File non valido.', StringUtils.ADVISE_TIME);
                        return;
                    }
                    formData.append('fileType', 'excercises/videos');
                    break;
                case ICON_TYPE:
                    if (event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/jpg' && event.target.files[0].type !== 'image/png') {
                        this.showToast('Attenzione! File non valido.', StringUtils.ADVISE_TIME);
                        return;
                    }
                    formData.append('fileType', 'excercises/icons');
                    break;
                default:
                    break;
            }
            formData.append('authUuc', this.loggedUser.uuc);
            this.spinner.show();
            this.fileService.uploadFile(formData)
                .subscribe(
                    data => {
                        this.spinner.hide();
                        switch (type) {
                            case IDEAL_MOVEMENT_TYPE:
                                this.idealMovementURL = data;
                                this.idealMovementLength = data.idealMovementLength;
                                break;
                            case VIDEO_TYPE:
                                this.videoData = data;
                                break;
                            case ICON_TYPE:
                                this.iconData = data;
                                break;
                            default:
                                break;
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.spinner.hide();
                        if (type === 0 && error.status === 409) {
                            this.showToast("Attenzione! Movimento ideale corrotto.", StringUtils.ADVISE_TIME);
                        } else {
                            this.showToast(StringUtils.CONNECTION_ERROR_MESSAGE, StringUtils.ADVISE_TIME);
                        }
                    }
                );
        }
    }

    unDoClick(): void {
        this.initForm();
        this.resetFiles();
    }

    resetFiles() {
        if (this.idealMovementURL !== undefined) {
            this.deleteFile(IDEAL_MOVEMENT_TYPE);
        }
        if (this.videoData !== undefined) {
            this.deleteFile(VIDEO_TYPE);
        }
        if (this.iconData !== undefined) {
            this.deleteFile(ICON_TYPE);
        }
    }

    isHandExerciseSelected(){
        if (this.form.get('category').value !== 'Riabilitazione mano') {
            this.form.get('targetHand').disable();
            return false;
        } else {
            this.form.get('targetHand').enable();
            return true;
        }
    }
}
