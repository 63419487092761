import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, Validators } from '@angular/forms';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Users } from '../../../class/users';
import { Router } from '@angular/router';
import { FISCAL_CODE_REGEX } from '../../../common/commons.class';
import { ToastManager } from '../../utils/toast-manager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../../class/interfaces/user';

import { myCustomTooltipDefaults } from '../../../common/custom-tooltip';
import { UserService } from '../../../services/users/user.service';
import { Roles } from '../../../authentication/util/roles';
import { StringUtils } from '../../../common/string-utils';
import { NgxSpinnerService } from 'ngx-spinner';

const REMOVE_DIALOG_CODE = 2;
const NUMBER_MAX_OF_RECORDS = 10;
const FILTER_REGEX = '^[a-zA-ZA-Za-zÀ-ÖØ-öø-ÿ\\s\'-]+$';

@Component({
    selector: 'app-view-users',
    templateUrl: './list-users-page.component.html',
    styleUrls: ['./list-users-page.component.scss'],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }, Users
    ],
})
export class ListUsersPageComponent extends ToastManager implements OnInit {

    private loggedUser: any;


    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.matSortValue = ms;
        if (this.dataSource) {
            this.dataSource.sort = ms;
        }
    }

    @ViewChild('surname') surname: ElementRef;

    @ViewChild(MatPaginator) set paginator(pg: MatPaginator) {
        this.paginatorValue = pg;
        if (this.dataSource) {
            this.dataSource.paginator = pg;
        }
    }

    matSortValue;
    displayedColumns = ['surname', 'name', 'fiscalCode', 'role', 'actions'];
    activeUucs: string[];
    userData: User[];
    allUserData: User[];
    paginatorValue;
    dataSource: MatTableDataSource<any>;
    nameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    surnameFilter = new FormControl('', Validators.pattern(FILTER_REGEX));
    fiscalCodeFilter = new FormControl('', Validators.pattern(FISCAL_CODE_REGEX));
    roleFilter = new FormControl('');
    sort;
    pageNumber = 0;
    lastPage;
    roles: string[];
    filterValues = {
        name: '',
        surname: '',
        fiscalCode: '',
        role: ''
    };

    constructor(
        private dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private users: Users,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private _sBar: MatSnackBar,
        private userService: UserService,
        private spinner: NgxSpinnerService
    ) {
        super(_sBar);
        this.spinner.show();
        this.roles = Roles.roles();
    }

    isLoggedAdmin(user) {
        return this.loggedUser.uuc == user.uuc;
    }

    openDialog(UserData, type): void {
        const dialogRef = this.dialog.open(UserDialogComponent, {
            data: {
                view: type,
                user: UserData,
                userNumbers: UserData.phoneNumbers || [],

            },
            disableClose: true,

        });
    }

    getFormValidation(): boolean {
        return this.nameFilter.valid && this.surnameFilter.valid && this.fiscalCodeFilter.valid;
    }

    resetFilters(): void {
        this.nameFilter.setValue('');
        this.surnameFilter.setValue('');
        this.fiscalCodeFilter.setValue('');
        this.roleFilter.setValue('');
        this.filter();
    }

    setDataSourceAttributes(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        const loggedUser = JSON.parse(sessionStorage.getItem('User'));
        if (!loggedUser) {
            this.router.navigate(['/login-page']);
        } else {
            loggedUser.resetPassword && this.router.navigate(['/change-password-page']);
        }
        this.loggedUser = loggedUser;
        this.userService.getAllUsers().subscribe((response) => {
            this.activeUucs = response.activeUucs;
            this.allUserData = response.users;
            this.allUserData.sort((a, b) => {
                // Handle null values by placing them at the end
                if (a.surname === null && b.surname === null) {
                    return 0;
                }
                if (a.surname === null) {
                    return 1; // Place null at the end
                }
                if (b.surname === null) {
                    return -1; // Place null at the end
                }

                // Compare non-null values using localeCompare
                return a.surname.localeCompare(b.surname);
            });

            this.userData = this.allUserData;
            this.dataSource = new MatTableDataSource(this.userData);
            this.cdr.detectChanges();
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
            this.dataSource.paginator = this.paginatorValue;
            this.lastPage = (this.allUserData.length / NUMBER_MAX_OF_RECORDS);
            this.spinner.hide();
        });
    }

    setDataSource(): void {
        this.dataSource = new MatTableDataSource(this.userData);
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        this.dataSource.sort = this.matSortValue;
        this.dataSource.paginator = this.paginatorValue;
    }

    changeTablePage(i): void {
        this.pageNumber += i;
        this.userData = this.allUserData;
        this.setDataSource();
    }

    filterButton(): void {
        if (!this.nameFilter.value && !this.surnameFilter.value && !this.fiscalCodeFilter.value && !this.roleFilter.value) {
            this.showToast(StringUtils.EMPTY_FILTERS_FIELDS_MESSAGE, StringUtils.ADVISE_TIME);
        } else {
            this.filter();
        }
    }

    filter(): void {

        this.filterValues = {
            name: '',
            surname: '',
            fiscalCode: '',
            role: ''
        };
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.filterValues.name = this.nameFilter.value.toLowerCase();
        this.filterValues.surname = this.surnameFilter.value.toLowerCase();
        this.filterValues.fiscalCode = this.fiscalCodeFilter.value.toLowerCase();
        this.filterValues.role = this.roleFilter.value.toLowerCase();
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    sortdata(sort: Sort): void {
        const data = this.dataSource.data;
        if (!sort.active || sort.direction === '') {
            this.userData = data;
            return;
        }
        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.compare(a.name, b.name, isAsc);
                case 'surname':
                    return this.compare(a.surname, b.surname, isAsc);
                case 'fiscalCode':
                    return this.compare(a.fiscalCode, b.fiscalCode, isAsc);
                case 'role':
                    return this.compare(a.role, b.role, isAsc);
                default:
                    return 0;
            }
        });
        this.paginatorValue.firstPage();
        this.dataSource.paginator = this.paginatorValue;
    }

    // tslint:disable-next-line:typedef
    compare(a: string, b: string, isAsc: boolean) {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }

    createFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function(data, filter): boolean {
          const searchTerms = JSON.parse(filter);
      
          const nameMatch = searchTerms.name
            ? data.name.toLowerCase().includes(searchTerms.name)
            : true;
      
          const surnameMatch = searchTerms.surname
            ? (data.surname && data.surname.toLowerCase().includes(searchTerms.surname))
            : true;
      
          const fiscalCodeMatch = searchTerms.fiscalCode
            ? data.fiscalCode.toLowerCase().includes(searchTerms.fiscalCode)
            : true;
      
          const roleMatch = searchTerms.role
            ? data.role.toLowerCase().includes(searchTerms.role)
            : true;
      
          return nameMatch && surnameMatch && fiscalCodeMatch && roleMatch;
        };
      
        return filterFunction;
      }
      
    checkActivePRI(user: User) {
        user.hasSomeActiveOperations = this.activeUucs.some((element) => {
            return element === user.uuc;
        });
        return user.hasSomeActiveOperations;
    }
}

