<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"
></ngx-spinner>
<div class='background main-div' fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="end">
        <mat-label class='header_label'>Home
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Gestione esercizi
        </mat-label>
        <mat-label class='header_label'>|
        </mat-label>
        <mat-label class='header_label'>Valutazione esercizi
        </mat-label>
    </div>
    <div class="filterDiv">
        <div class="h2 filterTitle">Esercizi da valutare</div>
        <div class='mat-elevation-z1 tableResponsive'>
            <table *ngIf="exerciseData" mat-table [dataSource]="exerciseData"
                   matSort (matSortChange)="sortdata($event, exerciseData)" matSortDisableClear="true" class="mat-elevation-z1 tableStyle">
                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="surname"> Cognome </th>
                    <td mat-cell *matCellDef="let exerciseExecutions" isEllipsisActive> {{exerciseExecutions.user.surname}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Nome </th>
                    <td mat-cell *matCellDef="let exerciseExecutions" isEllipsisActive> {{exerciseExecutions.user.name}} </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th class='category-column' mat-header-cell mat-sort-header="category" *matHeaderCellDef class="column-category"> Categoria
                    </th>
                    <td mat-cell *matCellDef="let exerciseExecution" class="column-category-content"> {{exerciseExecution.exercise.category}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="exerciseName">
                    <th class='category-column' mat-header-cell mat-sort-header="exerciseName" *matHeaderCellDef class="column-category"> Esercizio
                    </th>
                    <td mat-cell *matCellDef="let exerciseExecution" class="column-category-content"> {{exerciseExecution.exercise.name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="executionDate">
                    <th class='category-column' mat-header-cell mat-sort-header="executionDate" *matHeaderCellDef class="column-category"> Data esecuzione
                    </th>
                    <td mat-cell *matCellDef="let exerciseExecution" class="column-category-content"> {{this.datePipe.transform(exerciseExecution.executionDate, "dd/MM/yyyy")}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="column-action"> Azioni </th>
                    <td mat-cell *matCellDef="let exerciseExecution" class="column-action-content">
                        <div>
                            <mat-icon class="clickable-icon" matTooltip="Valuta esercizio {{exerciseExecution.exercise.name}}  "
                                      (click)="openDialog(exerciseExecution)" matTooltipPosition="above" [matTooltipShowDelay]="500"
                                      matTooltipClass="custom-tooltip">rate_review</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="exerciseData.data.length === 0" class="no-record mat-elevation-z1"><label>Nessun esercizio presente.</label></div>
            <div><mat-paginator #pag [pageSizeOptions]="[5, 10, 20,50,100,200]" showFirstLastButtons></mat-paginator></div>

        </div>
    </div>
</div>