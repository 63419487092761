<div class="background" fxLayout="row">
    <div *ngIf='data.view==1 || data.view == 0' class="main-div" fxLayout="column">
        <div class="header mt-0" fxLayout="row" fxLayoutAlign="center">
            <mat-label *ngIf="data.view === 1" class='header_label'>Modifica Utente
            </mat-label>
            <mat-label *ngIf="data.view === 0" class='header_label'>Dettaglio Utente
            </mat-label>
        </div>
        <div fxLayout="column">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form" [formGroup]="form">
                <div fxLayout="row" class="resizable" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="wrapper" appearance="outline" fxFlex="50" matTooltip="{{this.form.get('surname').value}} "
                                    matTooltipPosition="above" [matTooltipShowDelay]="500"
                                    matTooltipClass="custom-tooltip">
                        <mat-label>Cognome</mat-label>
                        <input matInput formControlName="surname" required>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="!this.form.get('surname').hasError('required')">Cognome non valido
                        </mat-error>
                        <mat-error *ngIf="this.form.get('surname').hasError('required')">Cognome obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four" matTooltip="{{this.form.get('name').value}} "
                                    matTooltipPosition="above" [matTooltipShowDelay]="500"
                                    matTooltipClass="custom-tooltip">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" required>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error *ngIf="!this.form.get('name').hasError('required')">Nome non valido</mat-error>
                        <mat-error *ngIf="this.form.get('name').hasError('required')">Nome obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four">
                        <mat-label>Codice fiscale</mat-label>
                        <input matInput formControlName="fiscalCode" oninput="this.value = this.value.toUpperCase()" required>
                        <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
                        <mat-error *ngIf="!this.form.get('fiscalCode').hasError('required')">Codice fiscale non valido
                        </mat-error>
                        <mat-error *ngIf="this.form.get('fiscalCode').hasError('required')">Codice fiscale obbligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" class="reverse" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div *ngIf="data.view === 1" fxFlex='50' fxLayout="row">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Numero di telefono</mat-label>
                            <input formControlName="number" matInput>
                            <mat-error>Numero di telefono non valido</mat-error>
                        </mat-form-field>
                        <mat-icon (click)='addNumber()' matSuffix class="secondary-text clickable-icon minusIcon">
                            add_circle_outline</mat-icon>
                    </div>
                    <div class="number-view-block" *ngIf="data.view === 0" fxFlex='50' fxLayout="column">
                        <label *ngIf="parsedNumber.length > 0" class="number-phone-title">Numeri di telefono</label>
                        <div *ngFor="let number of parsedNumber;let i = index" class="number-field"><label>{{number}}</label></div>
                        <div *ngIf="parsedNumber.length <= 0" class="number-field font-16"><label>Numeri di telefono</label></div>
                    </div>

                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four expand">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required>
                        <mat-icon matSuffix class="secondary-text"> mail_outline</mat-icon>
                        <mat-error *ngIf="!this.form.get('email').hasError('required')">Email non valida</mat-error>
                        <mat-error *ngIf="this.form.get('email').hasError('required')">Email obbligatoria</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="padding-left-four expand">
                        <mat-label>Ruolo</mat-label>
                        <mat-select formControlName="role" required>
                            <mat-option *ngFor="let role of roles" [value]="role">
                                {{role}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">work_outline</mat-icon>
                    </mat-form-field>
                </div>
            </form>
            <div *ngIf="data.view === 1" fxLayout='row' class="resizable numberContainer">
                <div class='number expand' fxLayout="column" fxLayoutAlign='top end'>
                    <div class='numberminus' *ngFor="let number of parsedNumber;let i = index">
                        <label class='numberLabel'>{{number}}</label>
                        <mat-icon *ngIf="data.view === 1" (click)='openPhoneRemovalDialog(number,i)' matSuffix
                                  class="secondary-text minusIcon clickable-icon">
                            delete
                        </mat-icon>
                    </div>
                </div>
                <div class='buttons-container expand' fxLayout="column" fxLayoutAlign='top end'>
                    <div class="submit_div resizable expand" fxLayout='row'>
                        <button *ngIf="data.view === 1" (click)='modifyUser()' mat-raised-button color="accent"
                                class="submit-button dialog-submit-button expand" aria-label="AGGIUNGI" [disabled]="form.invalid">
                            MODIFICA
                        </button>
                        <button *ngIf="data.view === 1" (click)='closeDialog()' mat-raised-button color="accent"
                                class="submit-button expand" aria-label="ANNULLA">
                            ANNULLA
                        </button>

                    </div>
                </div>
            </div>
            <button *ngIf="data.view === 0" (click)='closeDialog()' mat-raised-button color="accent"
                    class="submit-button expand exit" aria-label="ESCI">
                ESCI
            </button>
        </div>
    </div>
    <div *ngIf='data.view==2' fxLayout='column'>
        <div class="header mt-0 delete" fxLayout="row" fxLayoutAlign="center">
            <mat-label class='header_label'>ATTENZIONE!
            </mat-label>
        </div>
        <div class='Align-center' fxLayout='row'>
            <label class="text-center">Sei sicuro di voler eliminare l'utente
                {{data.user.surname + " " + data.user.name}}?</label>
        </div>
        <div fxLayout='row-reverse'>
            <button (click)='closeDialog()' mat-raised-button color="accent" class="delete-submit-button"
                    aria-label="ESCI">
                NO
            </button>
            <button (click)='deleteUser()' mat-raised-button color="accent" class="delete-submit-button"
                    aria-label="ESCI">
                SI
            </button>
        </div>
    </div>
</div>